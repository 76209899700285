import React, { useState, useEffect } from "react";
import Notice from "../../../partials/content/Notice";
import CodeExample from "../../../partials/content/CodeExample";
import { Table, Button } from "react-bootstrap";
import axios from "axios";
import { API } from "../../../../const";
import $ from "jquery";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import localeInfo from "rc-pagination/lib/locale/en_US";
import "rc-pagination/assets/index.css";
import LoadingOverlay from "react-loading-overlay";
import { RiseLoader } from "react-spinners";
const UsersList = props => {
  const [data, setData] = useState([]);
  const [showLoading, setShowLoading] = useState(true);

  const [currdata, setcurrData] = useState([]);
  const [count, setcount] = useState(0);
  const [current, setcurrent] = useState(1);
  const [formlist, setformData] = useState([]);
  const [formlist1, setformData1] = useState([]);
  // const apiUrlall = API + "api/user_getby_clientidall/"+props.match.params.id;
  // const apiUrl = API + "api/user_getby_clientid/"+props.match.params.id;

   var apiUrl = API + "api/user_getby_clientid/";
   var apiurlall = API + "api/user_getby_clientidall"

  useEffect(() => {
    const fetchData = async () => {
      if (localStorage.getItem("client_id") === "000") {
        var postData = {
          id: props.match.params.id
        };
      } else {
        postData = {
          id: localStorage.getItem("client_id")
        };
      }
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token")
        }
      };
      axios.post(apiurlall, postData, axiosConfig).then(result => {
        setData(result.data);
        let count = Object.keys(result.data).length;
        setcount(count);
        setShowLoading(false);
      });
    };

    fetchData();
  }, []);

  const loadData = async (offsetS, current) => {
    var apiUrlall = apiUrl;
    var off1 = offsetS;
    var off = offsetS;

    var curval;
    if (!current) {
      curval = 1;
    } else {
      curval = current;
    }
    setcurrent(curval);
    if (localStorage.getItem("client_id") === "000") {
      $.ajax({
        url: apiUrlall,
        data: {
          limit: 20,
          offset: off1,
          id: props.match.params.id,
          company_name: formlist.company_name,
          location_name: formlist1.location_name
        },

        dataType: "json",
        type: "POST",
        headers: {
          Authorization: localStorage.getItem("token")
        },

        success: data => {
          setcurrData(data);
          setShowLoading(false);

        },

        error: (xhr, status, err) => {
          console.error(this.props.url, status, err.toString()); // eslint-disable-line
        }
      });
    } else {
      $.ajax({
        url: apiUrlall,
        data: {
          limit: 20,
          offset: off,
          id: localStorage.getItem("client_id"),
          company_name: formlist.company_name,
          location_name: formlist1.location_name
        },

        dataType: "json",
        type: "POST",
        headers: {
          Authorization: localStorage.getItem("token")
        },

        success: data => {
          setcurrData(data);
          setShowLoading(false);

        },

        error: (xhr, status, err) => {
          console.error(this.props.url, status, err.toString()); // eslint-disable-line
        }
      });
    }
  };
  const onKeyUp = e => {
    const filterd = data.filter(item =>
      item.email.toLowerCase().includes(e.target.value.toLowerCase())
    );

    if (e.target.value) {
      setcurrData(filterd);
    } else {
      loadData();
    }
  };

  useEffect(() => {
    loadData();
  }, [data]);

  const editProduct = id => {
    props.history.push({
      pathname: "/client-users-edit/" + id
    });
  };

  const addusers = () => {
    props.history.push({
      pathname: "/useradd"
    });
  };

  const handlePageClick = data => {
    let offsetS = Math.ceil(data * 20 - 20);
    loadData(offsetS, data);
  };

  const handleChange = (id, status) => {
    var setstatus;
    if (status === "Enabled") {
      setstatus = "Disabled";
    } else {
      setstatus = "Enabled";
    }
    var postData = {
      id: id,
      mystatus: setstatus
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token")
      }
    };
    const api = API + "api/user_status";

    axios
      .post(api, postData, axiosConfig)
      .then(res => {
        alert(res.data.message);
        props.history.push({
          pathname: "/clientwiseuser"
        });
      })
      .catch(err => {
        //console.log("AXIOS ERROR: ", err);
      });
  };

  const edituser = id => {
    props.history.push({
      pathname: "/client-users-edit/" + id
    });
  };

  const deleteuser = (id, idx) => {
    var postData = {
      id: id
    };
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token")
      }
    };
    const apiurldelete = API + "api/user_delete/";
    axios
      .post(apiurldelete, postData, axiosConfig)
      .then(result => {
        alert("User Deleted Successfully");
        var updatedData = [...data];
        updatedData.splice(idx, 1);
        setData(updatedData);
        props.history.push("/clientwiseuser");
      })
      .catch(error => "");
  };
  const resetemaildata = async () => {
    window.location.reload();
  };
  const handleChange1 = event => {
    setformData({ ...formlist, [event.target.name]: event.target.value });
  };

  const handleChange12 = event => {
    setformData1({ ...formlist1, [event.target.name]: event.target.value });
  };

  const getemaildata = async () => {
    if (localStorage.getItem("client_id") === "000") {
      var postData = {
        id: props.match.params.id,
        company_name: formlist.company_name,
        location_name: formlist1.location_name,
        limit: 20
      };
    } else {
      postData = {
        id: localStorage.getItem("client_id"),
        company_name: formlist.company_name,
        location_name: formlist1.location_name,
        limit: 20
      };
    }
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token")
      }
    };
    setShowLoading(true);

    axios.post(apiUrl, postData, axiosConfig).then(result => {
      setData(result.data);
      let count = Object.keys(result.data).length;
      setcount(count);

      loadData();
    });
  };

  return (
    <LoadingOverlay
      active={showLoading}
      spinner={<RiseLoader sizeUnit={"px"} size={80} color={"#2a8dd1"} />}
    >
      <>
        <div className="row">
          <div className="col-md-12">
            <CodeExample beforeCodeTitle="Users List">
              <div className="kt-section">
                {props.match.params.id ? (
                  ""
                ) : (
                  <Button
                    variant="primary"
                    className="pull-right"
                    onClick={() => {
                      addusers();
                    }}
                  >
                    {" "}
                    Add Users
                  </Button>
                )}

                <div className="row">
                  <div className="col-6 col-md-4">
                    {" "}
                    <input
                      className="form-control col-md-12"
                      id="company_name"
                      name="company_name"
                      onChange={handleChange1}
                    />
                    <label>Company Name</label>
                  </div>
                  <div className="col-6 col-md-4">
                    {" "}
                    <input
                      className="form-control col-md-12"
                      id="location_name"
                      name="location_name"
                      onChange={handleChange12}
                    />
                    <label>User Name</label>
                  </div>

                  <div className="col-6 col-md-2">
                    <button
                      onClick={() => getemaildata()}
                      className=" form-control col-md-12 btn btn-success"
                    >
                      Search
                    </button>
                  </div>
                  <div className="col-6 col-md-2">
                    <button
                      onClick={() => resetemaildata()}
                      className=" form-control col-md-12 btn btn-warning"
                    >
                      Reset
                    </button>
                  </div>
                </div>

                <div className="kt-separator kt-separator--dashed responsive"></div>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Sr. No.</th>
                      <th>Company Name</th>
                      <th>User Name</th>
                      <th>Email</th>
                      <th>User Status </th>
                      <th>Location Name </th>

                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currdata.map((item, idx) => (
                      <tr key={idx}>
                        <td>{idx + 1 + (current - 1) * 20}</td>

                        <td>{item.cp_client && item.cp_client.company_name}</td>
                        <td>{item.name}</td>
                        <td>{item.email}</td>
                        <td>
                          <select
                            className="form-control wid50"
                            id="status"
                            onChange={() =>
                              handleChange(item.id, item.mystatus)
                            }
                          >
                            <option
                              selected={item.mystatus === "Enabled"}
                              value="Enabled"
                            >
                              Enabled
                            </option>
                            <option
                              selected={item.mystatus === "Disabled"}
                              value="Disabled"
                            >
                              Disabled
                            </option>
                          </select>
                        </td>
                        <td>{item.locationNames}</td>
                        <td>
                          <i
                            onClick={() => {
                              edituser(item.id);
                            }}
                            className="flaticon2-pen"
                          ></i>{" "}
                          /
                          <i
                            onClick={e =>
                              window.confirm(
                                "Are you sure you wish to delete this item?"
                              ) && deleteuser(item.id, idx)
                            }
                            className="flaticon-delete-1"
                          ></i>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Pagination
                  showQuickJumper
                  showSizeChanger
                  onChange={handlePageClick}
                  current={current}
                  total={count}
                  pageSize={20}
                  locale={localeInfo}
                />
              </div>
            </CodeExample>
          </div>
        </div>
      </>
    </LoadingOverlay>
  );
};
export default UsersList;
