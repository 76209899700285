import React, { useState, useEffect } from "react";
import axios from "axios";
import "rc-pagination/assets/index.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API } from "../../../../const";
import $ from "jquery";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import localeInfo from "rc-pagination/lib/locale/en_US";
import Moment from "react-moment";
var moment = require("moment");
toast.configure();

export default function CompanyList(props) {
  const [data1, setData1] = useState([]);

  const [data, setData] = useState({
    users: 0,
    sitelink: 0,
    client: 0,
    location: 0,
  });
  const [clientschart, setclientschart] = useState([]);
  const [clicksdata, setClicksData] = useState([]);
  const [reviewdata, setReviewData] = useState([]);
  const [allreviewdata, setallReviewData] = useState([]);

  const [clinetlist, setclientData] = useState([]);
  const [locationlist, setlocationData] = useState([]);
  const [locations, setlocations] = useState([]);
  const [count, setcount] = useState(0);
  const [formlist, setformData] = useState([]);
  const [formlist1, setformData1] = useState([]);
  const [formlist2, setformData2] = useState([]);
  const [formlist3, setformData3] = useState([]);
  const [currdata, setcurrData] = useState([]);
  const [current, setcurrent] = useState(1);
  const [count1, setcount1] = useState(0);

  var sitlinkurlall = API + "api/getlinkalldata/";
  var siteurlall = API + "api/listTestallrev";

  const apiUrl = API + "api/dashboard/" + localStorage.getItem("client_id");
  const apiUrlreviews = API + "api/review_all/";

  var clientschartdata = [];
  var reviewchartdata = [];
  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(apiUrl, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });

      setData(result.data);
    };

    fetchData();
    const fetchData2 = async () => {
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      };
      var postData = {
        client_id: localStorage.getItem("client_id"),
      };
      axios
        .post(apiUrlreviews, postData, axiosConfig)
        .then((res) => {
          setallReviewData(res.data);
          let count = Object.keys(res.data).length;
          setcount1(count);
        })
        .catch((err) => {
          //console.log("AXIOS ERROR: ", err);
        });
    };

    const fetchData123 = async () => {
      if (localStorage.getItem("user_type") === "employee") {
        var postData1 = {
          user_id: localStorage.getItem("id"),
          location_id: formlist1.location_name,
          user_type: localStorage.getItem("user_type"),
          user: localStorage.getItem("client_name"),
          client_id: localStorage.getItem("client_id"),
        };
      } else {
        postData1 = {
          user_id: formlist.user_id,
          location_id: formlist1.location_name,
          user_type: localStorage.getItem("user_type"),
          user: localStorage.getItem("client_name"),
          client_id: localStorage.getItem("client_id"),
        };
      }

      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      };

      axios
        .post(siteurlall, postData1, axiosConfig)
        .then((res) => {
          setData1(res.data);
          let count = Object.keys(res.data).length;
          //console.log(count);
          setcount(count);
        })
        .catch((err) => {
          //console.log("AXIOS ERROR: ", err);
        });
    };

    fetchData123();
    fetchData();
    fetchData2();
  }, []);
  let countClick = 0;
  const resend = async (id) => {
    if (countClick == 0) {
      countClick++;

      var resendapi = API + "api/resend_link/";

      var postData1 = {
        id: id,
      };

      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      };

      axios
        .post(resendapi, postData1, axiosConfig)
        .then((res) => {
          //console.log(res);

          toast(res.data.message);
          window.location.reload();
        })
        .catch((err) => {
          //console.log("AXIOS ERROR: ", err);
        });
      // code here
    } else {
      return false;
      // or evt.preventDefault();
    }
  };

  useEffect(() => {
    const fetchData121 = async () => {
      const apiUrl = API + "api/user_getby_clientidpost";

      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      };
      var postData = {
        id: localStorage.getItem("client_id"),
      };
      axios
        .post(apiUrl, postData, axiosConfig)
        .then((res) => {
          setclientData(res.data);
        })
        .catch((err) => {
          //console.log("AXIOS ERROR: ", err);
        });
    };

    const fetchData1221 = async () => {
      const apiUrl1 = API + "api/location_by_client_id";

      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      };
      var postData = {
        client_id: localStorage.getItem("client_id"),
      };
      axios
        .post(apiUrl1, postData, axiosConfig)
        .then((responce) => {
          var location = localStorage.getItem("locations").split(",");
          setlocations(location);
          //console.log(responce.data.location);
          setlocationData(responce.data.location);
        })
        .catch((err) => {
          //console.log("AXIOS ERROR: ", err);
        });
    };

    fetchData121();
    fetchData1221();
  }, []);

  const apiUrlclicks =
    API + "api/dashboardclientclicks/" + localStorage.getItem("client_id");
  const apiUrlreview =
    API + "api/dashboardclientreview/" + localStorage.getItem("client_id");
  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(apiUrlclicks, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      setClicksData(result.data.data);
    };

    const fetchData1 = async () => {
      const result = await axios(apiUrlreview, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      setReviewData(result.data);
    };

    fetchData();
    fetchData1();
  }, []);

  useEffect(() => {
    clicksdata.forEach((element) => {
      clientschartdata.push(element.count);
    });

    reviewdata.forEach((element) => {
      reviewchartdata.push(element.count);
    });
    // //console.log(clicksdata)
    setclientschart(clientschartdata);
    setReviewData(reviewchartdata);
  }, [clicksdata]);

  const handleChange = (event) => {
    setformData({ ...formlist, [event.target.name]: event.target.value });
  };

  const handleChange1 = (event) => {
    setformData1({ ...formlist1, [event.target.name]: event.target.value });
  };

  const handleChange2 = (event) => {
    setformData2({ ...formlist2, [event.target.name]: event.target.value });
  };

  const handleChange3 = (event) => {
    setformData3({ ...formlist3, [event.target.name]: event.target.value });
  };

  const loadData = async (offsetS, current) => {
    let sitlinkurl = API + "api/listTestrev/";
    let off = offsetS;
    var curval;
    if (!current) {
      curval = 1;
    } else {
      curval = current;
    }
    setcurrent(curval);
    $.ajax({
      url: sitlinkurl,
      data: {
        limit: 5,
        offset: off,
        user_id: formlist1.user_id,
        location_id: formlist1.location_name,
        user_type: localStorage.getItem("user_type"),
        user: localStorage.getItem("client_name"),
        client_id: localStorage.getItem("client_id"),
      },

      dataType: "json",
      type: "POST",
      headers: {
        Authorization: localStorage.getItem("token"),
      },

      success: (data) => {
        //console.log("data");
        //console.log(data);
        setcurrData(data);
      },

      error: (xhr, status, err) => {
        console.error(props.url, status, err.toString()); // eslint-disable-line
      },
    });
  };
  useEffect(() => {
    loadData();
  }, [data]);

  const handlePageClick = (data) => {
    let offsetS = Math.ceil(data * 5 - 5);
    loadData(offsetS, data);
  };

  const getemaildataSearch = async () => {
    if (formlist1.location_name !== "") {
      var postData1 = {
        user_id: formlist1.user_id,
        location_id: formlist1.location_name,
        user_type: localStorage.getItem("user_type"),
        user: localStorage.getItem("client_name"),
        client_id: localStorage.getItem("client_id"),
      };
    } else {
      postData1 = {
        user_id: formlist1.user_id,
        user_type: localStorage.getItem("user_type"),
        user: localStorage.getItem("client_name"),
        client_id: localStorage.getItem("client_id"),
      };
    }
    //console.log("postData1");
    //console.log(postData1);
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };

    axios
      .post(sitlinkurlall, postData1, axiosConfig)
      .then((res) => {
        setData(res.data);
        let count = Object.keys(res.data).length;
        setcount(count);
      })
      .catch((err) => {
        //console.log("AXIOS ERROR: ", err);
      });

    loadData();
  };

  const checkLocation = (item, idx) => {
    if (localStorage.getItem("user_type") === "client") {
      return (
        <option key={idx} value={item.id}>
          {item.add1}
          {", "}
          {item.city}
        </option>
      );
    } else {
      if (locations.includes(item.id.toString())) {
        return (
          <option key={idx} value={item.id}>
            {item.add1}
            {", "}
            {item.city}
          </option>
        );
      }
    }
  };

  let user_type = localStorage.getItem("user_type");
  let user_id = localStorage.getItem("id");

  const cp_clicksdata = (item, idx) => {
    if (item.link_type == "google") {
      return (
        <>
          <h6>Google </h6>{" "}
        </>
      );
    }
    if (item.link_type == "google2") {
      return (
        <>
          <h6>Google2 </h6>{" "}
        </>
      );
    }
    if (item.link_type == "fb") {
      return (
        <>
          <h6>Facebook </h6>
        </>
      );
    }
    if (item.link_type == "fb2") {
      return (
        <>
          <h6>Facebook2 </h6>
        </>
      );
    }
    if (item.link_type == "yelp") {
      return (
        <>
          <h6>Yelp </h6>
        </>
      );
    }
    if (item.link_type == "zillow") {
      return (
        <>
          <h6>Zillow </h6>
        </>
      );
    }
    if (item.link_type == "yp") {
      return (
        <>
          <h6>Yellow Pages </h6>
        </>
      );
    }
    if (item.link_type == "trip") {
      return (
        <>
          <h6>Trip Advisor </h6>
        </>
      );
    }
    if (item.link_type == "yell") {
      return (
        <>
          <h6>Yell </h6>
        </>
      );
    }
    if (item.link_type == "real") {
      return (
        <>
          <h6>Realtor </h6>
        </>
      );
    }
    if (item.link_type == "apar") {
      return (
        <>
          <h6>Apartments.com </h6>
        </>
      );
    }
    if (item.link_type == "aparrate") {
      return (
        <>
          <h6>Apartment Ratings </h6>
        </>
      );
    }
    if (item.link_type == "sf") {
      return (
        <>
          <h6>Spare Foot </h6>
        </>
      );
    }
    if (item.link_type == "great_schools") {
      return (
        <>
          <h6>Great School </h6>
        </>
      );
    }

    if (item.link_type == "healthgrades") {
      return (
        <>
          <h6>Health Grades </h6>
        </>
      );
    }
    if (item.link_type == "mysite") {
      return (
        <>
          <h6>Our Website </h6>
        </>
      );
    }
  };
  const userListing = (item, idx) => {
    if (user_type === "employee") {
      if (user_id == item.id) {
        return (
          <option key={idx} value={item.id}>
            {item.name}
          </option>
        );
      }
    } else {
      return (
        <option key={idx} value={item.id}>
          {item.name}
        </option>
      );
    }
  };

  return (
    <>
      <div
        className={
          localStorage.getItem("surveycount") != 0 ? "col-md-9" : "col-md-9 "
        }
      >
        <h5>Customer List</h5>
        <div className="row">
          <div className="col-md-5">
            <select
              className="form-control"
              id="location_name"
              name="location_name"
              // value={formdata.location_name}
              onChange={handleChange1}
            >
              <option value="">Select Location</option>
              {locationlist.map((item, idx) => checkLocation(item, idx))}
            </select>
          </div>
          <div className="col-md-5">
            <select
              className="form-control"
              id="user_id"
              name="user_id"
              // value={formdata.user_id}
              onChange={handleChange1}
            >
              <option value="">Select User</option>

              {clinetlist.map((item, idx) => userListing(item, idx))}
            </select>
          </div>
          <div className="col-2 col-md-2">
            <button
              type="button"
              onClick={() => getemaildataSearch()}
              className=" form-control col-md-12 btn btn-success"
            >
              Search
            </button>
          </div>
        </div>
        <br />
        <div className="table-responsive">
          <table className="table table-striped table-bordered table-hover">
            <thead>
              <tr>
                <th> # </th>
                <th> User Details </th>
                <th> Request Details </th>
                <th> Action </th>
              </tr>
            </thead>
            <tbody>
              {currdata.map((item, idx) => (
                <tr>
                  <td>{idx + 1 + (current - 1) * 5}</td>
                  <td>
                    {" "}
                    <p>{item.username}</p>{" "}
                    <h5>
                      {item.email} | {item.mobile}
                    </h5>{" "}
                    {/* <h5>{item.email === "0" ? "" : item.email} </h5>{" "} */}
                    <h5>
                      {/* Sent by : {item.via} - {item.user} */}
                      Sent by : {item.via} -{" "}
                      {item.via === "API"
                        ? item.cp_location
                          ? item.cp_location.l_name
                          : item.cp_client.company_name
                        : ""}
                      {item.via === "Manually"
                        ? item.cp_user && item.user_id != localStorage.getItem("id")
                          ?   item?.cp_user?.name
                          : localStorage.getItem("user_type") == "client" ? item?.cp_client?.client_name : item?.cp_user?.name
                        : ""}
                    </h5>{" "}
                  </td>

                  <td>
                    {" "}
                    <p>
                      Date:{" "}
                      <Moment format="MMM Do YY">{item.created_date}</Moment>
                    </p>{" "}
                    <h5> {item.survey >= 0 ? " Link" : " Link"} </h5>{" "}
                    <a target="_blank" href={item.link}>
                      {item.link}
                    </a>{" "}
                    <br></br>
                    {item.ratings &&
                      "Gate Response: " + item.ratings + " stars"}
                    {item.gate_results &&
                      "Gate Response: Clicked - " + item.gate_results}
                    {item.cp_clicks.length > 0 && <h5>Clicked ON :</h5>}
                    {item.cp_clicks.length > 0 &&
                      item.cp_clicks.map((item, idx) => cp_clicksdata(item))}
                  </td>

                  <td>
                    {item.resend === 0 ? (
                      <a onClick={() => resend(item.id)} title="Resend">
                        Resend Request
                      </a>
                    ) : (
                      "Resent on  - " +
                      moment(item.updatedAt).format("MMM Do YYYY")
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination
            showQuickJumper
            showSizeChanger
            onChange={handlePageClick}
            current={current}
            total={count}
            pageSize={5}
            locale={localeInfo}
          />
        </div>
      </div>
    </>
  );
}
