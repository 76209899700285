import React, { useState, useEffect } from "react";
import axios from "axios";
import { API } from "../../../../const";
import Surveyform from "./../Survey/form";

const EditSurvey = props => {
  const [client, setClient] = useState();

  const [formdata, setformdata] = useState({
    client_name1: "",
    loc_name: "",
    survey_name: "",
    active: "",
    url_field: "",
    comments: "",
    text_msg: "",
    email_msg: ""
  });
  const [location, setlocation] = useState([]);

  const apiUrl = API + "api/getsurvey_data";

  useEffect(() => {
    const fetchData = async () => {
      var postData = {
        id: props.match.params.id
      };

      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token")
        }
      };
      const result = await axios
        .post(apiUrl, postData, axiosConfig)
        .then(res => {
          if (res) {
            setlocation(res.data.location);
            setClient(res.data.data.cp_client.company_name);

            setformdata({
              client_name1: res.data.data.client_name1,
              loc_name: res.data.data.location_id,
              survey_name: res.data.data.survey_name,
              active: res.data.data.active,
              url_field: res.data.data.url_field,
              comments: res.data.data.comments,
              text_msg: res.data.data.text_msg,
              email_msg: res.data.data.email_msg,
              sur_temp: res.data.data.sur_temp,
              client_id: res.data.data.client_id
            });
          }
        })
        .catch(err => {
          //console.log("AXIOS ERROR: ", err);
        });
    };
    fetchData();
  }, []);

  return (
    <>
      {/* <Notice icon="flaticon-warning kt-font-primary">
                For more info please check the components's official{" "}
            </Notice> */}

      <div className="row">
        <div className="col-md-12">
          <div className="kt-space-40" />
          <Surveyform
            data={formdata}
            history={props.history}
            location={location}
            client={client}
            id={props.match.params.id}

          />
          <div className="kt-space-20" />
        </div>
      </div>
    </>
  );
};
export default EditSurvey;
