import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import axios from "axios";
import { API } from "../../../../const";
import "react-toastify/dist/ReactToastify.css";
import { makeStyles, useTheme } from "@material-ui/core/";
import Validator from "validatorjs";
import {
    FormControl,
    Select,
    MenuItem,
    Chip,
    Input,
    InputLabel,
    Checkbox,
    ListItemText
  } from "@material-ui/core";

import "./form.css";
const Userclientdataform = props => {
  const [clinetlist, setclientData] = useState([]);
  const [locationlist, setlocationData] = useState([]);
  const [style, setstyle] = useState("hidden");
  const [formdata, setformdata] = useState({
    client_id: "",
    email: "",
    password: "",
    name: "",
    locations: "",
    menu: "",
    user_type: "",
    mystatus: "",
    id: ""
  });
  const theme = useTheme();
  const useStyles = makeStyles(theme => ({
    button: {
      margin: theme.spacing(1)
    },
    // input: {
    //   display: 'none',
    // },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300
    },
    chips: {
      display: "flex",
      flexWrap: "wrap"
    },
    chip: {
      margin: 2
    }
  }));

  const classes = useStyles();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  };
  const [locationdata, setlocationdata] = useState([]);
  const [selectedSocieties, setSelectedSocieties] = useState([]);
  const [selectedSocieties1, setSelectedSocieties1] = useState([]);
  const [allSocieties, setAllSocieties] = useState([]);
  var temp_selected_slist = [];
  var temp_slist = [];
  useEffect(() => {
    if (formdata.locations !== "" && formdata.id !== "") {
      const apiUrlgetlocation = API + "api/location_byname/";
      var locarray = formdata.locations.split(",");
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token")
        }
      };
      var postData = {
        l_name: locarray
      };
      var temp_selected_slist = [];
      var temp_selected_slist1 = [];
      axios
        .post(apiUrlgetlocation, postData, axiosConfig)
        .then(result => {
          //console.log(result);

          result.data.forEach(el => {
            const obj = { id: el.id, name: el.l_name };
            temp_selected_slist.push(el.l_name);
            temp_selected_slist1.push(el.id);
          });
          setSelectedSocieties(temp_selected_slist);
          setSelectedSocieties1(temp_selected_slist1);

          // setlocationData(result.data);
        })
        .catch(err => {
          //console.log("AXIOS ERROR: ", err);
        });
    }

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token")
      }
    };
    var postData1 = {
      client_id:  localStorage.getItem("client_id")
    };
    axios
      .post(apiUrlstyle, postData1, axiosConfig)
      .then(result => {
        result.data.location.forEach(element => {
          const obj_temp = { id: element.id, name: element.l_name };
          temp_slist.push(obj_temp);
        });

        setAllSocieties(temp_slist);
        //console.log("allSocieties");
        //console.log(temp_slist);
      })

      .catch(err => {
        //console.log("AXIOS ERROR: ", err);
      });
  }, [formdata.id, formdata.locations]);

  useEffect(() => {
    const locatiodata = async () => {
      const apiUrlstyle = API + "api/location_by_client_id/";
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token")
        }
      };
      var postData = {
        client_id: await localStorage.getItem("client_id")
      };
      axios
        .post(apiUrlstyle, postData, axiosConfig)
        .then(result => {
          setlocationData(result.data.location);
          //console.log(result.data.location);

          //setformdata({ ...formdata, ['locations']: result.data.location });
        })
        .catch(err => {
          //console.log("AXIOS ERROR: ", err);
        });
    };

    const apiUrlstyle = API + "api/client_listall/";

    const getclinet = async () => {
      const result = await axios(apiUrlstyle, {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      });
      setclientData(result.data);
    };

    setformdata(props.data);
    locatiodata();
    getclinet();
  }, [props.data]);
  if (locationlist >= 0 && formdata.id !== "") {
    const apiUrlgetlocation = API + "api/location_byname/";
    var locarray = formdata.locations.split(",");
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token")
      }
    };
    var postData = {
      l_name: locarray
    };

    axios
      .post(apiUrlgetlocation, postData, axiosConfig)
      .then(result => {
        // //console.log(result);

        setlocationData(result.data);
      })
      .catch(err => {
        //console.log("AXIOS ERROR: ", err);
      });
  }

  const apiUrlstyle = API + "api/location_by_client_id/";
  const handleChange1 = event => {
    let newVal = event.target.value;
    let stateVal = locationdata;
    stateVal.indexOf(newVal) === -1
      ? stateVal.push(newVal)
      : stateVal.length === 1
      ? (stateVal = [])
      : stateVal.splice(stateVal.indexOf(newVal), 1);
    setformdata({ ...formdata, ["locations"]: stateVal });
  };

  const handleChange = event => {
    setformdata({ ...formdata, [event.target.id]: event.target.value }); // setformdata({ ...formdata, ["client_id"]: event.target.value });

    // getlocation(event.target.id,event.target.value);
  };

  const back = () => {
    props.history.push({
      pathname: "/clientwiseuser"
    });
  };
  const validateForm = () => {
    if (formdata.id === "") {
      let data = {
        email: formdata.email,
        password: formdata.password,
        menu: formdata.menu,
        user_type: formdata.user_type,
        mystatus: formdata.mystatus,
        locations: selectedSocieties1,
        name: formdata.name
      };

      let rules = {
        email: "required|email",
        password: "required",
        menu: "required",
        user_type: "required",
        mystatus: "required",
        locations: "required",
        name: "required"
      };

      let validation = new Validator(data, rules);

      if (validation.passes()) {
        return true;
      }
    } else {
      let data = {
        email: formdata.email,
        menu: formdata.menu,
        user_type: formdata.user_type,
        mystatus: formdata.mystatus,
        locations: selectedSocieties1,
        name: formdata.name
      };

      let rules = {
        email: "required|email",
        menu: "required",
        user_type: "required",
        mystatus: "required",
        locations: "required",
        name: "required"
      };

      let validation = new Validator(data, rules);

      if (validation.passes()) {
        return true;
      }
    }
  };

  const formSubmit = async () => {
    var postData = {
      id: props.data.id,
      client_id: localStorage.getItem("client_id"),
      email: formdata.email,
      password: formdata.password,
      name: formdata.name,
      locations: selectedSocieties1,
      menu: formdata.menu,
      user_type: formdata.user_type,
      mystatus: formdata.mystatus
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token")
      }
    };
    const apiedit = API + "api/user_update";
    const apiadd = API + "api/user_add";
    if (props.data.id) {
      axios
        .post(apiedit, postData, axiosConfig)
        .then(res => {
          alert(res.data.message);
          back();
        })
        .catch(err => {
          //console.log("AXIOS ERROR: ", err);
        });
    } else {
      axios
        .post(apiadd, postData, axiosConfig)
        .then(res => {
          alert(res.data.message);
          back();
        })
        .catch(err => {
          //console.log("AXIOS ERROR: ", err);
        });
    }
  };
  const handleChange_multiple = event => {
    // setformdata({vendorSociety : event.target.value});
    // setformdata({...formdata, vendorSociety :event.target.value})
    //console.log("event.target.value");
    //console.log(event.target.value);
    let newitems = event.target.value.filter(t => typeof t !== "string");
    //console.log("newitems");
    //console.log(newitems);

    let changed = newitems[0].name;
    let changed1 = newitems[0].id;

    //console.log("newitems[0]");
    //console.log(newitems[0]);

    let cleanthis = event.target.value.filter(t => typeof t === "string");
    //console.log("cleanthis");
    //console.log(cleanthis);

    newitems.forEach(i => cleanthis.push(i.name));
    const newSelectedItems = selectedSocieties.includes(changed)
      ? selectedSocieties.filter(v => v !== changed)
      : [...selectedSocieties, changed];
    const newSelectedItems1 = selectedSocieties1.includes(changed)
      ? selectedSocieties1.filter(v => v !== changed1)
      : [...selectedSocieties1, changed1];
    // //console.log(
    //   event.target.value,
    //   newSelectedItems,
    //   changed,
    //   selectedSocieties
    // );
    setSelectedSocieties(newSelectedItems);
    setSelectedSocieties1(newSelectedItems1);
  };
  // if(formdata.id!=="")
  // {
  // 	var locarray=formdata.locations.split(',');
  // 	//console.log(locarray)
  // }
//console.log("formdata");
//console.log(formdata);
  return (
    <div>
      <Form encType="multipart/form-data">
        <div className="review">
          <div className="row">
            <div className="col-md-12">
              <div className="card border-top-0 border-right-0 border-left-0 pb-4">
                <div className="card-header bg-white ">
                  <div className="row">
                    <div className="col-md-12">
                      <h5 className="card-title mb-0 pt-2">User</h5>
                      <span>All * fields are mandatory </span>
                    </div>
                    <div className="col-md-12 text-right">
                      <input
                        type="button"
                        disabled={!validateForm()}
                        onClick={() => formSubmit()}
                        value="Save"
                        className="btn btn-success save-btn font-weight-bold"
                      />
                      <input
                        type="button"
                        onClick={() => back()}
                        value="Back"
                        className="margin btn btn-primary save-btn font-weight-bold"
                      />
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div id="introduction">
                    <div className="row">
                      <div className="form-group col-md-6 ">
                        <FormControl className="form-control">
                          {/* <InputLabel htmlFor="select-multiple-chip">Chip</InputLabel> */}
                          <Select
                            multiple
                            name="societies"
                            id="societies"
                            value={selectedSocieties}
                            onChange={handleChange_multiple}
                            input={<Input id="select-multiple-chip" />}
                            renderValue={selected => (
                              <div className={classes.chips}>
                                {selected.map(s => {
                                  return (
                                    <Chip
                                      key={s}
                                      label={s}
                                      className={classes.chip}
                                    />
                                  );
                                  // const chipname = allSocieties.find(soc => soc.l_name === s);
                                  // return (chipname
                                  //             ?
                                  //             : '')
                                })}
                              </div>
                            )}
                            MenuProps={MenuProps}
                          >
                            {allSocieties.map(itemsociety => (
                              <MenuItem
                                key={itemsociety.id}
                                value={itemsociety}
                              >
                                <Checkbox
                                  checked={selectedSocieties.includes(
                                    itemsociety.name
                                  )}
                                />
                                <ListItemText primary={itemsociety.name} />
                              </MenuItem>
                            ))}
                          </Select>
                          {/* <span className="text-danger">{societyError}</span> */}
                          <label>Location *</label>
                        </FormControl>
                      </div>

                      <div className="form-group col-md-6 ">
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          name="email"
                          placeholder=" Email"
                          value={formdata.email}
                          onChange={handleChange}
                        />
                        <label>Enter Email * </label>
                      </div>
                      <div className="form-group col-md-6 ">
                      <input
                          type="hidden"
                          name="id"
                          id="id"
                          className="form-control"
                          placeholder="id"
                          value={formdata.id}
                        />
                        <input
                          type="text"
                          name="name"
                          id="name"
                          className="form-control"
                          placeholder="Enter Name"
                          value={formdata.name}
                          onChange={handleChange}
                        />
                        <label>Name * </label>
                      </div>
                      <div className="form-group col-md-6 ">
                        <input
                          type="password"
                          name="password"
                          id="password"
                          className="form-control"
                          placeholder="Password"
                          value={formdata.password}
                          onChange={handleChange}
                        />
                        <label>Password {formdata.id ? "" : "*"}</label>
                      </div>

                      <div className="form-group col-md-6">
                        <select
                          className="form-control"
                          id="menu"
                          name="menu"
                          defaultValue={formdata.menu}
                          onChange={handleChange}
                        >
                          <option>Please Select Menu Access </option>
                          <option
                            selected={formdata.menu === "Full"}
                            value="Full"
                          >
                            Full
                          </option>
                          <option
                            selected={formdata.menu === "Limited"}
                            value="Limited"
                          >
                            Limited
                          </option>
                        </select>
                        <label>Menu Access* </label>
                      </div>

                      <div className="form-group col-md-6">
                        <select
                          className="form-control"
                          id="user_type"
                          name="user_type"
                          defaultValue={formdata.user_type}
                          onChange={handleChange}
                        >
                          <option>Please Select User Type </option>
                          <option
                            selected={formdata.user_type === "employee"}
                            value="employee"
                          >
                            Employee
                          </option>
                          <option
                            selected={formdata.user_type === "client"}
                            value="client"
                          >
                            Client
                          </option>
                        </select>
                        <label>User Type (For Reporting Access)* </label>
                      </div>

                      <div className="form-group col-md-6">
                        <select
                          className="form-control"
                          id="mystatus"
                          name="mystatus"
                          defaultValue={formdata.status}
                          onChange={handleChange}
                        >
                          <option>Please Select Status</option>
                          <option
                            selected={formdata.mystatus === "Enabled"}
                            value="Enabled"
                          >
                            Enabled
                          </option>
                          <option
                            selected={formdata.mystatus === "Disabled"}
                            value="Disabled"
                          >
                            Disabled
                          </option>
                        </select>
                        <label>Status*</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};
export default Userclientdataform;
