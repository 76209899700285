import React, { useState, useEffect } from "react";
import { Form, Tabs, Tab } from "react-bootstrap";
import axios from "axios";
import { API } from "../../../../const";
import { Image } from "react-bootstrap";
import Dropzone from "react-dropzone";
import "react-toastify/dist/ReactToastify.css";
import Validator from "validatorjs";
import CKEditor from "ckeditor4-react";
import { ChromePicker } from "react-color";
import LoadingOverlay from "react-loading-overlay";
import "./form.css";
import { RiseLoader } from "react-spinners";
const Locationform = (props) => {
  const [clinetlist, setclientData] = useState([]);
  const [style, setstyle] = useState("hidden");
  const [showLoading, setShowLoading] = useState(false);
  const [formdata1, setformdata1] = useState({
    logo1: "",
    logo2: "",
  });
  const [formdata, setformdata] = useState({
    client_id: "",
    l_name: "",
    add1: "",
    add2: "",
    state: "",
    city: "",
    zip: "",
    fb_link: "",
    fb_link2: "",
    view_fb2: "",
    yelp_link: "",
    google_link: "",
    google_link2: "",
    google_count2: 0,
    google_ratings: 0.0,
    zillow_link: "",
    yp_link: "",
    trip_link: "",
    yell_link: "",
    sf_link: "",
    real_link: "",
    apar_link: "",
    aparrate_link: "",
    great_schools_link: "",
    healthgrades_link: "",
    mysite_link: "",
    thumbtack_link: "",
    amazon_link: "",
    fb_account: "",
    fb_account2: "",
    yelp_account: "",
    google_account: "",
    google_account2: "",
    zillow_account: "",
    yp_account: "",
    trip_account: "",
    yell_account: "",
    sf_account: "",
    real_account: "",
    apar_account: "",
    aparrate_account: "",
    great_schools_account: "",
    healthgrades_account: "",
    mysite_account: "",
    thumbtack_account: "",
    amazon_account: "",
    c_no: 0,
    c_email: "",
    msg: "",
    msg1: "",
    sms_msg: "",
    sms_msg1: "",
    google_count: 0,
    yelp_count: 0,
    fb_count: 0,
    fb_count2: 0,
    zillow_count: 0,
    yp_count: 0,
    trip_count: 0,
    yell_count: 0,
    sf_count: 0,
    real_count: 0,
    apar_count: 0,
    aparrate_count: 0,
    great_schools_count: 0,
    healthgrades_count: 0,
    thumbtack_count: 0,
    amazon_count: 0,
    google_ratings: 0.0,
    yelp_ratings: 0.0,
    fb_ratings: 0.0,
    fb_ratings: 0.0,
    zillow_ratings: 0.0,
    yp_ratings: 0.0,
    trip_ratings: 0.0,
    yell_ratings: 0.0,
    real_ratings: 0.0,
    apar_ratings: 0.0,
    aparrate_ratings: 0.0,
    great_schools_ratings: 0.0,
    healthgrades_ratings: 0.0,
    mysite_ratings: 0.0,
    thumbtack_ratings: 0.0,
    amazon_ratings: 0.0,
    google_date: "",
    google_date2: "",
    yelp_date: "",
    fb_date: "",
    fb_date2: "",
    zillow_date: "",
    yp_date: "",
    sf_date: "",
    apar_date: "",
    aparrate_date: "",
    real_date: "",
    trip_date: "",
    yell_date: "",
    great_schools_date: "",
    healthgrades_date: "",
    mysite_date: "",
    thumbtack_date: "",
    amazon_date: "",
    view_google: "",
    view_google2: "",
    view_yelp: "",
    view_fb: "",
    view_zillow: "",
    view_yp: "",
    view_trip: "",
    view_yell: "",
    view_sf: "",
    view_real: "",
    view_apar: "",
    view_aparrate: "",
    view_great_schools: "",
    view_healthgrades: "",
    view_mysite: "",
    view_thumbtack: "",
    view_amazon: "",
    setname: 0,
    businees_name: "",
    color_code: "",
    logo: "",
    review_msg: "",
    yes_msg: "",
    no_msg: "",
    subject: "",
    subject1: "",
    sign: "",
    sign1: "",
    thanks_msg: "",
    thanks_msg1: "",
    sendlogo: 0,
    sendlogo1: 0,
    sendlogoemail: 0,
    sendlogoemail1: 0,
    underlogo: "",
    coupon: "",
    setfeed: 0,
    loc_code: "",
    corp_code: "",
    corp_pass: "",
    corp_login: "",
    id: "",
    background: "",
    mysite_count: 0,
    sf_ratings: 0.0,
    sitelink: "",
    solar_review: "",
    solar_review_view: "",
    home_advisor: "",
    home_advisor_view: "",
    energysage: "",
    energysage_view : ""
  });
  const handleChangeComplete = (color, event) => {
    setformdata({ ...formdata, ["background"]: color.hex });
  };

  useEffect(() => {
    if (formdata.setname === 1) {
      setstyle("show");
    } else {
      setstyle("hidden");
    }
  }, [formdata.setname]);
  const onDroplogo = (acceptedFiles) => {
    let file = acceptedFiles[0];

    let reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = function() {
      setformdata({ ...formdata, ["logo1"]: reader.result });
      setformdata1({ ...formdata1, ["logo1"]: reader.result });
    };

    reader.onerror = function() {
      //console.log(reader.error);
    };
  };

  const onDroplogo1 = (acceptedFiles) => {
    let file = acceptedFiles[0];

    let reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = function() {
      setformdata({ ...formdata, ["logo2"]: reader.result });
      setformdata1({ ...formdata1, ["logo2"]: reader.result });
    };

    reader.onerror = function() {
      //console.log(reader.error);
    };
  };
  useEffect(() => {
    setformdata(props.data);
  }, [props.data]);

  const apiUrl = API + "api/client_listall";
  useEffect(() => {
    const fetchData = async () => {
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      };
      var postData = {};
      axios
        .post(apiUrl, postData, axiosConfig)
        .then((res) => {
          setclientData(res.data);
        })
        .catch((err) => {
          //console.log("AXIOS ERROR: ", err);
        });
    };

    fetchData();
  }, []);

  const handleChangeck1 = (event) => {
    if (event.editor) {
      var name = "msg";
      setformdata({ ...formdata, [name]: event.editor.getData() });
    }
  };
  const handleChangeck2 = (event) => {
    if (event.editor) {
      var name = "sign";
      setformdata({ ...formdata, [name]: event.editor.getData() });
    }
  };
  const handleChangeck3 = (event) => {
    if (event.editor) {
      var name = "msg1";
      setformdata({ ...formdata, [name]: event.editor.getData() });
    }
  };
  const handleChangeck4 = (event) => {
    if (event.editor) {
      var name = "sign1";
      setformdata({ ...formdata, [name]: event.editor.getData() });
    }
  };
  const handleChange = (event) => {
    if (
      event.target.id === "setname" ||
      event.target.id === "sendlogo1" ||
      event.target.id === "sendlogo" ||
      event.target.id === "sendlogoemail" ||
      event.target.id === "sendlogoemail1"
    ) {
      if (event.target.checked === true) {
        setformdata({ ...formdata, [event.target.id]: 1 });
        localStorage.setItem(event.target.id, 1);
      } else {
        setformdata({ ...formdata, [event.target.id]: 0 });
        localStorage.setItem(event.target.id, 0);
      }
    } else {
      setformdata({ ...formdata, [event.target.id]: event.target.value });
      if (event.target.id != "client_id") {
        localStorage.setItem(event.target.id, event.target.value);
      }
    }
  };

  const back = () => {
    props.history.push({
      pathname: "/location",
    });
    Object.keys(props.data).map((item) => {
      if (item != "client_id") {
        localStorage.removeItem(item);
      }
    });
  };
  const reset = () => {
    Object.keys(props.data).map((item) => {
      if (item != "client_id") {
        localStorage.removeItem(item);
      }
    });
    setformdata(props.data);
  };
  const validateForm = () => {
    let data = {
      client_id: formdata.client_id
        ? formdata.client_id
        : localStorage.getItem("client_id"),
      l_name: localStorage.getItem("l_name")
        ? localStorage.getItem("l_name")
        : formdata.l_name,
      add1: localStorage.getItem("add1")
        ? localStorage.getItem("add1")
        : formdata.add1,
      state: localStorage.getItem("state")
        ? localStorage.getItem("state")
        : formdata.state,
      city: localStorage.getItem("city")
        ? localStorage.getItem("city")
        : formdata.city,
      zip: localStorage.getItem("zip")
        ? localStorage.getItem("zip")
        : formdata.zip,
      logo: localStorage.getItem("logo")
        ? localStorage.getItem("logo")
        : formdata.logo1,
      yp_count: localStorage.getItem("yp_count")
        ? localStorage.getItem("yp_count")
        : formdata.yp_count,
      coupon: localStorage.getItem("logo2")
        ? localStorage.getItem("logo2")
        : formdata.logo2,
    };

    let rules = {
      l_name: "required",
    };

    let validation = new Validator(data, rules);

    if (validation.passes()) {
      return true;
    }
  };

  const formSubmit = async () => {
    setShowLoading(true);
    var postData = {
      id: props.data.id,
      client_id: formdata.client_id
        ? formdata.client_id
        : localStorage.getItem("client_id"),
      l_name: localStorage.getItem("l_name")
        ? localStorage.getItem("l_name")
        : formdata.l_name,
      add1: localStorage.getItem("add1")
        ? localStorage.getItem("add1")
        : formdata.add1,
      add2: localStorage.getItem("add2")
        ? localStorage.getItem("add2")
        : formdata.add2,
      state: localStorage.getItem("state")
        ? localStorage.getItem("state")
        : formdata.state,
      city: localStorage.getItem("city")
        ? localStorage.getItem("city")
        : formdata.city,
      zip: localStorage.getItem("zip")
        ? localStorage.getItem("zip")
        : formdata.zip,
      fb_link: localStorage.getItem("fb_link")
        ? localStorage.getItem("fb_link")
        : formdata.fb_link,
      fb_link2: localStorage.getItem("fb_link2")
        ? localStorage.getItem("fb_link2")
        : formdata.fb_link2,
      view_fb2: localStorage.getItem("view_fb2")
        ? localStorage.getItem("view_fb2")
        : formdata.view_fb2,
      google_link: localStorage.getItem("google_link")
        ? localStorage.getItem("google_link")
        : formdata.google_link,
      google_link2: localStorage.getItem("google_link2")
        ? localStorage.getItem("google_link2")
        : formdata.google_link2,
      google_date2: localStorage.getItem("google_date2")
        ? localStorage.getItem("google_date2")
        : formdata.google_date2,
      zillow_link: formdata.zillow_link,
      yp_link: localStorage.getItem("yp_link")
        ? localStorage.getItem("yp_link")
        : formdata.yp_link,
      trip_link: localStorage.getItem("trip_link")
        ? localStorage.getItem("trip_link")
        : formdata.trip_link,
      yell_link: localStorage.getItem("yell_link")
        ? localStorage.getItem("yell_link")
        : formdata.yell_link,
      yelp_link: localStorage.getItem("yelp_link")
        ? localStorage.getItem("yelp_link")
        : formdata.yelp_link,
      sf_link: localStorage.getItem("sf_link")
        ? localStorage.getItem("sf_link")
        : formdata.sf_link,
      view_sf: localStorage.getItem("view_sf")
        ? localStorage.getItem("view_sf")
        : formdata.view_sf,
      real_link: localStorage.getItem("real_link")
        ? localStorage.getItem("real_link")
        : formdata.real_link,
      view_real: localStorage.getItem("view_real")
        ? localStorage.getItem("view_real")
        : formdata.view_real,
      apar_link: localStorage.getItem("apar_link")
        ? localStorage.getItem("apar_link")
        : formdata.apar_link,
      view_apar: localStorage.getItem("view_apar")
        ? localStorage.getItem("view_apar")
        : formdata.view_apar,
      aparrate_link: localStorage.getItem("view_apar")
        ? localStorage.getItem("view_apar")
        : formdata.aparrate_link,
      view_aparrate: formdata.view_aparrate,
      great_schools_link: localStorage.getItem("view_aparrate")
        ? localStorage.getItem("view_aparrate")
        : formdata.great_schools_link,
      view_great_schools: localStorage.getItem("view_great_schools")
        ? localStorage.getItem("view_great_schools")
        : formdata.view_great_schools,
      healthgrades_link: localStorage.getItem("view_healthgrades")
        ? localStorage.getItem("view_healthgrades")
        : formdata.view_healthgrades,
      view_healthgrades: localStorage.getItem("view_healthgrades")
        ? localStorage.getItem("view_healthgrades")
        : formdata.view_healthgrades,
      mysite_link: localStorage.getItem("view_healthgrades")
        ? localStorage.getItem("view_healthgrades")
        : formdata.mysite_link,
      view_mysite: localStorage.getItem("view_mysite")
        ? localStorage.getItem("view_mysite")
        : formdata.view_mysite,
      thumbtack_link: localStorage.getItem("thumbtack_link")
        ? localStorage.getItem("thumbtack_link")
        : formdata.thumbtack_link,
      view_thumbtack: localStorage.getItem("view_thumbtack")
        ? localStorage.getItem("view_thumbtack")
        : formdata.view_thumbtack,
      amazon_link: localStorage.getItem("amazon_link")
        ? localStorage.getItem("amazon_link")
        : formdata.amazon_link,
      view_amazon: localStorage.getItem("view_amazon")
        ? localStorage.getItem("view_amazon")
        : formdata.view_amazon,
      fb_account: localStorage.getItem("fb_account")
        ? localStorage.getItem("fb_account")
        : formdata.fb_account,
      fb_account2: localStorage.getItem("fb_account2")
        ? localStorage.getItem("fb_account2")
        : formdata.fb_account2,
      yelp_account: localStorage.getItem("yelp_account")
        ? localStorage.getItem("yelp_account")
        : formdata.yelp_account,
      google_account: localStorage.getItem("google_account")
        ? localStorage.getItem("google_account")
        : formdata.google_account,
      google_account2: localStorage.getItem("google_account2")
        ? localStorage.getItem("google_account2")
        : formdata.google_account2,
      zillow_account: localStorage.getItem("zillow_account")
        ? localStorage.getItem("zillow_account")
        : formdata.zillow_account,
      yp_account: localStorage.getItem("yp_account")
        ? localStorage.getItem("yp_account")
        : formdata.yp_account,
      trip_account: localStorage.getItem("trip_account")
        ? localStorage.getItem("trip_account")
        : formdata.trip_account,
      yell_account: localStorage.getItem("yell_account")
        ? localStorage.getItem("yell_account")
        : formdata.yell_account,
      sf_account: localStorage.getItem("sf_account")
        ? localStorage.getItem("sf_account")
        : formdata.sf_account,
      real_account: localStorage.getItem("real_account")
        ? localStorage.getItem("real_account")
        : formdata.real_account,
      apar_account: localStorage.getItem("apar_account")
        ? localStorage.getItem("apar_account")
        : formdata.apar_account,
      aparrate_account: localStorage.getItem("aparrate_account")
        ? localStorage.getItem("aparrate_account")
        : formdata.aparrate_account,
      great_schools_account: localStorage.getItem("great_schools_account")
        ? localStorage.getItem("great_schools_account")
        : formdata.great_schools_account,
      healthgrades_account: localStorage.getItem("healthgrades_account")
        ? localStorage.getItem("healthgrades_account")
        : formdata.healthgrades_account,
      mysite_account: localStorage.getItem("mysite_account")
        ? localStorage.getItem("mysite_account")
        : formdata.mysite_account,
      thumbtack_account: localStorage.getItem("thumbtack_account")
        ? localStorage.getItem("thumbtack_account")
        : formdata.thumbtack_account,
      amazon_account: localStorage.getItem("amazon_account")
        ? localStorage.getItem("amazon_account")
        : formdata.amazon_account,
      c_no: localStorage.getItem("c_no")
        ? localStorage.getItem("c_no")
        : formdata.c_no,
      c_email: localStorage.getItem("c_email")
        ? localStorage.getItem("c_email")
        : formdata.c_email,
      msg: localStorage.getItem("msg")
        ? localStorage.getItem("msg")
        : formdata.msg,
      msg1: localStorage.getItem("msg1")
        ? localStorage.getItem("msg1")
        : formdata.msg1,
      google_count: localStorage.getItem("google_count")
        ? localStorage.getItem("google_count")
        : formdata.google_count,
      fb_count: localStorage.getItem("fb_count")
        ? localStorage.getItem("fb_count")
        : formdata.fb_count,
      fb_count2: localStorage.getItem("fb_count2")
        ? localStorage.getItem("fb_count2")
        : formdata.fb_count2,
      zillow_count: localStorage.getItem("zillow_count")
        ? localStorage.getItem("zillow_count")
        : formdata.zillow_count,
      trip_count: localStorage.getItem("trip_count")
        ? localStorage.getItem("trip_count")
        : formdata.trip_count,
      yell_count: localStorage.getItem("yell_count")
        ? localStorage.getItem("yell_count")
        : formdata.yell_count,
      yelp_count: localStorage.getItem("yelp_count")
        ? localStorage.getItem("yelp_count")
        : formdata.yelp_count,
      sf_count: localStorage.getItem("sf_count")
        ? localStorage.getItem("sf_count")
        : formdata.sf_count,
      real_count: localStorage.getItem("real_count")
        ? localStorage.getItem("real_count")
        : formdata.real_count,
      aparrate_count: localStorage.getItem("aparrate_count")
        ? localStorage.getItem("aparrate_count")
        : formdata.aparrate_count,
      great_schools_count: localStorage.getItem("great_schools_count")
        ? localStorage.getItem("great_schools_count")
        : formdata.great_schools_count,
      healthgrades_count: localStorage.getItem("healthgrades_count")
        ? localStorage.getItem("healthgrades_count")
        : formdata.healthgrades_count,
      thumbtack_count: localStorage.getItem("thumbtack_count")
        ? localStorage.getItem("thumbtack_count")
        : formdata.thumbtack_count,
      amazon_count: localStorage.getItem("amazon_count")
        ? localStorage.getItem("amazon_count")
        : formdata.amazon_count,
      google_ratings: localStorage.getItem("google_ratings")
        ? localStorage.getItem("google_ratings")
        : formdata.google_ratings,
      fb_ratings: localStorage.getItem("fb_ratings")
        ? localStorage.getItem("fb_ratings")
        : formdata.fb_ratings,
      fb_ratings2: localStorage.getItem("fb_ratings2")
        ? localStorage.getItem("fb_ratings2")
        : formdata.fb_ratings2,
      zillow_ratings: localStorage.getItem("zillow_ratings")
        ? localStorage.getItem("zillow_ratings")
        : formdata.zillow_ratings,
      yp_ratings: localStorage.getItem("yp_ratings")
        ? localStorage.getItem("yp_ratings")
        : formdata.yp_ratings,
      trip_ratings: localStorage.getItem("trip_ratings")
        ? localStorage.getItem("trip_ratings")
        : formdata.trip_ratings,
      yell_ratings: localStorage.getItem("yell_ratings")
        ? localStorage.getItem("yell_ratings")
        : formdata.yell_ratings,
      real_ratings: localStorage.getItem("real_ratings")
        ? localStorage.getItem("real_ratings")
        : formdata.real_ratings,
      apar_ratings: localStorage.getItem("apar_ratings")
        ? localStorage.getItem("apar_ratings")
        : formdata.apar_ratings,
      apar_count: localStorage.getItem("apar_count")
        ? localStorage.getItem("apar_count")
        : formdata.apar_count,
      sf_ratings: localStorage.getItem("sf_ratings")
        ? localStorage.getItem("sf_ratings")
        : formdata.sf_ratings,
      aparrate_ratings: localStorage.getItem("aparrate_ratings")
        ? localStorage.getItem("aparrate_ratings")
        : formdata.aparrate_ratings,
      great_schools_ratings: localStorage.getItem("great_schools_ratings")
        ? localStorage.getItem("great_schools_ratings")
        : formdata.great_schools_ratings,
      healthgrades_ratings: localStorage.getItem("healthgrades_ratings")
        ? localStorage.getItem("healthgrades_ratings")
        : formdata.healthgrades_ratings,
      mysite_ratings: localStorage.getItem("mysite_ratings")
        ? localStorage.getItem("mysite_ratings")
        : formdata.mysite_ratings,
      thumbtack_ratings: localStorage.getItem("thumbtack_ratings")
        ? localStorage.getItem("thumbtack_ratings")
        : formdata.thumbtack_ratings,
      amazon_ratings: localStorage.getItem("amazon_ratings")
        ? localStorage.getItem("amazon_ratings")
        : formdata.amazon_ratings,
      google_date: localStorage.getItem("google_date")
        ? localStorage.getItem("google_date")
        : formdata.google_date,
      fb_date: localStorage.getItem("fb_date")
        ? localStorage.getItem("fb_date")
        : formdata.fb_date,
      fb_date2: localStorage.getItem("fb_date2")
        ? localStorage.getItem("fb_date2")
        : formdata.fb_date2,
      zillow_date: localStorage.getItem("zillow_date")
        ? localStorage.getItem("zillow_date")
        : formdata.zillow_date,
      yp_date: localStorage.getItem("yp_date")
        ? localStorage.getItem("yp_date")
        : formdata.yp_date,
      sf_date: localStorage.getItem("sf_date")
        ? localStorage.getItem("sf_date")
        : formdata.sf_date,
      apar_date: localStorage.getItem("apar_date")
        ? localStorage.getItem("apar_date")
        : formdata.apar_date,
      aparrate_date: localStorage.getItem("aparrate_date")
        ? localStorage.getItem("aparrate_date")
        : formdata.aparrate_date,
      real_date: localStorage.getItem("real_date")
        ? localStorage.getItem("real_date")
        : formdata.real_date,
      trip_date: localStorage.getItem("trip_date")
        ? localStorage.getItem("trip_date")
        : formdata.trip_date,
      yell_date: localStorage.getItem("yell_date")
        ? localStorage.getItem("yell_date")
        : formdata.yell_date,
      great_schools_date: localStorage.getItem("great_schools_date")
        ? localStorage.getItem("great_schools_date")
        : formdata.great_schools_date,
      healthgrades_date: formdata.healthgrades_date,
      mysite_date: localStorage.getItem("mysite_date")
        ? localStorage.getItem("mysite_date")
        : formdata.mysite_date,
      thumbtack_date: localStorage.getItem("thumbtack_date")
        ? localStorage.getItem("thumbtack_date")
        : formdata.thumbtack_date,
      amazon_date: localStorage.getItem("amazon_date")
        ? localStorage.getItem("amazon_date")
        : formdata.amazon_date,
      view_google: localStorage.getItem("view_google")
        ? localStorage.getItem("view_google")
        : formdata.view_google,
      view_google2: localStorage.getItem("view_google2")
        ? localStorage.getItem("view_google2")
        : formdata.view_google2,
      view_yelp: localStorage.getItem("view_yelp")
        ? localStorage.getItem("view_yelp")
        : formdata.view_yelp,
      view_fb: localStorage.getItem("view_fb")
        ? localStorage.getItem("view_fb")
        : formdata.view_fb,
      view_zillow: localStorage.getItem("view_zillow")
        ? localStorage.getItem("view_zillow")
        : formdata.view_zillow,
      view_yp: localStorage.getItem("view_yp")
        ? localStorage.getItem("view_yp")
        : formdata.view_yp,
      view_trip: localStorage.getItem("view_trip")
        ? localStorage.getItem("view_trip")
        : formdata.view_trip,
      view_yell: localStorage.getItem("view_yell")
        ? localStorage.getItem("view_yell")
        : formdata.view_yell,
      setname: localStorage.getItem("setname")
        ? localStorage.getItem("setname")
        : formdata.setname,
      businees_name: localStorage.getItem("businees_name")
        ? localStorage.getItem("businees_name")
        : formdata.businees_name,
      color_code: formdata.background ? formdata.background : "#000000",
      logo: localStorage.getItem("logo1")
        ? localStorage.getItem("logo1")
        : formdata.logo1,
      review_msg: localStorage.getItem("review_msg")
        ? localStorage.getItem("review_msg")
        : formdata.review_msg,
      yes_msg: localStorage.getItem("sf_ratings")
        ? localStorage.getItem("sf_ratings")
        : formdata.yes_msg,
      no_msg: localStorage.getItem("no_msg")
        ? localStorage.getItem("no_msg")
        : formdata.no_msg,
      subject: localStorage.getItem("subject")
        ? localStorage.getItem("subject")
        : formdata.subject,
      subject1: localStorage.getItem("subject1")
        ? localStorage.getItem("subject1")
        : formdata.subject1,
      sign: localStorage.getItem("sign")
        ? localStorage.getItem("sign")
        : formdata.sign,
      sign1: localStorage.getItem("sign1")
        ? localStorage.getItem("sign1")
        : formdata.sign1,
      thanks_msg: localStorage.getItem("thanks_msg")
        ? localStorage.getItem("thanks_msg")
        : formdata.thanks_msg,
      thanks_msg1: localStorage.getItem("thanks_msg1")
        ? localStorage.getItem("thanks_msg1")
        : formdata.thanks_msg1,
      sendlogo: localStorage.getItem("sendlogo")
        ? localStorage.getItem("sendlogo")
        : formdata.sendlogo,
      sendlogo1: localStorage.getItem("sendlogo1")
        ? localStorage.getItem("sendlogo1")
        : formdata.sendlogo1,
      sendlogoemail: localStorage.getItem("sendlogoemail")
        ? localStorage.getItem("sendlogoemail")
        : formdata.sendlogoemail,
      sendlogoemail1: localStorage.getItem("sendlogoemail1")
        ? localStorage.getItem("sendlogoemail1")
        : formdata.sendlogoemail1,
      underlogo: localStorage.getItem("underlogo")
        ? localStorage.getItem("underlogo")
        : formdata.underlogo,
      coupon: localStorage.getItem("logo2")
        ? localStorage.getItem("logo2")
        : formdata.logo2,
      setfeed: localStorage.getItem("setfeed")
        ? localStorage.getItem("setfeed")
        : formdata.setfeed,
      loc_code: localStorage.getItem("loc_code")
        ? localStorage.getItem("loc_code")
        : formdata.loc_code,
      corp_code: localStorage.getItem("corp_code")
        ? localStorage.getItem("corp_code")
        : formdata.corp_code,
      corp_pass: localStorage.getItem("corp_pass")
        ? localStorage.getItem("corp_pass")
        : formdata.corp_pass,
      corp_login: localStorage.getItem("corp_login")
        ? localStorage.getItem("corp_login")
        : formdata.corp_login,
      when_to_send: localStorage.getItem("when_to_send")
        ? localStorage.getItem("when_to_send")
        : formdata.when_to_send,
      auto_sent_text: localStorage.getItem("auto_sent_text")
        ? localStorage.getItem("auto_sent_text")
        : formdata.auto_sent_text,
      mysite_count: localStorage.getItem("mysite_count")
        ? localStorage.getItem("mysite_count")
        : formdata.mysite_count,
      sms_msg1: localStorage.getItem("sf_ratings")
        ? localStorage.getItem("sf_ratings")
        : formdata.sms_msg1,
      sms_msg: localStorage.getItem("sms_msg")
        ? localStorage.getItem("sms_msg")
        : formdata.sms_msg,
      yelp_date: localStorage.getItem("yelp_date")
        ? localStorage.getItem("yelp_date")
        : formdata.yelp_date,
      google_count2: localStorage.getItem("google_count2")
        ? localStorage.getItem("google_count2")
        : formdata.google_count2,
      yelp_ratings: localStorage.getItem("yelp_ratings")
        ? localStorage.getItem("yelp_ratings")
        : formdata.yelp_ratings,
      yp_count: localStorage.getItem("yp_count")
        ? localStorage.getItem("yp_count")
        : formdata.yp_count,
      google_ratings2: localStorage.getItem("google_ratings2")
        ? localStorage.getItem("google_ratings2")
        : formdata.google_ratings2,
        solar_review: localStorage.getItem("solar_review")
        ? localStorage.getItem("solar_review")
        : formdata.solar_review,
       
        solar_review_view: localStorage.getItem("solar_review_view")
        ? localStorage.getItem("solar_review_view")
        : formdata.solar_review_view,
      
        home_advisor: localStorage.getItem("home_advisor")
        ? localStorage.getItem("home_advisor")
        : formdata.home_advisor,
       
        home_advisor_view: localStorage.getItem("home_advisor_view")
        ? localStorage.getItem("home_advisor_view")
        : formdata.home_advisor_view,
       
        energysage: localStorage.getItem("energysage")
        ? localStorage.getItem("energysage")
        : formdata.energysage,
       
        energysage_view: localStorage.getItem("energysage_view")
        ? localStorage.getItem("energysage_view")
        : formdata.energysage_view,

     
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };
    const apiedit = API + "api/location_update";
    const apiadd = API + "api/location_add";
    if (props.data.id) {
      axios
        .post(apiedit, postData, axiosConfig)
        .then((res) => {
          setShowLoading(false);
          alert(res.data.message);
          back();
        })
        .catch((err) => {
          //console.log("AXIOS ERROR: ", err);
        });
    } else {
      axios
        .post(apiadd, postData, axiosConfig)
        .then((res) => {
          alert(res.data.message);
          setShowLoading(false);
          Object.keys(props.data).map((item) => {
            if (item != "client_id") {
              localStorage.removeItem(item);
            }
          });
          back();
        })
        .catch((err) => {
          //console.log("AXIOS ERROR: ", err);
        });
    }
  };

  return (
    <LoadingOverlay
      active={showLoading}
      spinner={<RiseLoader sizeUnit={"px"} size={80} color={"#2a8dd1"} />}
    >
      <div>
        <div className="review">
          <div className="row">
            <div className="col-md-12">
              <div className="card border-top-0 border-right-0 border-left-0 pb-4">
                <div className="card-header bg-white ">
                  <div className="row">
                    <div className="col-md-12">
                      <h5 className="card-title mb-0 pt-2">Locations</h5>
                      <span>All * fields are mandatory </span>
                    </div>
                    <div className="col-md-12 text-right">
                      <input
                        type="button"
                        disabled={!validateForm()}
                        onClick={() => formSubmit()}
                        value="Save"
                        className="btn btn-success save-btn font-weight-bold"
                      />
                      <input
                        type="button"
                        onClick={() => reset()}
                        value="Reset"
                        className="margin btn btn-warning save-btn font-weight-bold"
                      />
                      <input
                        type="button"
                        onClick={() => back()}
                        value="Back"
                        className="margin btn btn-primary save-btn font-weight-bold"
                      />
                    </div>
                  </div>
                </div>
                <Form encType="multipart/form-data">
                  <Tabs
                    defaultActiveKey="profile"
                    id="uncontrolled-tab-example"
                  >
                    <Tab eventKey="profile" title="Location Profile">
                      <div className="card-body">
                        <div id="introduction">
                          <div className="row">
                            <div className="form-group col-md-6 ">
                              {localStorage.getItem("client_id") === "000" ? (
                                <select
                                  className="form-control"
                                  id="client_id"
                                  name="client_id"
                                  value={formdata.client_id}
                                  onChange={handleChange}
                                >
                                  <option value="">
                                    Please Select Company Name :
                                  </option>
                                  {clinetlist.map((item, idx) => (
                                    <option
                                      selected={
                                        formdata.client_id === item.client_id
                                      }
                                      key={idx}
                                      value={item.client_id}
                                    >
                                      {item.company_name}
                                    </option>
                                  ))}
                                </select>
                              ) : (
                                <input
                                  type="hidden"
                                  name="client_id"
                                  id="client_id"
                                  className="form-control"
                                  placeholder="client_id"
                                  value={localStorage.getItem("client_id")}
                                />
                              )}

                              <input
                                type="hidden"
                                name="id"
                                id="id"
                                className="form-control"
                                placeholder="id"
                                value={formdata.id}
                              />
                              <label>
                                Company Name
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <input
                                  type="checkbox"
                                  name="setname"
                                  id="setname"
                                  className="form-check-input is-valid"
                                  checked={formdata.setname == 1}
                                  value={formdata.setname}
                                  onChange={handleChange}
                                />
                                Do you want to set another business name?{" "}
                              </label>
                            </div>

                            <div className={style + " form-group col-md-4"}>
                              <input
                                type="text"
                                name="businees_name"
                                id="businees_name"
                                className="form-control"
                                placeholder="Business Name"
                                value={
                                  localStorage.getItem("businees_name")
                                    ? localStorage.getItem("businees_name")
                                    : formdata.businees_name
                                }
                                onChange={handleChange}
                              />
                              <label>Business Name</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="text"
                                name="l_name"
                                id="l_name"
                                className="form-control"
                                placeholder="Location Name"
                                value={
                                  localStorage.getItem("l_name")
                                    ? localStorage.getItem("l_name")
                                    : formdata.l_name
                                }
                                onChange={handleChange}
                              />
                              <label>
                                Location Name{" "}
                                <span className="text-danger">
                                  (this field is required)
                                </span>{" "}
                                :
                              </label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="text"
                                name="add1"
                                id="add1"
                                className="form-control"
                                placeholder="Address"
                                value={
                                  localStorage.getItem("add1")
                                    ? localStorage.getItem("add1")
                                    : formdata.add1
                                }
                                onChange={handleChange}
                              />
                              <label>Address :</label>
                            </div>

                            {/* <div className="form-group col-md-6 ">
                              <input
                                type="text"
                                name="add2"
                                id="add2"
                                className="form-control"
                                placeholder="Address 2"
                                value={
                                  localStorage.getItem("add2")
                                    ? localStorage.getItem("add2")
                                    : formdata.add2
                                }
                                onChange={handleChange}
                              />
                              <label>Address2 :</label>
                            </div> */}
                            <div className="form-group col-md-6 ">
                              <input
                                type="text"
                                name="city"
                                id="city"
                                className="form-control"
                                placeholder="City"
                                value={
                                  localStorage.getItem("city")
                                    ? localStorage.getItem("city")
                                    : formdata.city
                                }
                                onChange={handleChange}
                              />
                              <label>City :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="text"
                                name="state"
                                id="state"
                                className="form-control"
                                placeholder="State"
                                value={
                                  localStorage.getItem("state")
                                    ? localStorage.getItem("state")
                                    : formdata.state
                                }
                                onChange={handleChange}
                              />
                              <label>State :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="text"
                                name="zip"
                                id="zip"
                                className="form-control"
                                placeholder="Zip Code"
                                value={
                                  localStorage.getItem("zip")
                                    ? localStorage.getItem("zip")
                                    : formdata.zip
                                }
                                onChange={handleChange}
                              />
                              <label>Zip :</label>
                            </div>

                            <div className="form-group col-md-6 ">
                              <input
                                type="number"
                                name="c_no"
                                id="c_no"
                                className="form-control"
                                placeholder="Contact No "
                                value={
                                  localStorage.getItem("c_no")
                                    ? localStorage.getItem("c_no")
                                    : formdata.c_no
                                }
                                onChange={handleChange}
                              />
                              <label>Contact No :</label>
                            </div>

                            <div className="form-group col-md-6 ">
                              <input
                                type="email"
                                name="c_email"
                                id="c_email"
                                className="form-control"
                                placeholder="Contact Email "
                                value={
                                  localStorage.getItem("c_email")
                                    ? localStorage.getItem("c_email")
                                    : formdata.c_email
                                }
                                onChange={handleChange}
                              />
                              <label>Contact Email : </label>
                            </div>

                            <div
                              className={
                                 "form-group col-md-6"
                              }
                            >
                              <input
                                type="text"
                                name="loc_code"
                                id="loc_code"
                                className="form-control"
                                placeholder="SiteLink Location Code "
                                value={
                                  localStorage.getItem("loc_code")
                                    ? localStorage.getItem("loc_code")
                                    : formdata.loc_code
                                }
                                onChange={handleChange}
                              />
                              <label>SiteLink Location Code : </label>
                            </div>

                            <div
                              className={
                                 "form-group col-md-6"
                              }
                            >
                              <input
                                type="text"
                                name="corp_code"
                                id="corp_code"
                                className="form-control"
                                placeholder="SiteLink Crop Code  "
                                value={
                                  localStorage.getItem("corp_code")
                                    ? localStorage.getItem("corp_code")
                                    : formdata.corp_code
                                }
                                onChange={handleChange}
                              />
                              <label>SiteLink Crop Code : </label>
                            </div>

                            <div
                              className={
                                 "form-group col-md-6"
                              }
                            >
                              <input
                                type="text"
                                name="corp_pass"
                                id="corp_pass"
                                className="form-control"
                                placeholder="SiteLink Corp Password  "
                                value={
                                  localStorage.getItem("corp_pass")
                                    ? localStorage.getItem("corp_pass")
                                    : formdata.corp_pass
                                }
                                onChange={handleChange}
                              />
                              <label>SiteLink Corp Password : </label>
                            </div>

                            <div
                              className={
                                
                                  "form-group col-md-6 "
                                  
                              }
                            >
                              <input
                                type="text"
                                name="corp_login"
                                id="corp_login"
                                className="form-control"
                                placeholder="SiteLink Corp Login "
                                value={
                                  localStorage.getItem("corp_login")
                                    ? localStorage.getItem("corp_login")
                                    : formdata.corp_login
                                }
                                onChange={handleChange}
                              />
                              <label>SiteLink Corp Login : </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                    {localStorage.getItem("client_id") === "000" ?                     <Tab
                      eventKey="review_link"
                      title="Review Link"
                      className={
                        localStorage.getItem("client_id") === "000"
                          ? ""
                          : "hidden"
                      }
                    >
                      <div className="card-body">
                        <div id="introduction">
                          <div className="row">
                            <div className="form-group col-md-6 ">
                              <input
                                type="url"
                                name="google_link"
                                id="google_link"
                                className="form-control"
                                placeholder="www.google.com"
                                value={
                                  localStorage.getItem("google_link")
                                    ? localStorage.getItem("google_link")
                                    : formdata.google_link
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Google Review Link : </label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="url"
                                name="view_google"
                                id="view_google"
                                className="form-control"
                                placeholder="www.google.com"
                                value={
                                  localStorage.getItem("view_google")
                                    ? localStorage.getItem("view_google")
                                    : formdata.view_google
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>View Google Reviews Link : </label>
                            </div>

                            <div className="form-group col-md-6 ">
                              <input
                                type="url"
                                name="google_link2"
                                id="google_link2"
                                className="form-control"
                                placeholder="www.google.com"
                                value={
                                  localStorage.getItem("google_link2")
                                    ? localStorage.getItem("google_link2")
                                    : formdata.google_link2
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Google2 Review Link : </label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="url"
                                name="view_google2"
                                id="view_google2"
                                className="form-control"
                                placeholder="www.google.com"
                                value={
                                  localStorage.getItem("view_google2")
                                    ? localStorage.getItem("view_google2s")
                                    : formdata.view_google2
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>View Google2 Reviews Link : </label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="url"
                                name="yelp_link"
                                id="yelp_link"
                                className="form-control"
                                placeholder="www.yelp.com "
                                value={
                                  localStorage.getItem("yelp_link")
                                    ? localStorage.getItem("yelp_link")
                                    : formdata.yelp_link
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Yelp Review Link :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="url"
                                name="view_yelp"
                                id="view_yelp"
                                className="form-control"
                                placeholder="www.yelp.com "
                                value={
                                  localStorage.getItem("view_yelp")
                                    ? localStorage.getItem("view_yelp")
                                    : formdata.view_yelp
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>View Yelp Reviews Link :</label>
                            </div>

                            <div className="form-group col-md-6 ">
                              <input
                                type="url"
                                name="fb_link"
                                id="fb_link"
                                className="form-control"
                                placeholder="www.facebook.com "
                                value={
                                  localStorage.getItem("fb_link")
                                    ? localStorage.getItem("fb_link")
                                    : formdata.fb_link
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Facebook Review Link :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="url"
                                name="view_fb"
                                id="view_fb"
                                className="form-control"
                                placeholder="www.facebook.com "
                                value={
                                  localStorage.getItem("view_fb")
                                    ? localStorage.getItem("view_fb")
                                    : formdata.view_fb
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>View FB Reviews Link :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="url"
                                name="fb_link2"
                                id="fb_link2"
                                className="form-control"
                                placeholder="www.facebook.com "
                                value={
                                  localStorage.getItem("fb_link2")
                                    ? localStorage.getItem("fb_link2")
                                    : formdata.fb_link2
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Facebook2 Review Link :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="url"
                                name="view_fb2"
                                id="view_fb2"
                                className="form-control"
                                placeholder="www.facebook.com "
                                value={
                                  localStorage.getItem("view_fb2")
                                    ? localStorage.getItem("view_fb2")
                                    : formdata.view_fb2
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>View FB2 Reviews Link :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="url"
                                name="zillow_link"
                                id="zillow_link"
                                className="form-control"
                                placeholder="www.zillow.com "
                                value={
                                  localStorage.getItem("zillow_link")
                                    ? localStorage.getItem("zillow_link")
                                    : formdata.zillow_link
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Zillow Review Link :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="url"
                                name="view_zillow"
                                id="view_zillow"
                                className="form-control"
                                placeholder="www.zillow.com/ "
                                value={
                                  localStorage.getItem("view_zillow")
                                    ? localStorage.getItem("view_zillow")
                                    : formdata.view_zillow
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>View Zillow Review Link :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="url"
                                name="yp_link"
                                id="yp_link"
                                className="form-control"
                                placeholder="www.yellowpages.com/ "
                                value={
                                  localStorage.getItem("yp_link")
                                    ? localStorage.getItem("yp_link")
                                    : formdata.yp_link
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Yellow Pages Reviews Link :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="url"
                                name="view_yp"
                                id="view_yp"
                                className="form-control"
                                placeholder="www.yellowpages.com/ "
                                value={
                                  localStorage.getItem("view_yp")
                                    ? localStorage.getItem("view_yp")
                                    : formdata.view_yp
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>View Yellow Pages Reviews Link :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="url"
                                name="trip_link"
                                id="trip_link"
                                className="form-control"
                                placeholder="www.tripadvisor.com/"
                                value={
                                  localStorage.getItem("trip_link")
                                    ? localStorage.getItem("trip_link")
                                    : formdata.trip_link
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Trip Advisor Review Link :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="url"
                                name="view_trip"
                                id="view_trip"
                                className="form-control"
                                placeholder="www.tripadvisor.com/"
                                value={
                                  localStorage.getItem("view_trip")
                                    ? localStorage.getItem("view_trip")
                                    : formdata.view_trip
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>View Trip Advisor Reviews Link :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="url"
                                name="yell_link"
                                id="yell_link"
                                className="form-control"
                                placeholder="www.yell.com/"
                                value={
                                  localStorage.getItem("yell_link")
                                    ? localStorage.getItem("yell_link")
                                    : formdata.yell_link
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Yell Review Link :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="url"
                                name="view_yell"
                                id="view_yell"
                                className="form-control"
                                placeholder="www.yell.com/"
                                value={
                                  localStorage.getItem("view_yell")
                                    ? localStorage.getItem("view_yell")
                                    : formdata.view_yell
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>View yell Reviews Link :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="url"
                                name="sf_link"
                                id="sf_link"
                                className="form-control"
                                placeholder="www.sparefoot.com/"
                                value={
                                  localStorage.getItem("sf_link")
                                    ? localStorage.getItem("sf_link")
                                    : formdata.sf_link
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>SpareFoot Review Link :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="url"
                                name="view_sf"
                                id="view_sf"
                                className="form-control"
                                placeholder="www.sparefoot.com/"
                                value={
                                  localStorage.getItem("view_sf")
                                    ? localStorage.getItem("view_sf")
                                    : formdata.view_sf
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>View SpareFoot Reviews Link :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="url"
                                name="real_link"
                                id="real_link"
                                className="form-control"
                                placeholder="www.realtor.com/"
                                value={
                                  localStorage.getItem("real_link")
                                    ? localStorage.getItem("real_link")
                                    : formdata.real_link
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Realtor Review Link :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="url"
                                name="view_real"
                                id="view_real"
                                className="form-control"
                                placeholder="www.realtor.com/"
                                value={
                                  localStorage.getItem("view_real")
                                    ? localStorage.getItem("view_real")
                                    : formdata.view_real
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>View Realtor Reviews Link :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="url"
                                name="apar_link"
                                id="apar_link"
                                className="form-control"
                                placeholder="www.apartments.com/"
                                value={
                                  localStorage.getItem("apar_link")
                                    ? localStorage.getItem("apar_link")
                                    : formdata.apar_link
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Apartments.com Review Link :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="url"
                                name="view_apar"
                                id="view_apar"
                                className="form-control"
                                placeholder="www.apartments.com/"
                                value={
                                  localStorage.getItem("view_apar")
                                    ? localStorage.getItem("view_apar")
                                    : formdata.view_apar
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>View Apartments.com Reviews Link :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="url"
                                name="aparrate_link"
                                id="aparrate_link"
                                className="form-control"
                                placeholder="www.apartmentratings.com/"
                                value={
                                  localStorage.getItem("aparrate_link")
                                    ? localStorage.getItem("aparrate_link")
                                    : formdata.aparrate_link
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>ApartmentRatings Review Link :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="url"
                                name="view_aparrate"
                                id="view_aparrate"
                                className="form-control"
                                placeholder="www.apartmentratings.com/"
                                value={
                                  localStorage.getItem("view_aparrate")
                                    ? localStorage.getItem("view_aparrate")
                                    : formdata.view_aparrate
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>
                                View ApartmentRatings Reviews Link :
                              </label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="url"
                                name="great_schools_link"
                                id="great_schools_link"
                                className="form-control"
                                placeholder="www.greatschools.org/"
                                value={
                                  localStorage.getItem("great_schools_link")
                                    ? localStorage.getItem("great_schools_link")
                                    : formdata.great_schools_link
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Great Schools Review Link :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="url"
                                name="view_great_schools"
                                id="view_great_schools"
                                className="form-control"
                                placeholder="www.greatschools.org/"
                                value={
                                  localStorage.getItem("view_great_schools")
                                    ? localStorage.getItem("view_great_schools")
                                    : formdata.view_great_schools
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>View Great Schools Reviews Link :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="url"
                                name="healthgrades_link"
                                id="healthgrades_link"
                                className="form-control"
                                placeholder="https://www.healthgrades.com/"
                                value={
                                  localStorage.getItem("healthgrades_link")
                                    ? localStorage.getItem("healthgrades_link")
                                    : formdata.healthgrades_link
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Healthgrades Review Link :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="url"
                                name="view_healthgrades"
                                id="view_healthgrades"
                                className="form-control"
                                placeholder="https://www.healthgrades.com/"
                                value={
                                  localStorage.getItem("view_healthgrades")
                                    ? localStorage.getItem("view_healthgrades")
                                    : formdata.view_healthgrades
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>View Healthgrades Reviews Link :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="url"
                                name="mysite_link"
                                id="mysite_link"
                                className="form-control"
                                placeholder="https://www.website.com/"
                                value={
                                  localStorage.getItem("mysite_link")
                                    ? localStorage.getItem("mysite_link")
                                    : formdata.mysite_link
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Our Website Review Link :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="url"
                                name="view_mysite"
                                id="view_mysite"
                                className="form-control"
                                placeholder="https://www.website.com/"
                                value={
                                  localStorage.getItem("view_mysite")
                                    ? localStorage.getItem("view_mysite")
                                    : formdata.view_mysite
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>View Our Website Reviews Link :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="url"
                                name="thumbtack_link"
                                id="thumbtack_link"
                                className="form-control"
                                placeholder="https://www.thumbtack.com/"
                                value={
                                  localStorage.getItem("thumbtack_link")
                                    ? localStorage.getItem("thumbtack_link")
                                    : formdata.thumbtack_link
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Thumbtack Review Link :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="url"
                                name="view_thumbtack"
                                id="view_thumbtack"
                                className="form-control"
                                placeholder="https://www.thumbtack.com/"
                                value={
                                  localStorage.getItem("view_thumbtack")
                                    ? localStorage.getItem("view_thumbtack")
                                    : formdata.view_thumbtack
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>View Thumbtack Reviews Link :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="url"
                                name="amazon_link"
                                id="amazon_link"
                                className="form-control"
                                placeholder="https://www.amazon.com/"
                                value={
                                  localStorage.getItem("amazon_link")
                                    ? localStorage.getItem("amazon_link")
                                    : formdata.amazon_link
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Amazon Review Link :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="url"
                                name="view_amazon"
                                id="view_amazon"
                                className="form-control"
                                placeholder="https://www.amazon.com/"
                                value={
                                  localStorage.getItem("view_amazon")
                                    ? localStorage.getItem("view_amazon")
                                    : formdata.view_amazon
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>View Amazon Reviews Link :</label>
                            </div>




                      <div className="form-group col-md-6 ">
                              <input
                                type="url"
                                name="solar_review"
                                id="solar_review"
                                className="form-control"
                                placeholder="https://www.solar.com/"
                                value={
                                  localStorage.getItem("solar_review")
                                    ? localStorage.getItem("solar_review")
                                    : formdata.solar_review
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Solar Review Link :</label>
                            </div>

                            <div className="form-group col-md-6 ">
                              <input
                                type="url"
                                name="solar_review_view"
                                id="solar_review_view"
                                className="form-control"
                                placeholder="https://www.solar.com/"
                                value={
                                  localStorage.getItem("solar_review_view")
                                    ? localStorage.getItem("solar_review_view")
                                    : formdata.solar_review_view
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label> View Solar Review Link :</label>
                            </div>



                            <div className="form-group col-md-6 ">
                              <input
                                type="url"
                                name="energysage"
                                id="energysage"
                                className="form-control"
                                placeholder="https://www.energysage.com/"
                                value={
                                  localStorage.getItem("energysage")
                                    ? localStorage.getItem("energysage")
                                    : formdata.energysage
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Energysage Link :</label>
                            </div>




                      <div className="form-group col-md-6 ">
                              <input
                                type="url"
                                name="energysage_view"
                                id="energysage_view"
                                className="form-control"
                                placeholder="https://www.energysage.com/"
                                value={
                                  localStorage.getItem("energysage_view")
                                    ? localStorage.getItem("energysage_view")
                                    : formdata.energysage_view
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label> View Energysage Link :</label>
                            </div>


                      <div className="form-group col-md-6 ">
                              <input
                                type="url"
                                name="home_advisor"
                                id="home_advisor"
                                className="form-control"
                                placeholder="https://www.home_advisor.com/"
                                value={
                                  localStorage.getItem("home_advisor")
                                    ? localStorage.getItem("home_advisor")
                                    : formdata.home_advisor
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Home  Advisor Link :</label>
                            </div>




                      <div className="form-group col-md-6 ">
                              <input
                                type="url"
                                name="home_advisor_view"
                                id="home_advisor_view"
                                className="form-control"
                                placeholder="https://www.home_advisor.com/"
                                value={
                                  localStorage.getItem("home_advisor_view")
                                    ? localStorage.getItem("home_advisor_view")
                                    : formdata.home_advisor_view
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label> View Home  Advisor Link :</label>
                            </div>
                          </div>
                        </div>
                      </div>




                   

                            
                    </Tab> : "" }
                    {localStorage.getItem("client_id") === "000" ?
                    <Tab
                      eventKey="review_count"
                      title="Review Count"
                      className={
                        localStorage.getItem("client_id") === "000"
                          ? ""
                          : "hidden"
                      }
                    >
                      <div className="card-body">
                        <div id="introduction">
                          <div className="row">
                            <div className="form-group col-md-6 ">
                              <input
                                type="text"
                                name="google_account"
                                id="google_account"
                                className="form-control"
                                placeholder="Google Account Name"
                                value={
                                  localStorage.getItem("google_account")
                                    ? localStorage.getItem("google_account")
                                    : formdata.google_account
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Google Account Name :</label>
                              <div className="col-sm-2">
                                <a
                                  data-toggle="modal"
                                  data-target={"#myModal1"}
                                >
                                  <strong>Help?</strong>
                                </a>
                              </div>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="number"
                                name="google_count"
                                id="google_count"
                                className="form-control"
                                placeholder=""
                                value={
                                  localStorage.getItem("google_count")
                                    ? localStorage.getItem("google_count")
                                    : formdata.google_count
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Google Review Count :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="number"
                                name="google_ratings"
                                id="google_ratings"
                                className="form-control"
                                value={
                                  localStorage.getItem("google_ratings")
                                    ? localStorage.getItem("google_ratings")
                                    : formdata.google_ratings
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Google Avg Ratings :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="date"
                                name="google_date"
                                id="google_date"
                                className="form-control"
                                value={
                                  localStorage.getItem("google_date")
                                    ? localStorage.getItem("google_date")
                                    : formdata.google_date
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Google Review Date :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="text"
                                name="google_account2"
                                id="google_account2"
                                className="form-control"
                                placeholder="Google2 Account Name"
                                value={
                                  localStorage.getItem("google_account2")
                                    ? localStorage.getItem("google_account2")
                                    : formdata.google_account2
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Google2 Account Name :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="number"
                                name="google_count2"
                                id="google_count2"
                                className="form-control"
                                placeholder=""
                                value={
                                  localStorage.getItem("google_count2")
                                    ? localStorage.getItem("google_count2")
                                    : formdata.google_count2
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Google2 Review Count :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="number"
                                name="google_ratings2"
                                id="google_ratings2"
                                className="form-control"
                                value={
                                  localStorage.getItem("google_ratings2")
                                    ? localStorage.getItem("google_ratings2")
                                    : formdata.google_ratings2
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Google2 Avg Ratings :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="date"
                                name="google_date2"
                                id="google_date2"
                                className="form-control"
                                value={
                                  localStorage.getItem("google_date2")
                                    ? localStorage.getItem("google_date2")
                                    : formdata.google_date2
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Google2 Review Date :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="text"
                                name="fb_account"
                                id="fb_account"
                                className="form-control"
                                placeholder="Facebook Account Name"
                                value={
                                  localStorage.getItem("fb_account")
                                    ? localStorage.getItem("fb_account")
                                    : formdata.fb_account
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Facebook Account Name :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="number"
                                name="fb_count"
                                id="fb_count"
                                className="form-control"
                                value={
                                  localStorage.getItem("fb_count")
                                    ? localStorage.getItem("fb_count")
                                    : formdata.fb_count
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Facebook Review Count :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="number"
                                name="fb_ratings"
                                id="fb_ratings"
                                className="form-control"
                                value={
                                  localStorage.getItem("fb_ratings")
                                    ? localStorage.getItem("fb_ratings")
                                    : formdata.fb_ratings
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Facebook Avg Ratings :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="date"
                                name="fb_date"
                                id="fb_date"
                                className="form-control"
                                value={
                                  localStorage.getItem("fb_date")
                                    ? localStorage.getItem("fb_date")
                                    : formdata.fb_date
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Facebook Review Date :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="text"
                                name="fb_account2"
                                id="fb_account2"
                                className="form-control"
                                placeholder="Facebook2 Account Name"
                                value={
                                  localStorage.getItem("fb_account2")
                                    ? localStorage.getItem("fb_account2")
                                    : formdata.fb_account2
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Facebook2 Account Name :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="number"
                                name="fb_count2"
                                id="fb_count2"
                                className="form-control"
                                value={
                                  localStorage.getItem("fb_count2")
                                    ? localStorage.getItem("fb_count2")
                                    : formdata.fb_count2
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Facebook2 Account Count :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="number"
                                name="fb_ratings2"
                                id="fb_ratings2"
                                className="form-control"
                                value={
                                  localStorage.getItem("fb_ratings2")
                                    ? localStorage.getItem("fb_ratings2")
                                    : formdata.fb_ratings2
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Facebook2 Avg Ratings :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="date"
                                name="fb_date2"
                                id="fb_date2"
                                className="form-control"
                                value={
                                  localStorage.getItem("fb_date2")
                                    ? localStorage.getItem("fb_date2")
                                    : formdata.fb_date2
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Facebook2 Review Date :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="text"
                                name="zillow_account"
                                id="zillow_account"
                                className="form-control"
                                placeholder="Zillow Account Name"
                                value={
                                  localStorage.getItem("zillow_account")
                                    ? localStorage.getItem("zillow_account")
                                    : formdata.zillow_account
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Zillow Account Name :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="number"
                                name="zillow_count"
                                id="zillow_count"
                                className="form-control"
                                min="0"
                                value={
                                  localStorage.getItem("zillow_count")
                                    ? localStorage.getItem("zillow_count")
                                    : formdata.zillow_count
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Zillow Review Count :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="number"
                                name="zillow_ratings"
                                id="zillow_ratings"
                                className="form-control"
                                value={
                                  localStorage.getItem("zillow_ratings")
                                    ? localStorage.getItem("zillow_ratings")
                                    : formdata.zillow_ratings
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                                min="0"
                                step="0.1"
                                max="5"
                              />
                              <label>Zillow Avg Ratings :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="date"
                                name="zillow_date"
                                id="zillow_date"
                                className="form-control"
                                value={
                                  localStorage.getItem("zillow_date")
                                    ? localStorage.getItem("zillow_date")
                                    : formdata.zillow_date
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Zillow Review Date :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="text"
                                name="yp_account"
                                id="yp_account"
                                className="form-control"
                                placeholder="Yellow Pages Account Name"
                                value={
                                  localStorage.getItem("yp_account")
                                    ? localStorage.getItem("yp_account")
                                    : formdata.yp_account
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Yellow Pages Account Name :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="number"
                                name="yp_count"
                                id="yp_count"
                                className="form-control"
                                value={
                                  localStorage.getItem("yp_count")
                                    ? localStorage.getItem("yp_count")
                                    : formdata.yp_count
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Yellow Pages Review Count :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="number"
                                name="yp_ratings"
                                id="yp_ratings"
                                className="form-control"
                                value={
                                  localStorage.getItem("yp_ratings")
                                    ? localStorage.getItem("yp_ratings")
                                    : formdata.yp_ratings
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                                min="0"
                                max="5"
                                step="0.1"
                              />
                              <label>Yellow Pages Avg Ratings :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="date"
                                name="yp_date"
                                id="yp_date"
                                className="form-control"
                                value={
                                  localStorage.getItem("yp_date")
                                    ? localStorage.getItem("yp_date")
                                    : formdata.yp_date
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Yellow Pages Review Date :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="text"
                                name="trip_account"
                                id="trip_account"
                                className="form-control"
                                placeholder="Trip Advisor Account Name"
                                value={
                                  localStorage.getItem("trip_account")
                                    ? localStorage.getItem("trip_account")
                                    : formdata.trip_account
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Trip Advisor Account Name :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="number"
                                name="trip_count"
                                id="trip_count"
                                className="form-control"
                                value={
                                  localStorage.getItem("trip_count")
                                    ? localStorage.getItem("trip_count")
                                    : formdata.trip_count
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Trip Advisor Review Count :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="number"
                                name="trip_ratings"
                                id="trip_ratings"
                                className="form-control"
                                value={
                                  localStorage.getItem("trip_ratings")
                                    ? localStorage.getItem("trip_ratings")
                                    : formdata.trip_ratings
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                                min="0"
                                max="5"
                                step="0.1"
                              />
                              <label>Trip Advisor Avg Ratings :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="date"
                                name="trip_date"
                                id="trip_date"
                                className="form-control"
                                placeholder="Trip Advisor Review Date"
                                value={
                                  localStorage.getItem("trip_date")
                                    ? localStorage.getItem("trip_date")
                                    : formdata.trip_date
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                                min="0"
                                max="5"
                                step="0.1"
                              />
                              <label>Trip Advisor Review Date :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="text"
                                name="yell_account"
                                id="yell_account"
                                className="form-control"
                                placeholder="Yell Account Name"
                                value={
                                  localStorage.getItem("yell_account")
                                    ? localStorage.getItem("yell_account")
                                    : formdata.yell_account
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Yell Account Name :</label>
                            </div>

                            <div className="form-group col-md-6 ">
                              <input
                                type="number"
                                name="yell_count"
                                id="yell_count"
                                className="form-control"
                                min="0"
                                value={
                                  localStorage.getItem("yell_count")
                                    ? localStorage.getItem("yell_count")
                                    : formdata.yell_count
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Yell Review Count :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="number"
                                name="yell_ratings"
                                id="yell_ratings"
                                className="form-control"
                                min="0"
                                max="5"
                                step="0.1"
                                value={
                                  localStorage.getItem("yell_ratings")
                                    ? localStorage.getItem("yell_ratings")
                                    : formdata.yell_ratings
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Yell Avg Ratings :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="date"
                                name="yell_date"
                                id="yell_date"
                                className="form-control"
                                value={
                                  localStorage.getItem("yell_date")
                                    ? localStorage.getItem("yell_date")
                                    : formdata.yell_date
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Yell Review Date :</label>
                            </div>

                            <div className="form-group col-md-6 ">
                              <input
                                type="text"
                                name="sf_account"
                                id="sf_account"
                                className="form-control"
                                placeholder="SpareFoot Account Name"
                                value={
                                  localStorage.getItem("sf_account")
                                    ? localStorage.getItem("sf_account")
                                    : formdata.sf_account
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>SpareFoot Account Name :</label>
                            </div>

                            <div className="form-group col-md-6 ">
                              <input
                                type="number"
                                name="sf_count"
                                id="sf_count"
                                min="0"
                                className="form-control"
                                value={
                                  localStorage.getItem("sf_count")
                                    ? localStorage.getItem("sf_count")
                                    : formdata.sf_count
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>SpareFoot Review Count :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="number"
                                name="sf_ratings"
                                id="sf_ratings"
                                min="0"
                                max="5"
                                step="0.1"
                                className="form-control"
                                value={
                                  localStorage.getItem("sf_ratings")
                                    ? localStorage.getItem("sf_ratings")
                                    : formdata.sf_ratings
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>SpareFoot Avg Ratings :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="date"
                                name="sf_date"
                                id="sf_date"
                                min="0"
                                max="5"
                                step="0.1"
                                className="form-control"
                                value={
                                  localStorage.getItem("sf_date")
                                    ? localStorage.getItem("sf_date")
                                    : formdata.sf_date
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>SpareFoot Review Date :</label>
                            </div>

                            <div className="form-group col-md-6 ">
                              <input
                                type="text"
                                name="real_account"
                                id="real_account"
                                className="form-control"
                                placeholder="Realtor Account Name"
                                value={
                                  localStorage.getItem("real_account")
                                    ? localStorage.getItem("real_account")
                                    : formdata.real_account
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Realtor Account Name :</label>
                            </div>

                            <div className="form-group col-md-6 ">
                              <input
                                type="number"
                                name="real_count"
                                id="real_count"
                                min="0"
                                className="form-control"
                                value={
                                  localStorage.getItem("real_count")
                                    ? localStorage.getItem("real_count")
                                    : formdata.real_count
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Realtor Review Count :</label>
                            </div>

                            <div className="form-group col-md-6 ">
                              <input
                                type="number"
                                name="real_ratings"
                                id="real_ratings"
                                className="form-control"
                                value={
                                  localStorage.getItem("real_ratings")
                                    ? localStorage.getItem("real_ratings")
                                    : formdata.real_ratings
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                                min="0"
                                max="5"
                                step="0.1"
                              />
                              <label>Realtor Avg Ratings :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="date"
                                name="real_date"
                                id="real_date"
                                className="form-control"
                                value={
                                  localStorage.getItem("real_date")
                                    ? localStorage.getItem("real_date")
                                    : formdata.real_date
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Realtor Review Date :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="text"
                                name="apar_account"
                                id="apar_account"
                                className="form-control"
                                placeholder="Apartments.com Account Name"
                                value={
                                  localStorage.getItem("apar_account")
                                    ? localStorage.getItem("apar_account")
                                    : formdata.apar_account
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Apartments.com Account Name :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="number"
                                name="apar_count"
                                id="apar_count"
                                className="form-control"
                                value={
                                  localStorage.getItem("apar_count")
                                    ? localStorage.getItem("apar_count")
                                    : formdata.apar_count
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Apartments.com Review Count :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="number"
                                name="apar_ratings"
                                id="apar_ratings"
                                min="0"
                                className="form-control"
                                value={
                                  localStorage.getItem("apar_ratings")
                                    ? localStorage.getItem("apar_ratings")
                                    : formdata.apar_ratings
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Apartments.com Avg Ratings :</label>
                            </div>

                            <div className="form-group col-md-6 ">
                              <input
                                type="date"
                                name="apar_date"
                                id="apar_date"
                                className="form-control"
                                value={
                                  localStorage.getItem("apar_date")
                                    ? localStorage.getItem("apar_date")
                                    : formdata.apar_date
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Apartments.com Review Date :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="text"
                                name="aparrate_account"
                                id="aparrate_account"
                                className="form-control"
                                placeholder="ApartmentRatings Account Name"
                                value={
                                  localStorage.getItem("aparrate_account")
                                    ? localStorage.getItem("aparrate_account")
                                    : formdata.aparrate_account
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>ApartmentRatings Account Name :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="number"
                                name="aparrate_count"
                                id="aparrate_count"
                                className="form-control"
                                placeholder="ApartmentRatings Review Count"
                                value={
                                  localStorage.getItem("aparrate_count")
                                    ? localStorage.getItem("aparrate_count")
                                    : formdata.aparrate_count
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>ApartmentRatings Review Count :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="number"
                                name="aparrate_ratings"
                                id="aparrate_ratings"
                                className="form-control"
                                value={
                                  localStorage.getItem("aparrate_ratings")
                                    ? localStorage.getItem("aparrate_ratings")
                                    : formdata.aparrate_ratings
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                                min="0"
                                max="5"
                                step="0.1"
                              />
                              <label>ApartmentRatings Avg Ratings :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="date"
                                name="aparrate_date"
                                id="aparrate_date"
                                className="form-control"
                                placeholder="yyyy-mm-dd"
                                value={
                                  localStorage.getItem("aparrate_date")
                                    ? localStorage.getItem("aparrate_date")
                                    : formdata.aparrate_date
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>ApartmentRatings Review Date :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="text"
                                name="great_schools_account"
                                id="great_schools_account"
                                className="form-control"
                                placeholder="Great Schools Account Name"
                                value={
                                  localStorage.getItem("great_schools_account")
                                    ? localStorage.getItem(
                                        "great_schools_account"
                                      )
                                    : formdata.great_schools_account
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Great Schools Account Name :</label>
                            </div>

                            <div className="form-group col-md-6 ">
                              <input
                                type="number"
                                name="great_schools_count"
                                id="great_schools_count"
                                className="form-control"
                                min="0"
                                value={
                                  localStorage.getItem("great_schools_count")
                                    ? localStorage.getItem(
                                        "great_schools_count"
                                      )
                                    : formdata.great_schools_count
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Great Schools Review Count :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="number"
                                name="great_schools_ratings"
                                id="great_schools_ratings"
                                className="form-control"
                                value={
                                  localStorage.getItem("great_schools_ratings")
                                    ? localStorage.getItem(
                                        "great_schools_ratings"
                                      )
                                    : formdata.great_schools_ratings
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                                min="0"
                                max="5"
                                step="0.1"
                              />
                              <label>Great Schools Avg Ratings :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="date"
                                name="great_schools_date"
                                id="great_schools_date"
                                className="form-control"
                                placeholder="yyyy-mm-dd"
                                value={
                                  localStorage.getItem("great_schools_date")
                                    ? localStorage.getItem("great_schools_date")
                                    : formdata.great_schools_date
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Great Schools Review Date :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="text"
                                name="healthgrades_account"
                                id="healthgrades_account"
                                className="form-control"
                                placeholder="Healthgrades Account Name"
                                value={
                                  localStorage.getItem("healthgrades_account")
                                    ? localStorage.getItem(
                                        "healthgrades_account"
                                      )
                                    : formdata.healthgrades_account
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Healthgrades Account Name :</label>
                            </div>

                            <div className="form-group col-md-6 ">
                              <input
                                type="number"
                                name="healthgrades_count"
                                id="healthgrades_count"
                                className="form-control"
                                min="0"
                                value={
                                  localStorage.getItem("healthgrades_count")
                                    ? localStorage.getItem("healthgrades_count")
                                    : formdata.healthgrades_count
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Healthgrades Review Count :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="number"
                                name="healthgrades_ratings"
                                id="healthgrades_ratings"
                                className="form-control"
                                value={
                                  localStorage.getItem("healthgrades_ratings")
                                    ? localStorage.getItem(
                                        "healthgrades_ratings"
                                      )
                                    : formdata.healthgrades_ratings
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                                min="0"
                                max="5"
                                step="0.1"
                              />
                              <label>Healthgrades Avg Ratings :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="date"
                                name="healthgrades_date"
                                id="healthgrades_date"
                                className="form-control"
                                placeholder="yyyy-mm-dd"
                                value={
                                  localStorage.getItem("healthgrades_date")
                                    ? localStorage.getItem("healthgrades_date")
                                    : formdata.healthgrades_date
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Healthgrades Review Date :</label>
                            </div>

                            <div className="form-group col-md-6 ">
                              <input
                                type="text"
                                name="mysite_account"
                                id="mysite_account"
                                className="form-control"
                                placeholder="Our Website Account Name"
                                value={
                                  localStorage.getItem("mysite_account")
                                    ? localStorage.getItem("mysite_account")
                                    : formdata.mysite_account
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Our Website Account Name :</label>
                            </div>

                            <div className="form-group col-md-6 ">
                              <input
                                type="number"
                                name="mysite_count"
                                id="mysite_count"
                                className="form-control"
                                value={
                                  localStorage.getItem("mysite_count")
                                    ? localStorage.getItem("mysite_count")
                                    : formdata.mysite_count
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Our Website Review Count :</label>
                            </div>

                            <div className="form-group col-md-6 ">
                              <input
                                type="number"
                                name="mysite_ratings"
                                id="mysite_ratings"
                                className="form-control"
                                min="0"
                                value={
                                  localStorage.getItem("mysite_ratings")
                                    ? localStorage.getItem("mysite_ratings")
                                    : formdata.mysite_ratings
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Our Website Avg Ratings :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="date"
                                name="mysite_date"
                                id="mysite_date"
                                className="form-control"
                                placeholder="yyyy-mm-dd"
                                value={
                                  localStorage.getItem("mysite_date")
                                    ? localStorage.getItem("mysite_date")
                                    : formdata.mysite_date
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Our Website Review Date :</label>
                            </div>

                            <div className="form-group col-md-6 ">
                              <input
                                type="text"
                                name="thumbtack_account"
                                id="thumbtack_account"
                                className="form-control"
                                placeholder="Thumbtack Account Name"
                                value={
                                  localStorage.getItem("thumbtack_account")
                                    ? localStorage.getItem("thumbtack_account")
                                    : formdata.thumbtack_account
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Thumbtack Account Name :</label>
                            </div>

                            <div className="form-group col-md-6 ">
                              <input
                                type="number"
                                name="thumbtack_count"
                                id="thumbtack_count"
                                className="form-control"
                                min="0"
                                value={
                                  localStorage.getItem("thumbtack_count")
                                    ? localStorage.getItem("thumbtack_count")
                                    : formdata.thumbtack_count
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Thumbtack Review Count :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="number"
                                name="thumbtack_ratings"
                                id="thumbtack_ratings"
                                className="form-control"
                                value={
                                  localStorage.getItem("thumbtack_ratings")
                                    ? localStorage.getItem("thumbtack_ratings")
                                    : formdata.thumbtack_ratings
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                                min="0"
                                max="5"
                                step="0.1"
                              />
                              <label>Thumbtack Avg Ratings :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="date"
                                name="thumbtack_date"
                                id="thumbtack_date"
                                className="form-control"
                                placeholder="yyyy-mm-dd"
                                value={
                                  localStorage.getItem("thumbtack_date")
                                    ? localStorage.getItem("thumbtack_date")
                                    : formdata.thumbtack_date
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Thumbtack Review Date :</label>
                            </div>

                            <div className="form-group col-md-6 ">
                              <input
                                type="text"
                                name="amazon_account"
                                id="amazon_account"
                                className="form-control"
                                placeholder="Amazon Account Name"
                                value={
                                  localStorage.getItem("amazon_account")
                                    ? localStorage.getItem("amazon_account")
                                    : formdata.amazon_account
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Amazon Account Name :</label>
                            </div>

                            <div className="form-group col-md-6 ">
                              <input
                                type="number"
                                name="amazon_count"
                                id="amazon_count"
                                className="form-control"
                                min="0"
                                value={
                                  localStorage.getItem("amazon_count")
                                    ? localStorage.getItem("amazon_count")
                                    : formdata.amazon_count
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Amazon Review Count :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="number"
                                name="amazon_ratings"
                                id="amazon_ratings"
                                className="form-control"
                                value={
                                  localStorage.getItem("amazon_ratings")
                                    ? localStorage.getItem("amazon_ratings")
                                    : formdata.amazon_ratings
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                                min="0"
                                max="5"
                                step="0.1"
                              />
                              <label>Amazon Avg Ratings :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="date"
                                name="amazon_date"
                                id="amazon_date"
                                className="form-control"
                                placeholder="yyyy-mm-dd"
                                value={
                                  localStorage.getItem("amazon_date")
                                    ? localStorage.getItem("amazon_date")
                                    : formdata.amazon_date
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Amazon Review Date :</label>
                            </div>

                            <div className="form-group col-md-6 ">
                              <input
                                type="text"
                                name="yelp_account"
                                id="yelp_account"
                                className="form-control"
                                placeholder="Yelp Account Name"
                                value={
                                  localStorage.getItem("yelp_account")
                                    ? localStorage.getItem("yelp_account")
                                    : formdata.yelp_account
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Yelp Account Name :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="number"
                                name="yelp_count"
                                id="yelp_count"
                                className="form-control"
                                placeholder=""
                                value={
                                  localStorage.getItem("yelp_count")
                                    ? localStorage.getItem("yelp_count")
                                    : formdata.yelp_count
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Yelp Review Count :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="number"
                                name="yelp_ratings"
                                id="yelp_ratings"
                                className="form-control"
                                value={
                                  localStorage.getItem("yelp_ratings")
                                    ? localStorage.getItem("yelp_ratings")
                                    : formdata.yelp_ratings
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Yelp Avg Ratings :</label>
                            </div>
                            <div className="form-group col-md-6 ">
                              <input
                                type="date"
                                name="yelp_date"
                                id="yelp_date"
                                className="form-control"
                                placeholder="yyyy-mm-dd"
                                value={
                                  localStorage.getItem("yelp_date")
                                    ? localStorage.getItem("yelp_date")
                                    : formdata.yelp_date
                                }
                                onChange={handleChange}
                                disabled={
                                  localStorage.getItem("client_id") === "000"
                                    ? false
                                    : true
                                }
                              />
                              <label>Yelp Review Date :</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab> : "" }

                    {localStorage.getItem("client_id") === "000" ? (
                      <Tab eventKey="company_logo" title="Company Logo">
                        <div className="card-body">
                          <div id="introduction">
                            <div className="row">
                              <div className="form-group col-md-6 ">
                                <ChromePicker
                                  color={formdata.background}
                                  onChangeComplete={handleChangeComplete}
                                />

                                <label>Color Code:</label>
                              </div>

                              <div className="form-group col-md-6">
                                <Image
                                  src={
                                    formdata.logo1
                                      ? formdata.logo1
                                      : API + "api/" + formdata.logo
                                  }
                                  fluid
                                  width="100"
                                  className={
                                    formdata.logo1 || formdata.logo
                                      ? "image-padding"
                                      : "hidden"
                                  }
                                />
                                <Dropzone
                                  onDrop={(acceptedFiles) =>
                                    onDroplogo(acceptedFiles)
                                  }
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <section>
                                      <div {...getRootProps()}>
                                        <input
                                          {...getInputProps()}
                                          accept=".jpeg,.png,.jpg,.gif"
                                        />
                                        <p className="btn btn-secondary">
                                          Select File
                                        </p>
                                      </div>
                                    </section>
                                  )}
                                </Dropzone>
                                <label>
                                  Logo{" "}
                                  <small>
                                    Prefered Logo Size is (100 X 80) (this field
                                    is required)
                                  </small>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab>
                    ) : (
                      ""
                    )}

                    <Tab eventKey="review_msg" title="Review Message">
                      <div className="card-body">
                        <div id="introduction">
                          <div className="row">
                            <div className="form-group col-md-6 ">
                              <textarea
                                type="textarea"
                                name="sms_msg"
                                id="sms_msg"
                                className="form-control"
                                value={
                                  localStorage.getItem("sms_msg")
                                    ? localStorage.getItem("sms_msg")
                                    : formdata.sms_msg
                                }
                                onChange={handleChange}
                              />
                              <label>
                                Text Msg
                                Review:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                <br />
                              </label>
                              <div className="form-group col-md-12 ">
                                <input
                                  type="checkbox"
                                  name="sendlogo"
                                  id="sendlogo"
                                  className="form-check-input is-valid"
                                  checked={formdata.sendlogo == 1}
                                  value={formdata.sendlogo}
                                  onChange={handleChange}
                                />
                                <label>
                                  {" "}
                                  Do you want the logo to be sent with the
                                  Review text?{" "}
                                </label>
                                <br />
                                <small>
                                  [logo] "Hello [First Name], We at [Location
                                  Name] appreciate your business. We would love
                                  for you to leave us a review. Please click on
                                  the following link: [link]"{" "}
                                </small>
                              </div>
                            </div>

                            <div
                              className={
                                localStorage.getItem("surveycount") != 0
                                  ? "form-group col-md-6"
                                  : "hidden"
                              }
                            >
                              <textarea
                                type="textarea"
                                name="sms_msg1"
                                id="sms_msg1"
                                className="form-control"
                                value={
                                  localStorage.getItem("sms_msg1")
                                    ? localStorage.getItem("sms_msg1")
                                    : formdata.sms_msg1
                                }
                                onChange={handleChange}
                              />
                              <label>
                                Text Msg Survey :
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <br />
                              </label>
                              <div className="form-group col-md-12 ">
                                <input
                                  type="checkbox"
                                  name="sendlogo1"
                                  id="sendlogo1"
                                  className="form-check-input is-valid"
                                  checked={formdata.sendlogo1 == 1}
                                  onChange={handleChange}
                                />
                                <label>
                                  {" "}
                                  Do you want the logo to be sent with the
                                  Review text?{" "}
                                </label>
                                <br />
                                <small>
                                  [logo] "Hello [First Name], We at [Location
                                  Name] appreciate your business. We would love
                                  for you to leave us a review. Please click on
                                  the following link: [link]"{" "}
                                </small>
                              </div>
                            </div>

                            <div className="form-group col-md-6 ">
                              <textarea
                                className="form-control"
                                data={
                                  localStorage.getItem("msg")
                                    ? localStorage.getItem("msg")
                                    : formdata.msg
                                }
                                value={
                                  localStorage.getItem("msg")
                                    ? localStorage.getItem("msg")
                                    : formdata.msg
                                }
                                name="msg"
                                id="msg"
                                onChange={handleChange}
                              />
                              <label>Email Msg Review:</label>
                              <small>
                                {" "}
                                Default Email Message: We appreciate your
                                business! We would love to hear about your
                                experience with [Location Name]. Please click on
                                the link below to leave a review.{" "}
                              </small>
                            </div>
                            <div className="form-group col-md-6 ">
                              <textarea
                                type="textarea"
                                name="subject"
                                id="subject"
                                className="form-control"
                                value={
                                  localStorage.getItem("subject")
                                    ? localStorage.getItem("subject")
                                    : formdata.subject
                                }
                                onChange={handleChange}
                              />
                              <label>
                                Text Subject Review: <br />
                              </label>
                              <small>
                                {" "}
                                Default Text Subject: Feedback request from
                                [Company].{" "}
                              </small>
                            </div>

                            <div className="form-group col-md-6 ">
                              <textarea
                                data={formdata.sign}
                                value={
                                  localStorage.getItem("sign")
                                    ? localStorage.getItem("sign")
                                    : formdata.sign
                                }
                                id="sign"
                                className="form-control"
                                onChange={handleChange}
                              />
                              <label>Email Signature Review:</label>
                              <small>
                                {" "}
                                Default Email Signature : Thank you,[Location]
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                              </small>
                              <input
                                type="checkbox"
                                name="sendlogoemail"
                                id="sendlogoemail"
                                className="form-check-input is-valid"
                                checked={formdata.sendlogoemail == 1}
                                onChange={handleChange}
                              />
                              <label>
                                Do you want the logo to be sent with the Review
                                Email?{" "}
                              </label>
                              <br />
                            </div>

                            <div
                              className={
                                localStorage.getItem("surveycount") != 0
                                  ? "form-group col-md-6"
                                  : "hidden"
                              }
                            >
                              <textarea
                                data={
                                  localStorage.getItem("msg1")
                                    ? localStorage.getItem("msg1")
                                    : formdata.msg1
                                }
                                value={
                                  localStorage.getItem("msg1")
                                    ? localStorage.getItem("msg1")
                                    : formdata.msg1
                                }
                                name="msg1"
                                id="msg1"
                                className="form-control"
                                onChange={handleChange}
                              />
                              <label>Email Msg Survey :</label>
                              <small>
                                {" "}
                                Default Email Message: Hello [First Name], We at
                                [Location Name] appreciate your business. We are
                                always working to improve our business
                                practices. We would love to have you participate
                                in a quick survey so we can learn more about
                                your experience. Please click on the following
                                link:[link]{" "}
                              </small>
                            </div>
                            <div className="form-group col-md-6 ">
                              <textarea
                                type="textarea"
                                name="subject1"
                                id="subject1"
                                className="form-control"
                                value={
                                  localStorage.getItem("subject1")
                                    ? localStorage.getItem("subject1")
                                    : formdata.subject1
                                }
                                onChange={handleChange}
                              />
                              <label>
                                Email Subject Review: <br />
                              </label>
                              <small>
                                {" "}
                                Default Email Subject: Feedback request from
                                [Company].{" "}
                              </small>
                            </div>

                            <div
                              className={
                                localStorage.getItem("surveycount") != 0
                                  ? "form-group col-md-6"
                                  : "hidden"
                              }
                            >
                              <textarea
                                data={
                                  localStorage.getItem("sign1")
                                    ? localStorage.getItem("sign1")
                                    : formdata.sign1
                                }
                                value={
                                  localStorage.getItem("sign1")
                                    ? localStorage.getItem("sign1")
                                    : formdata.sign1
                                }
                                className="form-control"
                                id="sign1"
                                onChange={handleChange}
                              />
                              <label>Email Signature Survey:</label>
                              <small>
                                {" "}
                                Default Email Signature : Thank you,[Location]
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                              </small>
                              <input
                                type="checkbox"
                                name="sendlogoemail1"
                                id="sendlogoemail1"
                                className="form-check-input is-valid"
                                checked={formdata.sendlogoemail1 == 1}
                                onChange={handleChange}
                              />
                              <label>
                                Do you want the logo to be sent with the Survey
                                Email?{" "}
                              </label>
                              <br />
                            </div>
                            <div className="form-group col-md-6 ">
                              <textarea
                                type="textarea"
                                name="review_msg"
                                id="review_msg"
                                className="form-control"
                                value={
                                  localStorage.getItem("review_msg")
                                    ? localStorage.getItem("review_msg")
                                    : formdata.review_msg
                                }
                                onChange={handleChange}
                              />
                              <label>
                                Review Page Msg : <br />
                              </label>
                              <small>
                                {" "}
                                Default Review Page Msg: Would you recommend
                                [Location]?.
                              </small>
                            </div>

                            <div className="form-group col-md-6 ">
                              <textarea
                                type="textarea"
                                name="yes_msg"
                                id="yes_msg"
                                className="form-control"
                                value={
                                  localStorage.getItem("yes_msg")
                                    ? localStorage.getItem("yes_msg")
                                    : formdata.yes_msg
                                }
                                onChange={handleChange}
                              />
                              <label>
                                If Yes Msg : <br />
                              </label>
                              <small>
                                {" "}
                                Default Yes Msg: Feedback request from
                                [Company].
                              </small>
                            </div>

                            <div className="form-group col-md-6 ">
                              <textarea
                                type="textarea"
                                name="no_msg"
                                id="no_msg"
                                className="form-control"
                                value={
                                  localStorage.getItem("no_msg")
                                    ? localStorage.getItem("no_msg")
                                    : formdata.no_msg
                                }
                                onChange={handleChange}
                              />
                              <label>
                                If No Msg : <br />
                              </label>
                              <small>
                                {" "}
                                Default No Msg: We apologize that you were not
                                completely satisfied with your experience. How
                                can we do better?{" "}
                              </small>
                            </div>

                            <div className="form-group col-md-6 ">
                              <textarea
                                type="textarea"
                                name="thanks_msg"
                                id="thanks_msg"
                                className="form-control"
                                value={
                                  localStorage.getItem("thanks_msg")
                                    ? localStorage.getItem("thanks_msg")
                                    : formdata.thanks_msg
                                }
                                onChange={handleChange}
                              />
                              <label>
                                Thank You Msg Review : <br />
                              </label>
                              <small>
                                {" "}
                                Thank you for your time. We look forward to
                                serving you in the future.{" "}
                              </small>
                            </div>

                            <div
                              className={
                                localStorage.getItem("surveycount") != 0
                                  ? "form-group col-md-6"
                                  : "hidden"
                              }
                            >
                              <textarea
                                type="textarea"
                                name="thanks_msg1"
                                id="thanks_msg1"
                                className="form-control"
                                value={
                                  localStorage.getItem("thanks_msg1")
                                    ? localStorage.getItem("thanks_msg1")
                                    : formdata.thanks_msg1
                                }
                                onChange={handleChange}
                              />
                              <label>
                                Thank You Msg Survey : <br />
                              </label>
                              <small>
                                {" "}
                                Thank you for your time. We look forward to
                                serving you in the future.{" "}
                              </small>
                            </div>

                            <div className="form-group col-md-6 ">
                              <textarea
                                type="textarea"
                                name="underlogo"
                                id="underlogo"
                                className="form-control"
                                value={
                                  localStorage.getItem("underlogo")
                                    ? localStorage.getItem("underlogo")
                                    : formdata.underlogo
                                }
                                onChange={handleChange}
                              />
                              <label>
                                Review page text under client logo : <br />
                              </label>
                              <small> We'd love to hear from you! </small>
                            </div>

                            {/* <div className="form-group col-md-6">
                              <Image
                                src={
                                  formdata.logo2
                                    ? formdata.logo2
                                    : API + "api/" + formdata.coupon
                                }
                                fluid
                                width="100"
                                className={
                                  formdata.logo2 || formdata.coupon
                                    ? "image-padding"
                                    : "hidden"
                                }
                              />
                              <Dropzone
                                onDrop={(acceptedFiles) =>
                                  onDroplogo1(acceptedFiles)
                                }
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <section>
                                    <div {...getRootProps()}>
                                      <input
                                        {...getInputProps()}
                                        accept=".jpeg,.png,.jpg,.gif"
                                      />
                                      <p className="btn btn-secondary">
                                        Select File
                                      </p>
                                    </div>
                                  </section>
                                )}
                              </Dropzone>
                              <label>
                                Coupon Logo{" "}
                                <small>
                                  Prefered Logo Size is (100 X 80) (this field
                                  is required)
                                </small>
                              </label>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="myModal1" className="modal fade" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <img
                src="https://www.grofirereviews.com/uploads/3.png"
                height="400px"
                width="550px"
              />
              <br /> <br /> <br />
              <div className="text-center">
                <a
                  href="https://www.grofirereviews.com/uploads/3.png"
                  target="_blank"
                >
                  Full View{" "}
                </a>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};
export default Locationform;
