import React, { useState } from "react";
import Locationform from "./form1";

const ReviewLocation = props => {
  const [ formdata, setformdata ] = useState({
    id: ''
});


  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="kt-space-40" />
          <Locationform data={formdata} history={props.history} />
          <div className="kt-space-20" />
        </div>
      </div>
    </>
  );
};
export default ReviewLocation;
