import React, { useState, useEffect } from "react";
import CodeExample from "../../../partials/content/CodeExample";
import { Table, Button } from "react-bootstrap";
import axios from "axios";
import { API } from "../../../../const";
import $ from "jquery";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import localeInfo from "rc-pagination/lib/locale/en_US";
import "rc-pagination/assets/index.css";
import Moment from "react-moment";
import LoadingOverlay from "react-loading-overlay";
import { RiseLoader } from "react-spinners";
const FeedbackList = (props) => {
  const [data, setData] = useState([]);
  const [showLoading, setShowLoading] = useState(true);

  const [currdata, setcurrData] = useState([]);
  const [count, setcount] = useState(0);
  const [current, setcurrent] = useState(1);
  const [formlist, setformData] = useState([]);

  const apiUrlall = API + "api/client_email_list";
  useEffect(() => {
    const fetchData = async () => {
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      };
      var postData = {
        company_name: formlist.company_name,
        client_name: formlist.client_name,
      };
      axios
        .post(apiUrlall, postData, axiosConfig)
        .then((res) => {
          console.log(res.data.data);
          setcurrData(res.data.data);
          let count = Object.keys(res.data.data).length;
          setcount(count);
          setShowLoading(false);
        })
        .catch((err) => {
          setShowLoading(false);
          console.log("AXIOS ERROR: ", err);
        });
    };
    fetchData();
  }, []);

  const handlePageClick = (data) => {
    let offsetS = Math.ceil(data * 20 - 20);
    loadData(offsetS, data);
  };

  const loadData = async (offsetS, cu) => {
    setformData({ offset: offsetS });
    setformData({ ...formlist, offset: offsetS });
    if (!cu) {
      setcurrent(1);
    } else {
      setcurrent(cu);
    }
  };

  useEffect(() => {
    loadData();
  }, [data]);

  const handleChange = (event) => {
    setformData({ ...formlist, [event.target.name]: event.target.value });
  };

  const getemaildata = async () => {
    var postData1 = {
      client_name: formlist.client_name,
      company_name: formlist.company_name,
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };
    console.log(postData1);
    axios
      .post(apiUrlall, postData1, axiosConfig)
      .then((res) => {
        console.log(res.data.data);
        setcurrData(res.data.data);
        let count = Object.keys(res.data.data).length;
        setcount(count);
        setShowLoading(false);
      })
      .catch((err) => {
        setShowLoading(false);
        console.log("AXIOS ERROR: ", err);
      });
  };

  const resetemaildata = async () => {
    window.location.reload();
  };

  const viewLocations = (id, companyName) => {
    setShowLoading(true);
    localStorage.setItem("companyName", companyName);
    props.history.push({
      pathname: "/set_feedback_email/viewEmailloc/" + id,
    });
  };

  return (
    <LoadingOverlay
      active={showLoading}
      spinner={<RiseLoader sizeUnit={"px"} size={80} color={"#2a8dd1"} />}
    >
      <>
        <div className="row">
          <div className="col-md-12">
            <CodeExample beforeCodeTitle="Set Feedback Email">
              <div className="kt-section">
                {/* <span className="kt-section__sub">Customer Feedback</span> */}
                <br></br>
                <div className="row">
                  <div className="col-6 col-md-4">
                    <input
                      className="form-control col-md-12"
                      id="company_name"
                      name="company_name"
                      onChange={handleChange}
                    />
                    <label>Company Name</label>
                  </div>

                  <div className="col-6 col-md-4">
                    <input
                      className="form-control col-md-12"
                      id="client_name"
                      name="client_name"
                      onChange={handleChange}
                    />
                    <label>Client Name</label>
                  </div>

                  <div className="col-6 col-md-2">
                    <button
                      onClick={() => getemaildata()}
                      className=" form-control col-md-12 btn btn-success"
                    >
                      Search
                    </button>
                  </div>
                  <div className="col-6 col-md-2">
                    <button
                      onClick={() => resetemaildata()}
                      className=" form-control col-md-12 btn btn-warning"
                    >
                      Reset
                    </button>
                  </div>
                </div>
                <div className="kt-separator kt-separator--dashed responsive"></div>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Sr. No.</th>
                      <th>Client Name</th>
                      <th>Company Name</th>
                      <th>Default Email</th>
                      <th>Locations</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currdata.map((item, idx) => (
                      <tr key={idx}>
                        <td>{idx + 1 + (current - 1) * 20}</td>

                        <td> {item.client_name}</td>
                        <td>{item.company_name}</td>
                        <td>{item.cp_login && item.cp_login.email}</td>
                        <td>
                          <i
                            onClick={() => {
                              viewLocations(item.client_id, item.company_name);
                            }}
                            className="flaticon-eye btn btn-primary"
                          ></i>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Pagination
                  showQuickJumper
                  showSizeChanger
                  onChange={handlePageClick}
                  current={current}
                  total={count}
                  pageSize={20}
                  locale={localeInfo}
                />
              </div>
            </CodeExample>
          </div>
        </div>
      </>
    </LoadingOverlay>
  );
};
export default FeedbackList;
