import React, { useState, useEffect } from "react";
import { Portlet, PortletBody } from "../../partials/content/Portlet";
import QuickStatsChart from "../../widgets/QuickStatsChart";
import axios from "axios";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import localeInfo from "rc-pagination/lib/locale/en_US";
import localeInfo1 from "rc-pagination/lib/locale/en_US";
import { toast } from "react-toastify";
import { API } from "../../../const";
import OrdersWidget from "../../widgets/OrdersWidget";
import SalesBarChart from "../../widgets/SalesBarChart";
import CodeExample from "../../partials/content/CodeExample";
import { Table } from "react-bootstrap";
import $ from "jquery";
import Moment from "react-moment";
var moment = require("moment");

export default function Dashboardclient(props) {
  const [data1, setData1] = useState([]);

  const [data, setData] = useState({
    users: 0,
    sitelink: 0,
    client: 0,
    location: 0,
  });

  const [locationlist, setlocationData] = useState([]);
  const [locations, setlocations] = useState([]);
  const [formlist, setformData] = useState([]);
  const [formlist1, setformData1] = useState([]);
  const [formlist2, setformData2] = useState([]);
  const [formlist3, setformData3] = useState([]);
  const [count, setcount] = useState(0);
  const [currdata, setcurrData] = useState([]);
  const [current, setcurrent] = useState(1);

  const [currdata1, setcurrData1] = useState([]);
  const [count1, setcount1] = useState(0);
  const [current1, setcurrent1] = useState(1);

  var sitlinkurlall = API + "api/getlinkalluserdata/";
  const apiUrlreviewslist = API + "api/reviewlistlistuser";

  useEffect(() => {
    const fetchData123 = async () => {
      var postData1 = {
        user_id: localStorage.getItem("id"),
      };

      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      };

      axios
        .post(sitlinkurlall, postData1, axiosConfig)
        .then((res) => {
          setData1(res.data);
          let count = Object.keys(res.data).length;
          setcount(count);
        })
        .catch((err) => {
          //console.log("AXIOS ERROR: ", err);
        });
    };

    fetchData123();
  }, []);

  useEffect(() => {
    const fetchData1221 = async () => {
      const apiUrl1 = API + "api/location_by_client_id";

      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      };
      var postData = {
        client_id: localStorage.getItem("client_id"),
      };
      axios
        .post(apiUrl1, postData, axiosConfig)
        .then((responce) => {
          var location = localStorage.getItem("locations").split(",");
          setlocations(location);
          setlocationData(responce.data.location);
        })
        .catch((err) => {
          //console.log("AXIOS ERROR: ", err);
        });
    };

    fetchData1221();
  }, []);
  const checkLocation = (item, idx) => {
    if (localStorage.getItem("user_type") === "client") {
      return (
        <option key={idx} value={item.id}>
          {item.add1}
          {", "}
          {item.city}
        </option>
      );
    } else {
      if (locations.includes(item.id.toString())) {
        return (
          <option key={idx} value={item.id}>
            {item.add1}
            {", "}
            {item.city}
          </option>
        );
      }
    }
  };
  const handleChange1 = (event) => {
    setformData1({ ...formlist1, [event.target.name]: event.target.value });
  };
  const handleChange3 = (event) => {
    setformData3({ ...formlist3, [event.target.name]: event.target.value });
  };

  const loadData = async (offsetS, current) => {
    let sitlinkurl = API + "api/getuserlinkdata/";
    let off = offsetS;
    var curval;
    if (!current) {
      curval = 1;
    } else {
      curval = current;
    }
    setcurrent(curval);
    $.ajax({
      url: sitlinkurl,
      data: {
        limit: 5,
        offset: off,
        user_id: localStorage.getItem("id"),
        location_id: formlist1.location_name,
      },

      dataType: "json",
      type: "POST",
      headers: {
        Authorization: localStorage.getItem("token"),
      },

      success: (data) => {
        setcurrData(data);
      },

      error: (xhr, status, err) => {
        console.error(this.props.url, status, err.toString()); // eslint-disable-line
      },
    });
  };
  useEffect(() => {
    loadData();
  }, [data]);
  const handlePageClick = (data) => {
    let offsetS = Math.ceil(data * 5 - 5);
    loadData(offsetS, data);
  };

  const loadData1 = async (offsetS, current) => {
    let off = offsetS;
    var curval;
    if (!current) {
      curval = 1;
    } else {
      curval = current;
    }
    setcurrent1(curval);
    $.ajax({
      url: apiUrlreviewslist,
      data: {
        limit: 5,
        offset: off,
        user_id: localStorage.getItem("id"),
        location_id: formlist3.location_name,
      },
      dataType: "json",
      type: "POST",
      headers: {
        Authorization: localStorage.getItem("token"),
      },

      success: (data) => {
        setcurrData1(data);
      },

      error: (xhr, status, err) => {
        console.error(this.props.url, status, err.toString()); // eslint-disable-line
      },
    });
  };

  useEffect(() => {
    loadData1();
  }, [data1]);

  const handlePageClick1 = (data1) => {
    let offsetS = Math.ceil(data1 * 5 - 5);
    loadData1(offsetS, data1);
  };
  const getemaildata = async () => {
    var postData1 = {
      user_id: localStorage.getItem("id"),
      location_id: formlist1.location_name,
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };

    axios
      .post(sitlinkurlall, postData1, axiosConfig)
      .then((res) => {
        setData1(res.data);
        let count = Object.keys(res.data).length;
        setcount(count);
      })
      .catch((err) => {
        //console.log("AXIOS ERROR: ", err);
      });

    loadData();
  };

  const getemaildata1 = async () => {
    var postData1 = {
      user_id: localStorage.getItem("id"),
      location_id: formlist3.location_name,
      limit: "20",
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };

    axios
      .post(apiUrlreviewslist, postData1, axiosConfig)
      .then((res) => {
        setData1(res.data);
        let count = Object.keys(res.data).length;
        setcount1(count);
      })
      .catch((err) => {
        //console.log("AXIOS ERROR: ", err);
      });
  };

  const resetemaildata = async () => {
    // setEmailStartdate({ ...startdata });
    // setEmailEndata({ ...enddata });
    // setMovedata({ ...movedata });
    window.location.reload();
  };

  const cp_clicksdata = (item, idx) => {
    if (item.link_type == "google") {
      return (
        <>
          <h6>Google </h6>{" "}
        </>
      );
    }
    if (item.link_type == "google2") {
      return (
        <>
          <h6>Google2 </h6>{" "}
        </>
      );
    }
    if (item.link_type == "fb") {
      return (
        <>
          <h6>Facebook </h6>
        </>
      );
    }
    if (item.link_type == "fb2") {
      return (
        <>
          <h6>Facebook2 </h6>
        </>
      );
    }
    if (item.link_type == "yelp") {
      return (
        <>
          <h6>Yelp </h6>
        </>
      );
    }
    if (item.link_type == "zillow") {
      return (
        <>
          <h6>Zillow </h6>
        </>
      );
    }
    if (item.link_type == "yp") {
      return (
        <>
          <h6>Yellow Pages </h6>
        </>
      );
    }
    if (item.link_type == "trip") {
      return (
        <>
          <h6>Trip Advisor </h6>
        </>
      );
    }
    if (item.link_type == "yell") {
      return (
        <>
          <h6>Yell </h6>
        </>
      );
    }
    if (item.link_type == "real") {
      return (
        <>
          <h6>Realtor </h6>
        </>
      );
    }
    if (item.link_type == "apar") {
      return (
        <>
          <h6>Apartments.com </h6>
        </>
      );
    }
    if (item.link_type == "aparrate") {
      return (
        <>
          <h6>Apartment Ratings </h6>
        </>
      );
    }
    if (item.link_type == "sf") {
      return (
        <>
          <h6>Spare Foot </h6>
        </>
      );
    }
    if (item.link_type == "great_schools") {
      return (
        <>
          <h6>Great School </h6>
        </>
      );
    }

    if (item.link_type == "healthgrades") {
      return (
        <>
          <h6>Health Grades </h6>
        </>
      );
    }
    if (item.link_type == "mysite") {
      return (
        <>
          <h6>Our Website </h6>
        </>
      );
    }
  };
  let countClick = 0;

  const resend = async (id) => {
    if (countClick == 0) {
      countClick++;
      var resendapi = API + "api/resend_link/";

      var postData1 = {
        id: id,
      };

      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      };

      axios
        .post(resendapi, postData1, axiosConfig)
        .then((res) => {
          //console.log(res);

          toast(res.data.message);
          window.location.reload();
        })
        .catch((err) => {
          //console.log("AXIOS ERROR: ", err);
        });
    } else {
      return false;
      // or evt.preventDefault();
    }
  };

  return (
    <>
      <div className="row"></div>

      <div className="row row-no-padding row-col-separator-xl">
        <div className="col-xl-8">
          {/* <SalesBarChart
                sales={clientschart}
                title="Clicks"
                desc="All the Clicks are listed"
              /> */}
        </div>
        <div className="col-xl-4">{/* <OrdersWidget data={data} /> */}</div>
      </div>

      {/* <div className="row">
        <div className="row">
          <div className="col-6 col-md-6">
            <select
              className="form-control col-md-12"
              id="location_name"
              name="location_name"
              onChange={handleChange3}
            >
              <option value="undefined">Select Location</option>
              {locationlist.map((item, idx) => (
                <option key={idx} value={item.id}>
                  {" "}
                  {item.add1}
                  {item.city}
                </option>
              ))}
            </select>
            <label>Location List</label>
          </div>

          <div className="col-6 col-md-3">
            <button
              onClick={() => getemaildata1()}
              className=" form-control col-md-12 btn btn-success"
            >
              Search
            </button>
          </div>
          <div className="col-6 col-md-3">
            <button
              onClick={() => resetemaildata()}
              className=" form-control col-md-12 btn btn-warning"
            >
              Reset
            </button>
          </div>
        </div>
        <div className="col-md-12">
          <CodeExample beforeCodeTitle="Customers Feedback">
            <div className="kt-section">
              <div className="kt-separator kt-separator--dashed responsive"></div>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email/Mobile</th>
                    <th>Message</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {currdata1.map((item, idx) => (
                    <tr key={idx}>
                      <td> {item.cp_link && item.cp_link.username}</td>
                      <td>
                        {item.cp_link && item.cp_link.email}
                        {item.cp_login && item.cp_login.mobile}
                      </td>
                      <td>{item.desc}</td>
                      <td>
                        <Moment format="YYYY-MM-DD">{item.created_date}</Moment>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Pagination
                showQuickJumper
                showSizeChanger
                onChange={handlePageClick1}
                current={current1}
                total={count1}
                pageSize={5}
                locale={localeInfo1}
              />
            </div>
          </CodeExample>
        </div>
      </div> */}
      <div className="row">
        <div className="row">
          <div className="col-6 col-md-6">
            <select
              className="form-control col-md-12"
              id="location_name"
              name="location_name"
              onChange={handleChange1}
            >
              <option value="">Select Location</option>
              {locationlist.map((item, idx) => checkLocation(item, idx))}
            </select>
            <label>Location List</label>
          </div>

          <div className="col-6 col-md-3">
            <button
              onClick={() => getemaildata()}
              className=" form-control col-md-12 btn btn-success"
            >
              Search
            </button>
          </div>
          <div className="col-6 col-md-3">
            <button
              onClick={() => resetemaildata()}
              className=" form-control col-md-12 btn btn-warning"
            >
              Reset
            </button>
          </div>
        </div>
        <div className="col-md-12">
          <CodeExample beforeCodeTitle="Customer List">
            <div className="kt-section">
              <div className="kt-separator kt-separator--dashed responsive"></div>
              <Table striped bordered hover>
                <thead></thead>
                <tbody>
                  {currdata.map((item, idx) => (
                    <tr>
                      <td>{idx + 1}</td>
                      <td>
                        {" "}
                        <p>{item.username}</p>{" "}
                        <h5>
                          {item.email} | {item.mobile}
                        </h5>{" "}
                        {/* <h5>{item.email === "0" ? "" : item.email} </h5>{" "} */}
                        <h5>
                          Sent by : {item.via} -{" "}
                          {item.via === "API"
                              ? item.cp_location
                                ? item.cp_location.l_name
                                : item.cp_client.company_name
                              : ""}
                            {item.via === "Manually"
                              ? item.cp_user
                                ? item.cp_user.name
                                : item.cp_client.client_name
                              : ""}
                        </h5>{" "}
                      </td>

                      <td>
                        {" "}
                        <p>
                          Date:{" "}
                          <Moment format="MMM Do YY">
                            {item.created_date}
                          </Moment>
                        </p>{" "}
                        <h5>
                          {" "}
                          {item.type === "survey"
                            ? "Survey Link"
                            : "Review Link"}{" "}
                        </h5>{" "}
                        <a target="_blank" href={item.link}>
                          {item.link}
                        </a>{" "}
                        <br></br>
                        {item.ratings &&
                          "Gate Response: " + item.ratings + " stars"}
                        {item.gate_results &&
                          "Gate Response: Clicked - " + item.gate_results}
                        {item.cp_clicks.length > 0 && <h5>Clicked ON :</h5>}
                        {item.cp_clicks.length > 0 &&
                          item.cp_clicks.map((item, idx) =>
                            cp_clicksdata(item)
                          )}
                      </td>

                      <td>
                        {item.resend === 0 ? (
                          <a onClick={() => resend(item.id)} title="Resend">
                            Resend Request
                          </a>
                        ) : (
                          "Resent on  - " +
                          moment(item.updatedAt).format("MMM Do YYYY")
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Pagination
                showQuickJumper
                showSizeChanger
                onChange={handlePageClick}
                current={current}
                total={count}
                pageSize={5}
                locale={localeInfo}
              />
            </div>
          </CodeExample>
        </div>
      </div>
    </>
  );
}
