import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { TextField } from "@material-ui/core";
import clsx from "clsx";
import * as auth from "../../store/ducks/auth.duck";
import { updatepassword } from "../../crud/auth.crud";
import {API} from "../../../const"

function UpdatePassword(props) {
  const { intl } = props;
  //console.log(props.match.params.id);


  return (
    <>
      <div className="kt-login__head">
        <span className="kt-login__signup-label">
        </span>
        &nbsp;&nbsp;

      </div>

      <div className="kt-login__body">
        <div className="kt-login__form">
          <div className="kt-login__title">
            <h3>
            Update Password
            </h3>
          </div>

          <Formik
           initialValues={{
            email: "",
            password: "",
            password1:""
          }}
            validate={values => {
              const errors = {};

              if (!values.password) {
                errors.password = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              }

              if (!values.password1) {
                errors.password1 = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              }


              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
            if(values.password!==values.password1)
            {
                alert("Password and Confirm Password is not same ")

            }
            else
            {
                setTimeout(() => {
                    updatepassword(props.match.params.id,values.password1)
                     .then((res) => {
                        var msg = res.data.message;
                       alert(msg);
                       props.history.push({
                        pathname: '/login'
                      });
                      window.location.reload();


                       })
                        .catch(() => {
                         setSubmitting(false);
                        setStatus(
                           intl.formatMessage({
                          id: "AUTH.VALIDATION.INVALID_LOGIN"
                       })
                        );
                       });
                  }, 1000);
            }


            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <form
                noValidate={true}
                autoComplete="off"
                className="kt-form"
                onSubmit={handleSubmit}
              >
                {status ? (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                ) : (
                    <div></div>

                )}

                <div className="form-group">
                <TextField
                    type="password"
                    margin="normal"
                    label="Password"
                    className="kt-width-full"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    helperText={touched.password && errors.password}
                    error={Boolean(touched.password && errors.password)}
                  />
                  
                  <TextField
                    type="password"
                    margin="normal"
                    label="Confirm Password"
                    className="kt-width-full"
                    name="password1"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password1}
                    helperText={touched.password1 && errors.password1}
                    error={Boolean(touched.password1 && errors.password1)}
                  />
                </div>


                <div className="kt-login__actions">
                <Link
                      to="/auth"
                      className="btn btn-secondary btn-elevate kt-login__btn-secondary"
                    >
                      Back
                    </Link>

                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={isSubmitting}
                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(

                    )}`}

                  >
                    Submit
                  </button>
                </div>
              </form>
            )}
          </Formik>


        </div>
      </div>
    </>
  );
}

export default injectIntl(
  connect(
    null,
    auth.actions
  )(UpdatePassword)
);
