import React from "react";
import { connect } from "react-redux";
import objectPath from "object-path";
import Header from "./header/Header";
import SubHeader from "./sub-header/SubHeader";
import { withRouter } from "react-router-dom";
import HeaderMobile from "./header/HeaderMobile";
import AsideLeft from "./aside/AsideLeft";
import Footer from "./footer/Footer";
import HTMLClassService from "./HTMLClassService";
import LayoutConfig from "./LayoutConfig";
import Moment from "react-moment";
import USerMenuConfig from "./UserMenuConfig";

import MenuConfig from "./MenuConfig";
import Fullaccess from "./FullAccessUser";
import LimitedAccess from "./LimitedUser";

import LayoutInitializer from "./LayoutInitializer";
import KtContent from "./KtContent";
var moment = require("moment");
const email = localStorage.getItem("email");

const usermail = localStorage.getItem("client_id");
const user_type = localStorage.getItem("user_type");
const menu = localStorage.getItem("menu");
const last_login = localStorage.getItem("lastLogin");
var Menu = MenuConfig;
var date = new Date().getDate(); //Current Date
var month = new Date().getMonth() + 1; //Current Month
var year = new Date().getFullYear(); //Current Year
var hours = new Date().getHours(); //Current Hours
var min = new Date().getMinutes(); //Current Minutes
var sec = new Date().getSeconds(); //Current Seconds
var dates =
  year + "-" + month + "-" + date + " " + hours + ":" + min + ":" + sec;

const startDate = moment(last_login);
const timeEnd = moment(dates);

const diff = timeEnd.diff(startDate);
const diffDuration = moment.duration(diff);

setTimeout(function() {
  if (diffDuration.hours() > 6) {
    window.location.href = "/logout";
    //alert(diffDuration.hours())
  }
}, 3000);

if (usermail === "000") {
  // window.location.reload();
  Menu = MenuConfig;
} else if (usermail !== "000" && menu === "undefined") {
  //  window.location.reload();
  // alert("Client");
  Menu = USerMenuConfig;
} else if (usermail !== "000" && menu === "Full") {
  //window.location.reload();
  // alert("Full");
  Menu = Fullaccess;
} else if (usermail !== "000" && menu === "Limited") {
  //window.location.reload();
  // alert("Limited");
  Menu = LimitedAccess;
}

//import("./assets/Base.scss");

const htmlClassService = new HTMLClassService();

function Layout({
  children,
  asideDisplay,
  subheaderDisplay,
  selfLayout,
  layoutConfig,
}) {
  htmlClassService.setConfig(layoutConfig);
  // scroll to top after location changes
  window.scrollTo(0, 0);

  const contentCssClasses = htmlClassService.classes.content.join(" ");
  const contentContainerCssClasses = htmlClassService.classes.content_container.join(
    " "
  );
  return selfLayout !== "blank" ? (
    <LayoutInitializer
      styles={[]}
      menuConfig={Menu}
      layoutConfig={LayoutConfig}
      htmlClassService={htmlClassService}
    >
      {/* <!-- begin:: Header Mobile --> */}
      <HeaderMobile />
      {/* <!-- end:: Header Mobile --> */}

      <div className="kt-grid kt-grid--hor kt-grid--root">
        {/* <!-- begin::Body --> */}
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
          <div
            className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
            id="kt_wrapper"
          >
            <Header />
            {/* <!-- end:: Header --> */}
            <div
              className="kt-body kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-grid--stretch"
              id="kt_body"
            >
              {/* <!-- begin:: Aside Left --> */}
              {asideDisplay && (
                <>
                  <div
                    className={`kt-container ${contentContainerCssClasses} kt-container--fit kt-grid kt-grid--ver`}
                  >
                    <AsideLeft />
                    <div
                      className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
                      id="kt_content"
                    >
                      <KtContent>{children}</KtContent>
                    </div>
                  </div>
                </>
              )}
              {!asideDisplay && (
                <>
                  {/* <!-- begin:: Content --> */}
                  <div
                    id="kt_content"
                    className={`kt-content ${contentCssClasses} kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor`}
                  >
                    {/* <!-- begin:: Content Head --> */}
                    {subheaderDisplay && <SubHeader />}
                    {/* <!-- end:: Content Head --> */}

                    {/* <!-- begin:: Content Body --> */}
                    <KtContent>{children}</KtContent>
                    {/*<!-- end:: Content Body -->*/}
                  </div>
                  {/* <!-- end:: Content --> */}
                </>
              )}

              {/* <!-- end:: Aside Left --> */}
            </div>
            <Footer />
          </div>
        </div>

        {/* <!-- end:: Body --> */}
      </div>
    </LayoutInitializer>
  ) : (
    // BLANK LAYOUT
    <div className="kt-grid kt-grid--ver kt-grid--root kt-page">
      <KtContent>{children}</KtContent>
    </div>
  );
}

const mapStateToProps = ({ builder: { layoutConfig } }) => ({
  layoutConfig,
  selfLayout: objectPath.get(layoutConfig, "self.layout"),
  asideDisplay: objectPath.get(layoutConfig, "aside.self.display"),
  subheaderDisplay: objectPath.get(layoutConfig, "subheader.display"),
});

export default withRouter(connect(mapStateToProps)(Layout));
