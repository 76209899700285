import React, { useState, useEffect } from "react";
import Notice from "../../../partials/content/Notice";
import CodeExample from "../../../partials/content/CodeExample";
import { Table , Button } from "react-bootstrap";
import axios from "axios";
import {API} from "../../../../const"
import Clientform from "./../Clients/form"

const EditClient = props => {

    const [formdata, setformdata] = useState({
        id : "",
        company_name: "",
        background:"#0061FF",
        client_name: "",
        client_email: "",
        mobile: "",
        color_code: "#ffffff",
        client_password: "",
        msg_limit: "",
        r_msg: "",
        userfile: "",
        real_estate: "",
        bulk_import: "",
        sitelink: "",
        localtionlevel: "",
        spanish: "",
        corp_code: "",
        corp_login: "",
        corp_pass: "",
        starpage: "",
        reviewstar: "",
        cstatus: ""
      });

      const apiUrl = API + 'api/client_getdata';

      useEffect(() => {
		const fetchData = async () => {
            var postData = {
              "client_id" : props.match.params.id
              };

              let axiosConfig = {
                headers: {
                  "Content-Type": "application/json;charset=UTF-8",
                  Authorization: localStorage.getItem("token")
                }
              };
			const result = await axios
            .post(apiUrl, postData, axiosConfig)
            .then(res => {
                //console.log(res.data)
                if(res.data){
                    setformdata(
                        {
                            company_name: res.data.client.company_name,
                            client_name: res.data.client.client_name,
                            client_email: res.data.login_user.email,
                            mobile: res.data.client.mobile,
                            color_code: res.data.client.color_code,
                            client_password: "",
                            msg_limit: res.data.client.msg_limit,
                            r_msg: res.data.client.remaining_msg,
                            userfile: res.data.client.client_logo,
                            real_estate: res.data.client.real_estate,
                            bulk_import: res.data.client.bulk_import,
                            sitelink: res.data.client.sitelink,
                            localtionlevel: res.data.client.sitelink,
                            spanish: res.data.client.spanish,
                            corp_code: res.data.sitelink_data && res.data.sitelink_data.corp_code,
                            corp_login: res.data.sitelink_data && res.data.sitelink_data.corp_login,
                            corp_pass: res.data.sitelink_data && res.data.sitelink_data.corp_pass,
                            starpage: res.data.client.starpage,
                            reviewstar: res.data.client.reviewstar,
                            cstatus:  res.data.login_user.mystatus,
                            id : props.match.params.id,
                            background:res.data.client.color_code
                        })

                }
            })
            .catch(err => {
              //console.log("AXIOS ERROR: ", err);
            });
        };
		fetchData();
    }, []);

    return (
        <>
            {/* <Notice icon="flaticon-warning kt-font-primary">
                For more info please check the components's official{" "}
            </Notice> */}

            <div className="row">
                <div className="col-md-12">
                <div className="kt-space-40" />
                    <Clientform  data={formdata} history={props.history}/>
                    <div className="kt-space-20" />
                </div>
            </div>
        </>
    );
};
export default EditClient;
