import React, { useState } from "react";
import Locationform from "./form";

const AddLocation = props => {
  const [data, setData] = useState([]);
  const [formdata, setformdata] = useState({
    client_id: "",
    l_name: "",
    add1: "",
    add2: "",
    state: "",
    city: "",
    zip: "",
    fb_link: "",
    fb_link2: "",
    view_fb2: "",
    yelp_link: "",
    google_link: "",
    google_link2: "",
    google_count2: 0,
    zillow_link: "",
    yp_link: "",
    trip_link: "",
    yell_link: "",
    sf_link: "",
    real_link: "",
    apar_link: "",
    aparrate_link: "",
    great_schools_link: "",
    healthgrades_link: "",
    mysite_link: "",
    thumbtack_link: "",
    amazon_link: "",
    fb_account: "",
    fb_account2: "",
    yelp_account: "",
    google_account: "",
    google_account2: "",
    zillow_account: "",
    yp_account: "",
    trip_account: "",
    yell_account: "",
    sf_account: "",
    real_account: "",
    apar_account: "",
    aparrate_account: "",
    great_schools_account: "",
    healthgrades_account: "",
    mysite_account: "",
    thumbtack_account: "",
    amazon_account: "",
    c_no: 0,
    c_email: "",
    msg: "",
    msg1: "",
    sms_msg: "",
    sms_msg1: "",
    google_count: 0,
    yelp_count: 0,
    fb_count: 0,
    fb_count2: 0,
    zillow_count: 0,
    yp_count: 0,
    trip_count: 0,
    yell_count: 0,
    sf_count: 0,
    real_count: 0,
    apar_count: 0,
    aparrate_count: 0,
    great_schools_count: 0,
    healthgrades_count: 0,
    thumbtack_count: 0,
    amazon_count: 0,
    google_ratings: 0.0,
    yelp_ratings: 0.0,
    fb_ratings: 0.0,
    fb_ratings: 0.0,
    zillow_ratings: 0.0,
    yp_ratings: 0.0,
    trip_ratings: 0.0,
    yell_ratings: 0.0,
    real_ratings: 0.0,
    apar_ratings: 0.0,
    aparrate_ratings: 0.0,
    great_schools_ratings: 0.0,
    healthgrades_ratings: 0.0,
    mysite_ratings: 0.0,
    thumbtack_ratings: 0.0,
    amazon_ratings: 0.0,
    google_date: "",
    google_date2: "",
    yelp_date: "",
    fb_date: "",
    fb_date2: "",
    zillow_date: "",
    yp_date: "",
    sf_date: "",
    apar_date: "",
    aparrate_date: "",
    real_date: "",
    trip_date: "",
    yell_date: "",
    great_schools_date: "",
    healthgrades_date: "",
    mysite_date: "",
    thumbtack_date: "",
    amazon_date: "",
    view_google: "",
    view_google2: "",
    view_yelp: "",
    view_fb: "",
    view_zillow: "",
    view_yp: "",
    view_trip: "",
    view_yell: "",
    view_sf: "",
    view_real: "",
    view_apar: "",
    view_aparrate: "",
    view_great_schools: "",
    view_healthgrades: "",
    view_mysite: "",
    view_thumbtack: "",
    view_amazon: "",
    setname: 0,
    businees_name: "",
    color_code: "",
    logo: "",
    review_msg: "",
    yes_msg: "",
    no_msg: "",
    subject: "",
    subject1: "",
    sign: "",
    sign1: "",
    thanks_msg: "",
    thanks_msg1: "",
    sendlogo: 0,
    sendlogo1: 0,
    sendlogoemail: 0,
    sendlogoemail1: 0,
    underlogo: "",
    coupon: "",
    setfeed: 0,
    id: "",
    background: "",
    mysite_count: 0,
    sf_ratings: 0.0,
    solar_review: "",
    solar_review_view: "",
    home_advisor: "",
    home_advisor_view: "",
    energysage: "",
    energysage_view : ""
  });

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="kt-space-40" />
          <Locationform data={formdata} history={props.history} />
          <div className="kt-space-20" />
        </div>
      </div>
    </>
  );
};
export default AddLocation;
