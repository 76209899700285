//production

// export const API = "http://52.52.177.22:3003/";
// export const BASE_URL = "http://52.52.177.22:3000";
export const API = "https://www.grofirereviews.com:3005/";
export const BASE_URL = "https://www.grofirereviews.com/app";
//local

// export const API = "http://localhost:3003/";
// export const BASE_URL = "http://localhost:3006/";

//test
// export const API = "http://143.110.185.170:3003/";
// export const BASE_URL = "http://143.110.185.170:3000/app";
