import React, { useState, useEffect } from "react";
import CodeExample from "../../../partials/content/CodeExample";
import { Table, Button } from "react-bootstrap";
import axios from "axios";
import { API } from "../../../../const";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import localeInfo from "rc-pagination/lib/locale/en_US";
import "rc-pagination/assets/index.css";
import LoadingOverlay from "react-loading-overlay";
import { RiseLoader } from "react-spinners";
import Validator from 'validatorjs';
const SetFeedEmailList = props => {
  //console.log("propsmatchid");
  //console.log(props.match.params.id);

  const [data, setData] = useState([]);
  const [showLoading, setShowLoading] = useState(false);

  const [currdata, setcurrData] = useState([]);
  const [tempArr, settempArr] = useState([]);
  const [count, setcount] = useState(0);
  const [current, setcurrent] = useState(1);
  const [formdata, setformData] = useState([
    {
        first_email: "",
        second_email: "",
        third_email: "",
        fourth_email: "",
        fifth_email: "",
        sixth_email: "",
    }
  ]);

  const apiUrlall = API + "api/feedmail_by_location_id";
  useEffect(() => {
    const fetchData = async () => {
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token")
        }
      };
      var postData = {
        location_id: props.match.params.id
      };
      axios
        .post(apiUrlall, postData, axiosConfig)
        .then(res => {
            setcurrData(res.data.data);
            let count = Object.keys(res.data.data).length;
            setcount(count);
            let result=res.data.data;
            setformData({"first_email": result[0] && result[0].cp_feedmails && result[0].cp_feedmails[0] && result[0].cp_feedmails[0].email });
            setformData({"second_email": result[0] && result[0].cp_feedmails && result[0].cp_feedmails[1] && result[0].cp_feedmails[1].email });
            setformData({"third_email": result[0] && result[0].cp_feedmails && result[0].cp_feedmails[2] && result[0].cp_feedmails[2].email });
            setformData({"fourth_email": result[0] && result[0].cp_feedmails && result[0].cp_feedmails[3] && result[0].cp_feedmails[3].email });
            setformData({"fifth_email": result[0] && result[0].cp_feedmails && result[0].cp_feedmails[4] && result[0].cp_feedmails[4].email });
            setformData({"sixth_email": result[0] && result[0].cp_feedmails && result[0].cp_feedmails[5] && result[0].cp_feedmails[5].email });
            settempArr(result[0].cp_feedmails);
            setShowLoading(false);
        })
        .catch(err => {
            setShowLoading(false);
          //console.log("AXIOS ERROR: ", err);
        });
    };
    fetchData();
  }, []);

  const loadData = async (offsetS , cu) => {
    setformData({offset : offsetS});
    setformData({...formdata , offset : offsetS });
    if(!cu){
      setcurrent(1);
    }else{
      setcurrent(cu);
    }  
  };
  
  useEffect(() => {
    loadData();
  }, [data]);

  const handleChange = event => {
    setformData({ ...formdata, [event.target.name]: event.target.value });
    settempArr({ ...tempArr, [event.target.name]: event.target.value });
  };

  const validateForm = () => {
		let data = {
      first_email: formdata.first_email,
      second_email: formdata.second_email,
      third_email: formdata.third_email,
      fourth_email: formdata.fourth_email,
      fifth_email: formdata.fifth_email,
      sixth_email: formdata.sixth_email,
		};

		let rules = {
			first_email: 'required|email',
			second_email: 'email',
			third_email: 'email',
			fourth_email: 'email',
			fifth_email: 'email',
			sixth_email: 'email',
		};

		let validation = new Validator(data, rules);

		if (validation.passes()) {
			return true;
		}
  };
  
  //console.log("tempArr");
  //console.log(tempArr[0]);

  let saveFeedmailAPI =API + "api/save_feedmail";
  const formSubmit = async () => {
    setShowLoading(true);
    
    let PostData = [
      formdata.first_email?formdata.first_email :(tempArr && tempArr[0] && tempArr[0].email?tempArr[0].email:null),
      formdata.second_email?formdata.second_email : (tempArr && tempArr[1] && tempArr[1].email?tempArr[1].email:null),
      formdata.third_email?formdata.third_email : (tempArr && tempArr[2] && tempArr[2].email?tempArr[2].email:null),
      formdata.fourth_email?formdata.fourth_email : (tempArr && tempArr[3] && tempArr[3].email?tempArr[3].email:null),
      formdata.fifth_email?formdata.fifth_email : (tempArr && tempArr[4] && tempArr[4].email?tempArr[4].email:null),
      formdata.sixth_email?formdata.sixth_email : (tempArr && tempArr[5] && tempArr[5].email?tempArr[5].email:null),
    ];

    var postData1 = {
        loc_id: props.match.params.id,
        emails: PostData,
        optradio: formdata.optradio,
    };
    
    //console.log("postData1");
    //console.log(postData1);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token")
      }
    };

    axios
      .post(saveFeedmailAPI, postData1, axiosConfig)
      .then(res => {
        setShowLoading(false);
        alert(res.data.message);
        window.location.reload();
      })
      .catch(err => {
        setShowLoading(false);
        //console.log("AXIOS ERROR: ", err);
      });
}

  const back = () => {
    props.history.goBack();
  };

  let locationName = localStorage.getItem("locationName");
  let title = "Set for "+locationName+" location";

  //console.log("currdata");
  //console.log(currdata[0]);
  return (
    <LoadingOverlay
      active={showLoading}
      spinner={<RiseLoader sizeUnit={"px"} size={80} color={"#2a8dd1"} />}
    >
      <>
        
        <CodeExample beforeCodeTitle={title}>
        <div className="row">
            <div className="col-offset-9 col-3 text-right">
                <input
                    type="button"
                    onClick={() => back()}
                    value="Back"
                    className="margin btn btn-primary save-btn font-weight-bold"
                  />
            </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="kt-section">
                  {/* <span className="kt-section__sub">Customer Feedback</span> */}
                  <br/>
                  <div className="kt-separator kt-separator--dashed responsive"></div>
                  <form>
                  <input type="radio" name="optradio" value="0" onChange={handleChange} defaultChecked={currdata && currdata[0] && currdata[0].setfeed ==='0'}/><label>&nbsp; Default({currdata && currdata[0] && currdata[0].cp_login.email})</label><br/> 
                    <p>OR</p>
                  <input type="radio" name="optradio" value="1" onChange={handleChange} defaultChecked={currdata && currdata[0] && currdata[0].setfeed==='1'}/><label>&nbsp;Others</label>
                  <div className="col-4">
                    <input type="email" name="first_email" id="first_email" className="form-control" placeholder="First email"
                            onChange={handleChange} value={formdata.first_email}></input>
                  </div>
                  <br/>
                  <div className="col-4">
                    <input type="email" name="second_email" id="second_email" value={formdata.second_email} className="form-control" placeholder="Second email" onChange={handleChange}></input>
                  </div>
                  <br/>
                  <div className="col-4">
                    <input type="email" name="third_email" id="third_email" value={formdata.third_email} className="form-control" placeholder="Third email" onChange={handleChange}></input>
                  </div>
                  <br/>
                  <div className="col-4">
                    <input type="email" name="fourth_email" id="fourth_email" value={formdata.fourth_email} className="form-control" placeholder="Fourth email" onChange={handleChange}></input>
                  </div>
                  <br/>
                  <div className="col-4">
                    <input type="email" name="fifth_email" id="fifth_email" value={formdata.fifth_email} className="form-control" placeholder="Fifth email" onChange={handleChange}></input>
                  </div>
                  <br/>
                  <div className="col-4">
                    <input type="email" name="sixth_email" id="sixth_email" value={formdata.sixth_email} className="form-control" placeholder="Sixth email" onChange={handleChange}></input>
                  </div>
                  <br/>
                  <div className="col-4">
                    <input type="button" value="Save" className="form-control btn btn-primary" onClick={() => formSubmit()}/> 
                    {/* disabled={!validateForm()} */}
                  </div>
                  <br/>
                  </form>
                  </div>
              </div>
            </div>
            </CodeExample>
        
      </>
    </LoadingOverlay>
  );
};
export default SetFeedEmailList;