import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import Builder from "./Builder";
import Dashboard from "./Dashboard";
import Dashboardclient from "./Dashboardclient";
import Dashboarduser from "./Dashboarduser";

import Clients from "./Clients/Clients";
import Location from "./Locations/Location";

import AddClients from "./Clients/AddClient";
import EditClients from "./Clients/EditContent";

import Users from "./Users/Users";
import Sitelink from "./Sitelink/Sitelink";

import UsersClient from "./Users/UsersListClient";
import LocationClient from "./Locations/LocationlistClient";

import AddUsers from "./Users/AddUser";
import EditUsers from "./Users/EditUsers";
import EditClientUsers from "./Users/EditClientUsers";

import ReportUser from "./Users/Reports";
import { LayoutSplashScreen } from "../../../_metronic";

import AddLocation from "./Locations/AddLocation";
import EditLocation from "./Locations/EditLocation";

import SetSpanishOption from "./Locations/SetSpanishOption";

import EditProfile from "./Profile/EditContent";
import Change_Password from "./Users/Change_Password";
import AddUsersclient from "./Users/AddUser_client";
import FeedbackList from "./Feedback/FeedbackList";
import SetFeedbackEmailList from "./Feedback/SetFeedbackEmailList";
import ViewLocationClient from "./Feedback/ViewLocationlistClient";
import SetFeedEmailList from "./Feedback/SetFeedEmailList";
import ReviewLocation from "./Locations/ReviewLocation";

import SurveySend from "./Survey/SendSurvey";
// import ReviewRecommend from "./Review/Recommend";
// import ReviewIndex from "./Review/Index";

import Reviewlist from "./Users/Review";

import Survey from "./Survey/Survey";
import Question from "./Survey/Questions";
import EditSurvey from "./Survey/EditSurvey";
import AddSurvey from "./Survey/AddSurvey";
import AddQuestion from "./Questions/AddQuestion";
import EditQuestion from "./Questions/EditQuestion";

import MySurvey from "./Survey/mysurvey";
import Report_survey from "./Survey/Report";
import ReportUser_survey from "./Survey/UserReport";

import SurveyLinks from "./Survey/SurveyLinks";
import ReviewsScreen from "./Review/Myreview";
import ReviewActivityReportScreen from "./Review/ReviewActivityReport";
import ReviewActivityUserReportScreen from "./Review/ReviewActivityUserReport";
import SurveyLocation from "./Locations/SurveyLocation";
import SendTool from "./Locations/SendTool";

// import SurveyView from "./Survey/SurveyView";
export default function HomePage(props) {
  if (!localStorage.getItem("token")) {
    window.location.href = "/logout";
  }

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {localStorage.getItem("client_id") === "000" ? (
          <Redirect exact from="/" to="/dashboard" />
        ) : localStorage.getItem("menu") === "undefined" ? (
          <Redirect exact from="/" to="/dashboardclient" />
        ) : (
          <Redirect exact from="/" to="/dashboarduser" />
        )}

        {localStorage.getItem("client_id") === "000" ? (
          <>
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/clients" component={Clients} />
            <Route path="/sitelink" component={Sitelink} />
            <Route
              exact
              path="/set_feedback_email"
              component={SetFeedbackEmailList}
            />
            <Route
              exact
              path="/set_feedback_email/viewEmailloc/:id"
              component={ViewLocationClient}
            />
            <Route
              exact
              path="/set_feedback_email/setFeedEmails/:id"
              component={SetFeedEmailList}
            />
            <Route path="/clients-add" component={AddClients} />
            <Route path="/client-edit/:id" component={EditClients} />
            <Route path="/client-location/:id" component={LocationClient} />
            <Route path="/reviewlist/:id" component={Reviewlist} />
            <Route path="/reports/:id/:name" component={ReportUser} />
            <Route path="/client-survey/:id" component={Survey} />
            <Route path="/view_question/:id/:client_id" component={Question} />
            <Route path="/survey/:id" component={EditSurvey} />
            <Route path="/add-survey/:client_id" component={AddSurvey} />
            <Route path="/add-question/:client_id" component={AddQuestion} />
            <Route path="/question/:id" component={EditQuestion} />
            <Route path="/location" component={Location} exact />
            <Route path="/users" component={Users} />
            <Route path="/users-add" component={AddUsers} />
            <Route path="/users-edit/:id" component={EditUsers} exact />
            <Route exact path="/location-add" component={AddLocation} />
            <Route exact path="/location/:id" component={EditLocation} />
          </>
        ) : (
          <>
            <Route path="/builder" component={Builder} />
            <Route path="/dashboardclient" component={Dashboardclient} />
            <Route path="/dashboarduser" component={Dashboarduser} />

            <Route path="/users-add" component={AddUsers} />
            <Route path="/useradd" component={AddUsersclient} />

            <Route path="/client-users-add/:id" component={AddUsers} />
            <Route path="/location" component={Location} exact />

            <Route path="/users-edit/:id" component={EditUsers} exact />
            <Route
              path="/client-users-edit/:id"
              component={EditClientUsers}
              exact
            />
            <Route path="/client-users/:id" component={UsersClient} />
            <Route path="/clientwiseuser" component={UsersClient} />
            <Route path="/reviewadd" component={ReviewLocation} />

            <Route path="/clientwiselocation" component={LocationClient} />

            <Route exact path="/location-add" component={AddLocation} />
            <Route exact path="/location/:id" component={EditLocation} />
            <Route path="/feedback" component={FeedbackList} />

            <Route
              exact
              path="/location/set_spanish_language_option/:id"
              component={SetSpanishOption}
            />

            <Route path="/change_password" component={Change_Password} />
            <Route path="/myprofile/:id" component={EditProfile} />

            {/* <Route path="/survey-links" component={SurveyLinks} /> */}
            <Route path="/myreviews" component={ReviewsScreen} />
            {/* <Route path="/mysurvey" component={MySurvey} /> */}
            <Route
              path="/reports-activity"
              component={ReviewActivityReportScreen}
            />
            <Route
              path="/reports-user-activity"
              component={ReviewActivityUserReportScreen}
            />

            {/* <Route path="/send-survey" component={SurveyLocation} /> */}
            {/* <Route path="/surveyview/:id/:cust" component={SurveyView} />
        <Route path="/surveyview/:id/" component={SurveyView} /> */}

            {/* <Route path="/survey-report" component={Report_survey} /> */}
            <Route path="/survey-user-report" component={ReportUser_survey} />
            <Route path="/SendTool" component={SendTool} />
          </>
        )}
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
