import React from "react";
import { Form } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import LoadingOverlay from "react-loading-overlay";
import Surveyform1 from "../Survey/form1";
import CompanyLinks from "./CompanyLinkssurvey";
import "./form.css";
const Locationform2 = (props) => {
  return (
    <LoadingOverlay>
      <div>
        <Form encType="multipart/form-data">
          <div className="review">
            <div className="row">
              <div className="col-md-12">
                <div className="card border-top-0 border-right-0 border-left-0 pb-4">
                  <div className="card-header bg-white ">
                    <div className="row">
                      <div className="col-md-12"></div>
                      <div className="col-md-12 text-right" />
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div
                        className={
                          localStorage.getItem("surveycount") != 0
                            ? "col-md-3"
                            : "col-md-3 "
                        }
                      >
                        <Surveyform1></Surveyform1>
                      </div>
                      <CompanyLinks></CompanyLinks>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </LoadingOverlay>
  );
};
export default Locationform2;
