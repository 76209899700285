import React, { useState, useEffect } from "react";
import Notice from "../../../partials/content/Notice";
import CodeExample from "../../../partials/content/CodeExample";
import { Table, Button } from "react-bootstrap";
import axios from "axios";
import { API } from "../../../../const";
import Surveyform from "./../Survey/form";

const AddSurvey = props => {
  const [formdata, setformdata] = useState({
    client_id: localStorage.getItem("client_id"),
    survey_id: localStorage.getItem("survey_id")
  });
  const [location, setlocation] = useState([]);
  const [survey, setSurvey] = useState([]);
  const [client, setClient] = useState();
  useEffect(() => {
    const fetchData = async () => {
      var postData = {
        client_id: props.match.params.client_id
      };

      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token")
        }
      };
      const apiUrl = API + "api/getdata";

      const result = await axios
        .post(apiUrl, postData, axiosConfig)
        .then(res => {
          if (res.data) {
            setSurvey(res.data.survey);
            setlocation(res.data.location);
            setClient(res.data.client.company_name)
          }

        })
        .catch(err => {
          //console.log("AXIOS ERROR: ", err);
        });
    };
    fetchData();
  }, []);

  return (
    <>
      {/* <Notice icon="flaticon-warning kt-font-primary">
                For more info please check the components's official{" "}
            </Notice> */}

      <div className="row">
        <div className="col-md-12">
          <div className="kt-space-40" />
          <Surveyform
            data={formdata}
            history={props.history}
            survey={survey}
            client = {client}
            location={location}
            client_id = {props.match.params.client_id}
          />
          <div className="kt-space-20" />
        </div>
      </div>
    </>
  );
};
export default AddSurvey;
