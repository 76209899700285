import React, { useState, useEffect } from "react";
import Notice from "../../../partials/content/Notice";
import CodeExample from "../../../partials/content/CodeExample";
import { Table, Button } from "react-bootstrap";
import axios from "axios";
import { API } from "../../../../const";
import Clientform from "./../Profile/form";

const EditClient = (props) => {
  const [formdata, setformdata] = useState({
    id: "",
    company_name: "",
    cl_name: "",
    userfile: "",
    cl_email: "",
    cl_mobile: "",
    msg_limit: "",
    rmsg_limit: "",
    color_code: "",
  });

  const apiUrl = API + "api/myprofile";

  useEffect(() => {
    //   alert(props.match.params.id);
    const fetchData = async () => {
      var postData = {
        client_id: props.match.params.id,
      };

      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      };
      const result = await axios
        .post(apiUrl, postData, axiosConfig)
        .then((res) => {
          //console.log(res.data);

          if (res.data) {
            setformdata({
              company_name: res.data[0].company_name,
              client_name: res.data[0].client_name,
              client_email: res.data[0].cp_login.email,
              mobile: res.data[0].mobile,
              color_code: res.data[0].color_code,
              msg_limit: res.data[0].msg_limit,
              r_msg: res.data[0].remaining_msg,
              userfile: res.data[0].client_logo,
              id: props.match.params.id,
            });
          }
        })
        .catch((err) => {
          //console.log("AXIOS ERROR: ", err);
        });
    };
    fetchData();
  }, []);

  return (
    <>
      {/* <Notice icon="flaticon-warning kt-font-primary">
                For more info please check the components's official{" "}
            </Notice> */}

      <div className="row">
        <div className="col-md-12">
          <div className="kt-space-40" />
          <Clientform data={formdata} history={props.history} />
          <div className="kt-space-20 sdd" />
        </div>
      </div>
    </>
  );
};
export default EditClient;
