import React, { useState, useEffect } from "react";
import CodeExample from "../../../partials/content/CodeExample";
import axios from "axios";
import { API } from "../../../../const";
import Rating from "react-rating";
import "rc-pagination/assets/index.css";
import LoadingOverlay from "react-loading-overlay";
import { RiseLoader } from "react-spinners";
import { toast } from "react-toastify";

const SurveyViewAnswer = (props) => {
  const [showLoading, setShowLoading] = useState(true);

  const [data, setData] = useState([]);
  const [SurveyView, setSurvey] = useState([]);
  const [ratingindex, setratindindex] = useState(0);
  const [formdata, setformdata] = useState([]);
  const [ratings, setRatings] = useState({});
  const [subanswer, setSubanswer] = useState();
  const handleChange = (event) => {
    setformdata({ ...formdata, [event.target.name]: event.target.value });
  };

  const handleChangesub = (event) => {
    setSubanswer({ ...subanswer, [event.target.name]: event.target.value });
  };

  const handleChange2 = (event) => {
    setratindindex(event);
  };

  const handleChange1 = (event) => {
    setRatings({ ...ratings, [ratingindex]: event });
  };

  var apiUrlall = API + "api/surveyQuestionsAnswers/";

  useEffect(() => {
    const fetchData = async () => {
      var postData = {
        survey_name: props.match.params.id,
        custid: props.match.params.custid,
      };
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      };
      axios.post(apiUrlall, postData, axiosConfig).then((result) => {
        //console.log(result.data);
        setData(result.data.question);
        setSurvey(result.data.survey);
        setShowLoading(false);
      });
    };

    fetchData();
  }, []);

  const formSubmit = async () => {
    setShowLoading(true);
    var postData = {
      answer: formdata,
      subanswer: subanswer,
      survey_id: props.match.params.id,
      ratings: ratings,
      client_id: localStorage.getItem("client_id"),
      location_id: SurveyView.location_id,
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };
    const apiadd = API + "api/surveyQuestionADD";

    axios
      .post(apiadd, postData, axiosConfig)
      .then((res) => {
        toast(res.data.message);
        setShowLoading(false);
      })
      .catch((err) => {
        //console.log("AXIOS ERROR: ", err);
      });
  };

  const question = (item, idx) => {
    if (item.question_type == "TextBox") {
      return (
        <>
          <div className="textbox-div" key={idx}>
            <h2>{item.question}</h2>
            <textarea
              onChange={handleChange}
              className="form-control"
              name={idx}
              disabled
            >
              {item.cp_answers && item.cp_answers[0].answer}
            </textarea>
          </div>
        </>
      );
    } else if (item.question_type == "Dropdown") {
      return (
        <>
          <div className="dropdown-div">
            <h2>{item.question}</h2>
            <select
              onChange={handleChange}
              value={item.cp_answers && item.cp_answers[0].answer}
              disabled
              className="form-control"
              name={idx}
            >
              <option>Please Select </option>
              {item.option1 ? (
                <option value={item.option1}>{item.option1} </option>
              ) : (
                ""
              )}
              {item.option2 ? (
                <option value={item.option2}> {item.option2} </option>
              ) : (
                ""
              )}
              {item.option3 ? (
                <option value={item.option3}>{item.option3} </option>
              ) : (
                ""
              )}
              {item.option4 ? (
                <option value={item.option4}>{item.option4} </option>
              ) : (
                ""
              )}
              {item.option5 ? (
                <option value={item.option5}>{item.option5} </option>
              ) : (
                ""
              )}
              {item.option6 ? (
                <option value={item.option6}>{item.option6} </option>
              ) : (
                ""
              )}
              {item.option7 ? (
                <option value={item.option7}>{item.option7} </option>
              ) : (
                ""
              )}
              {item.option8 ? (
                <option value={item.option8}>{item.option8} </option>
              ) : (
                ""
              )}
              {item.option9 ? (
                <option value={item.option9}>{item.option9} </option>
              ) : (
                ""
              )}
              {item.option10 ? (
                <option value={item.option10}>{item.option10} </option>
              ) : (
                ""
              )}
              {item.option11 ? (
                <option value={item.option11}>{item.option11} </option>
              ) : (
                ""
              )}{" "}
              {item.option12 ? (
                <option value={item.option12}>{item.option12} </option>
              ) : (
                ""
              )}{" "}
              {item.option13 ? (
                <option value={item.option13}>{item.option13} </option>
              ) : (
                ""
              )}{" "}
              {item.option14 ? (
                <option value={item.option14}>{item.option14} </option>
              ) : (
                ""
              )}{" "}
              {item.option15 ? (
                <option value={item.option15}>{item.option15} </option>
              ) : (
                ""
              )}{" "}
              {item.option16 ? (
                <option value={item.option16}>{item.option16} </option>
              ) : (
                ""
              )}{" "}
              {item.option17 ? (
                <option value={item.option17}>{item.option17} </option>
              ) : (
                ""
              )}{" "}
              {item.option18 ? (
                <option value={item.option18}>{item.option18} </option>
              ) : (
                ""
              )}{" "}
              {item.option19 ? (
                <option value={item.option19}>{item.option19} </option>
              ) : (
                ""
              )}{" "}
              {item.option20 ? (
                <option value={item.option20}>{item.option20} </option>
              ) : (
                ""
              )}{" "}
              {item.option21 ? (
                <option value={item.option21}>{item.option21} </option>
              ) : (
                ""
              )}{" "}
              {item.option22 ? (
                <option value={item.option22}>{item.option22} </option>
              ) : (
                ""
              )}{" "}
              {item.option23 ? (
                <option value={item.option23}>{item.option23} </option>
              ) : (
                ""
              )}{" "}
              {item.option24 ? (
                <option value={item.option24}>{item.option24} </option>
              ) : (
                ""
              )}{" "}
              {item.option25 ? (
                <option value={item.option25}>{item.option25} </option>
              ) : (
                ""
              )}
            </select>
          </div>
        </>
      );
    } else if (item.question_type === "Checkbox") {
     var checkbox =""
      checkbox = item.cp_answers[0] && item.cp_answers[0].answer.split(",");
      console.log("checkbox")
      console.log(checkbox)
      return checkbox != "undefined" ? (
        <div className="radio-div">
          <h2>{item.question}</h2>
          <div className="row">
            {item.option1 ? (
              <div className="radio col-md-3">
                <label className="radio-inline">
                  <input
                    type="checkbox"
                    onChange={handleChange}
                    name={idx}
                    className="form-check-input"
                    value={item.option1}
                    aria-label={item.option1}
                    checked={
                      item.cp_answers[0] &&
                      checkbox.includes(item.option1)
                        ? true
                        : false
                    }
                    disabled
                  />
                  {item.option1}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option2 ? (
              <div className="radio col-md-3">
                <label className="radio-inline">
                  <input
                    type="checkbox"
                    onChange={handleChange}
                    name={idx}
                    className="form-check-input"
                    value={item.option2}
                    aria-label={item.option2}
                    checked={
                      item.cp_answers[0] &&
                      checkbox.includes(item.option2)
                        ? true
                        : false
                    }
                    disabled
                  />
                  {item.option2}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option3 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="checkbox"
                    onChange={handleChange}
                    name={idx}
                    className="form-check-input"
                    value={item.option3}
                    aria-label={item.option3}
                    checked={
                      item.cp_answers[0] &&
                      checkbox.includes(item.option3)
                        ? true
                        : false
                    }
                    disabled
                  />
                  {item.option3}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option4 ? (
              <div className="radio col-md-3">
                <label>
                  <input
                    type="checkbox"
                    onChange={handleChange}
                    name={idx}
                    className="form-check-input"
                    value={item.option4}
                    aria-label={item.option4}
                    checked={
                      item.cp_answers[0] &&
                      checkbox.includes(item.option4)
                        ? true
                        : false
                    }
                    disabled
                  />
                  {item.option4}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option5 ? (
              <div className="radio col-md-3">
                <label>
                  <input
                    type="checkbox"
                    onChange={handleChange}
                    name={idx}
                    className="form-check-input"
                    value={item.option5}
                    aria-label={item.option5}
                    checked={
                      item.cp_answers[0] &&
                      checkbox.includes(item.option5)
                        ? true
                        : false
                    }
                    disabled
                  />
                  {item.option5}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option6 ? (
              <div className="radio col-md-3">
                <label>
                  <input
                    type="checkbox"
                    onChange={handleChange}
                    name={idx}
                    className="form-check-input"
                    value={item.option6}
                    aria-label={item.option6}
                    checked={
                      item.cp_answers[0] &&
                      checkbox.includes(item.option6)
                        ? true
                        : false
                    }
                    disabled
                  />
                  {item.option6}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option7 ? (
              <div className="radio col-md-3">
                <label>
                  <input
                    type="checkbox"
                    onChange={handleChange}
                    name={idx}
                    className="form-check-input"
                    value={item.option7}
                    aria-label={item.option7}
                    checked={
                      item.cp_answers &&
                      checkbox &&
                      checkbox.includes(item.option7)
                        ? true
                        : false
                    }
                    disabled
                  />
                  {item.option7}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option8 ? (
              <div className="radio col-md-3">
                <label>
                  <input
                    type="checkbox"
                    onChange={handleChange}
                    name={idx}
                    className="form-check-input"
                    value={item.option8}
                    aria-label={item.option8}
                    checked={
                      item.cp_answers[0] &&
                      item.cp_answers[0].answer === item.option8
                        ? true
                        : false
                    }
                    disabled
                  />
                  {item.option8}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option9 ? (
              <div className="radio col-md-3">
                <label>
                  <input
                    type="checkbox"
                    onChange={handleChange}
                    name={idx}
                    className="form-check-input"
                    value={item.option9}
                    aria-label={item.option9}
                    checked={
                      item.cp_answers &&
                      checkbox &&
                      checkbox.includes(item.option9)
                        ? true
                        : false
                    }
                    disabled
                  />
                  {item.option9}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option10 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="checkbox"
                    name={idx}
                    onChange={handleChange}
                    className="form-check-input"
                    value={item.option10}
                    aria-label={item.option10}
                    disabled
                    checked={
                      item.cp_answers &&
                      checkbox &&
                      checkbox.includes(item.option10)
                        ? true
                        : false
                    }
                  />
                  {item.option10}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option11 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="checkbox"
                    name={idx}
                    onChange={handleChange}
                    className="form-check-input"
                    value={item.option11}
                    aria-label={item.option11}
                    disabled
                    checked={
                      item.cp_answers &&
                      checkbox &&
                      checkbox.includes(item.option11)
                        ? true
                        : false
                    }
                  />
                  {item.option11}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option12 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="checkbox"
                    name={idx}
                    onChange={handleChange}
                    className="form-check-input"
                    value={item.option12}
                    aria-label={item.option12}
                    disabled
                    checked={
                      item.cp_answers &&
                      checkbox &&
                      checkbox.includes(item.option12)
                        ? true
                        : false
                    }
                  />
                  {item.option12}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option13 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="checkbox"
                    name={idx}
                    onChange={handleChange}
                    className="form-check-input"
                    value={item.option13}
                    aria-label={item.option13}
                    disabled
                    checked={
                      item.cp_answers &&
                      checkbox &&
                      checkbox.includes(item.option13)
                        ? true
                        : false
                    }
                  />
                  {item.option13}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option14 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="checkbox"
                    name={idx}
                    onChange={handleChange}
                    className="form-check-input"
                    value={item.option14}
                    aria-label={item.option14}
                    disabled
                    checked={
                      item.cp_answers &&
                      checkbox &&
                      checkbox.includes(item.option14)
                        ? true
                        : false
                    }
                  />
                  {item.option14}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option15 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="checkbox"
                    name={idx}
                    onChange={handleChange}
                    className="form-check-input"
                    value={item.option15}
                    aria-label={item.option15}
                    disabled
                    checked={
                      item.cp_answers &&
                      checkbox &&
                      checkbox.includes(item.option15)
                        ? true
                        : false
                    }
                  />
                  {item.option15}
                </label>
              </div>
            ) : (
              ""
            )}

            {item.option16 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="checkbox"
                    name={idx}
                    onChange={handleChange}
                    className="form-check-input"
                    value={item.option16}
                    aria-label={item.option16}
                    disabled
                    checked={
                      item.cp_answers &&
                      checkbox &&
                      checkbox.includes(item.option16)
                        ? true
                        : false
                    }
                  />
                  {item.option16}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option17 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="checkbox"
                    name={idx}
                    onChange={handleChange}
                    className="form-check-input"
                    value={item.option17}
                    aria-label={item.option17}
                    disabled
                    checked={
                      item.cp_answers &&
                      checkbox &&
                      checkbox.includes(item.option17)
                        ? true
                        : false
                    }
                  />
                  {item.option17}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option18 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="checkbox"
                    name={idx}
                    onChange={handleChange}
                    className="form-check-input"
                    value={item.option18}
                    aria-label={item.option18}
                    disabled
                    checked={
                      item.cp_answers &&
                      checkbox &&
                      checkbox.includes(item.option18)
                        ? true
                        : false
                    }
                  />
                  {item.option18}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option19 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="checkbox"
                    name={idx}
                    onChange={handleChange}
                    className="form-check-input"
                    value={item.option19}
                    aria-label={item.option19}
                    disabled
                    checked={
                      item.cp_answers &&
                      checkbox &&
                      checkbox.includes(item.option19)
                        ? true
                        : false
                    }
                  />
                  {item.option19}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option20 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="checkbox"
                    name={idx}
                    onChange={handleChange}
                    className="form-check-input"
                    value={item.option20}
                    aria-label={item.option20}
                    disabled
                    checked={
                      item.cp_answers &&
                      checkbox &&
                      checkbox.includes(item.option20)
                        ? true
                        : false
                    }
                  />
                  {item.option20}
                </label>
              </div>
            ) : (
              ""
            )}

            {item.option21 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="checkbox"
                    name={idx}
                    onChange={handleChange}
                    className="form-check-input"
                    value={item.option21}
                    aria-label={item.option21}
                    disabled
                    checked={
                      item.cp_answers &&
                      checkbox &&
                      checkbox.includes(item.option21)
                        ? true
                        : false
                    }
                  />
                  {item.option21}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option22 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="checkbox"
                    name={idx}
                    onChange={handleChange}
                    className="form-check-input"
                    value={item.option22}
                    aria-label={item.option22}
                    disabled
                    checked={
                      item.cp_answers &&
                      checkbox &&
                      checkbox.includes(item.option22)
                        ? true
                        : false
                    }
                  />
                  {item.option22}
                </label>
              </div>
            ) : (
              ""
            )}

            {item.option23 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="checkbox"
                    name={idx}
                    onChange={handleChange}
                    className="form-check-input"
                    value={item.option23}
                    aria-label={item.option23}
                    disabled
                    checked={
                      item.cp_answers &&
                      checkbox &&
                      checkbox.includes(item.option23)
                        ? true
                        : false
                    }
                  />
                  {item.option23}
                </label>
              </div>
            ) : (
              ""
            )}

            {item.option24 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="checkbox"
                    name={idx}
                    onChange={handleChange}
                    className="form-check-input"
                    value={item.option24}
                    aria-label={item.option24}
                    disabled
                    checked={
                      item.cp_answers &&
                      checkbox &&
                      checkbox.includes(item.option24)
                        ? true
                        : false
                    }
                  />
                  {item.option24}
                </label>
              </div>
            ) : (
              ""
            )}

            {item.option25 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="checkbox"
                    name={idx}
                    onChange={handleChange}
                    className="form-check-input"
                    value={item.option25}
                    aria-label={item.option25}
                    disabled
                    checked={
                      item.cp_answers &&
                      checkbox &&
                      checkbox.includes(item.option25)
                        ? true
                        : false
                    }
                  />
                  {item.option25}
                </label>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        ""
      );
    } else if (item.question_type == "Radio") {
      return (
        <div className="radio-div">
          <h2>{item.question}</h2>
          <div className="row">
            {item.option1 ? (
              <div className="radio col-md-3">
                <label className="radio-inline">
                  <input
                    type="radio"
                    onChange={handleChange}
                    name={idx}
                    className="form-check-input"
                    value={item.option1}
                    aria-label={item.option1}
                    checked={
                      item.cp_answers[0] &&
                      item.cp_answers[0].answer === item.option1
                        ? true
                        : false
                    }
                    disabled
                  />
                  {item.option1}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option2 ? (
              <div className="radio col-md-3">
                <label className="radio-inline">
                  <input
                    type="radio"
                    onChange={handleChange}
                    name={idx}
                    className="form-check-input"
                    value={item.option2}
                    aria-label={item.option2}
                    checked={
                      item.cp_answers[0] &&
                      item.cp_answers[0].answer === item.option23
                        ? true
                        : false
                    }
                    disabled
                  />
                  {item.option2}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option3 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="radio"
                    onChange={handleChange}
                    name={idx}
                    className="form-check-input"
                    value={item.option3}
                    aria-label={item.option3}
                    checked={
                      item.cp_answers[0] &&
                      item.cp_answers[0].answer === item.option3
                        ? true
                        : false
                    }
                    disabled
                  />
                  {item.option3}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option4 ? (
              <div className="radio col-md-3">
                <label>
                  <input
                    type="radio"
                    onChange={handleChange}
                    name={idx}
                    className="form-check-input"
                    value={item.option4}
                    aria-label={item.option4}
                    checked={
                      item.cp_answers[0] &&
                      item.cp_answers[0].answer === item.option4
                        ? true
                        : false
                    }
                    disabled
                  />
                  {item.option4}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option5 ? (
              <div className="radio col-md-3">
                <label>
                  <input
                    type="radio"
                    onChange={handleChange}
                    name={idx}
                    className="form-check-input"
                    value={item.option5}
                    aria-label={item.option5}
                    checked={
                      item.cp_answers[0] &&
                      item.cp_answers[0].answer === item.option5
                        ? true
                        : false
                    }
                    disabled
                  />
                  {item.option5}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option6 ? (
              <div className="radio col-md-3">
                <label>
                  <input
                    type="radio"
                    onChange={handleChange}
                    name={idx}
                    className="form-check-input"
                    value={item.option6}
                    aria-label={item.option6}
                    checked={
                      item.cp_answers[0] &&
                      item.cp_answers[0].answer === item.option6
                        ? true
                        : false
                    }
                    disabled
                  />
                  {item.option6}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option7 ? (
              <div className="radio col-md-3">
                <label>
                  <input
                    type="radio"
                    onChange={handleChange}
                    name={idx}
                    className="form-check-input"
                    value={item.option7}
                    aria-label={item.option7}
                    checked={
                      item.cp_answers[0] &&
                      item.cp_answers[0].answer === item.option7
                        ? true
                        : false
                    }
                    disabled
                  />
                  {item.option7}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option8 ? (
              <div className="radio col-md-3">
                <label>
                  <input
                    type="radio"
                    onChange={handleChange}
                    name={idx}
                    className="form-check-input"
                    value={item.option8}
                    aria-label={item.option8}
                    checked={
                      item.cp_answers[0] &&
                      item.cp_answers[0].answer === item.option8
                        ? true
                        : false
                    }
                    disabled
                  />
                  {item.option8}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option9 ? (
              <div className="radio col-md-3">
                <label>
                  <input
                    type="radio"
                    onChange={handleChange}
                    name={idx}
                    className="form-check-input"
                    value={item.option9}
                    aria-label={item.option9}
                    checked={
                      item.cp_answers[0] &&
                      item.cp_answers[0].answer === item.option9
                        ? "true"
                        : ""
                    }
                    disabled
                  />
                  {item.option9}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option10 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="radio"
                    name={idx}
                    onChange={handleChange}
                    className="form-check-input"
                    value={item.option10}
                    aria-label={item.option10}
                    disabled
                    checked={
                      item.cp_answers[0] &&
                      item.cp_answers[0].answer === item.option10
                        ? true
                        : false
                    }
                  />
                  {item.option10}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option11 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="radio"
                    name={idx}
                    onChange={handleChange}
                    className="form-check-input"
                    value={item.option11}
                    aria-label={item.option11}
                    disabled
                    checked={
                      item.cp_answers[0] &&
                      item.cp_answers[0].answer === item.option11
                        ? true
                        : false
                    }
                  />
                  {item.option11}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option12 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="radio"
                    name={idx}
                    onChange={handleChange}
                    className="form-check-input"
                    value={item.option12}
                    aria-label={item.option12}
                    disabled
                    checked={
                      item.cp_answers[0] &&
                      item.cp_answers[0].answer === item.option12
                        ? true
                        : false
                    }
                  />
                  {item.option12}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option13 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="radio"
                    name={idx}
                    onChange={handleChange}
                    className="form-check-input"
                    value={item.option13}
                    aria-label={item.option13}
                    disabled
                    checked={
                      item.cp_answers[0] &&
                      item.cp_answers[0].answer === item.option13
                        ? true
                        : false
                    }
                  />
                  {item.option13}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option14 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="radio"
                    name={idx}
                    onChange={handleChange}
                    className="form-check-input"
                    value={item.option14}
                    aria-label={item.option14}
                    disabled
                    checked={
                      item.cp_answers[0] &&
                      item.cp_answers[0].answer === item.option14
                        ? true
                        : false
                    }
                  />
                  {item.option14}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option15 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="radio"
                    name={idx}
                    onChange={handleChange}
                    className="form-check-input"
                    value={item.option15}
                    aria-label={item.option15}
                    disabled
                    checked={
                      item.cp_answers[0] &&
                      item.cp_answers[0].answer === item.option15
                        ? true
                        : false
                    }
                  />
                  {item.option15}
                </label>
              </div>
            ) : (
              ""
            )}

            {item.option16 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="radio"
                    name={idx}
                    onChange={handleChange}
                    className="form-check-input"
                    value={item.option16}
                    aria-label={item.option16}
                    disabled
                    checked={
                      item.cp_answers[0] &&
                      item.cp_answers[0].answer === item.option16
                        ? true
                        : false
                    }
                  />
                  {item.option16}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option17 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="radio"
                    name={idx}
                    onChange={handleChange}
                    className="form-check-input"
                    value={item.option17}
                    aria-label={item.option17}
                    disabled
                    checked={
                      item.cp_answers[0] &&
                      item.cp_answers[0].answer === item.option17
                        ? true
                        : false
                    }
                  />
                  {item.option17}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option18 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="radio"
                    name={idx}
                    onChange={handleChange}
                    className="form-check-input"
                    value={item.option18}
                    aria-label={item.option18}
                    disabled
                    checked={
                      item.cp_answers[0] &&
                      item.cp_answers[0].answer === item.option18
                        ? true
                        : false
                    }
                  />
                  {item.option18}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option19 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="radio"
                    name={idx}
                    onChange={handleChange}
                    className="form-check-input"
                    value={item.option19}
                    aria-label={item.option19}
                    disabled
                    checked={
                      item.cp_answers[0] &&
                      item.cp_answers[0].answer === item.option19
                        ? true
                        : false
                    }
                  />
                  {item.option19}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option20 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="radio"
                    name={idx}
                    onChange={handleChange}
                    className="form-check-input"
                    value={item.option20}
                    aria-label={item.option20}
                    disabled
                    checked={
                      item.cp_answers[0] &&
                      item.cp_answers[0].answer === item.option20
                        ? true
                        : false
                    }
                  />
                  {item.option20}
                </label>
              </div>
            ) : (
              ""
            )}

            {item.option21 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="radio"
                    name={idx}
                    onChange={handleChange}
                    className="form-check-input"
                    value={item.option21}
                    aria-label={item.option21}
                    disabled
                    checked={
                      item.cp_answers[0] &&
                      item.cp_answers[0].answer === item.option21
                        ? true
                        : false
                    }
                  />
                  {item.option21}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option22 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="radio"
                    name={idx}
                    onChange={handleChange}
                    className="form-check-input"
                    value={item.option22}
                    aria-label={item.option22}
                    disabled
                    checked={
                      item.cp_answers[0] &&
                      item.cp_answers[0].answer === item.option22
                        ? true
                        : false
                    }
                  />
                  {item.option22}
                </label>
              </div>
            ) : (
              ""
            )}

            {item.option23 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="radio"
                    name={idx}
                    onChange={handleChange}
                    className="form-check-input"
                    value={item.option23}
                    aria-label={item.option23}
                    disabled
                    checked={
                      item.cp_answers[0] &&
                      item.cp_answers[0].answer === item.option23
                        ? true
                        : false
                    }
                  />
                  {item.option23}
                </label>
              </div>
            ) : (
              ""
            )}

            {item.option24 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="radio"
                    name={idx}
                    onChange={handleChange}
                    className="form-check-input"
                    value={item.option24}
                    aria-label={item.option24}
                    disabled
                    checked={
                      item.cp_answers[0] &&
                      item.cp_answers[0].answer === item.option24
                        ? true
                        : false
                    }
                  />
                  {item.option24}
                </label>
              </div>
            ) : (
              ""
            )}

            {item.option25 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="radio"
                    name={idx}
                    onChange={handleChange}
                    className="form-check-input"
                    value={item.option25}
                    aria-label={item.option25}
                    disabled
                    checked={
                      item.cp_answers[0] &&
                      item.cp_answers[0].answer === item.option25
                        ? true
                        : false
                    }
                  />
                  {item.option25}
                </label>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      );
    } else if (item.question_type == "Grid") {
      return (
        <>
          <h2>{item.question}</h2>
          {item.cp_subquestions &&
            item.cp_subquestions.map((element, id) => (
              <>
                <div className="textbox-div">
                  <h3>{element.subquestion}</h3>
                  <textarea
                    onChange={handleChangesub}
                    className="form-control"
                    disabled
                    name={element.id}
                  >
                    {element.cp_answers2 &&
                      element.cp_answers2.answer.split(",")}
                  </textarea>
                </div>
              </>
            ))}
        </>
      );
    } else if (item.question_type == "Ratings") {
      return (
        <>
          <div className="ratings-div">
            <h2>{item.question}</h2>
            <div onMouseEnter={() => handleChange2(idx)}>
              <Rating
                onClick={handleChange1}
                initialRating={
                  item.cp_answers &&
                  item.cp_answers[0] &&
                  item.cp_answers[0].answer
                }
                readonly
              />
              {item.cp_answers &&
                item.cp_answers[0] &&
                item.cp_answers[0].answer}
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <LoadingOverlay
      active={showLoading}
      spinner={<RiseLoader sizeUnit={"px"} size={80} color={"#2a8dd1"} />}
    >
      <>
        {/* <Notice icon="flaticon-warning kt-font-primary">
        For more info please check the components's official{" "}
      </Notice> */}

        <div className="row" id="survey-view__wrap">
          <div className="col-md-12">
            <CodeExample beforeCodeTitle={SurveyView.survey_name}>
              <div className="kt-section">
                <div className="kt-section__inner">
                  {data.map((item, idx) => question(item, idx))}
                </div>
                <div className="kt-separator kt-separator--dashed responsive"></div>
              </div>
            </CodeExample>
          </div>
        </div>
      </>
    </LoadingOverlay>
  );
};
export default SurveyViewAnswer;
