import axios from 'axios';
import { API } from '../../const';
export const LOGIN_URL = API + 'api/user_login';
export const REGISTER_URL = 'api/auth/register';
export const REQUEST_PASSWORD_URL = API + 'api/forgot-password';
export const UPDATE_PASSWORD_URL = API + 'api/updatespassword_both';

export const ME_URL = 'api/me';

export function login(email, password) {
	return axios.post(LOGIN_URL, { email, password });
}

export function register(email, fullname, username, password) {
	return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
	return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function updatepassword(email, password1) {
	return axios.post(UPDATE_PASSWORD_URL, { email, password1 });
}

export function getUserByToken() {
	// Authorization head should be fulfilled in interceptor.
	const user = localStorage.getItem('token');
	//console.log(user);
	if (localStorage.getItem('token')) {
		return [ 200, { ...user, password: undefined } ];
	}
}
