/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as builder from "../../ducks/builder";
import { toAbsoluteUrl } from "../../utils/utils";

class Brand extends React.Component {

  componentDidMount() {


    // eslint-disable-next-line no-undef
  }

  render() {
    const menu = localStorage.getItem("menu");
    const usermail = localStorage.getItem("client_id");
    return (
      <div
        className={`kt-header__brand ${this.props.brandClasses} kt-grid__item`}
        id="kt_header_brand"
      >
        <a href={usermail === "000" ? "/dashboard" :  menu !== "undefined" ? "dashboarduser" : "dashboardclient"} className="kt-header__brand-logo">
          <img
            alt="logo"
            src={toAbsoluteUrl("/media/logos/logo-4.png")}
            className="kt-header__brand-logo-default"
          />
          <img
            alt="logo"
            src={toAbsoluteUrl("/media/logos/logo-4-sm.png")}
            className="kt-header__brand-logo-sticky"
          />
        </a>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    brandClasses: builder.selectors.getClasses(store, {
      path: "brand",
      toString: true
    })
  };
};

export default connect(mapStateToProps)(Brand);
