import React, { useState, useEffect } from "react";
import Notice from "../../../partials/content/Notice";
import CodeExample from "../../../partials/content/CodeExample";
import { Table, Button } from "react-bootstrap";
import axios from "axios";
import { API } from "../../../../const";
import ClientUserform from "./../Users/userclientform";
import FormUser from "./../Users/userclientdataform";

const AddUser_client = props => {
  const [data, setData] = useState([]);
  const [formdata, setformdata] = useState({
    client_id: "",
    email: "",
    password: "",
    name: "",
    locations: "",
    menu: "",
    user_type: "",
    mystatus: "",
    id: "",
    paramid: props.match.params.id
  });

  if(props.match.params.id)  {

    return (
        <>

          <div className="row">
            <div className="col-md-12">
              <div className="kt-space-40" />
              <ClientUserform data={formdata} history={props.history} />
              <div className="kt-space-20" />
            </div>
          </div>
        </>
    );

}else{

    return (
        <>

          <div className="row">
            <div className="col-md-12">
              <div className="kt-space-40" />
              <FormUser data={formdata} history={props.history} />
              <div className="kt-space-20" />
            </div>
          </div>
        </>
      );
}

    }
export default AddUser_client;
