import React, { useState, useEffect } from "react";
import Notice from "../../../partials/content/Notice";
import CodeExample from "../../../partials/content/CodeExample";
import { Table, Button } from "react-bootstrap";
import axios from "axios";
import { API } from "../../../../const";
import Questionform from "./form";

const AddQuestion = (props) => {
  const [formdata, setformdata] = useState({
    client_name1: "",
    loc_name: "",
    p_question: "",
    question: "",
    orderno: "",
    type: "",
    required: 0,
    option1: "",
    option2: "",
    option3: "",
    option4: "",
    option5: "",
    option6: "",
    option7: "",
    option8: "",
    option9: "",
    option10: "",
    option11: "",
    option12: "",
    option13: "",
    option14: "",
    option15: "",
    option16: "",
    option17: "",
    option18: "",
    option19: "",
    option20: "",
    option21: "",
    option22: "",
    option23: "",
    option24: "",
    option25: "",
  });

  const [location, setlocation] = useState([]);
  const [question, setQuestion] = useState([]);
  const [client, setClient] = useState();
  const [subquestion, setsubquestion] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      var postData = {
        client_id: props.match.params.client_id,
      };

      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      };
      const apiUrl = API + "api/addques";

      const result = await axios
        .post(apiUrl, postData, axiosConfig)
        .then((res) => {
          if (res.data) {
            setQuestion(res.data.question);
            setlocation(res.data.location);
            setClient(res.data.client.company_name)
          }
        })
        .catch((err) => {
          //console.log("AXIOS ERROR: ", err);
        });
    };
    fetchData();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="kt-space-40" />
          <Questionform
            data={formdata}
            history={props.history}
            location={location}
            client={client}
            question={question}
            subquestion={subquestion}
            client_id={props.match.params.client_id}
          />
          <div className="kt-space-20" />
        </div>
      </div>
    </>
  );
};
export default AddQuestion;
