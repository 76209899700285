/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { useLastLocation } from "react-router-last-location";
import HomePage from "../pages/home/HomePage";
import AuthPage from "../pages/auth/AuthPage";
import ErrorsPage from "../pages/errors/ErrorsPage";
import LogoutPage from "../pages/auth/Logout";
import { LayoutContextProvider } from "../../_metronic";
import Layout from "../../_metronic/layout/Layout";
import * as routerHelpers from "../router/RouterHelpers";
import ReviewRecommend from "./../pages/home/Review/Recommend";
import ReviewIndex from "./../pages/home/Review/Index";
import SurveyView from "./../pages/home/Survey/SurveyView";
import SurveyLinksView from "./../pages/home/Survey/SurveyLinksView";
import SurveyViewAnswer from "./../pages/home/Survey/SurveyFeedbackAnswer";
import AccountDelete from "../pages/auth/AccountDelete";

export const Routes = withRouter(({ history }) => {
  const lastLocation = useLastLocation();
  routerHelpers.saveLastLocation(lastLocation);
  const { isAuthorized, menuConfig, userLastLocation } = useSelector(
    ({ auth, urls, builder: { menuConfig } }) => ({
      menuConfig,
      isAuthorized: auth.user != null,
      userLastLocation: routerHelpers.getLastLocation(),
    }),
    shallowEqual
  );

  return (
    /* Create `LayoutContext` from current `history` and `menuConfig`. */
    <LayoutContextProvider history={history} menuConfig={menuConfig}>
      <Switch>
        {!isAuthorized ? (
          /* Render auth page when user at `/auth` and not authorized. */
          <Route path="/auth/login" component={AuthPage} />
        ) : (
          /* Otherwise redirect to root page (`/`) */
          <Redirect from="/auth" to={userLastLocation} />
        )}

        <Route path="/error" component={ErrorsPage} />
        <Route path="/logout" component={LogoutPage} />
        <Route path="/auth/forgot-password" component={AuthPage} />
        <Route path="/auth/update-password/:id" component={AuthPage} />
        <Route path="/gr/index/:id" component={ReviewRecommend} />
        <Route path="/review/index/:id" component={ReviewIndex} />
        <Route path="/surveyview/:id/:cust" component={SurveyView} />
        <Route path="/surveyview/:id/" component={SurveyView} />
        <Route
          path="/SurveyLinksview/:id/:location_id"
          component={SurveyLinksView}
        />
        <Route path="/SurveyLinksView/:id" component={SurveyLinksView} />

        <Route
          path="/SurveyViewAnswer/:id/:custid"
          component={SurveyViewAnswer}
        />
        <Route path="/auth/delete-account" component={AuthPage} />

        {!isAuthorized ? (
          /* Redirect to `/auth` when user is not authorized */
          <Redirect to="/auth/login" />
        ) : (
          <Layout>
            <HomePage userLastLocation={userLastLocation} />
          </Layout>
        )}
      </Switch>
    </LayoutContextProvider>
  );
});
