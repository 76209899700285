import React, { useState, useEffect, Fragment } from "react";
import { Form } from "react-bootstrap";
import axios from "axios";
import { API } from "../../../../const";
import "react-toastify/dist/ReactToastify.css";
import Validator from "validatorjs";
import LoadingOverlay from "react-loading-overlay";
import { RiseLoader } from "react-spinners";

import "./form.css";
const Surveyform1 = (props) => {
  const [locationlist, setlocationData] = useState([]);
  const [data, setData] = useState({});
  const [data1, setData1] = useState([]);
  const [locations, setlocations] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [defaultMessage, setDefaultMessage] = useState(0);

  const [inputFields, setInputFields] = useState([
    {
      mess: "",
      cell_number: "",
      email: "",
      name: "",
    },
  ]);

  const handleAddFields = () => {
    const values = [...inputFields];
    values.push({});
    setInputFields(values);
  };

  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };

  const handleInputChange = (index, event) => {
    const values = [...inputFields];
    if (event.target.name === "name") {
      values[index].name = event.target.value;
    } else if (event.target.name === "cell_number") {
      values[index].cell_number = event.target.value;
    } else if (event.target.name === "email") {
      values[index].email = event.target.value;
    } else {
      values[index].mess = event.target.value;
    }

    setInputFields(values);
  };

  const handleSubmitSurvey = (e) => {
    e.preventDefault();
    // setShowLoading(true);
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };

    let reviewapi1 = API + "api/sendlink2_new";

    var postData12 = {
      client_id: localStorage.getItem("client_id"),
      cell_number: formdata.cell_number,
      inputFields: inputFields,
      loc_id: formdata.location_name,
      survey_id: formdata.survey_id,
      default: formdata.default,
      mess: formdata.mess,
      users_id: localStorage.getItem("id"),
    };
    //console.log("postData");
    //console.log(postData12);

    axios
      .post(reviewapi1, postData12, axiosConfig)
      .then((result) => {
        alert(result.data.message);
        // setShowLoading(false);
        window.location.reload();
        // //console.log(result.data.location);
        // alert(result.data.location);
      })
      .catch((err) => {
        // setShowLoading(false);
        //console.log("AXIOS ERROR: ", err);
      });
  };

  const [formdata, setformdata] = useState({
    location_name: "",
    setname: "",
    fb_link: 1,
    google_link: 1,
    fb_link2: 1,
    google_link2: 1,
    yelp_link: 1,
    zillow_link: 1,
    yp_link: 1,
    trip_link: 1,
    yell_link: 1,
    sf_link: 1,
    real_link: 1,
    apar_link: 1,
    aparrate_link: 1,
    great_schools_link: 1,
    healthgrades_link: 1,
    mysite_link: 1,
    thumbtack_link: 1,
    amazon_link: 1,
    default: 1,
    mess: "",
  });

  useEffect(() => {
    setDefaultSurvey();
  }, [locationlist]);

  useEffect(() => {
    const apiUrlstyle = API + "api/location_by_client_id/";

    const fetchData = async () => {
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      };
      var postData = {
        client_id: localStorage.getItem("client_id"),
      };
      axios
        .post(apiUrlstyle, postData, axiosConfig)
        .then((result) => {
          var location = localStorage.getItem("locations").split(",");
          setlocations(location);
          setlocationData(result.data.location);
          location &&
            location[0] &&
            setformdata({
              ...formdata,
              "location_name": location[0],
            });
        })
        .catch((err) => {
          //console.log("AXIOS ERROR: ", err);
        });
    };

    fetchData();
  }, []);

  const back = () => {
    props.history.push({
      pathname: "/users",
    });
  };
  const validateForm = () => {
    let data = {
      cell_number: inputFields[0].cell_number,
      email: inputFields[0].email,
      name: inputFields[0].name,
      location_name: inputFields[0].location_name,
      survey_id: formdata.survey_id,
    };

    let rules = {
      name: "required",
      survey_id: "required",
    };

    let validation = new Validator(data, rules);

    if (validation.passes()) {
      return true;
    }
  };

  const handleChange = (event) => {
    if (event.target.id === "default") {
      if (event.target.checked === true) {
        setformdata({ ...formdata, [event.target.id]: 1 });
      } else {
        setformdata({ ...formdata, [event.target.id]: 0 });
      }
    } else {
      setformdata({ ...formdata, [event.target.id]: event.target.value });
      if (event.target.id === "location_name") {
        const survey = API + "api/getsurveylocationid";
        let axiosConfig = {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: localStorage.getItem("token"),
          },
        };
        var postData = {
          location_id: event.target.value,
          client_id: localStorage.getItem("client_id"),
        };

        axios
          .post(survey, postData, axiosConfig)
          .then((result) => {
            setData1(result.data.survey);
            // alert(result.data.location);
          })
          .catch((err) => {
            //console.log("AXIOS ERROR: ", err);
          });
      }
    }
  };

  const setDefaultSurvey = () => {
    const survey = API + "api/getsurveylocationid";
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };
    var postData = {
      location_id: formdata.location_name,
      client_id: localStorage.getItem("client_id"),
    };

    axios
      .post(survey, postData, axiosConfig)
      .then((result) => {
        setData1(result.data.survey);
        // alert(result.data.location);
      })
      .catch((err) => {
        //console.log("AXIOS ERROR: ", err);
      });
  };
  const checkLocation = (item, idx) => {
    if (localStorage.getItem("user_type") === "client") {
      return (
        <option key={idx} value={item.id} selected={idx === 0}>
          {item.add1}
          {", "}
          {item.city}
        </option>
      );
    } else {
      if (locations.includes(item.id.toString())) {
        return (
            <option key={idx} value={item.id} selected={locations[0] == item.id ? true : false }>
            {item.add1}
            {", "}
            {item.city}
          </option>
        );
      }
    }
  };
  return (
    <LoadingOverlay
      active={showLoading}
      spinner={<RiseLoader sizeUnit={"px"} size={80} color={"#2a8dd1"} />}
    >
      <div>
        <Form onSubmit={handleSubmitSurvey} encType="multipart/form-data">
          <div className="review">
            <div className="row">
              <div className="col-md-12">
                <div className=" ">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-12">
                        <h5 className="card-title mb-0">Send Survey </h5>
                      </div>
                      <div className="col-md-12 text-right" />
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12">
                        <div id="introduction" className="review-form">
                          <div className="row">
                            <div className="col-md-12 mb-10">
                              <span>All * fields are mandatory </span>
                            </div>
                            <div className=" col-md-12 ">
                              <label>Location Name*</label>
                              <select
                                className="form-control"
                                id="location_name"
                                name="location_name"
                                value={formdata.location_name}
                                onChange={handleChange}
                              >
                                <option value="">Please Select Location</option>
                                {locationlist.map((item, idx) =>
                                  checkLocation(item, idx)
                                )}
                              </select>
                              <input
                                type="hidden"
                                name="id"
                                id="id"
                                className="form-control"
                                placeholder="id"
                                value={formdata.id}
                              />
                            </div>
                            <div className=" col-md-12 ">
                              <select
                                className="form-control"
                                id="survey_id"
                                name="survey_id"
                                value={formdata.survey_id}
                                onChange={handleChange}
                              >
                                <option value="">Please Select Survey</option>
                                {data1.map((item, idx) => (
                                  <option key={idx} value={item.id}>
                                    {item.survey_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          {inputFields.map((inputField, index) => (
                            <Fragment key={`${inputField}~${index}`}>
                              <div className="form-group   ">
                                <input
                                  type="text"
                                  name="name"
                                  id="name"
                                  className="form-control"
                                  placeholder="Name"
                                  value={inputField.name}
                                  onChange={(event) =>
                                    handleInputChange(index, event)
                                  }
                                />
                              </div>
                              <div className="form-group   ">
                                <input
                                  type="text"
                                  name="cell_number"
                                  id="cell_number"
                                  maxLength="10"
                                  className="form-control"
                                  placeholder="Cell Number"
                                  value={inputField.cell_number}
                                  onChange={(event) =>
                                    handleInputChange(index, event)
                                  }
                                />
                              </div>

                              <div className="form-group  ">
                                <input
                                  type="text"
                                  name="email"
                                  id="email"
                                  className="form-control"
                                  placeholder="Email"
                                  value={inputField.email}
                                  onChange={(event) =>
                                    handleInputChange(index, event)
                                  }
                                />
                              </div>

                              <div className="form-group col text-right">
                                {inputFields.length > 1 && (
                                  <button
                                    className="btn btn-danger"
                                    type="button"
                                    onClick={() => handleRemoveFields(index)}
                                  >
                                    -
                                  </button>
                                )}

                                <button
                                  className="btn btn-info"
                                  type="button"
                                  onClick={() => handleAddFields()}
                                >
                                  +
                                </button>
                              </div>
                            </Fragment>
                          ))}
                          <>
                            <div className="form-group">
                              <label className="align-fix">
                                <input
                                  type="checkbox"
                                  name="default"
                                  id="default"
                                  className="form-check-input is-valid"
                                  checked={formdata.default === 1}
                                  value={formdata.default}
                                  onChange={handleChange}
                                />{" "}
                                Default Message*
                              </label>
                            </div>
                            {formdata.default == 0 ? (
                              <div className="form-group">
                                <textarea
                                  rows={4}
                                  type="text"
                                  name="mess"
                                  id="mess"
                                  className="form-control"
                                  placeholder="Message"
                                  onChange={handleChange}
                                  maxLength={125}
                                  onKeyDown={(e) =>
                                    setDefaultMessage(e.target.value)
                                  }
                                />
                                <label>
                                  Characters Left:{" "}
                                  {125 -
                                    (defaultMessage.length
                                      ? defaultMessage.length
                                      : 0)}
                                  /125
                                </label>
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="form-group">
                              <input
                                type="button"
                                onClick={handleSubmitSurvey}
                                value="Send "
                                className="btn btn-success save-btn font-weight-bold"
                                disabled={!validateForm()}
                              />
                            </div>
                          </>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </LoadingOverlay>
  );
};
export default Surveyform1;
