import React, { useEffect, useState } from "react";
import axios from "axios";
import { API } from "../../../../const";
import { RiseLoader } from "react-spinners";
import CodeExample from "../../../partials/content/CodeExample";
import LoadingOverlay from "react-loading-overlay";
import { Table } from "react-bootstrap";
import { Legend, RadialBarChart, RadialBar } from "recharts";

export default function ReviewsScreen(props) {
  const location_by_client_id = API + "api/location_by_client_id/";

  const myreviews = API + "api/myreviews/";

  const myreviewsaverage = API + "api/myreviewsaverage/";

  const location_by_client_id_locationid =
    API + "api/location_by_client_id_locationid/";

  const [isShowLoader, setShowLoader] = useState(false);

  const [locationList, setlocationList] = useState([]);
  const [mainlocationList, setmainlocationList] = useState([]);
  const [locationpickervalue, setlocationpickervalue] = useState({
    id: "",
    name: "",
    l_name: "",
  });

  const [data, setdata] = useState([]);
  const [dataColorList, setdataColorList] = useState([]);

  const [dataReviewCount, setdataReviewCount] = useState([]);
  const [dataColorReviewCountList, setdataColorReviewCountList] = useState([]);

  const [dataLocation, setDataLocation] = useState({});

  useEffect(() => {
    CallLocationListAPI();
    CallSocialReviewCountAPI();
    CallSocialAvgRatingAPI();
  }, []);

  const CallLocationListAPI = async () => {
    var client_id = await localStorage.getItem("client_id");

    var post = {
      client_id: client_id,
    };

    setShowLoader(true);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };
    axios
      .post(location_by_client_id, post, axiosConfig)
      .then((responseData) => {
        if (responseData.status) {


          setmainlocationList(responseData.data.location);

          var dataTemp = [];
          if (responseData.data.location) {
            for (let i = 0; i < responseData.data.location.length; i++) {
              var object = responseData.data.location[i];
              dataTemp.push({
                label: String(object.add1 + "" + object.city),
                value: object.id,
              });
            //  console.log(object.add1 + "," + object.city);
            }
          }
          setlocationList(dataTemp);

          setShowLoader(false);
        } else {
          setShowLoader(false);
          alert(responseData.data);
        }
      });
  };

  const handleChangeForLocationList = (value) => {
    value = value.target.value;
    console.log("select location Id = " + value);

    var selectedValue = "",
      selectedValueLastName = "";
    for (let i = 0; i < locationList.length; i++) {
      var object = mainlocationList[i];

      if (object.id === value) {
        selectedValue = object.add1 + "" + object.city;
        selectedValueLastName = object.l_name;
      }
    }

    if (value != null) {
      setlocationpickervalue({
        ...locationpickervalue,
        ["id"]: value,
        ["name"]: selectedValue,
        ["l_name"]: selectedValueLastName,
      });
      CallSocialReviewCountAPI(value);
      CallSocialAvgRatingAPI(value);
      CallLocationAttributes(value);
    } else {
      setlocationpickervalue({
        ...locationpickervalue,
        ["id"]: 0,
        ["name"]: "",
        ["l_name"]: "",
      });
    }
  };

  const CallSocialReviewCountAPI = async (value) => {
    var client_id = await localStorage.getItem("client_id");
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };
    var post = "";

    post = {
      client_id: client_id,
      location_id: value === undefined ? "" : value,
    };

    setShowLoader(true);

    axios.post(myreviews, post, axiosConfig).then((responseData) => {
      if (responseData.status) {
        //console.log("Social Review Count Response = " + responseData.data);

        setdata(responseData.data);
        //console.log(data);

        for (let i = 0; i < responseData.data.length; i++) {
          var object = responseData.data[i];

          var dataTemp = [];
          var dataTempColorList = [];
          for (let i = 0; i < responseData.data.length; i++) {
            var object = responseData.data[i];

            // console.log("google " + object.google_count);
            // console.log("google 2 " + object.google_count2);

            var ColorCode =
              "rgb(" +
              Math.floor(Math.random() * 256) +
              "," +
              Math.floor(Math.random() * 256) +
              "," +
              Math.floor(Math.random() * 256) +
              ")";

            var colors = [
              "#d32f2f",
              "#C2185B",
              "#7B1FA2",
              "#512DA8",
              "#303F9F",
              "#1976D2",
              "#0288D1",
              "#0097A7",
              "#00796B",
              "#388E3C",
              "#689F38",
              "#AFB42B",
              "#FBC02D",
              "#FFA000",
              "#F57C00",
              "#E64A19",
              "#5D4037",
              "#616161",
            ];
            var names = [
              "Google",
              "Google 2",
              "Yelp",
              "Facebook",
              "Facebook 2",
              "Zillow",
              "Yp",
              "Yell",
              "Trip",
              "Sf",
              "Real",
              "Apar",
              "Aparrate",
              "Greate School",
              "Healthgrades",
              "My Site",
              "Thumbtack",
              "Amazon",
            ];

            var j = 0;
            for (let [key, value] of Object.entries(object)) {
              if (value == 0 || value == null) {
                console.log("value");
                console.log(value);
              } else {
                dataTempColorList.push({
                  name: names[j] + " : " + value,
                  nametab: names[j],
                  value: value,
                  fill: colors[j],
                });
                j = j + 1;
              }
            }
          }

          setdata(dataTemp);
        //  console.log(data);
          setdataColorList(dataTempColorList);
        //   console.log("Colors");
        //   console.log(dataTempColorList);
        }

        setShowLoader(false);
      } else {
        setShowLoader(false);
        alert(responseData.data);
      }
    });
  };

  const CallSocialAvgRatingAPI = async (value) => {
    var client_id = await localStorage.getItem("client_id");

    var post = "";

    post = {
      client_id: client_id,
      location_id: value === undefined ? "" : value,
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };

    setShowLoader(true);

    axios.post(myreviewsaverage, post, axiosConfig).then((responseData) => {
      if (responseData.status) {
        console.log("Social Avg Ratings Response = " + responseData.data);

        setdata(responseData.data);
      //  console.log(data);

        for (let i = 0; i < responseData.data.length; i++) {
          var object = responseData.data[i];

          var dataTemp1 = [];
          var dataTempColorList1 = [];
          for (let i = 0; i < responseData.data.length; i++) {
            var object = responseData.data[i];

            // console.log("google " + object.google_count);
            // console.log("google 2 " + object.google_count2);

            var ColorCode =
              "rgb(" +
              Math.floor(Math.random() * 256) +
              "," +
              Math.floor(Math.random() * 256) +
              "," +
              Math.floor(Math.random() * 256) +
              ")";

            var colors = [
              "#50B432",
              "#ED561B",
              "#DDDF00",
              "#24CBE5",
              "#64E572",
              "#FF9655",
              "#FFF263",
              "#6AF9C4",
              "#50A432",
              "#ED581B",
              "#DCDF00",
              "#27CBE5",
              "#67E572",
              "#EF9655",
              "#1ed5c4",
              "#ff5733",
              "#f32aa7",
              "#33ff8a",
              "#3383ff",
              "#703e87",
            ];

            var names = [
              "Google",
              "Google 2",
              "Yelp",
              "Facebook",
              "Facebook 2",
              "Zillow",
              "Yp",
              "Yell",
              "Trip",
              "Sf",
              "Real",
              "Apar",
              "Aparrate",
              "Greate School",
              "Healthgrades",
              "My Site",
              "Thumbtack",
              "Amazon",
            ];

            var j = 0;
            for (let [key, value] of Object.entries(object)) {
              if (value === "0.0000" || value === null) {
              } else {
                dataTempColorList1.push({
                  name: names[j] + " : " + value.toFixed(2),
                  value: value,
                  fill: colors[j],
                });
                j = j + 1;
              }
            }
          }

          setdataReviewCount(dataTemp1);
          console.log(data);
          setdataColorReviewCountList(dataTempColorList1);
        //   console.log("Colors");
        //   console.log(dataTempColorList1);
        }

        setShowLoader(false);
      } else {
        setShowLoader(false);
        alert(responseData.data);
      }
    });
  };

  const CallLocationAttributes = async (value) => {
    var post = {
      id: value,
      client_id: await localStorage.getItem("client_id"),
    };

    setShowLoader(true);
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };

    axios
      .post(location_by_client_id_locationid, post, axiosConfig)
      .then((responseData) => {
        if (responseData.status) {
        //   console.log(responseData);
        //   console.log("Location Attribute Response = " + responseData.data);
          if (responseData.data.location) {
            responseData.data.location.length <= 0
              ? setDataLocation(null)
              : setDataLocation(responseData.data.location);
          } else {
            setDataLocation(null);
          }
        }
      });
  };

  const CallResetData = () => {
    console.log("Reset");

    setlocationpickervalue({
      ...locationpickervalue,
      ["id"]: 0,
      ["name"]: "",
      ["l_name"]: "",
    });
    CallSocialReviewCountAPI(undefined);
    CallSocialAvgRatingAPI(undefined);
  };

  const style = {
    top: 0,
    left: 350,
    lineHeight: "24px",
  };

  return (
    <>
      <LoadingOverlay
        active={isShowLoader}
        spinner={<RiseLoader sizeUnit={"px"} size={80} color={"#2a8dd1"} />}
      >
        <>
          <div className="row">
            <div className="col-md-12">
              <CodeExample beforeCodeTitle="Review Report">
                <div className="kt-section">
                  <span className="kt-section__sub"></span>
                  <br></br>
                  <div className="row">
                    <div className="col-6 col-md-4">
                      <select
                        className="form-control col-md-12"
                        id="location_id"
                        name="location_id"
                        onChange={handleChangeForLocationList}
                      >
                        <option value="">Select Location</option>
                        {locationList.map((item, idx) => (
                          <option key={idx} value={item.value}>
                            {" "}
                            {item.label}
                          </option>
                        ))}
                      </select>
                      <label>Location List</label>
                    </div>
                  </div>
                  <div className="kt-separator kt-separator--dashed responsive"></div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="background-margin">
                      <h3>Social Review Count</h3>

                      <RadialBarChart
                        width={500}
                        height={500}
                        cx={150}
                        cy={150}
                        innerRadius={20}
                        outerRadius={150}
                        barSize={10}
                        data={dataColorList}
                      >
                        <RadialBar
                          minAngle={15}
                          label=""
                          background
                          clockWise
                          dataKey="value"
                        />
                        <Legend
                          iconSize={10}
                          width={120}
                          height={140}
                          layout="vertical"
                          verticalAlign="middle"
                          wrapperStyle={style}
                        />
                      </RadialBarChart>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="background-margin">
                      <h3>Social Review Ratings</h3>
                      <RadialBarChart
                        width={500}
                        height={500}
                        cx={150}
                        cy={150}
                        innerRadius={20}
                        outerRadius={150}
                        barSize={10}
                        data={dataColorReviewCountList}
                      >
                        <RadialBar
                          minAngle={15}
                          label=""
                          background
                          clockWise
                          dataKey="value"
                        />
                        <Legend
                          iconSize={10}
                          width={120}
                          height={140}
                          layout="vertical"
                          verticalAlign="middle"
                          wrapperStyle={style}
                        />
                      </RadialBarChart>
                    </div>
                  </div>
                </div>

                <Table striped bordered hover className="centeredtable">
                  <thead>
                    <tr>
                      {(dataLocation && dataLocation.fb_link === "undefined") ||
                      (dataLocation && dataLocation.fb_link === "") ? null : (
                        <th>
                          <a
                            target="_blank"
                            href={dataLocation && dataLocation.fb_link}
                          >
                            Facebook{" "}
                          </a>
                        </th>
                      )}

                      {(dataLocation &&
                        dataLocation.fb_link2 === "undefined") ||
                      (dataLocation && dataLocation.fb_link2 === "") ? null : (
                        <th>
                          <a
                            target="_blank"
                            href={dataLocation && dataLocation.fb_link2}
                          >
                            Facebook 2
                          </a>
                        </th>
                      )}

                      {(dataLocation &&
                        dataLocation.yelp_link === "undefined") ||
                      (dataLocation && dataLocation.yelp_link === "") ? null : (
                        <th>
                          <a
                            target="_blank"
                            href={dataLocation && dataLocation.yelp_link}
                          >
                            Yelp
                          </a>
                        </th>
                      )}

                      {(dataLocation &&
                        dataLocation.google_link === "undefined") ||
                      (dataLocation &&
                        dataLocation.google_link === "") ? null : (
                        <th>
                          <a
                            target="_blank"
                            href={dataLocation && dataLocation.google_link}
                          >
                            Google
                          </a>
                        </th>
                      )}

                      {(dataLocation &&
                        dataLocation.google_link2 === "undefined") ||
                      (dataLocation &&
                        dataLocation.google_link2 === "") ? null : (
                        <th>
                          <a
                            target="_blank"
                            href={dataLocation && dataLocation.google_link2}
                          >
                            Google 2
                          </a>
                        </th>
                      )}

                      {(dataLocation &&
                        dataLocation.zillow_link === "undefined") ||
                      (dataLocation &&
                        dataLocation.zillow_link === "") ? null : (
                        <th>
                          <a
                            target="_blank"
                            href={dataLocation && dataLocation.zillow_link}
                          >
                            Zillow
                          </a>
                        </th>
                      )}

                      {(dataLocation && dataLocation.yp_link === "undefined") ||
                      (dataLocation && dataLocation.yp_link === "") ? null : (
                        <th>
                          <a
                            target="_blank"
                            href={dataLocation && dataLocation.yp_link}
                          >
                            Yp
                          </a>
                        </th>
                      )}

                      {(dataLocation &&
                        dataLocation.trip_link === "undefined") ||
                      (dataLocation && dataLocation.trip_link === "") ? null : (
                        <th>
                          <a
                            target="_blank"
                            href={dataLocation && dataLocation.trip_link}
                          >
                            Trip
                          </a>
                        </th>
                      )}

                      {(dataLocation &&
                        dataLocation.yell_link === "undefined") ||
                      (dataLocation && dataLocation.yell_link === "") ? null : (
                        <th>
                          <a
                            target="_blank"
                            href={dataLocation && dataLocation.yell_link}
                          >
                            Yell
                          </a>
                        </th>
                      )}

                      {(dataLocation && dataLocation.sf_link === "undefined") ||
                      (dataLocation && dataLocation.sf_link === "") ? null : (
                        <th>
                          <a
                            target="_blank"
                            href={dataLocation && dataLocation.sf_link}
                          >
                            Sf
                          </a>
                        </th>
                      )}

                      {(dataLocation &&
                        dataLocation.real_link === "undefined") ||
                      (dataLocation && dataLocation.real_link === "") ? null : (
                        <th>
                          <a
                            target="_blank"
                            href={dataLocation && dataLocation.real_link}
                          >
                            Real
                          </a>
                        </th>
                      )}

                      {(dataLocation &&
                        dataLocation.apar_link === "undefined") ||
                      (dataLocation && dataLocation.apar_link === "") ? null : (
                        <th>
                          <a
                            target="_blank"
                            href={dataLocation && dataLocation.apar_link}
                          >
                            Apar
                          </a>
                        </th>
                      )}

                      {(dataLocation &&
                        dataLocation.aparrate_link === "undefined") ||
                      (dataLocation &&
                        dataLocation.aparrate_link === "") ? null : (
                        <th>
                          <a
                            target="_blank"
                            href={dataLocation && dataLocation.aparrate_link}
                          >
                            Aparrate
                          </a>
                        </th>
                      )}

                      {(dataLocation &&
                        dataLocation.great_schools_link === "undefined") ||
                      (dataLocation &&
                        dataLocation.great_schools_link === "") ? null : (
                        <th>
                          <a
                            target="_blank"
                            href={
                              dataLocation && dataLocation.great_schools_link
                            }
                          >
                            Greate Schools
                          </a>
                        </th>
                      )}

                      {(dataLocation &&
                        dataLocation.healthgrades_link === "undefined") ||
                      (dataLocation &&
                        dataLocation.healthgrades_link === "") ? null : (
                        <th>
                          <a
                            target="_blank"
                            href={
                              dataLocation && dataLocation.healthgrades_link
                            }
                          >
                            Healthgrades
                          </a>
                        </th>
                      )}

                      {(dataLocation &&
                        dataLocation.mysite_link === "undefined") ||
                      (dataLocation &&
                        dataLocation.mysite_link === "") ? null : (
                        <th>
                          <a
                            target="_blank"
                            href={dataLocation && dataLocation.mysite_link}
                          >
                            My Site
                          </a>
                        </th>
                      )}

                      {(dataLocation &&
                        dataLocation.thumbtack_link === "undefined") ||
                      (dataLocation &&
                        dataLocation.thumbtack_link === "") ? null : (
                        <th>
                          <a
                            target="_blank"
                            href={dataLocation && dataLocation.thumbtack_link}
                          >
                            Thumbtrack
                          </a>
                        </th>
                      )}

                      {(dataLocation &&
                        dataLocation.amazon_link === "undefined") ||
                      (dataLocation &&
                        dataLocation.amazon_link === "") ? null : (
                        <th>
                          <a
                            target="_blank"
                            href={dataLocation && dataLocation.amazon_link}
                          >
                            Amazon
                          </a>
                        </th>
                      )}
                    </tr>
                  </thead>
                </Table>
              </CodeExample>
            </div>
          </div>
        </>
      </LoadingOverlay>
    </>
  );
}
