import React, { useState, useEffect } from "react";
import Notice from "../../../partials/content/Notice";
import CodeExample from "../../../partials/content/CodeExample";
import { Table , Button } from "react-bootstrap";
import axios from "axios";
import {API} from "../../../../const"
import Userform from "./../Users/userclientdataform"

const EditClientUsers = props => {
    const [formdata, setformdata] = useState({
        client_id: "",
    email: "",
    password: "",
    name: "",
    locations: "",
    menu: "",
    user_type: "",
    mystatus: "",
    id:"",
      });
      const apiUrl = API + 'api/user_getbyid';

      useEffect(() => {
		const fetchData = async () => {
            var postData = {
              "id" : props.match.params.id
              };

              let axiosConfig = {
                headers: {
                  "Content-Type": "application/json;charset=UTF-8",
                  Authorization: localStorage.getItem("token")
                }
              };
			const result = await axios
            .post(apiUrl, postData, axiosConfig)
            .then(res => {
                if(res.data){
                    setformdata(
                        {
                            client_id: res.data.client_id,
                            email: res.data.email,
                            password: "",
                            name: res.data.name,
                            locations: res.data.locations,
                            menu: res.data.menu,
                            mystatus: res.data.mystatus,
                            user_type:res.data.user_type,
                            id: res.data.id,

                        }
                    )
                }
            })
            .catch(err => {
              //console.log("AXIOS ERROR: ", err);
            });
        };
		fetchData();
    }, []);

    return (
        <>
            {/* <Notice icon="flaticon-warning kt-font-primary">
                For more info please check the components's official{" "}
            </Notice> */}

            <div className="row">
                <div className="col-md-12">
                <div className="kt-space-40" />
                    <Userform  data={formdata} history={props.history}/>
                    <div className="kt-space-20" />
                </div>
            </div>
        </>
    );
};
export default EditClientUsers;
