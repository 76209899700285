import React, { useState, useEffect, Fragment } from "react";
import { Form } from "react-bootstrap";
import axios from "axios";
import { API } from "../../../../const";
import "react-toastify/dist/ReactToastify.css";
import Validator from "validatorjs";
import $ from "jquery";
import LoadingOverlay from "react-loading-overlay";
import { RiseLoader } from "react-spinners";
import Surveyform1 from "../Survey/form1";
import CompanyLinks from "./CompanyLinks";
import "./form.css";
const Locationform1 = (props) => {
  const [locationlist, setlocationData] = useState([]);
  const [data, setData] = useState({});
  const [data1, setData1] = useState({});
  const [defaultMessage, setDefaultMessage] = useState(0);
  const [showLoading, setShowLoading] = useState(false);
  const [locations, setlocations] = useState([]);

  const [inputFields, setInputFields] = useState([
    {
      mess: "",
      cell_number: "",
      email: "",
      name: "",
    },
  ]);

  const handleAddFields = () => {
    const values = [...inputFields];
    values.push({});
    setInputFields(values);
  };

  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };

  const handleInputChange = (index, event) => {
    const values = [...inputFields];
    if (event.target.name === "name") {
      values[index].name = event.target.value;
    } else if (event.target.name === "cell_number") {
      values[index].cell_number = event.target.value;
    } else if (event.target.name === "email") {
      values[index].email = event.target.value;
    } else {
      values[index].mess = event.target.value;
    }

    setInputFields(values);
  };

  const checkLocation = (item, idx) => {
    if (localStorage.getItem("user_type") === "client") {
      return (
        <option key={idx} value={item.id}>
          {item.add1}
          {", "}
          {item.city}
        </option>
      );
    } else {
      if (locations.includes(item.id.toString())) {
        return (
          <option key={idx} value={item.id}>
            {item.add1}
            {", "}
            {item.city}
          </option>
        );
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setShowLoading(true);
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };

    let reviewapi1 = API + "api/send_review_link";
    var postData12 = {
      client_id: localStorage.getItem("client_id"),
      cell_number: formdata.cell_number,
      inputFields: inputFields,
      loc_id: formdata.location_name,
      fb_link:
        typeof data.fb_link == "undefined" || data.fb_link === ""
          ? 0
          : formdata.fb_link,
      google_link:
        typeof data.google_link == "undefined" || data.google_link === ""
          ? 0
          : formdata.google_link,
      fb_link2:
        typeof data.fb_link2 == "undefined" || data.fb_link2 === ""
          ? 0
          : formdata.fb_link2,
      google_link2:
        typeof data.google_link2 == "undefined" || data.google_link2 === ""
          ? 0
          : formdata.google_link2,
      yelp_link:
        typeof data.yelp_link == "undefined" || data.yelp_link === ""
          ? 0
          : formdata.yelp_link,
      zillow_link:
        typeof data.zillow_link == "undefined" || data.zillow_link === ""
          ? 0
          : formdata.zillow_link,
      yp_link:
        typeof data.yp_link == "undefined" || data.yp_link === ""
          ? 0
          : formdata.yp_link,
      trip_link:
        typeof data.trip_link == "undefined" || data.trip_link === ""
          ? 0
          : formdata.trip_link,
      yell_link:
        typeof data.yell_link == "undefined" || data.yell_link === ""
          ? 0
          : formdata.yell_link,
      sf_link:
        typeof data.sf_link == "undefined" || data.sf_link === ""
          ? 0
          : formdata.sf_link,
      real_link:
        typeof data.real_link == "undefined" || data.real_link === ""
          ? 0
          : formdata.real_link,
      apar_link:
        typeof data.apar_link == "undefined" || data.apar_link === ""
          ? 0
          : formdata.apar_link,
      aparrate_link:
        typeof data.aparrate_link == "undefined" || data.aparrate_link === ""
          ? 0
          : formdata.aparrate_link,
      great_schools_link:
        typeof data.great_schools_link == "undefined" ||
        data.great_schools_link === ""
          ? 0
          : formdata.great_schools_link,
      healthgrades_link:
        typeof data.healthgrades_link == "undefined" ||
        data.healthgrades_link === ""
          ? 0
          : formdata.healthgrades_link,
      mysite_link:
        typeof data.mysite_link == "undefined" || data.mysite_link === ""
          ? 0
          : formdata.mysite_link,
      thumbtack_link:
        typeof data.thumbtack_link == "undefined" || data.thumbtack_link === ""
          ? 0
          : formdata.thumbtack_link,
      amazon_link:
        typeof data.amazon_link == "undefined" || data.amazon_link === ""
          ? 0
          : formdata.amazon_link,
          solar_review:
          typeof data.solar_review == "undefined" || data.solar_review === ""
            ? 0
            : formdata.solar_review,
            home_advisor:
            typeof data.home_advisor == "undefined" || data.home_advisor === ""
              ? 0
              : formdata.home_advisor,
            energysage:
            typeof data.energysage == "undefined" || data.energysage === ""
              ? 0
              : formdata.energysage,           
      spanish: "",
      default: formdata.default,
      mess: formdata.mess,
      users_id: localStorage.getItem("id"),
    };
    //console.log("postData12");
    // console.log(postData12);
    axios
      .post(reviewapi1, postData12, axiosConfig)
      .then((result) => {
        // alert(result.data.message);
        window.location.reload();
        setShowLoading(false);
        // //console.log(result.data.location);
        // alert(result.data.location);
      })
      .catch((err) => {
        setShowLoading(false);
        //console.log("AXIOS ERROR: ", err);
      });
  };
  const handleSubmitSpanish = (e) => {
    e.preventDefault();
    setShowLoading(true);
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };

    let reviewapi = API + "api/send_review_link1";

    var postData1 = {
      client_id: localStorage.getItem("client_id"),
      cell_number: formdata.cell_number,
      inputFields: inputFields,
      loc_id: formdata.location_name,
      fb_link: formdata.fb_link,
      google_link: formdata.google_link,
      fb_link2: formdata.fb_link2,
      google_link2: formdata.google_link2,
      yelp_link: formdata.yelp_link,
      zillow_link: formdata.zillow_link,
      yp_link: formdata.yp_link,
      trip_link: formdata.trip_link,
      yell_link: formdata.yell_link,
      sf_link: formdata.sf_link,
      real_link: formdata.real_link,
      apar_link: formdata.apar_link,
      aparrate_link: formdata.aparrate_link,
      great_schools_link: formdata.great_schools_link,
      healthgrades_link: formdata.healthgrades_link,
      mysite_link: formdata.mysite_link,
      thumbtack_link: formdata.thumbtack_link,
      amazon_link: formdata.amazon_link,
      home_advisor:formdata.home_advisor,
      solar_review:formdata.solar_review,
      energysage: formdata.energysage,
      spanish: "spanish",
      mess: formdata.mess,
      users_id: localStorage.getItem("id"),
    };

    axios
      .post(reviewapi, postData1, axiosConfig)
      .then((result) => {
        alert(result.data.message);
        window.location.reload();
        setShowLoading(false);
        // //console.log(result.data.location);
        // alert(result.data.location);
      })
      .catch((err) => {
        setShowLoading(false);
        //console.log("AXIOS ERROR: ", err);
      });
  };

  const [formdata, setformdata] = useState({
    location_name: "",
    setname: "",
    fb_link: 1,
    google_link: 1,
    fb_link2: 1,
    google_link2: 1,
    yelp_link: 1,
    zillow_link: 1,
    yp_link: 1,
    trip_link: 1,
    yell_link: 1,
    sf_link: 1,
    real_link: 1,
    apar_link: 1,
    aparrate_link: 1,
    great_schools_link: 1,
    healthgrades_link: 1,
    mysite_link: 1,
    thumbtack_link: 1,
    amazon_link: 1,
    solar_review:1,
    energysage:1,
    home_advisor:1,
    default: 1,
    mess: "",
  });

  useEffect(() => {
    const apiUrlstyle = API + "api/location_by_client_id/";

    const fetchData = async () => {
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      };
      var postData = {
        client_id: localStorage.getItem("client_id"),
      };
      axios
        .post(apiUrlstyle, postData, axiosConfig)
        .then((result) => {
          var location = localStorage.getItem("locations").split(",");
          setlocations(location);

          setlocationData(result.data.location);

          // location &&
          //   location[0] &&
          //   setformdata({
          //     ...formdata,
          //     location_name: location[0],
          //   });
        })
        .catch((err) => {
          //console.log("AXIOS ERROR: ", err);
        });
    };

    fetchData();
  }, []);

  const back = () => {
    props.history.push({
      pathname: "/users",
    });
  };
  const validateForm = () => {
    let data = {
      // cell_number: formdata.cell_number,
      email: inputFields[0].email,
      name: inputFields[0].name,
      location_name: formdata.location_name,
    };

    let rules = {
      name: "required",
      location_name: "required",
    };

    let validation = new Validator(data, rules);

    if (validation.passes()) {
      return true;
    }
  };

  // const setDefaultLinks = () => {
  //   const locationapi = API + "api/client_getdata_byclientid";
  //   const locationbyapi = API + "api/location_by_client_id_locationid";

  //   let axiosConfig = {
  //     headers: {
  //       "Content-Type": "application/json;charset=UTF-8",
  //       Authorization: localStorage.getItem("token"),
  //     },
  //   };
  //   var postData = {
  //     id: localStorage.getItem("client_id"),
  //   };
  //   var postData1 = {
  //     id: formdata.location_name,
  //     client_id: localStorage.getItem("client_id"),
  //   };
  //   axios
  //     .post(locationapi, postData, axiosConfig)
  //     .then((result) => {
  //       setData1(result.data.client);
  //       //console.log(result.data.client);
  //       // alert(result.data.location);
  //     })
  //     .catch((err) => {
  //       //console.log("AXIOS ERROR: ", err);
  //     });

  //   axios
  //     .post(locationbyapi, postData1, axiosConfig)
  //     .then((result) => {
  //       setData(result.data.location);
  //     })
  //     .catch((err) => {
  //       //console.log("AXIOS ERROR: ", err);
  //     });
  // };

  // useEffect(() => {
  //   setDefaultLinks();
  // }, [locationlist, data]);

  const handleChange = (event) => {
    if (
      event.target.id === "fb_link" ||
      event.target.id === "google_link" ||
      event.target.id === "fb_link2" ||
      event.target.id === "google_link2" ||
      event.target.id === "yelp_link" ||
      event.target.id === "zillow_link" ||
      event.target.id === "yp_link" ||
      event.target.id === "trip_link" ||
      event.target.id === "yell_link" ||
      event.target.id === "sf_link" ||
      event.target.id === "real_link" ||
      event.target.id === "apar_link" ||
      event.target.id === "aparrate_link" ||
      event.target.id === "great_schools_link" ||
      event.target.id === "healthgrades_link" ||
      event.target.id === "mysite_link" ||
      event.target.id === "thumbtack_link" ||
      event.target.id === "amazon_link" ||
      event.target.id === "solar_review" ||
      event.target.id === "home_advisor" ||
      event.target.id === "energysage" ||
      event.target.id === "default"
    ) {
      if (event.target.checked === true) {
        setformdata({ ...formdata, [event.target.id]: 1 });
      } else {
        setformdata({ ...formdata, [event.target.id]: 0 });
      }
    } else {
      setformdata({ ...formdata, [event.target.id]: event.target.value });
      if (event.target.id === "location_name") {
        const locationapi = API + "api/client_getdata_byclientid";
        const locationbyapi = API + "api/location_by_client_id_locationid";

        let axiosConfig = {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: localStorage.getItem("token"),
          },
        };
        var postData = {
          id: localStorage.getItem("client_id"),
        };
        var postData1 = {
          id: event.target.value,
          client_id: localStorage.getItem("client_id"),
        };
        axios
          .post(locationapi, postData, axiosConfig)
          .then((result) => {
            setData1(result.data.client);
            //console.log(result.data.client);
            // alert(result.data.location);
          })
          .catch((err) => {
            //console.log("AXIOS ERROR: ", err);
          });

        axios
          .post(locationbyapi, postData1, axiosConfig)
          .then((result) => {
            setData(result.data.location);
          })
          .catch((err) => {
            //console.log("AXIOS ERROR: ", err);
          });
      }
    }
  };
  return (
    <LoadingOverlay
      active={showLoading}
      spinner={<RiseLoader sizeUnit={"px"} size={80} color={"#2a8dd1"} />}
    >
      <div>
        <Form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="review">
            <div className="row">
              <div className="col-md-12">
                <div className="card border-top-0 border-right-0 border-left-0 pb-4">
                  <div className="card-header bg-white ">
                    <div className="row">
                      <div className="col-md-12"></div>
                      <div className="col-md-12 text-right" />
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-3">
                        <div id="introduction" className="review-form">
                          <div className="row">
                            <div className="col-md-12 mb-10">
                              <h5 className="card-title mb-0">Send Review</h5>
                            </div>
                            <div className="col-md-12 mb-10">
                              <span>All * fields are mandatory </span>
                            </div>
                            <div className="col-md-12 ">
                              <label>Location Name*</label>
                              <select
                                className="form-control"
                                id="location_name"
                                name="location_name"
                                value={formdata.location_name}
                                onChange={handleChange}
                              >
                                <option value="">Please Select Location</option>
                                {locationlist.map((item, idx) =>
                                  checkLocation(item, idx)
                                )}
                              </select>

                              <input
                                type="hidden"
                                name="id"
                                id="id"
                                className="form-control"
                                placeholder="id"
                                value={formdata.id}
                              />
                            </div>
                          </div>
                          {inputFields.map((inputField, index) => (
                            <Fragment key={`${inputField}~${index}`}>
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="name"
                                  id="name"
                                  className="form-control"
                                  placeholder="Name"
                                  value={inputField.name}
                                  onChange={(event) =>
                                    handleInputChange(index, event)
                                  }
                                />
                              </div>
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="cell_number"
                                  id="cell_number"
                                  maxLength="10"
                                  className="form-control"
                                  placeholder="Cell Number"
                                  value={inputField.cell_number}
                                  onChange={(event) =>
                                    handleInputChange(index, event)
                                  }
                                />
                              </div>

                              <div className="form-group">
                                <input
                                  type="text"
                                  name="email"
                                  id="email"
                                  className="form-control"
                                  placeholder="Email"
                                  value={inputField.email}
                                  onChange={(event) =>
                                    handleInputChange(index, event)
                                  }
                                />
                              </div>

                              <div className="form-group text-right">
                                {inputFields.length > 1 && (
                                  <button
                                    className="btn btn-danger"
                                    type="button"
                                    onClick={() => handleRemoveFields(index)}
                                  >
                                    -
                                  </button>
                                )}
                                <button
                                  className="btn btn-info"
                                  type="button"
                                  onClick={() => handleAddFields()}
                                >
                                  +
                                </button>
                              </div>
                            </Fragment>
                          ))}

                          <div className="form-group ">
                            <label className="align-fix">
                              <input
                                type="checkbox"
                                name="default"
                                id="default"
                                className="form-check-input is-valid"
                                checked={formdata.default === 1}
                                value={formdata.default}
                                onChange={handleChange}
                              />{" "}
                              Default Message*
                            </label>
                          </div>
                          {formdata.default == 0 ? (
                            <div className="form-group">
                              <textarea
                                rows={4}
                                type="text"
                                name="mess"
                                id="mess"
                                className="form-control"
                                placeholder="Message"
                                onChange={handleChange}
                                maxLength={125}
                                onKeyDown={(e) =>
                                  setDefaultMessage(e.target.value)
                                }
                              />
                              <label>
                                Characters Left:{" "}
                                {125 -
                                  (defaultMessage.length
                                    ? defaultMessage.length
                                    : 0)}
                                /125
                              </label>
                            </div>
                          ) : (
                            ""
                          )}

                          <div className="row">
                            {typeof data.fb_link == "undefined" ||
                            data.fb_link === "" ? (
                              ""
                            ) : (
                              <div className="form-group col-md-12 mb-left">
                                <label>
                                  <input
                                    type="checkbox"
                                    name="fb_link"
                                    id="fb_link"
                                    className="form-check-input is-valid"
                                    checked={formdata.fb_link === 1}
                                    value={formdata.fb_link}
                                    onChange={handleChange}
                                  />
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  Facebook*
                                </label>
                              </div>
                            )}

                            {typeof data.fb_link2 == "undefined" ||
                            data.fb_link2 === "" ? (
                              ""
                            ) : (
                              <div className="form-group col-md-12 mb-left">
                                <label>
                                  <input
                                    type="checkbox"
                                    name="fb_link2"
                                    id="fb_link2"
                                    className="form-check-input is-valid"
                                    checked={formdata.fb_link2 === 1}
                                    value={formdata.fb_link2}
                                    onChange={handleChange}
                                  />
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  Facebook2*
                                </label>
                              </div>
                            )}
                            {typeof data.google_link == "undefined" ||
                            data.google_link === "" ? (
                              ""
                            ) : (
                              <div className="form-group col-md-12 mb-left">
                                <label>
                                  <input
                                    type="checkbox"
                                    name="google_link"
                                    id="google_link"
                                    className="form-check-input is-valid"
                                    checked={formdata.google_link === 1}
                                    value={formdata.google_link}
                                    onChange={handleChange}
                                  />
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  Google*
                                </label>
                              </div>
                            )}

{typeof data.home_advisor == "undefined" ||
                            data.home_advisor === "" ? (
                              ""
                            ) : (
                              <div className="form-group col-md-12 mb-left">
                                <label>
                                  <input
                                    type="checkbox"
                                    name="home_advisor"
                                    id="home_advisor"
                                    className="form-check-input is-valid"
                                    checked={formdata.home_advisor === 1}
                                    value={formdata.home_advisor}
                                    onChange={handleChange}
                                  />
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  Home Advisor*
                                </label>
                              </div>
                            )}


{typeof data.energysage == "undefined" ||
                            data.energysage === "" || data.energysage === null ? (
                              ""
                            ) : (
                              <div className="form-group col-md-12 mb-left">
                                <label>
                                  <input
                                    type="checkbox"
                                    name="energysage"
                                    id="energysage"
                                    className="form-check-input is-valid"
                                    checked={formdata.energysage === 1}
                                    value={formdata.energysage}
                                    onChange={handleChange}
                                  />
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  Energysage*
                                </label>
                              </div>
                            )}


{typeof data.solar_review == "undefined" ||
                            data.solar_review === "" ? (
                              ""
                            ) : (
                              <div className="form-group col-md-12 mb-left">
                                <label>
                                  <input
                                    type="checkbox"
                                    name="solar_review"
                                    id="solar_review"
                                    className="form-check-input is-valid"
                                    checked={formdata.solar_review === 1}
                                    value={formdata.solar_review}
                                    onChange={handleChange}
                                  />
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  Solar Review*
                                </label>
                              </div>
                            )}



                            {typeof data.google_link2 == "undefined" ||
                            data.google_link2 === "" ? (
                              ""
                            ) : (
                              <div className="form-group col-md-12 mb-left">
                                <label>
                                  <input
                                    type="checkbox"
                                    name="google_link2"
                                    id="google_link2"
                                    className="form-check-input is-valid"
                                    checked={formdata.google_link2 === 1}
                                    value={formdata.google_link2}
                                    onChange={handleChange}
                                  />
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  Google2*
                                </label>
                              </div>
                            )}
                          </div>

                          <div className="row">
                            {typeof data.yelp_link == "undefined" ||
                            data.yelp_link === "" ? (
                              ""
                            ) : (
                              <div className="form-group col-md-12 mb-left">
                                <label>
                                  <input
                                    type="checkbox"
                                    name="yelp_link"
                                    id="yelp_link"
                                    className="form-check-input is-valid"
                                    checked={formdata.yelp_link === 1}
                                    value={formdata.yelp_link}
                                    onChange={handleChange}
                                  />
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  Yelp*
                                </label>
                              </div>
                            )}

                            {typeof data.yp_link == "undefined" ||
                            data.yp_link === "" ? (
                              ""
                            ) : (
                              <div className="form-group col-md-12 mb-left">
                                <label>
                                  <input
                                    type="checkbox"
                                    name="yp_link"
                                    id="yp_link"
                                    className="form-check-input is-valid"
                                    checked={formdata.yp_link === 1}
                                    value={formdata.yp_link}
                                    onChange={handleChange}
                                  />
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  Yp Link*
                                </label>
                              </div>
                            )}
                            {typeof data.trip_link == "undefined" ||
                            data.trip_link === "" ? (
                              ""
                            ) : (
                              <div className="form-group col-md-12 mb-left">
                                <label>
                                  <input
                                    type="checkbox"
                                    name="trip_link"
                                    id="trip_link"
                                    className="form-check-input is-valid"
                                    checked={formdata.trip_link === 1}
                                    value={formdata.trip_link}
                                    onChange={handleChange}
                                  />
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  Trip Link*
                                </label>
                              </div>
                            )}
                            {typeof data.yell_link == "undefined" ||
                            data.yell_link === "" ? (
                              ""
                            ) : (
                              <div className="form-group col-md-12 mb-left">
                                <label>
                                  <input
                                    type="checkbox"
                                    name="yell_link"
                                    id="yell_link"
                                    className="form-check-input is-valid"
                                    checked={formdata.yell_link === 1}
                                    value={formdata.yell_link}
                                    onChange={handleChange}
                                  />
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  Yell Link*
                                </label>
                              </div>
                            )}
                          </div>
                          <div className="row">
                            {typeof data.sf_link == "undefined" ||
                            data.sf_link === "" ? (
                              ""
                            ) : (
                              <div className="form-group col-md-12 mb-left">
                                <label>
                                  <input
                                    type="checkbox"
                                    name="sf_link"
                                    id="sf_link"
                                    className="form-check-input is-valid"
                                    checked={formdata.sf_link === 1}
                                    value={formdata.sf_link}
                                    onChange={handleChange}
                                  />
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  Sf Link*
                                </label>
                              </div>
                            )}

                            {typeof data.real_link == "undefined" ||
                            data.real_link === "" ? (
                              ""
                            ) : (
                              <div className="form-group col-md-12 mb-left">
                                <label>
                                  <input
                                    type="checkbox"
                                    name="real_link"
                                    id="real_link"
                                    className="form-check-input is-valid"
                                    checked={formdata.real_link === 1}
                                    value={formdata.real_link}
                                    onChange={handleChange}
                                  />
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  Real Link*
                                </label>
                              </div>
                            )}
                            {typeof data.apar_link == "undefined" ||
                            data.apar_link === "" ? (
                              ""
                            ) : (
                              <div className="form-group col-md-12 mb-left">
                                <label>
                                  <input
                                    type="checkbox"
                                    name="apar_link"
                                    id="apar_link"
                                    className="form-check-input is-valid"
                                    checked={formdata.apar_link === 1}
                                    value={formdata.apar_link}
                                    onChange={handleChange}
                                  />
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  Apar Link*
                                </label>
                              </div>
                            )}
                            {typeof data.aparrate_link == "undefined" ||
                            data.aparrate_link === "" ? (
                              ""
                            ) : (
                              <div className="form-group col-md-12 mb-left">
                                <label>
                                  <input
                                    type="checkbox"
                                    name="aparrate_link"
                                    id="aparrate_link"
                                    className="form-check-input is-valid"
                                    checked={formdata.aparrate_link === 1}
                                    value={formdata.aparrate_link}
                                    onChange={handleChange}
                                  />
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  Aparrate Link*
                                </label>
                              </div>
                            )}
                          </div>
                          <div className="row">
                            {typeof data.great_schools_link == "undefined" ||
                            data.great_schools_link === "" ? (
                              ""
                            ) : (
                              <div className="form-group col-md-12 mb-left">
                                <label>
                                  <input
                                    type="checkbox"
                                    name="great_schools_link"
                                    id="great_schools_link"
                                    className="form-check-input is-valid"
                                    checked={formdata.great_schools_link === 1}
                                    value={formdata.great_schools_link}
                                    onChange={handleChange}
                                  />
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  Great Schools Link*
                                </label>
                              </div>
                            )}
                            {typeof data.healthgrades_link == "undefined" ||
                            data.healthgrades_link === "" ? (
                              ""
                            ) : (
                              <div className="form-group col-md-12 mb-left">
                                <label>
                                  <input
                                    type="checkbox"
                                    name="healthgrades_link"
                                    id="healthgrades_link"
                                    className="form-check-input is-valid"
                                    checked={formdata.healthgrades_link === 1}
                                    value={formdata.healthgrades_link}
                                    onChange={handleChange}
                                  />
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  Healthgrades Link*
                                </label>
                              </div>
                            )}
                            {typeof data.mysite_link == "undefined" ||
                            data.mysite_link === "" ? (
                              ""
                            ) : (
                              <div className="form-group col-md-12 mb-left">
                                <label>
                                  <input
                                    type="checkbox"
                                    name="mysite_link"
                                    id="mysite_link"
                                    className="form-check-input is-valid"
                                    checked={formdata.mysite_link === 1}
                                    value={formdata.mysite_link}
                                    onChange={handleChange}
                                  />
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  Mysite Link*
                                </label>
                              </div>
                            )}
                            {typeof data.thumbtack_link == "undefined" ||
                            data.thumbtack_link === "" ? (
                              ""
                            ) : (
                              <div className="form-group col-md-12 mb-left">
                                <label>
                                  <input
                                    type="checkbox"
                                    name="thumbtack_link"
                                    id="thumbtack_link"
                                    className="form-check-input is-valid"
                                    checked={formdata.thumbtack_link === 1}
                                    value={formdata.thumbtack_link}
                                    onChange={handleChange}
                                  />
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  Thumbtack Link*
                                </label>
                              </div>
                            )}
                          </div>
                          <div className="row">
                            {typeof data.amazon_link == "undefined" ||
                            data.amazon_link === "" ? (
                              ""
                            ) : (
                              <div className="form-group col-md-12 mb-left">
                                <label>
                                  <input
                                    type="checkbox"
                                    name="amazon_link"
                                    id="amazon_link"
                                    className="form-check-input is-valid"
                                    checked={formdata.amazon_link === 1}
                                    value={formdata.amazon_link}
                                    onChange={handleChange}
                                  />
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  Amazon Link *
                                </label>
                              </div>
                            )}
                            {typeof data.zillow_link == "undefined" ||
                            data.zillow_link === "" ? (
                              ""
                            ) : (
                              <div className="form-group col-md-12 mb-left">
                                <label>
                                  <input
                                    type="checkbox"
                                    name="zillow_link"
                                    id="zillow_link"
                                    className="form-check-input is-valid"
                                    checked={formdata.zillow_link === 1}
                                    value={formdata.zillow_link}
                                    onChange={handleChange}
                                  />
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  zillow Link *
                                </label>
                              </div>
                            )}

                            {typeof data.zillow_link == "undefined" &&
                            data.zillow_link === "" &&
                            typeof data.amazon_link == "undefined" &&
                            typeof data.thumbtack_link == "undefined" &&
                            typeof data.mysite_link == "undefined" &&
                            typeof data.healthgrades_link == "undefined" &&
                            typeof data.great_schools_link == "undefined" &&
                            typeof data.aparrate_link == "undefined" &&
                            typeof data.apar_link == "undefined" &&
                            typeof data.real_link == "undefined" &&
                            typeof data.sf_link == "undefined" &&
                            typeof data.yell_link == "undefined" &&
                            typeof data.trip_link == "undefined" &&
                            typeof data.yp_link == "undefined" &&
                            typeof data.yelp_link == "undefined" &&
                            typeof data.google_link2 == "undefined" &&
                            typeof data.fb_link2 == "undefined" &&
                            typeof data.google_link == "undefined" &&
                            typeof data.home_advisor == "undefined" &&
                            typeof data.solar_review == "undefined" &&
                            typeof data.energysage == "undefined" &&
                            typeof data.fb_link == "undefined"
                              ? ""
                              : ""}
                            <div className="form-group col-md-12 ">
                              {typeof data1.spanish == "undefined" ||
                              data1.spanish !== 1 ? (
                                <input
                                  type="button"
                                  onClick={handleSubmit}
                                  value="Send "
                                  className="btn btn-success save-btn font-weight-bold"
                                  disabled={!validateForm()}
                                />
                              ) : (
                                <>
                                  {/* <input
                                    type="button"
                                    onClick={handleSubmitSpanish}
                                    value="Send Spanish"
                                    className="btn btn-success save-btn font-weight-bold"
                                    disabled={!validateForm()}
                                  ></input> */}

                                  <input
                                    type="button"
                                    onClick={handleSubmit}
                                    value="Send "
                                    className="btn btn-success save-btn font-weight-bold"
                                    disabled={!validateForm()}
                                  ></input>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <CompanyLinks></CompanyLinks>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </LoadingOverlay>
  );
};
export default Locationform1;