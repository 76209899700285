import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';

import axios from 'axios';
import { API } from '../../../../const';
import { Image } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Validator from 'validatorjs';
import './form.css';
import { ChromePicker } from 'react-color';
toast.configure();

const Clientform = (props) => {
	const [ style, setstyle ] = useState('hidden');
	const [ formdata, setformdata ] = useState({
		company_name: '',
		background: '#0061FF',
		client_name: '',
		client_email: '',
		mobile: '',
		color_code: '#ffffff',
		client_password: '',
		msg_limit: '',
		r_msg: '',
		userfile: '',
		real_estate: '',
		bulk_import: '',
		sitelink: '',
		localtionlevel: '',
		spanish: '',
		corp_code: '',
		corp_login: '',
		corp_pass: '',
		starpage: '',
		reviewstar: '',
		cstatus: '',
		logo1: '',
		client_id: props.data.id
	});

	const handleChangeComplete = (color, event) => {
		setformdata({ ...formdata, ['background']: color.hex });
	};
	const validateForm = () => {
		let data = {
			company_name: formdata.company_name,
			client_name: formdata.client_name,

			client_email: formdata.client_email,

			userfile: formdata.logo1
		};

		let rules = {
			company_name: 'required',
			client_email: 'required|email',
			client_name: 'required'
		};

		let validation = new Validator(data, rules);

		if (validation.passes()) {
			return true;
		}
	};

	useEffect(
		() => {
            setformdata(props.data);
           // console.log(props.data)
		},
		[ props.data ]
	);

	useEffect(
		() => {
			if (formdata.sitelink === 1) {
				setstyle('show');
			} else {
				setstyle('hidden');
			}
		},
		[ formdata.sitelink ]
	);

	const back = () => {
		props.history.push({
			pathname: '/clients'
		});
	};
	const handleChange = (event) => {
		setformdata({ ...formdata, [event.target.id]: event.target.value });
		if (event.target.id === 'sitelink') {
			if (event.target.checked === true) {
				setstyle('show');
				setformdata({ ...formdata, ['sitelink']: 1 });
			} else {
				setstyle('hidden');
				setformdata({ ...formdata, ['sitelink']: 0 });
			}
		}

		if (event.target.id === 'real_estate') {
			if (event.target.checked === true) {
				setformdata({ ...formdata, ['real_estate']: 1 });
			} else {
				setformdata({ ...formdata, ['real_estate']: 0 });
			}
		}

		if (event.target.id === 'real_estate') {
			if (event.target.checked === true) {
				setformdata({ ...formdata, ['real_estate']: 1 });
			} else {
				setformdata({ ...formdata, ['real_estate']: 0 });
			}
		}

		if (event.target.id === 'spanish') {
			if (event.target.checked === true) {
				setformdata({ ...formdata, ['spanish']: 1 });
			} else {
				setformdata({ ...formdata, ['spanish']: 0 });
			}
		}

		if (event.target.id === 'localtionlevel') {
			if (event.target.checked === true) {
				setformdata({ ...formdata, ['localtionlevel']: 1 });
			} else {
				setformdata({ ...formdata, ['localtionlevel']: 0 });
			}
		}

		if (event.target.id === 'starpage') {
			if (event.target.checked === true) {
				setformdata({ ...formdata, ['starpage']: 1 });
			} else {
				setformdata({ ...formdata, ['starpage']: 0 });
			}
		}
	};

	const onDroplogo = (acceptedFiles) => {
		let file = acceptedFiles[0];

		let reader = new FileReader();

		reader.readAsDataURL(file);

		reader.onload = function() {
			//console.log(reader.result);
			setformdata({ ...formdata, ['logo1']: reader.result });
		};

		reader.onerror = function() {
		//	console.log(reader.error);
		};
	};

	const formSubmit = async () => {
		var postData = {
			id: props.data.id,
			company_name: formdata.company_name,
			client_url: formdata.client_name,
			client_name: formdata.client_name,
			email: formdata.client_email,
			mobile: formdata.mobile,
			color_code: formdata.background,
			password: formdata.client_password,
			msg_limit: formdata.msg_limit,
			remaining_msg: formdata.r_msg,
			userfile: formdata.logo1,
			real_estate: formdata.real_estate,
			bulk_import: formdata.bulk_import,
			sitelink: formdata.sitelink,
			localtionlevel: formdata.localtionlevel,
			spanish: formdata.spanish,
			corp_code: formdata.corp_code,
			corp_login: formdata.corp_login,
			corp_pass: formdata.corp_pass,
			starpage: formdata.starpage,
			reviewstar: formdata.reviewstar,
			mystatus: formdata.cstatus,
			client_id: props.data.id
		};

		let axiosConfig = {
			headers: {
				'Content-Type': 'application/json;charset=UTF-8',
				Authorization: localStorage.getItem('token')
			}
		};
		const apiedit = API + 'api/client_update';
		const apiadd = API + 'api/client_add';
		if (props.data.id) {
			axios
				.post(apiedit, postData, axiosConfig)
				.then((res) => {
					toast(res.data.message);

					back();
				})
				.catch((err) => {
				//	console.log('AXIOS ERROR: ', err);
				});
		} else {
			axios
				.post(apiadd, postData, axiosConfig)
				.then((res) => {
					toast(res.data.message);

					back();
				})
				.catch((err) => {
				//	console.log('AXIOS ERROR: ', err);
				});
		}
	};

	return (
		<div>
			<Form encType="multipart/form-data">
				<div className="review">
					<div className="row">
						<div className="col-md-12">
							<div className="card border-top-0 border-right-0 border-left-0 pb-4">
								<div className="card-header bg-white ">
									<div className="row">
										<div className="col-md-9">
											<h5 className="card-title mb-0 pt-2">Client</h5>
										</div>
										<div className="col-md-3 text-right">
											<input
												type="button"
												onClick={() => back()}
												value="Back"
												className="margin btn btn-primary save-btn font-weight-bold"
											/>
											<input
												type="button"
												disabled={!validateForm()}
												onClick={() => formSubmit()}
												value="Save"
												className="btn btn-success save-btn font-weight-bold"
											/>
										</div>
									</div>
								</div>
								<div className="card-body">
									<div id="introduction">
										<div className="row">
											<div className="form-group col-md-4 ">
												<input
													type="text"
													name="company_name"
													id="company_name"
													className="form-control"
													placeholder="Company Name "
													value={formdata.company_name}
													onChange={handleChange}
												/>
												<input
													type="hidden"
													name="id"
													id="id"
													className="form-control"
													placeholder="id"
													value={formdata.id}
												/>
												<label>
													Enter Company Name{' '}
													<span className="text-danger">(this field is required)</span>
												</label>
											</div>
											<div className="form-group col-md-4 ">
												<input
													type="text"
													name="client_name"
													id="client_name"
													className="form-control"
													placeholder="Client Name"
													value={formdata.client_name}
													onChange={handleChange}
												/>
												<label>
													Enter Client Name<span className="text-danger">(this field is required)</span>{' '}
												</label>
											</div>
											<div className="form-group col-md-4 ">
												<input
													type="email"
													className="form-control"
													id="client_email"
													name="client_email"
													placeholder="Client Email"
													value={formdata.client_email}
													onChange={handleChange}
												/>
												<label>
													Enter Email{' '}
													<span className="text-danger">(this field is required)</span>
												</label>
											</div>
											<div className="form-group col-md-4 ">
												<input
													type="text"
													name="mobile"
													id="mobile"
													className="form-control"
													placeholder="Mobile No"
													value={formdata.mobile}
													onChange={handleChange}
												/>
												<label>Mobile No</label>
											</div>

											<div className="form-group col-md-4 ">
												<input
													type="number"
													name="msg_limit"
													id="msg_limit"
													className="form-control"
													placeholder="Message Limit"
													value={formdata.msg_limit}
													onChange={handleChange}
												/>
												<label>Message Limit</label>
											</div>
											<div className="form-group col-md-4 ">
												<input
													type="password"
													name="client_password"
													id="client_password"
													className="form-control"
													placeholder="Client Password"
													value={formdata.client_password}
													onChange={handleChange}
												/>
												<label>Client Password</label>
											</div>

											<div className="form-group col-md-4 ">
												<input
													type="number"
													name="r_msg"
													id="r_msg"
													className="form-control"
													placeholder="Remaining Text Message :"
													value={formdata.r_msg}
													onChange={handleChange}
												/>
												<label>Remaining Text Message</label>
											</div>
											<div className="form-group col-md-2">
												<Image
													src={formdata.logo1 ? formdata.logo1 :API + 'api/' + formdata.userfile}
													fluid
													width="100"
													className={formdata.userfile || formdata.logo1 ? 'image-padding' : 'hidden'}
												/>
												<Dropzone onDrop={(acceptedFiles) => onDroplogo(acceptedFiles)}>
													{({ getRootProps, getInputProps }) => (
														<section>
															<div {...getRootProps()}>
																<input
																	{...getInputProps()}
																	accept=".jpeg,.png,.jpg,.gif"
																/>
																<p className="btn btn-secondary">Select File</p>
															</div>
														</section>
													)}
												</Dropzone>
												<label>
													Logo
													<small>
														<span className="text-danger">
															Prefered Logo Size is (100 X 80) (this field is required)
														</span>
													</small>
												</label>
											</div>

											<div className="form-group col-md-2">
												<input
													type="checkbox"
													name="real_estate"
													id="real_estate"
													className="form-check-input is-valid"
													value={formdata.real_estate}
													onChange={handleChange}
												/>
												<label className="check-api-checkbox">Real Estate Client</label>
											</div>
											<div className="form-group col-md-2 ">
												<input
													type="checkbox"
													name="spanish"
													id="spanish"
													className="form-check-input is-valid"
													checked={formdata.spanish}
													onChange={handleChange}
												/>
												<label className="check-api-checkbox">Spanish Language Option</label>
											</div>
											<div className="form-group col-md-2 ">
												<input
													type="checkbox"
													name="localtionlevel"
													id="localtionlevel"
													className="form-check-input is-valid"
													checked={formdata.localtionlevel}
													onChange={handleChange}
												/>
												<label className="check-api-checkbox">
													is Sitelink location level:{' '}
												</label>
											</div>

											<div className="form-group col-md-6 margin-checkbox ">
												<input
													type="checkbox"
													name="sitelink"
													id="sitelink"
													className="form-check-input is-valid"
													checked={formdata.sitelink === 1}
													onChange={handleChange}
												/>
												<label className="check-api-checkbox">Sitelink </label>
											</div>
                                            <div className="form-group col-md-4 margin-checkbox">
												<input
													type="checkbox"
													name="starpage"
													id="starpage"
													className="form-check-input is-valid "
													checked={formdata.starpage === 1}
													onChange={handleChange}
												/>
												<label className="check-api-checkbox  ">Review Page star </label>
											</div>


											<div className={style + ' form-group col-md-4'}>
												<input
													type="text"
													name="corp_code"
													id="corp_code"
													className="form-control"
													placeholder="Corp Code"
													value={formdata.corp_code}
													onChange={handleChange}
												/>
												<label>Corp No</label>
											</div>
											<div className={style + ' form-group col-md-4'}>
												<input
													type="text"
													name="corp_login"
													id="corp_login"
													className="form-control"
													placeholder="Corp Login"
													value={formdata.corp_login}
													onChange={handleChange}
												/>
												<label>
													For Corp login,use client User name then add :::
													{formdata.corp_login} after the user name with no spaces.
												</label>
											</div>
											<div className={style + ' form-group col-md-4'}>
												<input
													type="text"
													name="corp_pass"
													id="corp_pass"
													className="form-control"
													placeholder="Corp Password"
													value={formdata.corp_pass}
													onChange={handleChange}
												/>
												<label>Corp Password: </label>
											</div>

											<div className="form-group col-md-4">
												<select
													className="form-control"
													id="reviewstar"
													name="reviewstar"
													defaultValue={formdata.reviewstar}
													onChange={handleChange}
												>
													<option value="">Please Select Star</option>
													<option selected={formdata.reviewstar === 1} value="1">
														1 Star
													</option>
													<option selected={formdata.reviewstar === 2} value="2">
														2 Star
													</option>
													<option selected={formdata.reviewstar === 3} value="3">
														3 Star
													</option>
													<option selected={formdata.reviewstar === 4} value="4">
														4 Star
													</option>
													<option selected={formdata.reviewstar === 5} value="5">
														5 Star
													</option>
												</select>
												<label>(if yes) Show Review Links Page after </label>
											</div>

											<div className="form-group col-md-4">
												<select
													className="form-control"
													id="cstatus"
													name="cstatus"
													defaultValue={formdata.status}
													onChange={handleChange}
												>
													<option>Please Select Status</option>
													<option selected={formdata.cstatus == 'Enabled'} value="Enabled">
														Enabled
													</option>
													<option selected={formdata.cstatus == 'Disabled'} value="Disabled">
														Disabled
													</option>
												</select>
												<label>Status</label>
											</div>
											<div className="form-group col-md-4 ">
												<ChromePicker
													color={formdata.background}
													onChangeComplete={handleChangeComplete}
												/>
												<label>Color Code</label>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Form>
		</div>
	);
};
export default Clientform;
