import React, { useState, useEffect } from "react";
import axios from "axios";
import { API, BASE_URL } from "../../../../const";
import "react-toastify/dist/ReactToastify.css";
import LoadingOverlay from "react-loading-overlay";
import { RiseLoader } from "react-spinners";
import { Image } from "react-bootstrap";
import Rating from "react-rating";

const Confirm = (props) => {
  //console.log(props.match);
  localStorage.setItem("linkUrl", props.match.url);
  let linkUrl = localStorage.getItem("linkUrl");
  //console.log("link");
  //console.log(linkUrl);
  const [client, setClient] = useState([]);
  const [linkdata, setLinkData] = useState([]);

  const [formdata, setformdata] = useState({
    desc: "",
    star: 1,
    client_id: "",
    cust_id: "",
    location_id: "",
    user_id: "",
    location_name: "",
    location_logo:"",
    client_email: "",
  });
  const [data, setData] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const [showHideDiv1, setshowHideDiv1] = useState(true);
  const [showHideDiv2, setshowHideDiv2] = useState(false);
  const [showHideDiv3, setshowHideDiv3] = useState(false);

  const getshorturl = API + "api/getshorturl/" + props.match.params.id;
  useEffect(() => {
    const fetchData = async () => {
      var postData = {
        survey_id: props.match.params.id,
      };
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      };
      axios.post(getshorturl, postData, axiosConfig).then((result) => {
        setData(result.data.data);
        setShowLoading(false);
      });
    };

    fetchData();
  }, []);

  useEffect(() => {
    const apiUrlgetdata = API + "api/getreviewdata/"; //+ props.match.params.id +  props.location.search;
    var postData = {
      link: BASE_URL + linkUrl,
    };
    const getinvitedata = async () => {
      const result = await axios.post(apiUrlgetdata, postData);
      let linkData = result.data.data;
      setClient(result.data.data.cp_client);
      console.log(result.data.data.cp_client);
      setLinkData(linkData);
      setformdata({
        client_id: linkData.client_id,
        cust_id: linkData.cust_id,
        user_id: linkData.user_id,
        location_id: linkData.location_id,
        email: linkData.email,
        username: linkData.username,
        setfeed: linkData.cp_location.setfeed,
        location_name: linkData.cp_location.l_name,
        location_logo: linkData.cp_location.logo,
        client_email: linkData.cp_client.cp_login.email,
      });
    };
    getinvitedata();
  }, []);

  const showinvite = (value , ratings) => {
    setShowLoading(true);
    if(!ratings){
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      };

      let gate_results = API + "api/user/gate_results";

      var postData = {
        id: linkdata.id,
        gate_results: "Yes",
      };

      axios
        .post(gate_results, postData, axiosConfig)
        .then((result) => {
          setShowLoading(false);
        })
        .catch((err) => {
          //console.log("AXIOS ERROR: ", err);
        });
    }

    setTimeout(() => {
      props.history.push({
        pathname: data.url,
      });
      setShowLoading(false);
    }, 2000);
  };

  const showNo = (value ,ratings) => {
    setShowLoading(true);

    if(!ratings){
    let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      };

      let gate_results = API + "api/user/gate_results";

      var postData = {
        id: linkdata.id,
        gate_results: "No",
      };

      axios
        .post(gate_results, postData, axiosConfig)
        .then((result) => {
          setShowLoading(false);
        })
        .catch((err) => {
          //console.log("AXIOS ERROR: ", err);
        });
      }
    setshowHideDiv1(false);
    setshowHideDiv2(true);
    setShowLoading(false);
  };

  const handleChange = (event) => {
    setformdata({ ...formdata, [event.target.id]: event.target.value });
  };

  const handleRatings = (ratings) => {
    setShowLoading(true);
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };

    let ratingsapi = API + "api/user/ratings";

    var postData = {
      id: linkdata.id,
      star: ratings,
    };

    axios
      .post(ratingsapi, postData, axiosConfig)
      .then((result) => {
        setShowLoading(false);
      })
      .catch((err) => {
        //console.log("AXIOS ERROR: ", err);
      });
      
console.log('client.reviewstar')
console.log(client.reviewstar)
    if (ratings >= client?.reviewstar) {
      showinvite(props.match.params.id , ratings);
    } else {
      showNo(props.match.params.id , ratings);
    }
  };

  const handleSubmit = (e) => {
    setShowLoading(true);
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };

    let feedbackapi = API + "api/user/feedback";

    var postData = {
      desc: formdata.desc,
      star: 1,
      client_id: formdata.client_id,
      cust_id: formdata.cust_id,
      user_id: formdata.user_id,
      location_id: formdata.location_id,
      email: formdata.email,
      username: formdata.username,
      setfeed: formdata.setfeed ? formdata.setfeed : 0,
      location_name: formdata.location_name,
      client_email: formdata.client_email,
    };

    axios
      .post(feedbackapi, postData, axiosConfig)
      .then((result) => {
        alert(result.data.message);
        setshowHideDiv1(false);
        setshowHideDiv2(false);
        setshowHideDiv3(true);
        setShowLoading(false);
      })
      .catch((err) => {
        setshowHideDiv1(false);
        setshowHideDiv2(true);
        setshowHideDiv3(false);
        setShowLoading(false);
        //console.log("AXIOS ERROR: ", err);
      });
  };

  return (
    <LoadingOverlay
      active={showLoading}
      spinner={<RiseLoader sizeUnit={"px"} size={80} color={"#2a8dd1"} />}
    >
      <section className="text-center box__middle">
        <section className="tp_small-cont" id="land-page">
            {formdata.location_logo ? (
            <div className="tp_header-logo">
              {" "}
              <Image
                src={client && API + "api/" + formdata.location_logo}
                className="img-fluid"
                alt={client.company_name}
              />{" "}
            </div>
          ) : (
            <div className="tp_header-logo">
              {" "}
              <Image
                src={client && API + "api/" + client.client_logo}
                className="img-fluid"
                alt={client.company_name}
              />{" "}
            </div>
          )}

          <div className="tp_inr-cont" id="tp_refer">
            {showHideDiv1 && (
              <div className="tp_form" id="deault_div">
                <h2 className="text-center">
                  {client.starpage === 1 ? "How would you Rate your experience with" :  "Would you recommend"} {formdata.location_name}
                  {/* Hi{formdata.name}, Would you recommend Pune */}
                </h2>
                {client.starpage === 1 ? (
                  <h2 className="text-center">
                    <Rating
                      emptySymbol="far fa-star fa-2x"
                      fullSymbol="fa fa-star fa-2x"
                     // initialRating={client.reviewstar ? client.reviewstar : 1}
                      onChange={(rate) => handleRatings(rate)}
                    />
                  </h2>
                ) : (
                  <form>
                    <div className=" text-center btn-wrap">
                      <input
                        type="button"
                        value="Yes"
                        onClick={() => showinvite(props.match.params.id)}
                        className="form-control btn btn-lg btn-block btn-success save-btn"
                      />
                    </div>
                    <br />
                    <div className=" text-center btn-wrap">
                      <input
                        type="button"
                        value="No"
                        onClick={() => showNo(props.match.params.id)}
                        className="form-control btn-lg btn-block btn btn-danger save-btn"
                      />
                    </div>
                  </form>
                )}
              </div>
            )}
            {showHideDiv2 && (
              <div
                className="text-center btn-wrap box-middle__no"
                id="no_option_div"
              >
                <h5>
                  We apologize that you were not completely satisfied with your
                  experience.
                  <br />
                  <span>How can we do better?</span>
                </h5>
                <div className="box-middle__textarea">
                  <textarea
                    className="form-control"
                    maxLength=""
                    rows="6"
                    id="desc"
                    name="desc"
                    required
                    value={formdata.desc}
                    onChange={handleChange}
                  ></textarea>
                </div>
                <br />
                <input
                  type="button"
                  value="Send Feedback"
                  onClick={() => handleSubmit()}
                  className="btn btn-success btn-lg btn-block"
                />
              </div>
            )}

            {showHideDiv3 && (
              <div className="text-center btn-wrap" id="thanks_div">
                <h5>
                  Thank you for your feedback
                  <br />
                  We look forward to serving you in the future.
                </h5>
              </div>
            )}
          </div>
        </section>
      </section>
    </LoadingOverlay>
  );
};

export default Confirm;
