import React, { useState, useEffect } from "react";
import Notice from "../../../partials/content/Notice";
import CodeExample from "../../../partials/content/CodeExample";
import { Table , Button } from "react-bootstrap";
import axios from "axios";
import {API} from "../../../../const"
import Clientform from "./../Clients/form"


const AddClient = props => {
    const [data, setData] = useState([]);

    const [formdata, setformdata] = useState({
        company_name: "",
        client_name: "",
        client_email: "",
        background: '#0061FF',
        mobile: "",
        color_code: "#ffffff",
        client_password: "",
        msg_limit: "",
        r_msg: "",
        userfile: "",
        real_estate: "",
        bulk_import: "",
        sitelink: "",
        localtionlevel: "",
        spanish: "",
        corp_code: "",
        corp_login: "",
        corp_pass: "",
        starpage: "",
        reviewstar: "",
        cstatus: ""
      });


    return (
        <>
            {/* <Notice icon="flaticon-warning kt-font-primary">
                For more info please check the components's official{" "}
            </Notice> */}

            <div className="row">
                <div className="col-md-12">
                <div className="kt-space-40" />
                    <Clientform  data={formdata} history={props.history}/>
                    <div className="kt-space-20" />
                </div>
            </div>
        </>
    );
};
export default AddClient;
