import React, { useState, useEffect } from "react";
import CodeExample from "../../../partials/content/CodeExample";
import { Table, Button } from "react-bootstrap";
import axios from "axios";
import { API } from "../../../../const";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import localeInfo from "rc-pagination/lib/locale/en_US";
import "rc-pagination/assets/index.css";
import LoadingOverlay from "react-loading-overlay";
import { RiseLoader } from "react-spinners";
const ViewLocationlistClient = props => {
   
  const [data, setData] = useState([]);
  const [showLoading, setShowLoading] = useState(true);

  const [currdata, setcurrData] = useState([]);
  const [count, setcount] = useState(0);
  const [current, setcurrent] = useState(1);
  const [formlist, setformData] = useState([]);

  //console.log("propsmatchid");
  //console.log(props.match.params.id);

  const apiUrlall = API + "api/location_by_client_id";
  useEffect(() => {
    const fetchData = async () => {
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token")
        }
      };
      var postData = {
        client_id: props.match.params.id
      };
      axios
        .post(apiUrlall, postData, axiosConfig)
        .then(res => {
            //console.log(res.data);
            setcurrData(res.data.location);
            let count = Object.keys(res.data.location).length;
            setcount(count);
            setShowLoading(false);
        })
        .catch(err => {
            setShowLoading(false);
          //console.log("AXIOS ERROR: ", err);
        });
    };
    fetchData();
  }, []);

  const handlePageClick = data => {
    let offsetS = Math.ceil(data * 20 - 20);
    loadData(offsetS, data);
  };

  const loadData = async (offsetS , cu) => {
    setformData({offset : offsetS});
    setformData({...formlist , offset : offsetS });
    if(!cu){
      setcurrent(1);
    }else{
      setcurrent(cu);
    }  
  };
  
  useEffect(() => {
    loadData();
  }, [data]);

  const handleChange = event => {
    setformData({ ...formlist, [event.target.name]: event.target.value });
  };

  const getemaildata = async () => {
    var postData1 = {
        client_name: formlist.client_name,
        company_name: formlist.company_name
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token")
      }
    };
//console.log(postData1);
    axios
      .post(apiUrlall, postData1, axiosConfig)
      .then(res => {
        //console.log(res.data.data);
        setcurrData(res.data.data);
        let count = Object.keys(res.data.data).length;
        setcount(count);
        setShowLoading(false);
      })
      .catch(err => {
        setShowLoading(false);
        //console.log("AXIOS ERROR: ", err);
      });
}

  const viewEmailLocations = (id, locationName) => {
    setShowLoading(true);
    localStorage.setItem("locationName", locationName);
    props.history.push({
      pathname: "/set_feedback_email/setFeedEmails/" + id
    });
  };

  const back = () => {
    props.history.goBack();
  };

  let companyName = localStorage.getItem("companyName");
  let title = "Set Feedback Email for "+companyName;
  return (
    <LoadingOverlay
      active={showLoading}
      spinner={<RiseLoader sizeUnit={"px"} size={80} color={"#2a8dd1"} />}
    >
      <>
        <div className="row">
          <div className="col-md-12">
          
            <CodeExample beforeCodeTitle={title}>
            <div className="col-offset-9 col-3 text-right">
                <input
                    type="button"
                    onClick={() => back()}
                    value="Back"
                    className="margin btn btn-primary save-btn font-weight-bold"
                  />
            </div>
            
              <div className="kt-section">
                <br></br>
                
                <div className="kt-separator kt-separator--dashed responsive"></div>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Sr. No.</th>
                      <th>Location Name</th>
                      <th>Address</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currdata && currdata.length>0? currdata.map((item, idx) => (
                      <tr key={idx}>
                        <td>{idx + 1 + (current - 1) * 20}</td>
                        <td>
                          {item.l_name}
                        </td>
                        <td>{item.add1} {item.city}</td>
                        <td>
                            <i
                              onClick={() => {
                                viewEmailLocations(item.id, item.l_name);
                              }}
                              className="btn btn-primary"
                            >Set</i>
                          
                        </td>
                      </tr>
                    ))
                    :
                    <tr className="text-center">
                      <td colSpan="4">No data found</td>
                    </tr>
                  }
                  </tbody>
                </Table>
                <Pagination
                  showQuickJumper
                  showSizeChanger
                  onChange={handlePageClick}
                  current={current}
                  total={count}
                  pageSize={20}
                  locale={localeInfo}
                />
              </div>
            </CodeExample>
          </div>
        </div>
      </>
    </LoadingOverlay>
  );
};
export default ViewLocationlistClient;