import React, { useState } from "react";
import Surveyform1 from "../Survey/form1";
import CompanyLinks from "./CompanyLinks";
import Locationform1 from "./form1";
const SendTool = (props) => {
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="kt-space-40" />
          <Locationform1></Locationform1>
          <CompanyLinks></CompanyLinks>
          <Surveyform1></Surveyform1>
          <div className="kt-space-20" />
        </div>
      </div>
    </>
  );
};
export default SendTool;
