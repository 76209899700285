import React, { useMemo, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Portlet, PortletBody } from "../../partials/content/Portlet";
import { metronic } from "../../../_metronic";
import QuickStatsChart from "../../widgets/QuickStatsChart";
import CodeExample from "../../partials/content/CodeExample";
import { Table } from "react-bootstrap";
import axios from "axios";
import "rc-pagination/assets/index.css";
import "rc-pagination/assets/index.css";
import { API } from "../../../const";
import OrdersWidget from "../../widgets/OrdersWidget";
import SalesBarChart from "../../widgets/SalesBarChart";
import {
  Legend,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";

export default function Dashboard(props) {
  const [data, setData] = useState({
    users: 0,
    sitelink: 0,
    client: 0,
    location: 0,
  });
  const [clientsdata, setclientsdata] = useState([]);
  const [usersdata, setusersData] = useState([]);
  const [clientschart, setclientschart] = useState([]);
  const [clicksdata, setClicksData] = useState([]);
  const apiUrl = API + "api/dashboard";
  var clientschartdata = [];

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(apiUrl, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });

      setData(result.data);
    };

    fetchData();
  }, []);

  const apiUrlclicks = API + "api/dashboardclicks";
  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(apiUrlclicks, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      setClicksData(result.data.data);
    };

    fetchData();
  }, []);

  useEffect(() => {
    var labels = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];

    clicksdata.forEach((element, idx) => {
      clientschartdata.push({ name: labels[idx], count: element.count });
    });
    // //console.log(clicksdata)
    setclientschart(clientschartdata);
  }, [clicksdata]);

  const apiUrlall = API + "api/latest_users";
  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(apiUrlall, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });

      setusersData(result.data);
    };

    fetchData();
  }, []);

  const handleChange = (id, status) => {
    var setstatus;
    if (status === "Enabled") {
      setstatus = "Disabled";
    } else {
      setstatus = "Enabled";
    }
    var postData = {
      id: id,
      mystatus: setstatus,
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };
    const api = API + "api/client_status";

    axios
      .post(api, postData, axiosConfig)
      .then((res) => {
        alert(res.data.message);
        props.history.push({
          pathname: "/dashboard",
        });
      })
      .catch((err) => {
        //console.log("AXIOS ERROR: ", err);
      });
  };

  const handleChange1 = (id, status) => {
    var setstatus;
    if (status === "Enabled") {
      setstatus = "Disabled";
    } else {
      setstatus = "Enabled";
    }
    var postData = {
      id: id,
      mystatus: setstatus,
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };
    const api = API + "api/user_status";

    axios
      .post(api, postData, axiosConfig)
      .then((res) => {
        alert(res.data.message);
        props.history.push({
          pathname: "/dashboard",
        });
      })
      .catch((err) => {
        //console.log("AXIOS ERROR: ", err);
      });
  };

  const apiUrlclient = API + "api/latest_client";
  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(apiUrlclient, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });

      setclientsdata(result.data);

      //
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-xl-6">
          <div className="row row-full-height">
            <div className="col-sm-12 col-md-12 col-lg-6">
              <Portlet className="kt-portlet--height-fluid-half kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart value={data.users} desc="Total Users" />
                </PortletBody>
              </Portlet>

              <div className="kt-space-20" />
            </div>

            <div className="col-sm-12 col-md-12 col-lg-6">
              <Portlet className="kt-portlet--height-fluid-half kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart
                    value={data.location}
                    desc="Total Locations"
                  />
                </PortletBody>
              </Portlet>

              <div className="kt-space-20" />
            </div>
          </div>
        </div>
        <div className="col-xl-6">
          <div className="row row-full-height">
            <div className="col-sm-12 col-md-12 col-lg-6">
              <Portlet className="kt-portlet--height-fluid-half kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart
                    value={data.review}
                    desc="Total Review Link Sent"
                  />
                </PortletBody>
              </Portlet>

              <div className="kt-space-20" />
            </div>

            <div className="col-sm-12 col-md-12 col-lg-6">
              <Portlet className="kt-portlet--height-fluid-half kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart value={data.clicks} desc="Total Clicks" />
                </PortletBody>
              </Portlet>

              <div className="kt-space-20" />
            </div>
          </div>
        </div>
      </div>

      <Portlet>
        <PortletBody fit={true}>
          <div className="row row-no-padding row-col-separator-xl">
            <div className="col-xl-4">
              <Portlet>
                <PortletBody fit={true}>
                  <div className="row row-no-padding row-col-separator-xl">
                    <div className="col-xl-12">
                      <div className="background-margin ">
                        <h3>Monthly Report</h3>

                        <BarChart
                          width={1100}
                          height={300}
                          data={clientschart}
                          margin={{
                            top: 15,
                            right: 20,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 6" />
                          <XAxis dataKey="name" />
                          <YAxis />
                          <Tooltip />
                          <Legend />
                          <Bar dataKey="count" fill="#8884d8" />
                        </BarChart>
                      </div>
                    </div>
                  </div>
                </PortletBody>
              </Portlet>
            </div>
          </div>
        </PortletBody>
      </Portlet>

      <div className="row">
        <div className="col-md-7">
          <CodeExample beforeCodeTitle="Clients List">
            <div className="kt-section">
              <div className="kt-separator kt-separator--dashed responsive"></div>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Company Name</th>
                    <th>Email</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {clientsdata.map((item, idx) => (
                    <tr>
                      <td>{item.client_name}</td>
                      <td>{item.company_name}</td>
                      <td>{item.cp_login && item.cp_login.email}</td>
                      <td>
                        <select
                          className="form-control"
                          id="status"
                          onChange={() =>
                            handleChange(
                              item.cp_login.id,
                              item.cp_login.mystatus
                            )
                          }
                        >
                          <option
                            selected={item.cp_login.mystatus === "Enabled"}
                            value="Enabled"
                          >
                            Enabled
                          </option>
                          <option
                            selected={item.cp_login.mystatus === "Disabled"}
                            value="Disabled"
                          >
                            Disabled
                          </option>
                        </select>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </CodeExample>
        </div>
        <div className="col-md-5">
          <CodeExample beforeCodeTitle="Users List">
            <div className="kt-section">
              <div className="kt-separator kt-separator--dashed responsive"></div>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>User Name</th>
                    <th>Email</th>
                    <th>User Status </th>
                  </tr>
                </thead>
                <tbody>
                  {usersdata.map((item, idx) => (
                    <tr>
                      <td>{item.name}</td>
                      <td>{item.email}</td>

                      <td>
                        <select
                          className="form-control wid50"
                          id="status"
                          onChange={() => handleChange1(item.id, item.mystatus)}
                        >
                          <option
                            selected={item.mystatus === "Enabled"}
                            value="Enabled"
                          >
                            Enabled
                          </option>
                          <option
                            selected={item.mystatus === "Disabled"}
                            value="Disabled"
                          >
                            Disabled
                          </option>
                        </select>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </CodeExample>
        </div>
      </div>
    </>
  );
}
