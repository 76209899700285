import React, { useState, useEffect } from "react";
import Notice from "../../../partials/content/Notice";
import CodeExample from "../../../partials/content/CodeExample";
import { Table, Button } from "react-bootstrap";
import axios from "axios";
import { API } from "../../../../const";
import $ from "jquery";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import localeInfo from "rc-pagination/lib/locale/en_US";
import "rc-pagination/assets/index.css";

const Location = (props) => {
  const [data, setData] = useState([]);
  const [currdata, setcurrData] = useState([]);
  const [count, setcount] = useState(0);
  const [current, setcurrent] = useState(1);
  const [formlist, setformData] = useState([]);
  const [formlist1, setformData1] = useState([]);
  const apiUrl = API + "api/user_getby_locationidall/";

  const apiUrlall = API + "api/user_getby_locationid/";

  useEffect(() => {
    const fetchData = async () => {
      var postData = {
        client_id: props.match.params.id,
      };

      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      };
      axios.post(apiUrlall, postData, axiosConfig).then((result) => {
        setData(result.data);
        let count = Object.keys(result.data).length;
        setcount(count);
      });
    };

    fetchData();
  }, []);

  const loadData = async (offsetS, current) => {
    var off1 = offsetS;
    var off = offsetS;

    var curval;
    if (!current) {
      curval = 1;
    } else {
      curval = current;
    }
    setcurrent(curval);

    $.ajax({
      url: apiUrl,
      data: {
        limit: 20,
        offset: off1,
        client_id: props.match.params.id,
        company_name: formlist.company_name,
        location_name: formlist1.location_name,
      },
      dataType: "json",
      type: "POST",
      headers: {
        Authorization: localStorage.getItem("token"),
      },

      success: (data) => {
        setcurrData(data);
      },

      error: (xhr, status, err) => {
        console.error(this.props.url, status, err.toString()); // eslint-disable-line
      },
    });
  };

  useEffect(() => {
    loadData();
  }, [data]);

  const editProduct = (id) => {
    props.history.push({
      pathname: "/client-edit/" + id,
    });
  };

  const addusers = () => {
    props.history.push({
      pathname: "/location-add",
    });
  };

  const handlePageClick = (data) => {
    let offsetS = Math.ceil(data * 20 - 20);
    loadData(offsetS, data);
  };

  const edituser = (id) => {
    props.history.push({
      pathname: "/location/" + id,
    });
  };

  const deleteuser = (id, idx) => {
    var postData = {
      id: id,
    };
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };
    const apiurldelete = API + "api/location_delete_byid/";
    axios
      .post(apiurldelete, postData, axiosConfig)
      .then((result) => {
        alert("Location Deleted Successfully");
        var updatedData = [...data];
        updatedData.splice(idx, 1);
        setData(updatedData);
        props.history.push("/location");
      })
      .catch((error) => "");
  };
  const handleChange = (event) => {
    setformData({ ...formlist, [event.target.name]: event.target.value });
  };

  const handleChange1 = (event) => {
    setformData1({ ...formlist1, [event.target.name]: event.target.value });
  };
  const resetemaildata = async () => {
    window.location.reload();
  };
  const getemaildata = async () => {
    if (localStorage.getItem("client_id") === "000") {
      var postData = {
        client_id: "",
        company_name: formlist.company_name,
        location_name: formlist1.location_name,
      };
    } else {
      postData = {
        client_id: localStorage.getItem("client_id"),
        company_name: formlist.company_name,
        location_name: formlist1.location_name,
      };
    }
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };

    axios.post(apiUrlall, postData, axiosConfig).then((result) => {
      //console.log(result.data);
      setData(result.data);
      let count = Object.keys(result.data).length;
      setcount(count);
      loadData();
    });
  };
  return (
    <>
      {/* <Notice icon="flaticon-warning kt-font-primary">
        For more info please check the components's official{" "}
      </Notice> */}

      <div className="row">
        <div className="col-md-12">
          <CodeExample beforeCodeTitle="Location List">
            <div className="kt-section">
              <Button
                className="pull-right"
                onClick={() => {
                  addusers();
                }}
              >
                {" "}
                Add Location
              </Button>
              <div className="row">
                <div className="col-6 col-md-4">
                  <input
                    className="form-control col-md-12"
                    id="company_name"
                    name="company_name"
                    onChange={handleChange}
                  />

                  <label>Company Name</label>
                </div>

                <div className="col-6 col-md-4">
                  <input
                    className="form-control col-md-12"
                    id="location_name"
                    name="location_name"
                    onChange={handleChange1}
                  />

                  <label>Location Name</label>
                </div>

                <div className="col-6 col-md-2">
                  <button
                    onClick={() => getemaildata()}
                    className=" form-control col-md-12 btn btn-success"
                  >
                    Search
                  </button>
                </div>
                <div className="col-6 col-md-2">
                  <button
                    onClick={() => resetemaildata()}
                    className=" form-control col-md-12 btn btn-warning"
                  >
                    Reset
                  </button>
                </div>
              </div>

              <div className="kt-separator kt-separator--dashed responsive"></div>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th>Company Name</th>
                    <th>Location Name </th>
                    <th>Address</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currdata.map((item, idx) => (
                    <tr>
                      <td>{idx + 1 + (current - 1) * 20}</td>

                      <td>{item.cp_client && item.cp_client.company_name}</td>

                      <td>{item.l_name}</td>
                      <td>
                        {item.add1}
                        {item.city}
                      </td>

                      <td>
                        <i
                          onClick={() => {
                            edituser(item.id);
                          }}
                          className="flaticon2-pen"
                        ></i>{" "}
                        /
                        <i
                          onClick={(e) =>
                            window.confirm(
                              "Are you sure you wish to delete this item?"
                            ) && deleteuser(item.id, idx)
                          }
                          className="flaticon-delete-1"
                        ></i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Pagination
                showQuickJumper
                showSizeChanger
                onChange={handlePageClick}
                current={current}
                total={count}
                pageSize={20}
                locale={localeInfo}
              />
            </div>
          </CodeExample>
        </div>
      </div>
    </>
  );
};
export default Location;
