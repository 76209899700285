import React, { useState, useEffect } from "react";
import axios from "axios";
import { API } from "../../../../const";
import Clientform from "../Questions/form";

const EditQuestion = (props) => {
  const [formdata, setformdata] = useState({
    id: "",
    client_name1: "",
    client_id: "",
    loc_name: "",
    p_question: "",
    question: "",
    orderno: "",
    type: "",
    required: 0,
    option1: "",
    option2: "",
    option3: "",
    option4: "",
    option5: "",
    option6: "",
    option7: "",
    option8: "",
    option9: "",
    option10: "",
    option11: "",
    option12: "",
    option13: "",
    option14: "",
    option15: "",
    option16: "",
    option17: "",
    option18: "",
    option19: "",
    option20: "",
    option21: "",
    option22: "",
    option23: "",
    option24: "",
    option25: "",
  });
  const [location, setlocation] = useState([]);
  const [subquestion, setsubquestion] = useState([]);

  const apiUrl = API + "api/editques";

  useEffect(() => {
    const fetchData = async () => {
      var postData = {
        id: props.match.params.id,
      };

      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      };
      const result = await axios
        .post(apiUrl, postData, axiosConfig)
        .then((res) => {
          if (res) {
            setlocation(res.data.location);
            setsubquestion(res.data.subquestion);
            setformdata({
              loc_name: res.data.data.location_id,
              p_question: res.data.data.type,
              question: res.data.data.question,
              orderno: res.data.data.seq_no,
              client_id: res.data.data.client_id,
              type: res.data.data.question_type,
              option1: res.data.data.option1,
              option2: res.data.data.option2,
              option3: res.data.data.option3,
              option4: res.data.data.option4,
              option5: res.data.data.option5,
              option6: res.data.data.option6,
              option7: res.data.data.option7,
              option8: res.data.data.option8,
              option9: res.data.data.option9,
              option10: res.data.data.option10,
              option11: res.data.data.option11,
              option12: res.data.data.option12,
              option13: res.data.data.option13,
              option14: res.data.data.option14,
              option15: res.data.data.option15,
              option16: res.data.data.option16,
              option17: res.data.data.option17,
              option18: res.data.data.option18,
              option19: res.data.data.option19,
              option20: res.data.data.option20,
              option21: res.data.data.option21,
              option22: res.data.data.option22,
              option23: res.data.data.option23,
              option24: res.data.data.option24,
              option25: res.data.data.option25,
              required: res.data.data.required,
            });
          }
        })
        .catch((err) => {
          //console.log("AXIOS ERROR: ", err);
        });
    };
    fetchData();
  }, []);

  return (
    <>
      {/* <Notice icon="flaticon-warning kt-font-primary">
                For more info please check the components's official{" "}
            </Notice> */}

      <div className="row">
        <div className="col-md-12">
          <div className="kt-space-40" />
          <Clientform
            data={formdata}
            history={props.history}
            location={location}
            subquestion={subquestion}
            id={props.match.params.id}
          />
          <div className="kt-space-20" />
        </div>
      </div>
    </>
  );
};
export default EditQuestion;
