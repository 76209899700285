import React, { useState, useEffect } from "react";
import Notice from "../../../partials/content/Notice";
import CodeExample from "../../../partials/content/CodeExample";
import { Table, Button } from "react-bootstrap";
import axios from "axios";
import Moment from "react-moment";

import { API } from "../../../../const";
import $ from "jquery";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import localeInfo from "rc-pagination/lib/locale/en_US";
import "rc-pagination/assets/index.css";
import LoadingOverlay from "react-loading-overlay";
import { RiseLoader } from "react-spinners";
const Sitelink = (props) => {
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1; //January is 0!

  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  var today = yyyy + "-" + mm + "-" + dd;

  const [showLoading, setShowLoading] = useState(false);

  const [data, setData] = useState([]);
  const [currdata, setcurrData] = useState([]);
  const [count, setcount] = useState(0);
  const [current, setcurrent] = useState(1);
  const [clinetlist, setclientData] = useState([]);
  const [locationlist, setlocationData] = useState([]);
  const [formdata, setformdata] = useState({
    client_id: "",
  });
  const [formdata1, setformdata1] = useState({
    location_id: "",
  });
  const startdata = {
    startdate: "",
  };
  const enddata = {
    enddate: "",
  };
  const movedata = {
    movetype: "",
  };
  const [sdate, setStartdate] = useState({
    startdata,
  });

  const [edate, setEndata] = useState({
    enddata,
  });

  const [semaildate, setEmailStartdate] = useState({
    startdata,
  });

  const [eemaildate, setEmailEndata] = useState({
    enddata,
  });

  const [emovedate, setMovedata] = useState({
    movedata,
  });

  const handleSelectemail = (event) => {
    setEmailStartdate({
      ...semaildate,
      [event.target.name]: event.target.value,
    });
  };
  const handleSelectemail1 = (event) => {
    setEmailEndata({ ...eemaildate, [event.target.name]: event.target.value });
  };

  const handleSelectmovetype = (event) => {
    setMovedata({ ...emovedate, [event.target.name]: event.target.value });
  };
  const handleChange = (event) => {
    setformdata({ ...formdata, [event.target.id]: event.target.value });
    const locationlistapi = API + "api/location_by_client_id";
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };
    var postData = {
      client_id: event.target.value,
    };
    axios
      .post(locationlistapi, postData, axiosConfig)
      .then((res) => {
        //console.log(res.data.location);
        setlocationData(res.data.location);
      })
      .catch((err) => {
        //console.log("AXIOS ERROR: ", err);
      });
  };
  const handleChange1 = (event) => {
    setformdata1({ ...formdata1, [event.target.id]: event.target.value });
  };

  const apiUrlall = API + "api/site_listall";
  const apiUrl = API + "api/site_list";

  useEffect(() => {
    const fetchData = async () => {
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      };
      var postData = {};
      axios
        .post(apiUrlall, postData, axiosConfig)
        .then((res) => {
          setData(res.data);
          let count = Object.keys(res.data).length;
          setcount(count);
          setShowLoading(false);
        })
        .catch((err) => {
          //console.log("AXIOS ERROR: ", err);
        });
    };

    fetchData();
  }, []);
  const loadData = async (offsetS, current) => {
    setShowLoading(true);
    let off = offsetS;
    var curval;
    if (!current) {
      var curval = 1;
    } else {
      var curval = current;
    }
    setcurrent(curval);
    $.ajax({
      url: apiUrl,
      data: {
        limit: 20,
        offset: off,
        start_date: semaildate.startdate,
        end_date: eemaildate.enddate,
        move_type: emovedate.movetype,
        client_id: formdata.client_id,
        location_id: formdata1.location_id,
      },
      dataType: "json",
      type: "POST",
      headers: {
        Authorization: localStorage.getItem("token"),
      },

      success: (data) => {
        setShowLoading(false);
        setcurrData(data);
      },

      error: (xhr, status, err) => {
        console.error(this.props.url, status, err.toString()); // eslint-disable-line
      },
    });
  };
  const onKeyUp = (e) => {
    const filterd = data.filter((item) => item.MoveIn.includes(e.target.value));

    if (e.target.value) {
      setcurrData(filterd);
    } else {
      loadData();
    }
  };

  useEffect(() => {
    loadData();
  }, [data]);

  const handlePageClick = (data) => {
    let offsetS = Math.ceil(data * 20 - 20);
    loadData(offsetS, data);
  };

  const clintlistapi = API + "api/clientwisesite";
  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(clintlistapi, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });

      setclientData(result.data);
    };

    fetchData();
  }, []);
  var postData1 = {
    start_date: semaildate.startdate,
    end_date: eemaildate.enddate,
    move_type: emovedate.movetype,
    client_id: formdata.client_id,
    location_id: formdata1.location_id,
  };

  const getemaildata = async () => {
    setShowLoading(true);
    if (semaildate.startdate > eemaildate.enddate) {
      alert("Please select start date less than end date");
    } else {
      const result = await axios.post(apiUrlall, postData1, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });

      setData(result.data);
      setShowLoading(false);
      let count = Object.keys(result.data).length;
      setcount(count);
      const loadData = async (offsetS, current) => {
        let off = offsetS;
        var curval;
        if (!current) {
          var curval = 1;
        } else {
          var curval = current;
        }
        setcurrent(curval);
        $.ajax({
          url: apiUrl,
          data: {
            limit: 20,
            offset: off,
            start_date: semaildate.startdate,
            end_date: eemaildate.enddate,
            move_type: emovedate.movetype,
            client_id: formdata.client_id,
            location_id: formdata1.location_id,
          },
          dataType: "json",
          type: "POST",
          headers: {
            Authorization: localStorage.getItem("token"),
          },

          success: (data) => {
            setcurrData(data);
            setShowLoading(false);
          },

          error: (xhr, status, err) => {
            console.error(this.props.url, status, err.toString()); // eslint-disable-line
          },
        });
      };
      loadData();
    }
  };

  const resetemaildata = async () => {
    window.location.reload();
  };

  return (
    <LoadingOverlay
      active={showLoading}
      spinner={<RiseLoader sizeUnit={"px"} size={80} color={"#2a8dd1"} />}
    >
      <>
        {/* <Notice icon="flaticon-warning kt-font-primary">
        For more info please check the components's official{" "}
      </Notice> */}

        <div className="row">
          <div className="col-md-12">
            <CodeExample beforeCodeTitle="Sitelink List">
              <div className="kt-section">
                <div className="row">
                  <div className="col-6 col-md-4">
                    <input
                      type="date"
                      name="startdate"
                      id="startdate"
                      value={semaildate.startdate}
                      onChange={handleSelectemail}
                      className="form-control col-md-12"
                      max={today}
                    />
                    <label>Start Date</label>
                  </div>

                  <div className="col-6 col-md-4">
                    <input
                      type="date"
                      name="enddate"
                      id="enddate"
                      value={eemaildate.enddate}
                      onChange={handleSelectemail1}
                      className="form-control col-md-12"
                      max={today}
                    />
                    <label>End Date</label>
                  </div>

                  <div className="col-6 col-md-4">
                    <select
                      className="form-control col-md-12"
                      id="movetype"
                      name="movetype"
                      defaultValue={emovedate.movetype}
                      onChange={handleSelectmovetype}
                    >
                      <option>Select Move Type</option>
                      <option value="movein">MoveIn</option>
                      <option value="moveout">MoveOut</option>
                    </select>
                    <label>Move Type</label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 col-md-4">
                    <select
                      className="form-control col-md-12"
                      id="client_id"
                      name="client_id"
                      value={formdata.client_id}
                      onChange={handleChange}
                    >
                      <option value="">Please Select Client Name :</option>
                      {clinetlist.map((item, idx) => (
                        <option
                          selected={formdata.client_id === item.client_id}
                          key={idx}
                          value={item.client_id}
                        >
                          {item.company_name}
                        </option>
                      ))}
                    </select>
                    <label>Client List</label>
                  </div>

                  <div className="col-6 col-md-4">
                    {/* <select
                      className="form-control col-md-12"
                      id="location_id"
                      name="location_id"
                      value={formdata1.location_id}
                      onChange={handleChange1}
                    >
                      <option value="">Please Select Location Name :</option>
                      {locationlist.map((item, idx) => (
                        <option
                          selected={formdata1.location_id === item.id}
                          key={idx}
                          value={item.id}
                        >
                          {item.l_name}
                        </option>
                      ))}
                    </select>
                    <label>Location List</label> */}
                  </div>
                  <div className="col-6 col-md-2 pull-right">
                    <button
                      onClick={() => getemaildata()}
                      className=" form-control col-md-12 btn btn-success"
                    >
                      Search
                    </button>
                  </div>
                  <div className="col-6 col-md-2 pull-right">
                    <button
                      onClick={() => resetemaildata()}
                      className=" form-control col-md-12 btn btn-warning"
                    >
                      Reset
                    </button>
                  </div>
                </div>

                <div className="kt-separator kt-separator--dashed responsive"></div>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Sr. No.</th>
                      <th>Location Name</th>
                      <th>Name</th>
                      <th>Address</th>
                      <th>Phone</th>
                      <th>Email</th>
                      <th>Type</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currdata.map((item, idx) => (
                      <tr key={idx}>
                        <td>{idx + 1 + (current - 1) * 20}</td>

                        <td>{item.cp_location && item.cp_location.l_name}</td>

                        <td>{item.TenantName}</td>
                        <td>
                          {item.Address} , {item.City}
                        </td>
                        <td>{item.sPhone}</td>
                        <td>{item.sEmail}</td>
                        <td>{item.MoveIn === "1" ? "MoveIn" : "MoveOut"}</td>
                        <td>
                          <Moment format="YYYY-MM-DD HH:mm">
                            {item.MoveDate}
                          </Moment>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Pagination
                  showQuickJumper
                  showSizeChanger
                  onChange={handlePageClick}
                  current={current}
                  total={count}
                  pageSize={20}
                  locale={localeInfo}
                />
              </div>
            </CodeExample>
          </div>
        </div>
      </>
    </LoadingOverlay>
  );
};
export default Sitelink;
