export default {
  header: {
    self: {},
    items: [
      {
        title: "Dashboard",
        root: true,
        alignment: "left",
        page: "dashboard",
        translate: "MENU.DASHBOARD"
      },
      {
        title: "Clients",
        root: true,
        alignment: "left",
        page: "clients"
      },
      {
        title: "Locations",
        root: true,
        alignment: "left",
        page: "location"
      },
      {
        title: "Users",
        root: true,
        alignment: "left",
        page: "users"
      },
      {
        title: "Sitelink",
        root: true,
        alignment: "left",
        page: "sitelink"
      },
      {
        title: "Set Feedback Email",
        root: true,
        alignment: "left",
        page: "set_feedback_email"
      }
    ]
  },
  aside: {
    self: {},
    items: [
      {
        title: "Dashboard",
        root: true,
        icon: "flaticon2-architecture-and-city",
        page: "dashboard",
        translate: "MENU.DASHBOARD",
        bullet: "dot"
      }
    ]
  }
};
