import React, { useState, useEffect } from "react";
import { Form, Tabs, Tab } from "react-bootstrap";
import axios from "axios";
import { API } from "../../../../const";
import { Image } from "react-bootstrap";
import Dropzone from "react-dropzone";
import "react-toastify/dist/ReactToastify.css";
import Validator from "validatorjs";
import { CKEditor } from "ckeditor4-react";
import { ChromePicker } from "react-color";
import LoadingOverlay from "react-loading-overlay";
import "./form.css";
import { RiseLoader } from "react-spinners";
const SetSpanishOptionform = (props) => {
  const [clinetlist, setclientData] = useState([]);
  const [style, setstyle] = useState("hidden");
  const [showLoading, setShowLoading] = useState(false);
  const [formdata1, setformdata1] = useState({
    logo1: "",
    logo2: "",
  });
  const [formdata, setformdata] = useState({
    client_id: "",
    msg: "",
    msg1: "",
    sms_msg: "",
    sms_msg1: "",
    review_msg: "",
    yes_msg: "",
    no_msg: "",
    subject: "",
    subject1: "",
    sign: "",
    sign1: "",
    thanks_msg: "",
    thanks_msg1: "",
    sendlogo: 0,
    sendlogo1: 0,
    sendlogoemail: 0,
    sendlogoemail1: 0,
    underlogo: "",
  });
  const handleChangeComplete = (color, event) => {
    setformdata({ ...formdata, ["background"]: color.hex });
  };

  useEffect(() => {
    if (formdata.setname === 1) {
      setstyle("show");
    } else {
      setstyle("hidden");
    }
  }, [formdata.setname]);
  const onDroplogo = (acceptedFiles) => {
    let file = acceptedFiles[0];

    let reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = function() {
      setformdata({ ...formdata, ["logo1"]: reader.result });
      setformdata1({ ...formdata1, ["logo1"]: reader.result });
    };

    reader.onerror = function() {
      //console.log(reader.error);
    };
  };

  useEffect(() => {
    setformdata(props.data);
    //console.log(props.data)
  }, [props.data]);

  const apiUrl = API + "api/getByIdSpanish";
  useEffect(() => {
    const fetchData = async () => {
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      };
      var postData = {
        id: localStorage.getItem("locationId"),
      };
      axios
        .post(apiUrl, postData, axiosConfig)
        .then((res) => {
          setformdata(res);
        })
        .catch((err) => {
          //console.log("AXIOS ERROR: ", err);
        });
    };

    fetchData();
  }, []);

  const handleChangeck1 = (event) => {
    if (event.editor) {
      var name = "msg";
      setformdata({ ...formdata, [name]: event.editor.getData() });
    }
  };
  const handleChangeck2 = (event) => {
    if (event.editor) {
      var name = "sign";
      setformdata({ ...formdata, [name]: event.editor.getData() });
    }
  };
  const handleChangeck3 = (event) => {
    if (event.editor) {
      var name = "msg1";
      setformdata({ ...formdata, [name]: event.editor.getData() });
    }
  };
  const handleChangeck4 = (event) => {
    if (event.editor) {
      var name = "sign1";
      setformdata({ ...formdata, [name]: event.editor.getData() });
    }
  };
  const handleChange = (event) => {
    if (
      event.target.id === "setname" ||
      event.target.id === "sendlogo1" ||
      event.target.id === "sendlogo" ||
      event.target.id === "sendlogoemail" ||
      event.target.id === "sendlogoemail1"
    ) {
      if (event.target.checked === true) {
        setformdata({ ...formdata, [event.target.id]: 1 });
        localStorage.setItem(event.target.id, 1);
      } else {
        setformdata({ ...formdata, [event.target.id]: 0 });
        localStorage.setItem(event.target.id, 0);
      }
    } else {
      setformdata({ ...formdata, [event.target.id]: event.target.value });
      if (event.target.id != "client_id") {
        localStorage.setItem(event.target.id, event.target.value);
      }
    }
  };

  const back = () => {
    props.history.push({
      pathname: "/location",
    });
    Object.keys(props.data).map((item) => {
      if (item != "client_id") {
        localStorage.removeItem(item);
      }
    });
  };
  const reset = () => {
    Object.keys(props.data).map((item) => {
      if (item != "client_id") {
        localStorage.removeItem(item);
      }
    });
    setformdata(props.data);
  };
  const validateForm = () => {
    let data = {
      sms_msg: formdata.sms_msg,
    };

    let rules = {
      sms_msg: "required",
    };

    let validation = new Validator(data, rules);

    if (validation.passes()) {
      return true;
    }
  };

  const formSubmit = async () => {
    setShowLoading(true);
    var postData = {
      id: formdata.id,
      client_id: formdata.client_id,
      review_msg: formdata.review_msg,
      no_msg: formdata.no_msg,
      subject: formdata.subject,
      subject1: formdata.subject1,
      sign: formdata.sign,
      sign1: formdata.sign1,
      thanks_msg: formdata.thanks_msg,
      thanks_msg1: formdata.thanks_msg1,
      sendlogo: formdata.sendlogo,
      sendlogo1: formdata.sendlogo1,
      sendlogoemail: formdata.sendlogoemail,
      sendlogoemail1: formdata.sendlogoemail1,
      underlogo: formdata.underlogo,
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };
    const apiedit = API + "api/update_spanish";
    const apiadd = API + "api/add_spanish";
    if (formdata.id) {
      axios
        .post(apiedit, postData, axiosConfig)
        .then((res) => {
          setShowLoading(false);
          alert(res.data.message);
          back();
        })
        .catch((err) => {
          //console.log("AXIOS ERROR: ", err);
        });
    } else {
      axios
        .post(apiadd, postData, axiosConfig)
        .then((res) => {
          setShowLoading(false);
          alert(res.data.message);
          back();
        })
        .catch((err) => {
          //console.log("AXIOS ERROR: ", err);
        });
    }
  };

  return (
    <LoadingOverlay
      active={showLoading}
      spinner={<RiseLoader sizeUnit={"px"} size={80} color={"#2a8dd1"} />}
    >
      <div>
        <div className="review">
          <div className="row">
            <div className="col-md-12">
              <div className="card border-top-0 border-right-0 border-left-0 pb-4">
                <div className="card-header bg-white ">
                  <div className="row">
                    <div className="col-md-12">
                      <h5 className="card-title mb-0 pt-2">
                        Set Spanish Language Options
                      </h5>
                      {/* <span>All * fields are mandatory </span> */}
                    </div>
                    <div className="col-md-12 text-right">
                      <input
                        type="button"
                        disabled={!validateForm()}
                        onClick={() => formSubmit()}
                        value="Save"
                        className="btn btn-success save-btn font-weight-bold"
                      />
                      <input
                        type="button"
                        onClick={() => reset()}
                        value="Reset"
                        className="margin btn btn-warning save-btn font-weight-bold"
                      />
                      <input
                        type="button"
                        onClick={() => back()}
                        value="Back"
                        className="margin btn btn-primary save-btn font-weight-bold"
                      />
                    </div>
                  </div>
                </div>
                <Form encType="multipart/form-data">
                  <Tabs
                    defaultActiveKey="review_msg"
                    id="uncontrolled-tab-example"
                  >
                    <Tab eventKey="review_msg">
                      <div className="card-body">
                        <div id="introduction">
                          <div className="row">
                            <div className="form-group col-md-6 ">
                              <textarea
                                type="textarea"
                                name="sms_msg"
                                id="sms_msg"
                                className="form-control"
                                value={formdata.sms_msg}
                                onChange={handleChange}
                              />
                              <label>
                                Text Msg
                                Review:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                <br />
                              </label>
                            </div>

                            <div className="form-group col-md-6 ">
                              <textarea
                                type="textarea"
                                name="sms_msg1"
                                id="sms_msg1"
                                className="form-control"
                                value={formdata.sms_msg1}
                                onChange={handleChange}
                              />
                              <label>
                                Text Msg Survey :
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <br />
                              </label>
                            </div>

                            <div className="form-group col-md-6 ">
                              <CKEditor
                                data={
                                  localStorage.getItem("msg")
                                    ? localStorage.getItem("msg")
                                    : formdata.msg
                                }
                                value={formdata.msg}
                                name="msg"
                                id="msg"
                                onChange={(evt) => handleChangeck1(evt)}
                              />
                              <label>Email Msg Review:</label>
                              <small>
                                {" "}
                                Default Email Message: We appreciate your
                                business! We would love to hear about your
                                experience with [Location Name]. Please click on
                                the link below to leave a review.{" "}
                              </small>
                            </div>
                            <div className="form-group col-md-6 ">
                              <textarea
                                type="textarea"
                                name="subject"
                                id="subject"
                                className="form-control"
                                value={formdata.subject}
                                onChange={handleChange}
                              />
                              <label>
                                Text Subject Review: <br />
                              </label>
                              <small>
                                {" "}
                                Default Text Subject: Feedback request from
                                [Company].{" "}
                              </small>
                            </div>

                            <div className="form-group col-md-6 ">
                              <CKEditor
                                data={formdata.sign}
                                value={formdata.sign}
                                id="cke_email_sign1"
                                onChange={(evt) => handleChangeck2(evt)}
                              />
                              <label>Email Signature Review:</label>
                              <small>
                                {" "}
                                Default Email Signature : Thank you,[Location]
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                              </small>
                              <br />
                            </div>

                            <div className="form-group col-md-6 ">
                              <CKEditor
                                data={
                                  localStorage.getItem("msg1")
                                    ? localStorage.getItem("msg1")
                                    : formdata.msg1
                                }
                                onChange={(evt) => handleChangeck3(evt)}
                              />
                              <label>Email Msg Survey :</label>
                              <small>
                                {" "}
                                Default Email Message: Hello [First Name], We at
                                [Location Name] appreciate your business. We are
                                always working to improve our business
                                practices. We would love to have you participate
                                in a quick survey so we can learn more about
                                your experience. Please click on the following
                                link:[link]{" "}
                              </small>
                            </div>
                            <div className="form-group col-md-6 ">
                              <textarea
                                type="textarea"
                                name="subject1"
                                id="subject1"
                                className="form-control"
                                value={formdata.subject1}
                                onChange={handleChange}
                              />
                              <label>
                                Email Subject Review: <br />
                              </label>
                              <small>
                                {" "}
                                Default Email Subject: Feedback request from
                                [Company].{" "}
                              </small>
                            </div>

                            <div className="form-group col-md-6 ">
                              <CKEditor
                                data={
                                  localStorage.getItem("sign1")
                                    ? localStorage.getItem("sign1")
                                    : formdata.sign1
                                }
                                id="cke_email_sign1"
                                onChange={(evt) => handleChangeck4(evt)}
                              />
                              <label>Email Signature Survey:</label>
                              <small>
                                {" "}
                                Default Email Signature : Thank you,[Location]
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                              </small>
                              <br />
                            </div>
                            <div className="form-group col-md-6 ">
                              <textarea
                                type="textarea"
                                name="review_msg"
                                id="review_msg"
                                className="form-control"
                                value={formdata.review_msg}
                                onChange={handleChange}
                              />
                              <label>
                                Review Page Msg : <br />
                              </label>
                              <small>
                                {" "}
                                Default Review Page Msg: Would you recommend
                                [Location]?.
                              </small>
                            </div>

                            <div className="form-group col-md-6 ">
                              <textarea
                                type="textarea"
                                name="yes_msg"
                                id="yes_msg"
                                className="form-control"
                                value={formdata.yes_msg}
                                onChange={handleChange}
                              />
                              <label>
                                If Yes Msg : <br />
                              </label>
                              <small>
                                {" "}
                                Default Yes Msg: Feedback request from
                                [Company].
                              </small>
                            </div>

                            <div className="form-group col-md-6 ">
                              <textarea
                                type="textarea"
                                name="no_msg"
                                id="no_msg"
                                className="form-control"
                                value={formdata.no_msg}
                                onChange={handleChange}
                              />
                              <label>
                                If No Msg : <br />
                              </label>
                              <small>
                                {" "}
                                Default No Msg: We apologize that you were not
                                completely satisfied with your experience. How
                                can we do better?{" "}
                              </small>
                            </div>

                            <div className="form-group col-md-6 ">
                              <textarea
                                type="textarea"
                                name="thanks_msg"
                                id="thanks_msg"
                                className="form-control"
                                value={formdata.thanks_msg}
                                onChange={handleChange}
                              />
                              <label>
                                Thank You Msg Review : <br />
                              </label>
                              <small>
                                {" "}
                                Thank you for your time. We look forward to
                                serving you in the future.{" "}
                              </small>
                            </div>

                            <div className="form-group col-md-6 ">
                              <textarea
                                type="textarea"
                                name="thanks_msg1"
                                id="thanks_msg1"
                                className="form-control"
                                value={formdata.thanks_msg1}
                                onChange={handleChange}
                              />
                              <label>
                                Thank You Msg Survey : <br />
                              </label>
                              <small>
                                {" "}
                                Thank you for your time. We look forward to
                                serving you in the future.{" "}
                              </small>
                            </div>

                            <div className="form-group col-md-6 ">
                              <textarea
                                type="textarea"
                                name="underlogo"
                                id="underlogo"
                                className="form-control"
                                value={formdata.underlogo}
                                onChange={handleChange}
                              />
                              <label>
                                Review page text under client logo : <br />
                              </label>
                              <small> We'd love to hear from you! </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="myModal1" className="modal fade" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <img
                src="https://www.grofirereviews.com/uploads/3.png"
                height="400px"
                width="550px"
              />
              <br /> <br /> <br />
              <div className="text-center">
                <a
                  href="https://www.grofirereviews.com/uploads/3.png"
                  target="_blank"
                >
                  Full View{" "}
                </a>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};
export default SetSpanishOptionform;
