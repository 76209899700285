import React, { useState, useEffect } from "react";
import SetSpanishOptionform from "./SetSpanishOptionform";
import axios from "axios";
import { API } from "../../../../const";

const SetSpanishOption = (props) => {
  const [formdata, setformdata] = useState({
    review_msg: "",
    yes_msg: "",
    no_msg: "",
    subject: "",
    subject1: "",
    sign: "",
    sign1: "",
    thanks_msg: "",
    thanks_msg1: "",
    underlogo: "",
    id: "",
    background: "",
    sf_ratings: 0,
    mysite_count: 0,
    loc_id: "",
    client_id: "",
  });

  const apiUrl = API + "api/getByIdSpanish";

  useEffect(() => {
    const fetchData = async () => {
      var postData = {
        id: localStorage.getItem("locationId"),
      };

      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      };
      const result = await axios
        .post(apiUrl, postData, axiosConfig)
        .then((res) => {
          if (res.data) {
            //console.log(res.data);
            setformdata({
              review_msg: res.data.review_msg,
              yes_msg: res.data.yes_msg,
              no_msg: res.data.no_msg,
              subject: res.data.subject,
              subject1: res.data.subject1,
              sign: res.data.sign,
              sign1: res.data.sign1,
              thanks_msg: res.data.thanks_msg,
              thanks_msg1: res.data.thanks_msg1,
              underlogo: res.data.underlogo,
              coupon: res.data.coupon,
              setfeed: res.data.setfeed,
              id: res.data.id,
              client_id: res.data.client_id,
            });
          }
        })
        .catch((err) => {
          //console.log("AXIOS ERROR: ", err);
        });
    };
    fetchData();
  }, []);
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="kt-space-40" />
          <SetSpanishOptionform data={formdata} history={props.history} />
          <div className="kt-space-20" />
        </div>
      </div>
    </>
  );
};
export default SetSpanishOption;
