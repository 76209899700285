import React, { useMemo, useState, useEffect, PureComponent } from "react";

import CodeExample from "../../../partials/content/CodeExample";
import axios from "axios";
import { API } from "../../../../const";
import $ from "jquery";

import LoadingOverlay from "react-loading-overlay";
import { RiseLoader } from "react-spinners";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const UserReportList = (props) => {
  const [data, setData] = useState([]);

  const [clinetlist, setclientData] = useState([]);
  const [locationlist, setlocationData] = useState([]);
  const [formlist, setformData] = useState([]);
  const [formlist1, setformData1] = useState([]);
  const [formlist12, setformData12] = useState({
    location_id_key: "",
  });
  const [locations, setlocations] = useState([]);

  const apiUrlall = API + "api/survey_report";
  useEffect(() => {
    const fetchData = async () => {
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      };
      var postData = {
        client_id: localStorage.getItem("client_id"),
      };
      axios
        .post(apiUrlall, postData, axiosConfig)
        .then((res) => {
          setData(res.data.array);
        })
        .catch((err) => {
          //console.log("AXIOS ERROR: ", err);
        });
    };

    fetchData();
  }, []);

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28"];

  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {data[index].value} {data[index].name}
      </text>
    );
  };

  useEffect(() => {
    const fetchData1 = async () => {
      const apiUrl = API + "api/user_getby_clientidpost";

      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      };
      var postData = {
        id: localStorage.getItem("client_id"),
      };
      axios
        .post(apiUrl, postData, axiosConfig)
        .then((res) => {
          setclientData(res.data);
        })
        .catch((err) => {
          //console.log("AXIOS ERROR: ", err);
        });
    };

    const fetchData12 = async () => {
      const apiUrl1 = API + "api/location_by_client_id";

      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      };
      var postData = {
        client_id: localStorage.getItem("client_id"),
      };
      axios
        .post(apiUrl1, postData, axiosConfig)
        .then((responce) => {
          var location = localStorage.getItem("locations").split(",");
          setlocations(location);
          setlocationData(responce.data.location);
        })
        .catch((err) => {
          //console.log("AXIOS ERROR: ", err);
        });
    };

    fetchData1();
    fetchData12();
  }, []);

  const checkLocation = (item, idx) => {
    if (localStorage.getItem("user_type") === "client") {
      return (
        <option key={idx} value={item.id} >
          {item.add1}
          {", "}
          {item.city}
        </option>
      );
    } else {
      if (locations.includes(item.id.toString())) {
        return (
          <option key={idx} value={item.id} >
            {item.add1}
            {", "}
            {item.city}
          </option>
        );
      }
    }
  };

  const handleChange = (event) => {
    setformData({ ...formlist, [event.target.name]: event.target.value });
  };

  const handleChange1 = (event) => {
    const selectedIndex = event.target.options.selectedIndex;
    const location_target_id = event.target.options[selectedIndex].getAttribute(
      "data-key"
    );

    setformData1({ ...formlist1, [event.target.name]: event.target.value });
    setformData12({ ...formlist12, ["location_id_key"]: location_target_id });
  };
  const getemaildata = async () => {
    var postData1 = {
      user: localStorage.getItem("id"),
      location: formlist1.location_id,
      location_id_key: formlist12.location_id_key,
      client_id: localStorage.getItem("client_id"),
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };

    axios
      .post(apiUrlall, postData1, axiosConfig)
      .then((res) => {
        setData(res.data.array);
      })
      .catch((err) => {
        //console.log("AXIOS ERROR: ", err);
      });
  };

  const resetemaildata = async () => {
    window.location.reload();
  };

  return (
    // <LoadingOverlay active={showLoading} spinner={<RiseLoader sizeUnit={"px"} size={80} color={"#2a8dd1"} />}>

    <>
      {/* <Notice icon="flaticon-warning kt-font-primary">
        For more info please check the components's official{" "}
      </Notice> */}

      <div className="row">
        <div className="col-md-12">
          <CodeExample beforeCodeTitle="Survey Reports">
            <div className="kt-section">
              <span className="kt-section__sub">Customer Feedback</span>
              <br></br>
              <div className="row">
                <div className="col-6 col-md-6">
                  <select
                    className="form-control col-md-12"
                    id="location_id"
                    name="location_id"
                    onChange={handleChange1}
                  >
                    <option value="">Select Location</option>
                    <option value="0" data-key="0">
                      {" "}
                      All Location
                    </option>
                    {locationlist.map((item, idx) => checkLocation(item, idx))}
                  </select>
                  <label>Location List</label>
                </div>

                <div className="col-6 col-md-3">
                  <button
                    onClick={() => getemaildata()}
                    className=" form-control col-md-12 btn btn-success"
                  >
                    Search
                  </button>
                </div>
                <div className="col-6 col-md-3">
                  <button
                    onClick={() => resetemaildata()}
                    className=" form-control col-md-12 btn btn-warning"
                  >
                    Reset
                  </button>
                </div>
              </div>

              <div className="col-md-6">
                <div className="background-margin">
                  <PieChart width={500} height={500}>
                    <Pie
                      data={data}
                      cx={200}
                      cy={200}
                      labelLine={false}
                      label={renderCustomizedLabel}
                      outerRadius={200}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {data.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </div>
              </div>
            </div>
          </CodeExample>
        </div>
      </div>
    </>
    // </LoadingOverlay>
  );
};
export default UserReportList;
