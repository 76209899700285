import React, { useState, useEffect } from "react";
import { Portlet, PortletBody } from "../../partials/content/Portlet";
import QuickStatsChart from "../../widgets/QuickStatsChart";
import axios from "axios";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import localeInfo from "rc-pagination/lib/locale/en_US";
import localeInfo1 from "rc-pagination/lib/locale/en_US";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API } from "../../../const";
import OrdersWidget from "../../widgets/OrdersWidget";
import SalesBarChart from "../../widgets/SalesBarChart";
import CodeExample from "../../partials/content/CodeExample";
import { Table } from "react-bootstrap";
import $ from "jquery";
import Moment from "react-moment";
var moment = require("moment");

toast.configure();

export default function Dashboardclient(props) {
  const [data1, setData1] = useState([]);

  const [data, setData] = useState({
    users: 0,
    sitelink: 0,
    client: 0,
    location: 0,
  });
  const [clientschart, setclientschart] = useState([]);
  const [clicksdata, setClicksData] = useState([]);
  const [reviewdata, setReviewData] = useState([]);
  const [allreviewdata, setallReviewData] = useState([]);

  const [clinetlist, setclientData] = useState([]);
  const [locationlist, setlocationData] = useState([]);
  const [formlist, setformData] = useState([]);
  const [formlist1, setformData1] = useState([]);
  const [formlist2, setformData2] = useState([]);
  const [formlist3, setformData3] = useState([]);
  const [count, setcount] = useState(0);
  const [currdata, setcurrData] = useState([]);
  const [current, setcurrent] = useState(1);

  const [currdata1, setcurrData1] = useState([]);
  const [count1, setcount1] = useState(0);
  const [current1, setcurrent1] = useState(1);

  var sitlinkurlall = API + "api/getlinkalldata/";
  var sitlinkurl = API + "api/listTestClient/";
  var siteurlall = API + "api/listTestall";

  const apiUrl = API + "api/dashboard/" + localStorage.getItem("client_id");
  const apiUrlreviews = API + "api/review_all/";

  const apiUrlreviewslist = API + "api/reviewlist";
  var clientschartdata = [];
  var reviewchartdata = [];
  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(apiUrl, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });

      setData(result.data);
    };

    fetchData();
    const fetchData2 = async () => {
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      };
      var postData = {
        client_id: localStorage.getItem("client_id"),
      };
      axios
        .post(apiUrlreviews, postData, axiosConfig)
        .then((res) => {
          setallReviewData(res.data);
          //  let count = Object.keys(res.data).length;
          //  setcount(count);
        })
        .catch((err) => {
          //console.log("AXIOS ERROR: ", err);
        });
    };

    const fetchData123 = async () => {
      var postData1 = {
        user_id: formlist.user_id,
        user_type: localStorage.getItem("user_type"),
        client_id: localStorage.getItem("client_id"),
      };

      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      };

      axios
        .post(siteurlall, postData1, axiosConfig)
        .then((res) => {
          setData1(res.data);
          let count = Object.keys(res.data).length;
          // alert(count);
          setcount(count);
        })
        .catch((err) => {
          //console.log("AXIOS ERROR: ", err);
        });
    };

    fetchData123();
    fetchData();
    fetchData2();
  }, []);

  useEffect(() => {
    const fetchData121 = async () => {
      const apiUrl = API + "api/user_getby_clientidpost";

      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      };
      var postData = {
        id: localStorage.getItem("client_id"),
      };
      axios
        .post(apiUrl, postData, axiosConfig)
        .then((res) => {
          setclientData(res.data);
        })
        .catch((err) => {
          //console.log("AXIOS ERROR: ", err);
        });
    };

    const fetchData1221 = async () => {
      const apiUrl1 = API + "api/location_by_client_id";

      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      };
      var postData = {
        client_id: localStorage.getItem("client_id"),
      };
      axios
        .post(apiUrl1, postData, axiosConfig)
        .then((responce) => {
          setlocationData(responce.data.location);
        })
        .catch((err) => {
          //console.log("AXIOS ERROR: ", err);
        });
    };

    fetchData121();
    fetchData1221();
  }, []);

  const apiUrlclicks =
    API + "api/dashboardclientclicks/" + localStorage.getItem("client_id");
  const apiUrlreview =
    API + "api/dashboardclientreview/" + localStorage.getItem("client_id");
  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(apiUrlclicks, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      setClicksData(result.data.data);
    };

    const fetchData1 = async () => {
      const result = await axios(apiUrlreview, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      setReviewData(result.data);
    };

    fetchData();
    fetchData1();
  }, []);

  useEffect(() => {
    var labels = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];

    clicksdata.forEach((element, idx) => {
      clientschartdata.push({ name: labels[idx], count: element.count });
    });

    reviewdata.forEach((element) => {
      reviewchartdata.push(element.count);
    });
    // //console.log(clicksdata)
    setclientschart(clientschartdata);
    // //console.log("clientschartdata");
    // //console.log(clientschartdata);
    setReviewData(reviewchartdata);
  }, [clicksdata]);

  const handleChange = (event) => {
    setformData({ ...formlist, [event.target.name]: event.target.value });
  };

  const handleChange1 = (event) => {
    setformData1({ ...formlist1, [event.target.name]: event.target.value });
  };

  const handleChange2 = (event) => {
    setformData2({ ...formlist2, [event.target.name]: event.target.value });
  };

  const handleChange3 = (event) => {
    setformData3({ ...formlist3, [event.target.name]: event.target.value });
  };
  const loadData = async (offsetS, current) => {
    let off = offsetS;
    var curval;
    if (!current) {
      curval = 1;
    } else {
      curval = current;
    }
    setcurrent(curval);
    $.ajax({
      url: sitlinkurl,
      data: {
        limit: 5,
        offset: off,
        user_id: formlist.user_id,
        location_id: formlist1.location_name,
        user_type: localStorage.getItem("user_type"),
        user: localStorage.getItem("client_name"),
        client_id: localStorage.getItem("client_id"),
      },

      dataType: "json",
      type: "POST",
      headers: {
        Authorization: localStorage.getItem("token"),
      },

      success: (data) => {
        setcurrData(data);
        // let count = Object.keys(data).length;
        // setcount(count);
      },

      error: (xhr, status, err) => {
        console.error(this.props.url, status, err.toString()); // eslint-disable-line
      },
    });
  };
  useEffect(() => {
    loadData();
  }, [data]);
  const handlePageClick = (data) => {
    let offsetS = Math.ceil(data * 5 - 5);
    loadData(offsetS, data);
  };

  const loadData1 = async (offsetS, current) => {
    let off = offsetS;
    var curval;
    if (!current) {
      curval = 1;
    } else {
      curval = current;
    }
    setcurrent1(curval);
    $.ajax({
      url: apiUrlreviewslist,
      data: {
        limit: 5,
        offset: off,
        client_id: localStorage.getItem("client_id"),
        user: formlist2.user_id,
        location: formlist3.location_name,
      },
      dataType: "json",
      type: "POST",
      headers: {
        Authorization: localStorage.getItem("token"),
      },

      success: (data) => {
        setcurrData1(data);
        let count = Object.keys(data).length;
        setcount1(count);
      },

      error: (xhr, status, err) => {
        console.error(this.props.url, status, err.toString()); // eslint-disable-line
      },
    });
  };
  // const onKeyUp = (e) => {
  //   const filterd = data.filter((item) =>
  //     item.client_name.toLowerCase().includes(e.target.value.toLowerCase())
  //   );

  //   if (e.target.value) {
  //     setcurrData1(filterd);
  //   } else {
  //     loadData1();
  //   }
  // };

  useEffect(() => {
    loadData1();
  }, [data1]);

  const getemaildata = async () => {
    var postData1 = {
      user_id: formlist.user_id,
      location_id: formlist1.location_name,
      user_type: localStorage.getItem("user_type"),
      user: localStorage.getItem("client_name"),
      client_id: localStorage.getItem("client_id"),
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };

    axios
      .post(sitlinkurlall, postData1, axiosConfig)
      .then((res) => {
        setData(res.data);
        // let count = Object.keys(res.data).length;
        // setcount(count);
      })
      .catch((err) => {
        //console.log("AXIOS ERROR: ", err);
      });

    loadData();
  };

  const getemaildata1 = async () => {
    var postData1 = {
      client_id: localStorage.getItem("client_id"),
      user: formlist2.user_id,
      location: formlist3.location_name,
      limit: "20",
    };

    //console.log(postData1);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };

    axios
      .post(apiUrlreviewslist, postData1, axiosConfig)
      .then((res) => {
        setData1(res.data);
        let count = Object.keys(res.data).length;
        setcount1(count);
      })
      .catch((err) => {
        //console.log("AXIOS ERROR: ", err);
      });
    loadData1();
  };

  const resetemaildata = async () => {
    // setEmailStartdate({ ...startdata });
    // setEmailEndata({ ...enddata });
    // setMovedata({ ...movedata });
    window.location.reload();
  };
  let countClick = 0;

  const resend = async (id) => {
    if (countClick == 0) {
      countClick++;
      var resendapi = API + "api/resend_link/";

      var postData1 = {
        id: id,
      };

      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      };

      axios
        .post(resendapi, postData1, axiosConfig)
        .then((res) => {
          //console.log(res);

          toast(res.data.message);
          window.location.reload();
        })
        .catch((err) => {
          //console.log("AXIOS ERROR: ", err);
        });
    } else {
      alert("countClick");
      alert(countClick);
      return false;
      // or evt.preventDefault();
    }
  };

  let user_type = localStorage.getItem("user_type");
  let user_id = localStorage.getItem("id");

  const cp_clicksdata = (item, idx) => {
    if (item.link_type == "google") {
      return (
        <>
          <h6>Google </h6>{" "}
        </>
      );
    }
    if (item.link_type == "google2") {
      return (
        <>
          <h6>Google2 </h6>{" "}
        </>
      );
    }
    if (item.link_type == "fb") {
      return (
        <>
          <h6>Facebook </h6>
        </>
      );
    }
    if (item.link_type == "fb2") {
      return (
        <>
          <h6>Facebook2 </h6>
        </>
      );
    }
    if (item.link_type == "yelp") {
      return (
        <>
          <h6>Yelp </h6>
        </>
      );
    }
    if (item.link_type == "zillow") {
      return (
        <>
          <h6>Zillow </h6>
        </>
      );
    }
    if (item.link_type == "yp") {
      return (
        <>
          <h6>Yellow Pages </h6>
        </>
      );
    }
    if (item.link_type == "trip") {
      return (
        <>
          <h6>Trip Advisor </h6>
        </>
      );
    }
    if (item.link_type == "yell") {
      return (
        <>
          <h6>Yell </h6>
        </>
      );
    }
    if (item.link_type == "real") {
      return (
        <>
          <h6>Realtor </h6>
        </>
      );
    }
    if (item.link_type == "apar") {
      return (
        <>
          <h6>Apartments.com </h6>
        </>
      );
    }
    if (item.link_type == "aparrate") {
      return (
        <>
          <h6>Apartment Ratings </h6>
        </>
      );
    }
    if (item.link_type == "sf") {
      return (
        <>
          <h6>Spare Foot </h6>
        </>
      );
    }
    if (item.link_type == "great_schools") {
      return (
        <>
          <h6>Great School </h6>
        </>
      );
    }

    if (item.link_type == "healthgrades") {
      return (
        <>
          <h6>Health Grades </h6>
        </>
      );
    }
    if (item.link_type == "mysite") {
      return (
        <>
          <h6>Our Website </h6>
        </>
      );
    }
  };

  const userListing = (item, idx) => {
    if (user_type === "employee") {
      if (user_id == item.id) {
        return (
          <option key={idx} value={item.id}>
            {item.name}
          </option>
        );
      }
    } else {
      return (
        <option key={idx} value={item.id}>
          {item.name}
        </option>
      );
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="row height-hundred">
            <div className="col-sm-12 col-md-12 col-lg-4">
              <a href="/app/clientwiseuser">
                <Portlet className="kt-portlet--height-fluid-half kt-portlet--border-bottom-brand">
                  <PortletBody fluid={true}>
                    <QuickStatsChart value={data.users} desc="Total Users" />
                  </PortletBody>
                </Portlet>
              </a>
              <div className="kt-space-20" />
            </div>

            <div className="col-sm-12 col-md-12 col-lg-4">
              <a href="/app/location">
                <Portlet className="kt-portlet--height-fluid-half kt-portlet--border-bottom-brand">
                  <PortletBody fluid={true}>
                    <QuickStatsChart
                      value={data.location}
                      desc="Total Locations"
                    />
                  </PortletBody>
                </Portlet>
              </a>

              <div className="kt-space-20" />
            </div>

            <div className="col-sm-12 col-md-12 col-lg-4">
              <a href="/app/feedback">
                <Portlet className="kt-portlet--height-fluid-half kt-portlet--border-bottom-brand">
                  <PortletBody fluid={true}>
                    <QuickStatsChart
                      value={data.review}
                      desc="Customer Feedback"
                    />
                  </PortletBody>
                </Portlet>
              </a>
              <div className="kt-space-20" />
            </div>
          </div>
        </div>
        <div className="col-xl-6">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6">
              {/* <Portlet className="kt-portlet--height-fluid-half kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart
                    value={data.review}
                    desc="Total Review Link Sent"
                  />
                </PortletBody>
              </Portlet> */}

              <div className="kt-space-20" />
            </div>

            {/* <div className="col-sm-12 col-md-12 col-lg-6">
              <Portlet className="kt-portlet--height-fluid-half kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart value={data.clicks} desc="Total Clicks" />
                </PortletBody>
              </Portlet>

              <div className="kt-space-20" />
            </div> */}
          </div>
        </div>
      </div>
      {/* <Portlet>
        <PortletBody fit={true}>
          <div className="row row-no-padding row-col-separator-xl">
            <div className="col-xl-12">
              <div className="background-margin ">
                <h3>Monthly Report</h3>

                <BarChart
                  width={1100}
                  height={300}
                  data={clientschart}
                  margin={{
                    top: 15,
                    right: 20,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 6" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="count" fill="#8884d8" />
                </BarChart>
              </div>
            </div>
             <div className="col-xl-4">
              <OrdersWidget data={data} />
            </div>
          </div>
        </PortletBody>
      </Portlet> */}
      <div className="list-wrap">
        <div className="row ">
          <div className="col-6 col-md-4">
            <select
              className="form-control col-md-12"
              id="user_id"
              name="user_id"
              onChange={handleChange}
            >
              <option value="">Select User</option>
              {clinetlist.map((item, idx) => userListing(item, idx))}
            </select>
            <label>User List</label>
          </div>

          <div className="col-6 col-md-4">
            <select
              className="form-control"
              id="location_name"
              name="location_name"
              // value={formdata.location_name}
              onChange={handleChange1}
            >
              <option value="">Select Location</option>
              {locationlist.map((item, idx) => (
                <option key={idx} value={item.id}>
                  {item.add1}
                  {", "}
                  {item.city}
                </option>
              ))}
            </select>
            <label>Location List</label>
          </div>

          <div className="col-6 col-md-2">
            <button
              onClick={() => getemaildata()}
              className=" form-control col-md-12 btn btn-success"
            >
              Search
            </button>
          </div>
          <div className="col-6 col-md-2">
            <button
              onClick={() => resetemaildata()}
              className=" form-control col-md-12 btn btn-warning"
            >
              Reset
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <CodeExample beforeCodeTitle="Customer List">
              <div className="kt-section">
                <div className="kt-separator kt-separator--dashed responsive"></div>
                <Table striped bordered hover>
                  <thead></thead>
                  <tbody>
                    {currdata.map((item, idx) => (
                      <tr>
                        <td>{idx + 1}</td>
                        <td>
                          {" "}
                          <p>{item.username}</p>{" "}
                          <h5>
                            {item.email} | {item.mobile}
                          </h5>{" "}
                          {/* <h5>{item.email === "0" ? "" : item.email} </h5>{" "} */}
                          <h5>
                            Sent by : {item.via} -{" "}
                            {item.via === "API"
                              ? item.cp_location
                                ? item.cp_location.l_name
                                : item.cp_client.company_name
                              : ""}
                                                  {item.via === "Manually"
                        ? item.cp_user && item.user_id != localStorage.getItem("id")
                          ?   item?.cp_user?.name
                          : localStorage.getItem("user_type") == "client" ? item?.cp_client?.client_name : item?.cp_user?.name
                        : ""}
                          </h5>{" "}
                        </td>

                        <td>
                          {" "}
                          <p>
                            Date:{" "}
                            <Moment format="MMM Do YYYY">
                              {item.created_date}
                            </Moment>
                          </p>{" "}
                          <h5>
                            {" "}
                            {item.type === "survey"
                              ? "Survey Link"
                              : "Review Link"}{" "}
                          </h5>{" "}
                          <a target="_blank" href={item.link}>
                            {item.link}
                          </a>{" "}
                          <br></br>
                          {item.ratings &&
                            "Gate Response: " + item.ratings + " stars"}
                          {item.gate_results &&
                            "Gate Response: Clicked - " +
                              item.gate_results}{" "}
                          {item.cp_clicks.length > 0 && <h5>Clicked ON :</h5>}
                          {item.cp_clicks.length > 0 &&
                            item.cp_clicks.map((item, idx) =>
                              cp_clicksdata(item)
                            )}
                        </td>

                        <td>
                          {item.resend === 0 ? (
                            <a onClick={() => resend(item.id)} title="Resend">
                              Resend Request
                            </a>
                          ) : (
                            "Resent on  - " +
                            moment(item.updatedAt).format("MMM Do YYYY")
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Pagination
                  showQuickJumper
                  showSizeChanger
                  onChange={handlePageClick}
                  current={current}
                  total={count}
                  pageSize={5}
                  locale={localeInfo}
                />
              </div>
            </CodeExample>
          </div>
        </div>
      </div>
    </>
  );
}
