import React, { useState, useEffect } from "react";
import CodeExample from "../../../partials/content/CodeExample";
import axios from "axios";
import { API } from "../../../../const";
import Rating from "react-rating";
import "rc-pagination/assets/index.css";
import LoadingOverlay from "react-loading-overlay";
import { RiseLoader } from "react-spinners";
import { toast } from "react-toastify";
import StarRatings from "react-star-ratings";
import { Image } from "react-bootstrap";
import { Form } from "react-bootstrap";
import $ from "jquery";


const SurveyView = (props) => {
  const [showLoading, setShowLoading] = useState(true);
  const [data, setData] = useState([]);
  const [SurveyView, setSurvey] = useState([]);
  const [client, setClient] = useState([]);
  const [checked, setChecked] = useState({});
  const [ratingindex, setratindindex] = useState(0);
  const [formdata, setformdata] = useState([]);
  const [ratings, setRatings] = useState({});
  const [ratings1, setRatings1] = useState({});
  const [subanswer, setSubanswer] = useState();
  const [formdatacheckbox, setformdatacheckbox] = useState([]);

  const handleChange = (event) => {
    setformdata({ ...formdata, [event.target.name]: event.target.value });
  };

  const handleChangecheckbox = (event) => {
    var checkedOne = true;
    var checkboxes = document.getElementsByName(event.target.name);
    checkedOne = Array.prototype.slice.call(checkboxes).some((x) => x.checked);
    if (checkedOne == true) {
      checkedOne = false;
    } else {
      checkedOne = true;
    }
    setChecked({ ...checked, [event.target.name]: checkedOne });
    if (event.target.checked === true) {
      setformdatacheckbox({
        ...formdatacheckbox,
        [event.target.value]: event.target.name,
      });
    } else {
      var eventvalue = event.target.value;
      delete formdatacheckbox[eventvalue];
    }
  };

  const handleCheck = (check, name) => {
    setformdatacheckbox((formdatacheckbox) => ({
      ...formdatacheckbox,
      [check]: name,
    }));
  };
  const handleChangecheckboxall = (event) => {
    var checkboxes = document.getElementsByName(event.target.name);
    if (event.target.checked === true) {
      for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].type == "checkbox") {
          var check = checkboxes[i].value;
          var name = checkboxes[i].name;
          handleCheck(check, name);
          checkboxes[i].checked = true;
        }
      }
    } else {
      for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].type == "checkbox") {
          var eventvalue = checkboxes[i].value;
          delete formdatacheckbox[eventvalue];
          checkboxes[i].checked = false;
        }
      }
    }
  };

  const handleChangesub = (event) => {
    setSubanswer({ ...subanswer, [event.target.name]: event.target.value });
  };

  const handleChange2 = (event, ind) => {
    setratindindex(event);
  };

  const handleChange1 = (event, event1) => {
    setRatings({ ...ratings, [ratingindex]: event });
    setRatings1({ ...ratings1, [event1]: event });
    //console.log(ratings1);
  };

  var apiUrlall = API + "api/surveyQuestions/";

  useEffect(() => {
    const fetchData = async () => {
      var postData = {
        survey_name: props.match.params.id,
      };
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      };
      //console.log(postData);
      axios
        .post(apiUrlall, postData, axiosConfig)
        .then((result) => {
          //console.log(result.data);
          setData(result.data.question);
          setSurvey(result.data.survey);
          setClient(result.data.client);
          setShowLoading(false);
        })
        .catch((response) => {
          //console.log(response.error);
          if (response.error === undefined) {
            //console.log("data");
            //console.log(data.length);
            setShowLoading(false);
          }
        });
    };

    fetchData();
  }, []);

  const formSubmit = async (evt) => {
    evt.preventDefault();
    if (formdatacheckbox) {
      var db = Object.keys(formdatacheckbox)
        .map(function(k) {
          return formdatacheckbox[k];
        })
        .join(",");
      var arr = db.split(",");
      var uniqueNames = [];
      $.each(arr, function(i, el) {
        if ($.inArray(el, uniqueNames) === -1) uniqueNames.push(el);
      });
      var temp = [];
      uniqueNames.forEach((element) => {
        temp[element] = Object.keys(formdatacheckbox)
          .filter(function(k) {
            if (element === formdatacheckbox[k]) {
              return k;
            }
          })
          .join(",");
      });
    }
    setShowLoading(true);
    var postData = {
      answer: formdata,
      subanswer: subanswer,
      survey_id: props.match.params.id,
      ratings: ratings,
      client_id: localStorage.getItem("client_id"),
      location_id: SurveyView.location_id,
      cust: props.match.params.cust,
      checkboxkeys: uniqueNames ? uniqueNames : "",
      checkboxesvalue: temp ? temp : "",
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };
    const apiadd = API + "api/surveyQuestionADD";

    axios
      .post(apiadd, postData, axiosConfig)
      .then((res) => {
        toast(res.data.message);
        setShowLoading(false);
      })
      .catch((err) => {
        //console.log("AXIOS ERROR: ", err);
      });
  };

  const question = (item, idx) => {
    if (item.question_type == "TextBox") {
      return (
        <>
          <div className="textbox-div">
            <h2>{item.question}</h2>
            <textarea
              onChange={handleChange}
              className="form-control"
              placeholder="Please Type Here"
              name={item.id}
            ></textarea>
          </div>
        </>
      );
    } else if (item.question_type == "Dropdown") {
      return (
        <>
          <div className="dropdown-div">
            <h2>{item.question}</h2>
            <select
              onChange={handleChange}
              className="form-control"
              name={item.id}
            >
              <option>Please Select </option>
              {item.option1 ? (
                <option value={item.option1}>{item.option1} </option>
              ) : (
                ""
              )}
              {item.option2 ? (
                <option value={item.option2}> {item.option2} </option>
              ) : (
                ""
              )}
              {item.option3 ? (
                <option value={item.option3}>{item.option3} </option>
              ) : (
                ""
              )}
              {item.option4 ? (
                <option value={item.option4}>{item.option4} </option>
              ) : (
                ""
              )}
              {item.option5 ? (
                <option value={item.option5}>{item.option5} </option>
              ) : (
                ""
              )}
              {item.option6 ? (
                <option value={item.option6}>{item.option6} </option>
              ) : (
                ""
              )}
              {item.option7 ? (
                <option value={item.option7}>{item.option7} </option>
              ) : (
                ""
              )}
              {item.option8 ? (
                <option value={item.option8}>{item.option8} </option>
              ) : (
                ""
              )}
              {item.option9 ? (
                <option value={item.option9}>{item.option9} </option>
              ) : (
                ""
              )}
              {item.option10 ? (
                <option value={item.option10}>{item.option10} </option>
              ) : (
                ""
              )}
              {item.option11 ? (
                <option value={item.option11}>{item.option11} </option>
              ) : (
                ""
              )}
              {item.option12 ? (
                <option value={item.option12}>{item.option12} </option>
              ) : (
                ""
              )}
              {item.option13 ? (
                <option value={item.option13}>{item.option13} </option>
              ) : (
                ""
              )}
              {item.option14 ? (
                <option value={item.option14}>{item.option14} </option>
              ) : (
                ""
              )}
              {item.option15 ? (
                <option value={item.option15}>{item.option15} </option>
              ) : (
                ""
              )}
              {item.option16 ? (
                <option value={item.option16}>{item.option16} </option>
              ) : (
                ""
              )}
              {item.option17 ? (
                <option value={item.option17}>{item.option17} </option>
              ) : (
                ""
              )}
              {item.option18 ? (
                <option value={item.option18}>{item.option18} </option>
              ) : (
                ""
              )}
              {item.option19 ? (
                <option value={item.option19}>{item.option19} </option>
              ) : (
                ""
              )}
              {item.option20 ? (
                <option value={item.option20}>{item.option20} </option>
              ) : (
                ""
              )}
              {item.option21 ? (
                <option value={item.option21}>{item.option21} </option>
              ) : (
                ""
              )}
              {item.option22 ? (
                <option value={item.option22}>{item.option22} </option>
              ) : (
                ""
              )}
              {item.option23 ? (
                <option value={item.option23}>{item.option23} </option>
              ) : (
                ""
              )}
              {item.option24 ? (
                <option value={item.option24}>{item.option24} </option>
              ) : (
                ""
              )}
              {item.option25 ? (
                <option value={item.option25}>{item.option25} </option>
              ) : (
                ""
              )}
            </select>
          </div>
        </>
      );
    } else if (item.question_type == "Radio") {
      return (
        <div className="radio-div">
          <h2>{item.question}</h2>
          <div className="row">
            {item.option1 ? (
              <div className="radio col-md-3">
                <label className="radio-inline">
                  <input
                    type="radio"
                    onChange={handleChange}
                    name={item.id}
                    className="form-check-input"
                    value={item.option1}
                    aria-label={item.option1}
                  />
                  {item.option1}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option2 ? (
              <div className="radio col-md-3">
                <label className="radio-inline">
                  <input
                    type="radio"
                    onChange={handleChange}
                    name={item.id}
                    className="form-check-input"
                    value={item.option2}
                    aria-label={item.option2}
                  />
                  {item.option2}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option3 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="radio"
                    onChange={handleChange}
                    name={item.id}
                    className="form-check-input"
                    value={item.option3}
                    aria-label={item.option3}
                  />
                  {item.option3}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option4 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="radio"
                    onChange={handleChange}
                    name={item.id}
                    className="form-check-input"
                    value={item.option4}
                    aria-label={item.option4}
                  />
                  {item.option4}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option5 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="radio"
                    onChange={handleChange}
                    name={item.id}
                    className="form-check-input"
                    value={item.option5}
                    aria-label={item.option5}
                  />
                  {item.option5}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option6 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="radio"
                    onChange={handleChange}
                    name={item.id}
                    className="form-check-input"
                    value={item.option6}
                    aria-label={item.option6}
                  />
                  {item.option6}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option7 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="radio"
                    onChange={handleChange}
                    name={item.id}
                    className="form-check-input"
                    value={item.option7}
                    aria-label={item.option7}
                  />
                  {item.option7}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option8 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="radio"
                    onChange={handleChange}
                    name={item.id}
                    className="form-check-input"
                    value={item.option8}
                    aria-label={item.option8}
                  />
                  {item.option8}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option9 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="radio"
                    onChange={handleChange}
                    name={item.id}
                    className="form-check-input"
                    value={item.option9}
                    aria-label={item.option9}
                  />
                  {item.option9}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option10 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="radio"
                    name={item.id}
                    onChange={handleChange}
                    className="form-check-input"
                    value={item.option10}
                    aria-label={item.option10}
                  />
                  {item.option10}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option11 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="radio"
                    name={item.id}
                    onChange={handleChange}
                    className="form-check-input"
                    value={item.option11}
                    aria-label={item.option11}
                  />
                  {item.option11}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option12 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="radio"
                    name={item.id}
                    onChange={handleChange}
                    className="form-check-input"
                    value={item.option12}
                    aria-label={item.option12}
                  />
                  {item.option12}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option13 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="radio"
                    name={item.id}
                    onChange={handleChange}
                    className="form-check-input"
                    value={item.option13}
                    aria-label={item.option13}
                  />
                  {item.option13}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option14 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="radio"
                    name={item.id}
                    onChange={handleChange}
                    className="form-check-input"
                    value={item.option14}
                    aria-label={item.option14}
                  />
                  {item.option14}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option15 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="radio"
                    name={item.id}
                    onChange={handleChange}
                    className="form-check-input"
                    value={item.option15}
                    aria-label={item.option15}
                  />
                  {item.option15}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option16 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="radio"
                    name={item.id}
                    onChange={handleChange}
                    className="form-check-input"
                    value={item.option16}
                    aria-label={item.option16}
                  />
                  {item.option16}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option17 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="radio"
                    name={item.id}
                    onChange={handleChange}
                    className="form-check-input"
                    value={item.option17}
                    aria-label={item.option17}
                  />
                  {item.option17}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option18 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="radio"
                    name={item.id}
                    onChange={handleChange}
                    className="form-check-input"
                    value={item.option18}
                    aria-label={item.option18}
                  />
                  {item.option18}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option19 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="radio"
                    name={item.id}
                    onChange={handleChange}
                    className="form-check-input"
                    value={item.option19}
                    aria-label={item.option19}
                  />
                  {item.option19}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option20 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="radio"
                    name={item.id}
                    onChange={handleChange}
                    className="form-check-input"
                    value={item.option20}
                    aria-label={item.option20}
                  />
                  {item.option20}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option21 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="radio"
                    name={item.id}
                    onChange={handleChange}
                    className="form-check-input"
                    value={item.option21}
                    aria-label={item.option21}
                  />
                  {item.option21}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option22 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="radio"
                    name={item.id}
                    onChange={handleChange}
                    className="form-check-input"
                    value={item.option22}
                    aria-label={item.option22}
                  />
                  {item.option22}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option23 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="radio"
                    name={item.id}
                    onChange={handleChange}
                    className="form-check-input"
                    value={item.option23}
                    aria-label={item.option23}
                  />
                  {item.option23}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option24 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="radio"
                    name={item.id}
                    onChange={handleChange}
                    className="form-check-input"
                    value={item.option24}
                    aria-label={item.option24}
                  />
                  {item.option24}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option25 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="radio"
                    name={item.id}
                    onChange={handleChange}
                    className="form-check-input"
                    value={item.option25}
                    aria-label={item.option25}
                  />
                  {item.option25}
                </label>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      );
    } else if (item.question_type == "Checkbox") {
      return (
        <div className="radio-div">
          <h2>{item.question} </h2>
          <div className="row">
            <label className="col-md-12 pull-right">
              <input
                type="checkbox"
                onChange={handleChangecheckboxall}
                name={item.id}
                className="form-check-input"
                aria-label={item.option1}
              />
              {"SelectAll"}
            </label>
          </div>

          <div className="row">
            {item.option1 ? (
              <div className="radio col-md-3">
                <label className="radio-inline">
                  <input
                    type="checkbox"
                    onChange={handleChangecheckbox}
                    name={item.id}
                    required={
                      typeof checked[item.id] == "undefined"
                        ? true
                        : checked[item.id]
                    }
                    className="form-check-input"
                    value={item.option1}
                    aria-label={item.option1}
                  />
                  {item.option1}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option2 ? (
              <div className="radio col-md-3">
                <label className="radio-inline">
                  <input
                    type="checkbox"
                    onChange={handleChangecheckbox}
                    name={item.id}
                    required={
                      typeof checked[item.id] == "undefined"
                        ? true
                        : checked[item.id]
                    }
                    className="form-check-input"
                    value={item.option2}
                    aria-label={item.option2}
                  />
                  {item.option2}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option3 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="checkbox"
                    onChange={handleChangecheckbox}
                    name={item.id}
                    required={
                      typeof checked[item.id] == "undefined"
                        ? true
                        : checked[item.id]
                    }
                    className="form-check-input"
                    value={item.option3}
                    aria-label={item.option3}
                  />
                  {item.option3}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option4 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="checkbox"
                    onChange={handleChangecheckbox}
                    name={item.id}
                    required={
                      typeof checked[item.id] == "undefined"
                        ? true
                        : checked[item.id]
                    }
                    className="form-check-input"
                    value={item.option4}
                    aria-label={item.option4}
                  />
                  {item.option4}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option5 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="checkbox"
                    onChange={handleChangecheckbox}
                    name={item.id}
                    required={
                      typeof checked[item.id] == "undefined"
                        ? true
                        : checked[item.id]
                    }
                    className="form-check-input"
                    value={item.option5}
                    aria-label={item.option5}
                  />
                  {item.option5}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option6 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="checkbox"
                    onChange={handleChangecheckbox}
                    name={item.id}
                    required={
                      typeof checked[item.id] == "undefined"
                        ? true
                        : checked[item.id]
                    }
                    className="form-check-input"
                    value={item.option6}
                    aria-label={item.option6}
                  />
                  {item.option6}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option7 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="checkbox"
                    onChange={handleChangecheckbox}
                    name={item.id}
                    required={
                      typeof checked[item.id] == "undefined"
                        ? true
                        : checked[item.id]
                    }
                    className="form-check-input"
                    value={item.option7}
                    aria-label={item.option7}
                  />
                  {item.option7}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option8 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="checkbox"
                    onChange={handleChangecheckbox}
                    name={item.id}
                    required={
                      typeof checked[item.id] == "undefined"
                        ? true
                        : checked[item.id]
                    }
                    className="form-check-input"
                    value={item.option8}
                    aria-label={item.option8}
                  />
                  {item.option8}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option9 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="checkbox"
                    onChange={handleChangecheckbox}
                    name={item.id}
                    required={
                      typeof checked[item.id] == "undefined"
                        ? true
                        : checked[item.id]
                    }
                    className="form-check-input"
                    value={item.option9}
                    aria-label={item.option9}
                  />
                  {item.option9}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option10 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="checkbox"
                    name={item.id}
                    required={
                      typeof checked[item.id] == "undefined"
                        ? true
                        : checked[item.id]
                    }
                    onChange={handleChangecheckbox}
                    className="form-check-input"
                    value={item.option10}
                    aria-label={item.option10}
                  />
                  {item.option10}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option11 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="checkbox"
                    name={item.id}
                    required={
                      typeof checked[item.id] == "undefined"
                        ? true
                        : checked[item.id]
                    }
                    onChange={handleChangecheckbox}
                    className="form-check-input"
                    value={item.option11}
                    aria-label={item.option11}
                  />
                  {item.option11}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option12 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="checkbox"
                    name={item.id}
                    required={
                      typeof checked[item.id] == "undefined"
                        ? true
                        : checked[item.id]
                    }
                    onChange={handleChangecheckbox}
                    className="form-check-input"
                    value={item.option12}
                    aria-label={item.option12}
                  />
                  {item.option12}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option13 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="checkbox"
                    name={item.id}
                    required={
                      typeof checked[item.id] == "undefined"
                        ? true
                        : checked[item.id]
                    }
                    onChange={handleChangecheckbox}
                    className="form-check-input"
                    value={item.option13}
                    aria-label={item.option13}
                  />
                  {item.option13}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option14 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="checkbox"
                    name={item.id}
                    required={
                      typeof checked[item.id] == "undefined"
                        ? true
                        : checked[item.id]
                    }
                    onChange={handleChangecheckbox}
                    className="form-check-input"
                    value={item.option14}
                    aria-label={item.option14}
                  />
                  {item.option14}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option15 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="checkbox"
                    name={item.id}
                    required={
                      typeof checked[item.id] == "undefined"
                        ? true
                        : checked[item.id]
                    }
                    onChange={handleChangecheckbox}
                    className="form-check-input"
                    value={item.option15}
                    aria-label={item.option15}
                  />
                  {item.option15}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option16 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="checkbox"
                    name={item.id}
                    required={
                      typeof checked[item.id] == "undefined"
                        ? true
                        : checked[item.id]
                    }
                    onChange={handleChangecheckbox}
                    className="form-check-input"
                    value={item.option16}
                    aria-label={item.option16}
                  />
                  {item.option16}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option17 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="checkbox"
                    name={item.id}
                    required={
                      typeof checked[item.id] == "undefined"
                        ? true
                        : checked[item.id]
                    }
                    onChange={handleChangecheckbox}
                    className="form-check-input"
                    value={item.option17}
                    aria-label={item.option17}
                  />
                  {item.option17}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option18 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="checkbox"
                    name={item.id}
                    required={
                      typeof checked[item.id] == "undefined"
                        ? true
                        : checked[item.id]
                    }
                    onChange={handleChangecheckbox}
                    className="form-check-input"
                    value={item.option18}
                    aria-label={item.option18}
                  />
                  {item.option18}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option19 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="checkbox"
                    name={item.id}
                    required={
                      typeof checked[item.id] == "undefined"
                        ? true
                        : checked[item.id]
                    }
                    onChange={handleChangecheckbox}
                    className="form-check-input"
                    value={item.option19}
                    aria-label={item.option19}
                  />
                  {item.option19}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option20 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="checkbox"
                    name={item.id}
                    required={
                      typeof checked[item.id] == "undefined"
                        ? true
                        : checked[item.id]
                    }
                    onChange={handleChangecheckbox}
                    className="form-check-input"
                    value={item.option20}
                    aria-label={item.option20}
                  />
                  {item.option20}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option21 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="checkbox"
                    name={item.id}
                    required={
                      typeof checked[item.id] == "undefined"
                        ? true
                        : checked[item.id]
                    }
                    onChange={handleChangecheckbox}
                    className="form-check-input"
                    value={item.option21}
                    aria-label={item.option21}
                  />
                  {item.option21}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option22 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="checkbox"
                    name={item.id}
                    required={
                      typeof checked[item.id] == "undefined"
                        ? true
                        : checked[item.id]
                    }
                    onChange={handleChangecheckbox}
                    className="form-check-input"
                    value={item.option22}
                    aria-label={item.option22}
                  />
                  {item.option22}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option23 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="checkbox"
                    name={item.id}
                    required={
                      typeof checked[item.id] == "undefined"
                        ? true
                        : checked[item.id]
                    }
                    onChange={handleChangecheckbox}
                    className="form-check-input"
                    value={item.option23}
                    aria-label={item.option23}
                  />
                  {item.option23}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option24 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="checkbox"
                    name={item.id}
                    required={
                      typeof checked[item.id] == "undefined"
                        ? true
                        : checked[item.id]
                    }
                    onChange={handleChangecheckbox}
                    className="form-check-input"
                    value={item.option24}
                    aria-label={item.option24}
                  />
                  {item.option24}
                </label>
              </div>
            ) : (
              ""
            )}
            {item.option25 ? (
              <div className="radio col-md-3">
                <label>
                  {" "}
                  <input
                    type="checkbox"
                    name={item.id}
                    required={
                      typeof checked[item.id] == "undefined"
                        ? true
                        : checked[item.id]
                    }
                    onChange={handleChangecheckbox}
                    className="form-check-input"
                    value={item.option25}
                    aria-label={item.option25}
                  />
                  {item.option25}
                </label>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      );
    } else if (item.question_type == "Grid") {
      return (
        <>
          <div className="textbox-div">
            <h2>{item.question}</h2>

            <div id="top__heading">
              <div className="row">
                <div className="col-4">&nbsp;</div>

                <>
                  {item.option1 ? (
                    <div className="col-2 bg">
                      <div className="col-2">
                        <span> {item.option1} </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {item.option2 ? (
                    <div className="col-2 bg">
                      <div className="col-2">
                        <span> {item.option2} </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {item.option3 ? (
                    <div className="col-2 bg">
                      <div className="col-2">
                        <span> {item.option3} </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {item.option4 ? (
                    <div className="col-2 bg">
                      <div className="col-2">
                        <span> {item.option4} </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {item.option5 ? (
                    <div className="col-2 bg">
                      <div className="col-2">
                        <span> {item.option5} </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {item.option6 ? (
                    <div className="col-2 bg">
                      <div className="col-2">
                        <span> {item.option6} </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {item.option7 ? (
                    <div className="col-2 bg">
                      <div className="col-2">
                        <span> {item.option7} </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {item.option8 ? (
                    <div className="col-2 bg">
                      <div className="col-2">
                        <span> {item.option8} </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {item.option9 ? (
                    <div className="col-2 bg">
                      <div className="col-2">
                        <span> {item.option9} </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {item.option10 ? (
                    <div className="col-2 bg">
                      <div className="col-2">
                        <span> {item.option10} </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {item.option11 ? (
                    <div className="col-2 bg">
                      <div className="col-2">
                        <span> {item.option11} </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {item.option12 ? (
                    <div className="col-2 bg">
                      <div className="col-2">
                        <span> {item.option12} </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {item.option13 ? (
                    <div className="col-2 bg">
                      <div className="col-2">
                        <span> {item.option13} </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {item.option14 ? (
                    <div className="col-2 bg">
                      <div className="col-2">
                        <span> {item.option14} </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {item.option15 ? (
                    <div className="col-2 bg">
                      <div className="col-2">
                        <span> {item.option15} </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {item.option17 ? (
                    <div className="col-2 bg">
                      <div className="col-2">
                        <span> {item.option17} </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {item.option18 ? (
                    <div className="col-2 bg">
                      <div className="col-2">
                        <span> {item.option18} </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {item.option19 ? (
                    <div className="col-2 bg">
                      <div className="col-2">
                        <span> {item.option19} </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {item.option20 ? (
                    <div className="col-2 bg">
                      <div className="col-2">
                        <span> {item.option20} </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {item.option21 ? (
                    <div className="col-2 bg">
                      <div className="col-2">
                        <span> {item.option21} </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {item.option22 ? (
                    <div className="col-2 bg">
                      <div className="col-2">
                        <span> {item.option22} </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {item.option23 ? (
                    <div className="col-2 bg">
                      <div className="col-2">
                        <span> {item.option23} </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {item.option24 ? (
                    <div className="col-2 bg">
                      <div className="col-2">
                        <span> {item.option24} </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {item.option25 ? (
                    <div className="col-2 bg">
                      <div className="col-2">
                        <span> {item.option25} </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              </div>
            </div>

            {item.cp_subquestions &&
              item.cp_subquestions.map((element, id) => (
                <>
                  <div id="question__wrap">
                    <div className="row">
                      <div className="col-4 bg">
                        <h6>{element.subquestion}</h6>
                      </div>

                      {item.option1 ? (
                        <div className="col-2 bg">
                          <input
                            type="radio"
                            onChange={handleChangesub}
                            name={element.id}
                            required={item.required == 1 ? true : false}
                            className="form-check-input"
                            value={item.option1}
                            aria-label={item.option1}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {item.option2 ? (
                        <div className="col-2 bg">
                          <input
                            type="radio"
                            onChange={handleChangesub}
                            name={element.id}
                            required={item.required == 1 ? true : false}
                            className="form-check-input"
                            value={item.option2}
                            aria-label={item.option2}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {item.option3 ? (
                        <div className="col-2 bg">
                          <input
                            type="radio"
                            onChange={handleChangesub}
                            name={element.id}
                            required={item.required == 1 ? true : false}
                            className="form-check-input"
                            value={item.option3}
                            aria-label={item.option3}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {item.option4 ? (
                        <div className="col-2 bg">
                          <input
                            type="radio"
                            onChange={handleChangesub}
                            name={element.id}
                            required={item.required == 1 ? true : false}
                            className="form-check-input"
                            value={item.option4}
                            aria-label={item.option4}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {item.option5 ? (
                        <div className="col-2 bg">
                          <input
                            type="radio"
                            onChange={handleChangesub}
                            name={element.id}
                            required={item.required == 1 ? true : false}
                            className="form-check-input"
                            value={item.option5}
                            aria-label={item.option5}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {item.option6 ? (
                        <div className="col-2 bg">
                          <input
                            type="radio"
                            onChange={handleChangesub}
                            name={element.id}
                            required={item.required == 1 ? true : false}
                            className="form-check-input"
                            value={item.option6}
                            aria-label={item.option6}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {item.option7 ? (
                        <div className="col-2 bg">
                          <input
                            type="radio"
                            onChange={handleChangesub}
                            name={element.id}
                            required={item.required == 1 ? true : false}
                            className="form-check-input"
                            value={item.option7}
                            aria-label={item.option7}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {item.option8 ? (
                        <div className="col-2 bg">
                          <input
                            type="radio"
                            onChange={handleChangesub}
                            name={element.id}
                            required={item.required == 1 ? true : false}
                            className="form-check-input"
                            value={item.option8}
                            aria-label={item.option8}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {item.option9 ? (
                        <div className="col-2 bg">
                          <input
                            type="radio"
                            onChange={handleChangesub}
                            name={element.id}
                            required={item.required == 1 ? true : false}
                            className="form-check-input"
                            value={item.option9}
                            aria-label={item.option9}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {item.option10 ? (
                        <div className="col-2 bg">
                          <input
                            type="radio"
                            onChange={handleChangesub}
                            name={element.id}
                            required={item.required == 1 ? true : false}
                            className="form-check-input"
                            value={item.option10}
                            aria-label={item.option10}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {item.option11 ? (
                        <div className="col-2 bg">
                          <input
                            type="radio"
                            onChange={handleChangesub}
                            name={element.id}
                            required={item.required == 1 ? true : false}
                            className="form-check-input"
                            value={item.option11}
                            aria-label={item.option11}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {item.option12 ? (
                        <div className="col-2 bg">
                          <input
                            type="radio"
                            onChange={handleChangesub}
                            name={element.id}
                            required={item.required == 1 ? true : false}
                            className="form-check-input"
                            value={item.option12}
                            aria-label={item.option12}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {item.option13 ? (
                        <div className="col-2 bg">
                          <input
                            type="radio"
                            onChange={handleChangesub}
                            name={element.id}
                            required={item.required == 1 ? true : false}
                            className="form-check-input"
                            value={item.option13}
                            aria-label={item.option13}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {item.option14 ? (
                        <div className="col-2 bg">
                          <input
                            type="radio"
                            onChange={handleChangesub}
                            name={element.id}
                            required={item.required == 1 ? true : false}
                            className="form-check-input"
                            value={item.option14}
                            aria-label={item.option14}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {item.option15 ? (
                        <div className="col-2 bg">
                          <input
                            type="radio"
                            onChange={handleChangesub}
                            name={element.id}
                            required={item.required == 1 ? true : false}
                            className="form-check-input"
                            value={item.option15}
                            aria-label={item.option15}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {item.option16 ? (
                        <div className="col-2 bg">
                          <input
                            type="radio"
                            onChange={handleChangesub}
                            name={element.id}
                            required={item.required == 1 ? true : false}
                            className="form-check-input"
                            value={item.option16}
                            aria-label={item.option16}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {item.option17 ? (
                        <div className="col-2 bg">
                          <input
                            type="radio"
                            onChange={handleChangesub}
                            name={element.id}
                            required={item.required == 1 ? true : false}
                            className="form-check-input"
                            value={item.option17}
                            aria-label={item.option17}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {item.option18 ? (
                        <div className="col-2 bg">
                          <input
                            type="radio"
                            onChange={handleChangesub}
                            name={element.id}
                            required={item.required == 1 ? true : false}
                            className="form-check-input"
                            value={item.option18}
                            aria-label={item.option18}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {item.option19 ? (
                        <div className="col-2 bg">
                          <input
                            type="radio"
                            onChange={handleChangesub}
                            name={element.id}
                            required={item.required == 1 ? true : false}
                            className="form-check-input"
                            value={item.option19}
                            aria-label={item.option19}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {item.option20 ? (
                        <div className="col-2 bg">
                          <input
                            type="radio"
                            onChange={handleChangesub}
                            name={element.id}
                            required={item.required == 1 ? true : false}
                            className="form-check-input"
                            value={item.option20}
                            aria-label={item.option20}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {item.option21 ? (
                        <div className="col-2 bg">
                          <input
                            type="radio"
                            onChange={handleChangesub}
                            name={element.id}
                            required={item.required == 1 ? true : false}
                            className="form-check-input"
                            value={item.option21}
                            aria-label={item.option21}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {item.option22 ? (
                        <div className="col-2 bg">
                          <input
                            type="radio"
                            onChange={handleChangesub}
                            name={element.id}
                            required={item.required == 1 ? true : false}
                            className="form-check-input"
                            value={item.option22}
                            aria-label={item.option22}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {item.option23 ? (
                        <div className="col-2 bg">
                          <input
                            type="radio"
                            onChange={handleChangesub}
                            name={element.id}
                            required={item.required == 1 ? true : false}
                            className="form-check-input"
                            value={item.option23}
                            aria-label={item.option23}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {item.option24 ? (
                        <div className="col-2 bg">
                          <input
                            type="radio"
                            onChange={handleChangesub}
                            name={element.id}
                            required={item.required == 1 ? true : false}
                            className="form-check-input"
                            value={item.option24}
                            aria-label={item.option24}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {item.option25 ? (
                        <div className="col-2 bg">
                          <input
                            type="radio"
                            onChange={handleChangesub}
                            name={element.id}
                            required={item.required == 1 ? true : false}
                            className="form-check-input"
                            value={item.option25}
                            aria-label={item.option25}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </>
              ))}

            <div className="kt-space-30" />
          </div>
        </>
      );
    } else if (item.question_type === "Ratings") {
      var id = "rating" + item.id;
      return (
        <>
          <div className="ratings-div">
            <h2>{item.question}</h2>
            <div onMouseEnter={() => handleChange2(item.id)}>
              <StarRatings
                rating={ratings1[id]}
                starRatedColor="blue"
                changeRating={handleChange1}
                numberOfStars={5}
                name={"rating" + item.id}
              />
              {ratings1[id]}
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <LoadingOverlay
      active={showLoading}
      spinner={<RiseLoader sizeUnit={"px"} size={120} color={"#2a8dd1"} />}
    >
      <>
        {/* <Notice icon="flaticon-warning kt-font-primary">
        For more info please check the components's official{" "}
      </Notice> */}

        <div id="survey-view__wrap">
          <div className="logo-header">
            <div className="logo-wrap text-center">
              {data.length > 0 && (
                <Image
                  src={client && API + "api/" + client.client_logo}
                  className="img-fluid"
                  alt={client.company_name}
                />
              )}
            </div>
          </div>
          <div
            className="comp-wrap"
            style={{ backgroundColor: "#" + client.color_code }}
          >
            <div className="container">
              <Form onSubmit={formSubmit} encType="multipart/form-data">
                {data.length > 0 && (
                  <CodeExample beforeCodeTitle={SurveyView.survey_name}>
                    <small>
                      <h5>{SurveyView.comments}</h5>
                    </small>
                    <div className="kt-section">
                      <div className="kt-section__inner">
                        {data.length > 0 &&
                          data.map((item, idx) => question(item, idx))}
                        <div className="col-md-3 save-button text-left">
                          <input
                            type="submit"
                            value="Send"
                            //onClick={() => formSubmit()}
                            className="btn btn-success save-btn font-weight-bold"
                          />
                        </div>
                      </div>
                    </div>
                  </CodeExample>
                )}
                {data.length === 0 && (
                  <CodeExample beforeCodeTitle={SurveyView.survey_name}>
                    <div className="kt-section">
                      <div className="kt-section__inner">
                        <h2 className="text-center">Data not found.</h2>
                      </div>
                    </div>
                  </CodeExample>
                )}
              </Form>
            </div>
          </div>
        </div>
      </>
    </LoadingOverlay>
  );
};
export default SurveyView;
