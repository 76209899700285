import React, { useState, useEffect } from "react";
import axios from "axios";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import localeInfo from "rc-pagination/lib/locale/en_US";
import { API } from "../../../../const";
import CodeExample from "../../../partials/content/CodeExample";
import { Table, Button } from "react-bootstrap";
import $ from "jquery";
import Moment from "react-moment";

export default function Review(props) {
  const [formdata, setformdata] = useState({
    id: "",
    company_name: "",
    client_name: "",
    client_email: "",
    mobile: "",
    color_code: "",
    client_password: "",
    msg_limit: "",
    r_msg: "",
    userfile: "",
    real_estate: "",
    bulk_import: "",
    sitelink: "",
    localtionlevel: "",
    spanish: "",
    corp_code: "",
    corp_login: "",
    corp_pass: "",
    starpage: "",
    reviewstar: "",
    cstatus: "",
  });
  const [data, setData] = useState([]);

  const [count, setcount] = useState(0);
  const [currdata, setcurrData] = useState([]);
  const [current, setcurrent] = useState(1);

  var sitlinkurlall = API + "api/getlinkalldata/";

  const apiUrl = API + "api/client_getdata";

  useEffect(() => {
    const fetchData = async () => {
      var postData = {
        client_id: props.match.params.id,
      };

      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      };

      axios
        .post(apiUrl, postData, axiosConfig)
        .then((res) => {
          if (res.data) {
            setformdata({
              client_name: res.data.client.client_name,
              company_name: res.data.client.company_name,
            });
          }
        })
        .catch((err) => {
          //console.log("AXIOS ERROR: ", err);
        });
    };

    const fetchData123 = async () => {
      var postData1 = {
        // user_type: localStorage.getItem("user_type"),
       // user_id: formdata.client_name,
         client_id: props.match.params.id,
      };

      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      };

      axios
        .post(sitlinkurlall, postData1, axiosConfig)
        .then((res) => {
          setData(res.data);
          let count = Object.keys(res.data).length;
          setcount(count);
        })
        .catch((err) => {
          //console.log("AXIOS ERROR: ", err);
        });
    };

    fetchData123();
    fetchData();
  }, []);

  const loadData = async (offsetS, current) => {
    let sitlinkurl = API + "api/getlinkdata/";
    let off = offsetS;
    var curval;
    if (!current) {
      curval = 1;
    } else {
      curval = current;
    }
    setcurrent(curval);
    $.ajax({
      url: sitlinkurl,
      data: {
        limit: 20,
        offset: off,

        // user_type: localStorage.getItem("user_type"),
        client_id: props.match.params.id,
      },

      dataType: "json",
      type: "POST",
      headers: {
        Authorization: localStorage.getItem("token"),
      },

      success: (data) => {
        setcurrData(data);
      },

      error: (xhr, status, err) => {
        console.error(this.props.url, status, err.toString()); // eslint-disable-line
      },
    });
  };
  useEffect(() => {
    loadData();
  }, [data]);
  const handlePageClick = (data) => {
    let offsetS = Math.ceil(data * 20 - 20);
    loadData(offsetS, data);
  };
  const back = () => {
    props.history.push({
      pathname: "/clients",
    });
  };
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <CodeExample beforeCodeTitle="Customers List">
            <div className="kt-section">
              <Button
                variant="primary"
                className="pull-right btn btn-primary"
                onClick={() => {
                  back();
                }}
              >
                {" "}
                Back
              </Button>
              <span>Company Name: </span> <h5>{formdata.company_name}</h5>
              <div className="kt-separator kt-separator--dashed responsive"></div>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th> Name</th>
                    <th>Location</th>
                    <th>Info</th>
                  </tr>
                </thead>
                <tbody>
                  {currdata.map((item, idx) => (
                    <tr key={idx}>
                      <td>{idx + 1 + (current - 1) * 20}</td>

                      <td>
                        {" "}
                        <p>{item.username}</p> <h5>{item.email}</h5>{" "}
                        <h5>{item.mobile === "0" ? "" : item.mobile} </h5>{" "}
                        <h5>
                          Sent by : {item.via} - {item.user}
                        </h5>{" "}
                      </td>

                      <td>{item.cp_location && item.cp_location.l_name}</td>
                      <td>
                        {" "}
                        <p>
                          Date:{" "}
                          <Moment format="MMM Do YY">
                            {item.created_date}
                          </Moment>
                        </p>{" "}
                        <h5>
                          {" "}
                          {item.type === "survey"
                            ? "Survey Link"
                            : "Review Link"}{" "}
                        </h5>{" "}
                        <a target="_blank" href={item.link}>
                          {item.link}
                        </a>{" "}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Pagination
                showQuickJumper
                showSizeChanger
                onChange={handlePageClick}
                current={current}
                total={count}
                pageSize={20}
                locale={localeInfo}
              />
            </div>
          </CodeExample>
        </div>
      </div>
    </>
  );
}
