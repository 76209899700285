export default {
  header: {
    self: {},
    items: [
      {
        title: "Send Review Request",
        root: true,
        alignment: "left",
        page: "reviewadd",
      },
      // {
      // 	title: 'Reviews',
      // 	root: true,
      // 	alignment: 'left',
      // 	page: 'myreviews'
      // }
    ],
  },
  aside: {
    self: {},
    items: [
      // {
      // 	title: 'Dashboard',
      // 	root: true,
      // 	icon: 'flaticon2-architecture-and-city',
      // 	page: 'dashboard',
      // 	translate: 'MENU.DASHBOARD',
      // 	bullet: 'dot'
      // }
    ],
  },
};
