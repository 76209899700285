import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import axios from "axios";
import { API } from "../../../../const";
import { Image } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Validator from "validatorjs";
const Clientform = (props) => {
  const [style, setstyle] = useState("hidden");
  const [formdata, setformdata] = useState({
    id: "",
    company_name: "",
    cl_name: "",
    userfile: "",
    cl_email: "",
    cl_mobile: "",
    msg_limit: "",
    rmsg_limit: "",
    color_code: "",
    client_id: props.data.id,
  });

  const validateForm = () => {
    let data = {
      company_name: formdata.company_name,
      client_name: formdata.client_name,
      client_email: formdata.client_email,
      userfile: formdata.logo1,
    };

    let rules = {
      company_name: "required",
      client_email: "required|email",
      client_name: "required",
    };

    let validation = new Validator(data, rules);

    if (validation.passes()) {
      return true;
    }
  };

  useEffect(() => {
    setformdata(props.data);
  }, [props.data]);

  useEffect(() => {
    if (formdata.sitelink === 1) {
      setstyle("show");
    } else {
      setstyle("hidden");
    }
  }, [formdata.sitelink]);

  const back = () => {
    props.history.push({
      pathname: "/clients",
    });
  };
  const handleChange = (event) => {
    setformdata({ ...formdata, [event.target.id]: event.target.value });
  };

  const onDroplogo = (acceptedFiles) => {
    let file = acceptedFiles[0];

    let reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = function() {
      //console.log(reader.result);
      setformdata({ ...formdata, ["logo1"]: reader.result });
    };

    reader.onerror = function() {
      //console.log(reader.error);
    };
  };

  const formSubmit = async () => {
    var postData = {
      id: props.data.id,
      company_name: formdata.company_name,
      client_url: formdata.client_name,
      client_name: formdata.client_name,
      email: formdata.client_email,
      mobile: formdata.mobile,
      color_code: formdata.color_code,
      msg_limit: formdata.msg_limit,
      remaining_msg: formdata.r_msg,
      userfile: formdata.logo1,
      client_id: props.data.id,
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };
    const apiedit = API + "api/profile_update";
    if (props.data.id) {
      axios
        .post(apiedit, postData, axiosConfig)
        .then((res) => {
          alert(res.data.message);
          window.location.reload();
        })
        .catch((err) => {
          //console.log("AXIOS ERROR: ", err);
        });
    }
  };

  return (
    <div>
      <Form encType="multipart/form-data">
        <div className="review">
          <div className="row">
            <div className="col-md-12">
              <div className="card border-top-0 border-right-0 border-left-0 pb-4">
                <div className="card-header bg-white ">
                  <div className="row">
                    <div className="col-md-12">
                      <h5 className="card-title mb-0 pt-2">Edit Profile</h5>
                    </div>
                    <div className="col-md-12 text-right">
                      <input
                        type="button"
                        onClick={() => back()}
                        value="Back"
                        className="margin btn btn-primary save-btn font-weight-bold"
                      />
                      <input
                        type="button"
                        disabled={!validateForm()}
                        onClick={() => formSubmit()}
                        value="Save"
                        className="btn btn-success save-btn font-weight-bold"
                      />
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div id="introduction">
                    <div className="row">
                      <div className="form-group col-md-6 ">
                        <input
                          type="text"
                          name="company_name"
                          id="company_name"
                          className="form-control"
                          placeholder="Company Name "
                          value={formdata.company_name}
                          onChange={handleChange}
                        />
                        <input
                          type="hidden"
                          name="id"
                          id="id"
                          className="form-control"
                          placeholder="id"
                          value={formdata.id}
                        />
                        <label>
                          Enter Company Name (this field is required)
                        </label>
                      </div>
                      <div className="form-group col-md-6 ">
                        <input
                          type="text"
                          name="client_name"
                          id="client_name"
                          className="form-control"
                          placeholder="Client Name"
                          value={formdata.client_name}
                          onChange={handleChange}
                        />
                        <label>
                          Enter Client Name (this field is required)
                        </label>
                      </div>
                      <div className="form-group col-md-6 ">
                        <input
                          type="email"
                          className="form-control"
                          id="client_email"
                          name="client_email"
                          placeholder="Client Email"
                          value={formdata.client_email}
                          onChange={handleChange}
                        />
                        <label>Enter Email (this field is required)</label>
                      </div>
                      <div className="form-group col-md-6 ">
                        <input
                          type="text"
                          name="mobile"
                          id="mobile"
                          className="form-control"
                          placeholder="Mobile No"
                          value={formdata.mobile}
                          onChange={handleChange}
                        />
                        <label>Mobile No:</label>
                      </div>
                      <div className="form-group col-md-6 ">
                        <input
                          type="text"
                          name="color_code"
                          id="color_code"
                          className="form-control"
                          placeholder="Color Code"
                          value={formdata.color_code}
                          onChange={handleChange}
                        />
                        <label>Color No:</label>
                      </div>
                      <div className="form-group col-md-6 ">
                        <input
                          type="number"
                          name="msg_limit"
                          id="msg_limit"
                          className="form-control"
                          placeholder="Message Limit"
                          value={formdata.msg_limit}
                          onChange={handleChange}
                          disabled
                        />
                        <label>SMS limit per month:</label>
                      </div>

                      <div className="form-group col-md-6 ">
                        <input
                          type="number"
                          name="r_msg"
                          id="r_msg"
                          className="form-control"
                          placeholder="Remaining Text Message :"
                          value={formdata.r_msg}
                          onChange={handleChange}
                          disabled
                        />
                        <label>Remaining SMS:</label>
                      </div>

                      <div className="form-group col-md-2">
                        <Image
                          src={
                            formdata.userfile
                              ? API + "api/" + formdata.userfile
                              : formdata.logo1
                          }
                          fluid
                          width="100"
                          className={
                            formdata.userfile || formdata.logo1
                              ? "image-padding"
                              : "hidden"
                          }
                        />
                        <Dropzone
                          onDrop={(acceptedFiles) => onDroplogo(acceptedFiles)}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <section>
                              <div {...getRootProps()}>
                                <input
                                  {...getInputProps()}
                                  accept=".jpeg,.png,.jpg,.gif"
                                />
                                <p className="btn btn-secondary">Select File</p>
                              </div>
                            </section>
                          )}
                        </Dropzone>
                        <label>
                          Logo{" "}
                          <small>
                            Prefered Logo Size is (100 X 80) (this field is
                            required)
                          </small>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};
export default Clientform;
