import React, { useState, useEffect, Fragment } from "react";
import { Form } from "react-bootstrap";

import axios from "axios";
import { API } from "../../../../const";
import { Image } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Validator from "validatorjs";
import "./form.css";
import { ChromePicker } from "react-color";
toast.configure();

const Clientform = (props) => {
  const [style, setstyle] = useState("hidden");
  const [formdata, setformdata] = useState({
    loc_name: "",
    p_question: "",
    question: "",
    orderno: "",
    type: "",
    option1: "",
    option2: "",
    option3: "",
    option4: "",
    option5: "",
    option6: "",
    option7: "",
    option8: "",
    option9: "",
    option10: "",
    option11: "",
    option12: "",
    option13: "",
    option14: "",
    option15: "",
    option16: "",
    option17: "",
    option18: "",
    option19: "",
    option20: "",
    option21: "",
    option22: "",
    option23: "",
    option24: "",
    option25: "",
  });

  const [inputFields, setInputFields] = useState([
    {
      subquestion: "",
    },
  ]);

  const validateForm = () => {
    let data = {
      client_name1: formdata.client_name1,
      loc_name: formdata.loc_name,
      p_question: formdata.p_question,
      question: formdata.question,
      required: formdata.required,
    };

    let rules = {
      loc_name: "required",
      required: "required",
    };

    let validation = new Validator(data, rules);

    if (validation.passes()) {
      return true;
    }
  };

  useEffect(() => {
    setformdata(props.data);
    //console.log("props.client");
    //console.log(props);
  }, [props.data]);

  useEffect(() => {
    if (props.subquestion.length > 0) {
      setInputFields(props.subquestion);
    }
  }, [props.subquestion]);

  useEffect(() => {
    if (formdata.sitelink === 1) {
      setstyle("show");
    } else {
      setstyle("hidden");
    }
  }, [formdata.sitelink]);

  const back = () => {
    props.history.push({
      pathname:
        "/view_question/" +
        localStorage.getItem("survey_id") +
        "/" +
        localStorage.getItem("clientid"),
    });
  };
  const handleChange = (event) => {
    setformdata({ ...formdata, [event.target.id]: event.target.value });
  };

  const handleInputChange = (index, event) => {
    const values = [...inputFields];
    values[index].subquestion = event.target.value;

    setInputFields(values);
  };

  const handleAddFields = () => {
    const values = [...inputFields];
    values.push({});
    setInputFields(values);
  };

  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    //console.log(values);
    setInputFields(values);
  };

  const formSubmit = async () => {
    var postData = {
      client_id: props.client_id,
      client_name1: formdata.client_name1,
      loc_name: formdata.loc_name,
      p_question: formdata.p_question,
      question: formdata.question,
      orderno: formdata.orderno,
      type: formdata.type,
      option1: formdata.option1,
      option2: formdata.option2,
      option3: formdata.option3,
      option4: formdata.option4,
      option5: formdata.option5,
      option6: formdata.option6,
      option7: formdata.option7,
      option8: formdata.option8,
      option9: formdata.option9,
      option10: formdata.option10,
      option11: formdata.option11,
      option12: formdata.option12,
      option13: formdata.option13,
      option14: formdata.option14,
      option15: formdata.option15,
      option16: formdata.option16,
      option17: formdata.option17,
      option18: formdata.option18,
      option19: formdata.option19,
      option20: formdata.option20,
      option21: formdata.option21,
      option22: formdata.option22,
      option23: formdata.option23,
      option24: formdata.option24,
      option25: formdata.option25,
      subquestion: inputFields,
      survey_id: localStorage.getItem("survey_id"),
      survey_name: localStorage.getItem("url_field"),
      id: props.id,
      required: formdata.required
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };
    const apiedit = API + "api/question_update";
    const apiadd = API + "api/addquestion";
    if (props.id) {
      axios
        .post(apiedit, postData, axiosConfig)
        .then((res) => {
          toast(res.data.message);
          props.history.push({
            pathname:
              "/view_question/" +
              localStorage.getItem("survey_id") +
              "/" +
              props.data.client_id,
          });
        })
        .catch((err) => {
          //console.log("AXIOS ERROR: ", err);
        });
    } else {
      axios
        .post(apiadd, postData, axiosConfig)
        .then((res) => {
          toast(res.data.message);
          props.history.push({
            pathname:
              "/view_question/" +
              localStorage.getItem("survey_id") +
              "/" +
              props.client_id,
          });
        })
        .catch((err) => {
          //console.log("AXIOS ERROR: ", err);
        });
    }
  };

  return (
    <div>
      <Form encType="multipart/form-data">
        <div className="review">
          <div className="row">
            <div className="col-md-12">
              <div className="card border-top-0 border-right-0 border-left-0 pb-4">
                <div className="card-header bg-white ">
                  <div className="row">
                    <div className="col-md-9">
                      <h5 className="card-title mb-0 pt-2">Question</h5>
                    </div>
                    <div className="col-md-3 text-right">
                      <input
                        type="button"
                        onClick={() => back()}
                        value="Back"
                        className="margin btn btn-primary save-btn font-weight-bold"
                      />
                      <input
                        type="button"
                        disabled={!validateForm()}
                        onClick={() => formSubmit()}
                        value="Save"
                        className="btn btn-success save-btn font-weight-bold"
                      />
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div id="introduction">
                    <div className="row">
                      <div className="form-group col-md-4 ">
                        <input
                          type="text"
                          name="client_name1"
                          id="client_name1"
                          className="form-control"
                          placeholder="Company Name "
                          value={props.client}
                          onChange={handleChange}
                          readOnly
                        />
                        <input
                          type="hidden"
                          name="id"
                          id="id"
                          className="form-control"
                          placeholder="id"
                          value={formdata.id}
                        />
                        <label>
                          Enter Company Name{" "}
                          <span className="text-danger">
                            (this field is required)
                          </span>
                        </label>
                      </div>
                      <div className="form-group col-md-4 ">
                        <select
                          className="form-control"
                          id="loc_name"
                          name="loc_name"
                          onChange={handleChange}
                        >
                          <option value="">Please Select Location :</option>
                          <option value="0">For All Locations :</option>
                          {props.location &&
                            props.location.map((item, idx) => (
                              <option
                                selected={formdata.loc_name === item.id}
                                key={idx}
                                value={item.id}
                              >
                                {item.add1 + ", " + item.city}
                              </option>
                            ))}
                        </select>
                        <label>
                          Enter Location Name
                          <span className="text-danger">
                            (this field is required)
                          </span>{" "}
                        </label>
                      </div>
                      <div className="form-group col-md-4 ">
                        <select
                          className="form-control"
                          id="required"
                          name="required"
                          onChange={handleChange}
                        >
                          <option value="0" selected={formdata.required === 0}>No </option>
                          <option value="1" selected={formdata.required === 1}>Yes </option>
                        </select>
                        <label>
                          Required
                          <span className="text-danger">
                            (this field is required)
                          </span>{" "}
                        </label>
                      </div>
                      <div
                        className={
                          props.data.id
                            ? "form-group col-md-4 show"
                            : "form-group col-md-4 hidden"
                        }
                      >
                        <select
                          className="form-control"
                          id="p_question"
                          name="p_question"
                          onChange={handleChange}
                        >
                          <option value="">Please Select Question :</option>
                          {props.question &&
                            props.question.map((item, idx) => (
                              <option
                                selected={formdata.p_question === item.id}
                                key={idx}
                                value={item.id}
                              >
                                {item.question}
                              </option>
                            ))}
                        </select>
                        <label>
                          Select Previous Question{" "}
                          <span className="text-danger">
                            (this field is required)
                          </span>
                        </label>
                      </div>

                      <div className="form-group col-md-4 ">
                        <input
                          type="text"
                          name="question"
                          id="question"
                          className="form-control"
                          placeholder="Add Question"
                          value={formdata.question}
                          onChange={handleChange}
                        />
                        <label>Add Question</label>
                      </div>

                      <div className="form-group col-md-4 ">
                        <input
                          type="number"
                          name="orderno"
                          id="orderno"
                          className="form-control"
                          placeholder="Question Order No"
                          value={formdata.orderno}
                          onChange={handleChange}
                        />
                        <label>Question Order No </label>
                      </div>

                      <div className="form-group col-md-4 ">
                        <select
                          className="form-control"
                          id="type"
                          name="type"
                          onChange={handleChange}
                        >
                          <option value="">Select Question Type :</option>
                          <option
                            selected={formdata.type === "Dropdown"}
                            key={2}
                            value="Dropdown"
                          >
                            Dropdown
                          </option>
                          <option
                            selected={formdata.type === "TextBox"}
                            key={3}
                            value="TextBox"
                          >
                            TextBox
                          </option>
                          <option
                            selected={formdata.type === "Ratings"}
                            key={4}
                            value="Ratings"
                          >
                            Ratings
                          </option>
                          <option
                            selected={formdata.type === "Radio"}
                            key={5}
                            value="Radio"
                          >
                            Single Choice(Radio Button)
                          </option>
                          <option
                            selected={formdata.type === "Grid"}
                            key={6}
                            value="Grid"
                          >
                            Series of Questions
                          </option>
                          <option
                            selected={formdata.type === "Checkbox"}
                            key={7}
                            value="Checkbox"
                          >
                            Checkbox
                          </option>
                        </select>
                        <label>Question Type</label>
                      </div>

                      <div
                        id="sub_question"
                        className={
                          formdata.type !== "Grid"
                            ? "hidden col-md-12"
                            : "show col-md-12"
                        }
                      >
                        {inputFields.map((inputField, index) => (
                          <Fragment key={`${inputField}~${index}`}>
                            <div className="row col-md-12">
                              <div className="form-group col-md-10 ">
                                <input
                                  type="text"
                                  name="subquestion"
                                  id="subquestion"
                                  className="form-control"
                                  placeholder="Add SubQuestions "
                                  value={inputField.subquestion}
                                  onChange={(event) =>
                                    handleInputChange(index, event)
                                  }
                                />
                                <label> Add SubQuestions </label>
                              </div>

                              <div className="form-group col ">
                                <button
                                  className="btn btn-danger"
                                  type="button"
                                  onClick={() => handleRemoveFields(index)}
                                >
                                  -
                                </button>
                                <button
                                  className="btn btn-info"
                                  type="button"
                                  onClick={() => handleAddFields()}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          </Fragment>
                        ))}
                      </div>

                      <div
                        id="dropdown"
                        className={
                          formdata.type !== "Dropdown" &&
                          formdata.type !== "Checkbox" &&
                          formdata.type !== "Grid" &&
                          formdata.type !== "Radio"
                            ? "hidden col-md-12"
                            : "show col-md-12"
                        }
                      >
                        <div className="row col-md-12">
                          <div className="form-group col-md-10 ">
                            <input
                              type="text"
                              name="option1"
                              id="option1"
                              className="form-control"
                              placeholder="Option1 "
                              value={formdata.option1}
                              onChange={handleChange}
                            />
                            <label> Option1 </label>
                          </div>
                          <div className="form-group col-md-10 ">
                            <input
                              type="text"
                              name="option2"
                              id="option2"
                              className="form-control"
                              placeholder="Option2 "
                              value={formdata.option2}
                              onChange={handleChange}
                            />
                            <label> Option2 </label>
                          </div>
                          <div className="form-group col-md-10 ">
                            <input
                              type="text"
                              name="option3"
                              id="option3"
                              className="form-control"
                              placeholder="Option3 "
                              value={formdata.option3}
                              onChange={handleChange}
                            />
                            <label> Option3 </label>
                          </div>
                          <div className="form-group col-md-10 ">
                            <input
                              type="text"
                              name="option4"
                              id="option4"
                              className="form-control"
                              placeholder="Option4 "
                              value={formdata.option4}
                              onChange={handleChange}
                            />
                            <label> Option4 </label>
                          </div>
                          <div className="form-group col-md-10 ">
                            <input
                              type="text"
                              name="option5"
                              id="option5"
                              className="form-control"
                              placeholder="Option5 "
                              value={formdata.option5}
                              onChange={handleChange}
                            />
                            <label> Option5 </label>
                          </div>
                          <div className="form-group col-md-10 ">
                            <input
                              type="text"
                              name="option6"
                              id="option6"
                              className="form-control"
                              placeholder="Option6 "
                              value={formdata.option6}
                              onChange={handleChange}
                            />
                            <label> Option6 </label>
                          </div>
                          <div className="form-group col-md-10 ">
                            <input
                              type="text"
                              name="option7"
                              id="option7"
                              className="form-control"
                              placeholder="Option7 "
                              value={formdata.option7}
                              onChange={handleChange}
                            />
                            <label> Option7 </label>
                          </div>
                          <div className="form-group col-md-10 ">
                            <input
                              type="text"
                              name="option8"
                              id="option8"
                              className="form-control"
                              placeholder="Option8 "
                              value={formdata.option8}
                              onChange={handleChange}
                            />
                            <label> Option8 </label>
                          </div>
                          <div className="form-group col-md-10 ">
                            <input
                              type="text"
                              name="option9"
                              id="option9"
                              className="form-control"
                              placeholder="Option9 "
                              value={formdata.option9}
                              onChange={handleChange}
                            />
                            <label> Option9 </label>
                          </div>
                          <div className="form-group col-md-10 ">
                            <input
                              type="text"
                              name="option10"
                              id="option10"
                              className="form-control"
                              placeholder="Option10 "
                              value={formdata.option10}
                              onChange={handleChange}
                            />
                            <label> Option10 </label>
                          </div>
                          <div className="form-group col-md-10 ">
                            <input
                              type="text"
                              name="option11"
                              id="option11"
                              className="form-control"
                              placeholder="Option11 "
                              value={formdata.option11}
                              onChange={handleChange}
                            />
                            <label> Option11 </label>
                          </div>
                          <div className="form-group col-md-10 ">
                            <input
                              type="text"
                              name="option12"
                              id="option12"
                              className="form-control"
                              placeholder="Option12 "
                              value={formdata.option12}
                              onChange={handleChange}
                            />
                            <label> Option12 </label>
                          </div>
                          <div className="form-group col-md-10 ">
                            <input
                              type="text"
                              name="option13"
                              id="option13"
                              className="form-control"
                              placeholder="Option13 "
                              value={formdata.option13}
                              onChange={handleChange}
                            />
                            <label> Option13 </label>
                          </div>
                          <div className="form-group col-md-10 ">
                            <input
                              type="text"
                              name="option14"
                              id="option14"
                              className="form-control"
                              placeholder="Option14 "
                              value={formdata.option14}
                              onChange={handleChange}
                            />
                            <label> Option14 </label>
                          </div>
                          <div className="form-group col-md-10 ">
                            <input
                              type="text"
                              name="option15"
                              id="option15"
                              className="form-control"
                              placeholder="Option15 "
                              value={formdata.option15}
                              onChange={handleChange}
                            />
                            <label> Option15 </label>
                          </div>
                          <div className="form-group col-md-10 ">
                            <input
                              type="text"
                              name="option16"
                              id="option16"
                              className="form-control"
                              placeholder="Option16 "
                              value={formdata.option16}
                              onChange={handleChange}
                            />
                            <label> Option16 </label>
                          </div>
                          <div className="form-group col-md-10 ">
                            <input
                              type="text"
                              name="option17"
                              id="option17"
                              className="form-control"
                              placeholder="Option17 "
                              value={formdata.option17}
                              onChange={handleChange}
                            />
                            <label> Option17 </label>
                          </div>
                          <div className="form-group col-md-10 ">
                            <input
                              type="text"
                              name="option18"
                              id="option18"
                              className="form-control"
                              placeholder="Option18 "
                              value={formdata.option18}
                              onChange={handleChange}
                            />
                            <label> Option18 </label>
                          </div>
                          <div className="form-group col-md-10 ">
                            <input
                              type="text"
                              name="option19"
                              id="option19"
                              className="form-control"
                              placeholder="Option19 "
                              value={formdata.option19}
                              onChange={handleChange}
                            />
                            <label> Option19 </label>
                          </div>
                          <div className="form-group col-md-10 ">
                            <input
                              type="text"
                              name="option20"
                              id="option20"
                              className="form-control"
                              placeholder="Option20 "
                              value={formdata.option20}
                              onChange={handleChange}
                            />
                            <label> Option20 </label>
                          </div>
                          <div className="form-group col-md-10 ">
                            <input
                              type="text"
                              name="option21"
                              id="option21"
                              className="form-control"
                              placeholder="Option21 "
                              value={formdata.option21}
                              onChange={handleChange}
                            />
                            <label> Option21 </label>
                          </div>
                          <div className="form-group col-md-10 ">
                            <input
                              type="text"
                              name="option22"
                              id="option22"
                              className="form-control"
                              placeholder="Option22 "
                              value={formdata.option22}
                              onChange={handleChange}
                            />
                            <label> Option22 </label>
                          </div>
                          <div className="form-group col-md-10 ">
                            <input
                              type="text"
                              name="option23"
                              id="option23"
                              className="form-control"
                              placeholder="Option23 "
                              value={formdata.option23}
                              onChange={handleChange}
                            />
                            <label> Option23 </label>
                          </div>
                          <div className="form-group col-md-10 ">
                            <input
                              type="text"
                              name="option24"
                              id="option24"
                              className="form-control"
                              placeholder="Option24 "
                              value={formdata.option24}
                              onChange={handleChange}
                            />
                            <label> Option24 </label>
                          </div>
                          <div className="form-group col-md-10 ">
                            <input
                              type="text"
                              name="option25"
                              id="option25"
                              className="form-control"
                              placeholder="Option25 "
                              value={formdata.option25}
                              onChange={handleChange}
                            />
                            <label> Option25 </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};
export default Clientform;
