import React, { useEffect, useState } from "react";
import axios from "axios";
import { API } from "../../../../const";
import { RiseLoader } from "react-spinners";
import CodeExample from "../../../partials/content/CodeExample";
import LoadingOverlay from "react-loading-overlay";
import {
  Legend,
  RadialBarChart,
  RadialBar,
  BarChart,
  Bar,
  XAxis,
  Sector,
  YAxis,
  CartesianGrid,
  Tooltip,
  PieChart,
  Pie,
  ResponsiveContainer,
} from "recharts";
// import { user_getby_clientidpost, location_by_client_id, review_report, review_report_search, review_report_monthwise, location_by_client_id_locationid } from '../constants/Api'

export default function ReviewActivityReportScreen(props) {
  const [isShowLoader, setShowLoader] = useState(false);

  const [userList, setuserList] = useState([]);
  const [locationList, setlocationList] = useState([]);
  const [mainlocationList, setmainlocationList] = useState([]);

  const user_getby_clientidpost = API + "api/user_getby_clientidpost/";
  const location_by_client_id = API + "api/location_by_client_id/";
  const review_report = API + "api/review_report/";
  const review_report_search = API + "api/review_report_search/";
  const review_report_monthwise = API + "api/review_report_monthwise/";
  const review_report_monthwise_search =
    API + "api/review_report_monthwise_search/";
  const location_by_client_id_locationid =
    API + "api/location_by_client_id_locationid/";

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  const [userpickervalue, setuserpickervalue] = useState({
    id: "",
    name: "",
  });

  const style = {
    top: 0,
    left: 350,
    lineHeight: "24px",
  };

  const [locationpickervalue, setlocationpickervalue] = useState({
    id: "",
    name: "",
  });

  const [yearpickervalue, setyearpickervalue] = useState({
    id: "",
    name: "",
  });

  var radio_props_years = [
    { label: "2011", value: 0 },
    { label: "2012", value: 1 },
    { label: "2013", value: 2 },
    { label: "2014", value: 3 },
    { label: "2015", value: 4 },
    { label: "2016", value: 5 },
    { label: "2017", value: 6 },
    { label: "2018", value: 7 },
    { label: "2019", value: 8 },
    { label: "2019", value: 9 },
    { label: "2020", value: 10 },
  ];

  const [dataLocation, setDataLocation] = useState({});

  const [data, setdata] = useState([]);
  const [dataList, setdataList] = useState([]);

  const [datafb, setdatafb] = useState([]);
  const [dataColorfbList, setdataColorfbList] = useState([]);

  const [datafb2, setdatafb2] = useState([]);
  const [dataColorfb2List, setdataColorfb2List] = useState([]);

  const [datagoogle, setdatagoogle] = useState([]);
  const [dataColorgoogleList, setdataColorgoogleList] = useState([]);

  const [datagoogle2, setdatagoogle2] = useState([]);
  const [dataColorgoogle2List, setdataColorgoogle2List] = useState([]);

  const [datayelp, setdatayelp] = useState([]);
  const [dataColoryelpList, setdataColoryelpList] = useState([]);

  const [datazillow, setdatazillow] = useState([]);
  const [dataColorzillowList, setdataColorzillowList] = useState([]);

  const [datayp, setdatayp] = useState([]);
  const [dataColorypList, setdataColorypList] = useState([]);

  const [datatrip, setdatatrip] = useState([]);
  const [dataColortripList, setdataColortripList] = useState([]);

  const [datayell, setdatayell] = useState([]);
  const [dataColoryellList, setdataColoryellList] = useState([]);

  const [datasf, setdatasf] = useState([]);
  const [dataColorsfList, setdataColorsfList] = useState([]);

  const [datareal, setdatareal] = useState([]);
  const [dataColorrealList, setdataColorrealList] = useState([]);

  const [dataapar, setdataapar] = useState([]);
  const [dataColoraparList, setdataColoraparList] = useState([]);

  const [dataaparrate, setdataaparrate] = useState([]);
  const [dataColoraparrateList, setdataColoraparrateList] = useState([]);

  const [datagreat_schools, setdatagreat_schools] = useState([]);
  const [dataColorgreat_schoolsList, setdataColorgreat_schoolsList] = useState(
    []
  );

  const [datahealthgrades, setdatahealthgrades] = useState([]);
  const [dataColorhealthgradesList, setdataColorhealthgradesList] = useState(
    []
  );

  const [datamysite, setdatamysite] = useState([]);
  const [dataColormysiteList, setdataColormysiteList] = useState([]);

  const [datathumbtack, setdatathumbtack] = useState([]);
  const [dataColorthumbtackList, setdataColorthumbtackList] = useState([]);

  const [dataamazon, setdataamazon] = useState([]);
  const [dataColoramazonList, setdataColoramazonList] = useState([]);

  const [monthwiseList, setmonthwiseList] = useState([]);

  const [activeIndex, setactiveIndex] = useState(0);

  const onPieEnter = (data, index) => {
    setactiveIndex(index);
  };

  useEffect(() => {
    CallUserListAPI();
    CallLocationListAPI();
    CallReportAPI();
    CallMonthWiseReportAPI();
  }, []);

  const CallUserListAPI = async () => {
    var client_id = await localStorage.getItem("client_id");

    var post = {
      id: client_id,
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };
    axios
      .post(user_getby_clientidpost, post, axiosConfig)
      .then((responseData) => {
        if (responseData.status) {
          console.log(
            "User List Response = " + JSON.stringify(responseData.data));

          var dataTemp = [];
          for (let i = 0; i < responseData.data.length; i++) {
            var object = responseData.data[i];
            dataTemp.push({ label: String(object.name), value: object.id });
          }
          setuserList(dataTemp);

          setShowLoader(false);
        } else {
          setShowLoader(false);
          alert(responseData.data);
        }
      });
  };

  const CallLocationListAPI = async () => {
    var client_id = await localStorage.getItem("client_id");

    var post = {
      client_id: client_id,
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };
    axios
      .post(location_by_client_id, post, axiosConfig)
      .then((responseData) => {
        if (responseData.status) {
          console.log(
            "Location List Response = " +
              JSON.stringify(responseData.data.location)
          );

          setmainlocationList(responseData.data.location);

          var dataTemp = [];
          for (let i = 0; i < responseData.data.location.length; i++) {
            var object = responseData.data.location[i];
            dataTemp.push({
              label: String(object.add1 + "" + object.city),
              value: object.id,
            });
          }
          setlocationList(dataTemp);

          setShowLoader(false);
        } else {
          setShowLoader(false);
          alert(responseData.data);
        }
      });
  };

  const handleChangeForUserList = (value) => {
    value = value.target.value;
    console.log("select username Id = " + value);

    var selectedValue = "";
    for (let i = 0; i < userList.length; i++) {
      if (userList[i].value === value) {
        selectedValue = userList[i].label;
        console.log("Name : " + selectedValue);
      }
    }

    if (value != null) {
      setuserpickervalue({
        ...userpickervalue,
        ["id"]: value,
        ["name"]: selectedValue,
      });
    } else {
      setuserpickervalue({ ...userpickervalue, ["id"]: 0, ["name"]: "" });
    }
  };

  const handleChangeForLocationList = (value) => {
    value = value.target.value;
    console.log("select location Id = " + value);

    var selectedValue = "";
    for (let i = 0; i < locationList.length; i++) {
      var object = mainlocationList[i];

      if (object.id === value) {
        selectedValue = String(object.add1 + " " + object.city);
        console.log("Location name : " + selectedValue);
      }
    }

    if (value != null) {
      setlocationpickervalue({
        ...locationpickervalue,
        ["id"]: value,
        ["name"]: selectedValue,
      });
      console.log("locationpickervalue");
      console.log(locationpickervalue);
    } else {
      setlocationpickervalue({
        ...locationpickervalue,
        ["id"]: 0,
        ["name"]: "",
      });
    }
  };

  const handleChangeForOptionList = (value) => {
    value = value.target.value;
    console.log("selected value = " + value);

    var selectedValue = "";
    for (let i = 0; i < radio_props_years.length; i++) {
      var object = radio_props_years[i];

      if (object.value == value) {
        selectedValue = String(object.label);
        console.log("Year name : " + selectedValue);
      }
    }

    if (value != null) {
      setyearpickervalue({
        ...yearpickervalue,
        ["id"]: 0,
        ["name"]: selectedValue,
      });
    } else {
      setyearpickervalue({ ...yearpickervalue, ["id"]: 0, ["name"]: "" });
    }
  };

  const CallReportAPI = async () => {
    var client_id = await localStorage.getItem("client_id");
    var client_name = await localStorage.getItem("client_name");

    var post = {
      client_id: client_id,
      client_name: client_name,
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };
    axios.post(review_report, post, axiosConfig).then((responseData) => {
      if (responseData.status) {
        console.log(
          "Review Report List Response = " + JSON.stringify(responseData.data)
        );

        var dataTemp = [];
        var dataTempList = [];

        dataTemp.push({
          name: "total : " + responseData.data.total,
          value: responseData.data.total,
          fill: "#DDDF00",
          arc: { padAngle: 0.0 },
        });
        dataTempList.push({
          name: "total",
          value: responseData.data.total,
          color: "#DDDF00",
        });

        dataTemp.push({
          name: "clicks : " + responseData.data.clicks,
          value: responseData.data.clicks,
          fill: "#FF4500",
          arc: { padAngle: 0.0 },
        });
        dataTempList.push({
          name: "clicks",
          value: responseData.data.total,
          color: "#FF4500",
        });

        dataTemp.push({
          name: "feedback :" + responseData.data.feedback,
          value: responseData.data.feedback,
          fill: "#008000",
          arc: { padAngle: 0.0 },
        });
        dataTempList.push({
          name: "feedback",
          value: responseData.data.total,
          color: "#008000",
        });

        setdata(dataTemp);
        setdataList(dataTempList);
        console.log(dataTempList);
        setShowLoader(false);
      } else {
        setShowLoader(false);
        alert(responseData.data);
      }
    });
  };

  const CallMonthWiseReportAPI = async () => {
    var client_id = await localStorage.getItem("client_id");
    var client_name = await localStorage.getItem("client_name");

    var post = {
      client_id: client_id,
      client_name: client_name,
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };
    axios
      .post(review_report_monthwise, post, axiosConfig)
      .then((responseData) => {
        if (responseData.status) {
          //   console.log(
          //     "Review month wise Report List Response = " +
          //       JSON.stringify(responseData.data)
          //   );

          var dataTemp = [];

          var dataTempnull = [
            {
              month: 1,
              count: 0,
              label: "Jan",
            },
            {
              month: 2,
              count: 0,
              label: "Feb",
            },
            {
              month: 3,
              count: 0,
              label: "Mar",
            },
            {
              month: 4,
              count: 0,
              label: "Apr",
            },
            {
              month: 5,
              count: 0,
              label: "May",
            },
            {
              month: 6,
              count: 0,
              label: "Jun",
            },
            {
              month: 7,
              count: 0,
              label: "July",
            },
            {
              month: 8,
              count: 0,
              label: "Aug",
            },
            {
              month: 9,
              count: 0,
              label: "Sept",
            },
            {
              month: 10,
              count: 0,
              label: "Oct",
            },
            {
              month: 11,
              count: 0,
              label: "Nov",
            },
            {
              month: 12,
              count: 0,
              label: "Dec",
            },
          ];

          if (responseData.data.total) {
            var object = responseData.data;
            dataTemp.push(
              {
                name: "Jan",
                count: object.total[0].count.toFixed(2),
                clicks: object.clicks ? object.clicks[0].count.toFixed(2) : 0,
                feedback: object.feedback ? object.feedback[0].count.toFixed(2) : 0,
              },
              {
                name: "Feb",
                count: object.total[1].count.toFixed(2),
                clicks: object.clicks ? object.clicks[1].count.toFixed(2) : 0,
                feedback: object.feedback ? object.feedback[1].count.toFixed(2) : 0,
              },
              {
                name: "Mar",
                count: object.total[2].count.toFixed(2),
                clicks: object.clicks ? object.clicks[2].count.toFixed(2) : 0,
                feedback: object.feedback ? object.feedback[2].count.toFixed(2) : 0,
              },
              {
                name: "April",
                count: object.total[3].count.toFixed(2),
                clicks: object.clicks ? object.clicks[3].count.toFixed(2) : 0,
                feedback: object.feedback ? object.feedback[3].count.toFixed(2) : 0,
              },
              {
                name: "May",
                count: object.total[4].count.toFixed(2),
                clicks: object.clicks ? object.clicks[4].count.toFixed(2) : 0,
                feedback: object.feedback ? object.feedback[4].count.toFixed(2) : 0,
              },
              {
                name: "June",
                count: object.total[5].count.toFixed(2),
                clicks: object.clicks ? object.clicks[5].count.toFixed(2) : 0,
                feedback: object.feedback ? object.feedback[5].count.toFixed(2) : 0,
              },
              {
                name: "July",
                count: object.total[6].count.toFixed(2),
                clicks: object.clicks ? object.clicks[6].count.toFixed(2) : 0,
                feedback: object.feedback ? object.feedback[6].count.toFixed(2) : 0,
              },
              {
                name: "Aug",
                count: object.total[7].count.toFixed(2),
                clicks: object.clicks ? object.clicks[7].count.toFixed(2) : 0,
                feedback: object.feedback ? object.feedback[7].count.toFixed(2) : 0,
              },
              {
                name: "Sep",
                count: object.total[8].count.toFixed(2),
                clicks: object.clicks ? object.clicks[8].count.toFixed(2) : 0,
                feedback: object.feedback ? object.feedback[8].count.toFixed(2) : 0,
              },
              {
                name: "Oct",
                count: object.total[9].count.toFixed(2),
                clicks: object.clicks ? object.clicks[9].count.toFixed(2) : 0,
                feedback: object.feedback ? object.feedback[9].count.toFixed(2) : 0,
              },
              {
                name: "Nov",
                count: object.total[10].count.toFixed(2),
                clicks: object.clicks ? object.clicks[10].count.toFixed(2) : 0,
                feedback: object.feedback ? object.feedback[10].count.toFixed(2) : 0,
              },
              {
                name: "Dec",
                count: object.total[11].count.toFixed(2),
                clicks: object.clicks ? object.clicks[11].count.toFixed(2) : 0,
                feedback: object.feedback ? object.feedback[11].count.toFixed(2) : 0,
              }
            );

            setmonthwiseList(dataTemp);
            console.log("Monthwise : Data");
            console.log(dataTemp);
          } else {
            setmonthwiseList(dataTemp);
            console.log("Monthwise : Data");
            setmonthwiseList(dataTemp);
          }

          setShowLoader(false);
        } else {
          setShowLoader(false);
          alert(responseData.data);
        }
      });
  };

  const CallMonthWiseReportAPISearch = async () => {
    var client_id = await localStorage.getItem("client_id");
    var client_name = await localStorage.getItem("client_name");

    var post = {
      client_id: client_id,
      client_name: client_name,
      location: locationpickervalue.id,
      user: userpickervalue.id,
      year: yearpickervalue.name,
    };

    setShowLoader(true);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };
    axios
      .post(review_report_monthwise_search, post, axiosConfig)
      .then((responseData) => {
        if (responseData.status) {
          //   console.log(
          //     "Review month wise Report List Response = " +
          //       JSON.stringify(responseData.data)
          //   );

          var dataTemp = [];
          if (responseData.data.total) {
            var object = responseData.data;
            dataTemp.push(
              {
                name: "Jan",
                count: object.total[0].count.toFixed(2),
                clicks: object.clicks ? object.clicks[0].count.toFixed(2) : 0,
                feedback: object.feedback ? object.feedback[0].count.toFixed(2) : 0,
              },
              {
                name: "Feb",
                count: object.total[1].count.toFixed(2),
                clicks: object.clicks ? object.clicks[1].count.toFixed(2) : 0,
                feedback: object.feedback ? object.feedback[1].count.toFixed(2) : 0,
              },
              {
                name: "Mar",
                count: object.total[2].count.toFixed(2),
                clicks: object.clicks ? object.clicks[2].count.toFixed(2) : 0,
                feedback: object.feedback ? object.feedback[2].count.toFixed(2) : 0,
              },
              {
                name: "April",
                count: object.total[3].count.toFixed(2),
                clicks: object.clicks ? object.clicks[3].count.toFixed(2) : 0,
                feedback: object.feedback ? object.feedback[3].count.toFixed(2) : 0,
              },
              {
                name: "May",
                count: object.total[4].count.toFixed(2),
                clicks: object.clicks ? object.clicks[4].count.toFixed(2) : 0,
                feedback: object.feedback ? object.feedback[4].count.toFixed(2) : 0,
              },
              {
                name: "June",
                count: object.total[5].count.toFixed(2),
                clicks: object.clicks ? object.clicks[5].count.toFixed(2) : 0,
                feedback: object.feedback ? object.feedback[5].count.toFixed(2) : 0,
              },
              {
                name: "July",
                count: object.total[6].count.toFixed(2),
                clicks: object.clicks ? object.clicks[6].count.toFixed(2) : 0,
                feedback: object.feedback ? object.feedback[6].count.toFixed(2) : 0,
              },
              {
                name: "Aug",
                count: object.total[7].count.toFixed(2),
                clicks: object.clicks ? object.clicks[7].count.toFixed(2) : 0,
                feedback: object.feedback ? object.feedback[7].count.toFixed(2) : 0,
              },
              {
                name: "Sep",
                count: object.total[8].count.toFixed(2),
                clicks: object.clicks ? object.clicks[8].count.toFixed(2) : 0,
                feedback: object.feedback ? object.feedback[8].count.toFixed(2) : 0,
              },
              {
                name: "Oct",
                count: object.total[9].count.toFixed(2),
                clicks: object.clicks ? object.clicks[9].count.toFixed(2) : 0,
                feedback: object.feedback ? object.feedback[9].count.toFixed(2) : 0,
              },
              {
                name: "Nov",
                count: object.total[10].count.toFixed(2),
                clicks: object.clicks ? object.clicks[10].count.toFixed(2) : 0,
                feedback: object.feedback ? object.feedback[10].count.toFixed(2) : 0,
              },
              {
                name: "Dec",
                count: object.total[11].count.toFixed(2),
                clicks: object.clicks ? object.clicks[11].count.toFixed(2) : 0,
                feedback: object.feedback ? object.feedback[11].count.toFixed(2) : 0,
              }
            );

            setmonthwiseList(dataTemp);
            console.log("Monthwise : Data");
            console.log(dataTemp);
          } else {
            setmonthwiseList(dataTemp);
            console.log("Monthwise : Data");
            setmonthwiseList(dataTemp);
          }

          setShowLoader(false);
        } else {
          setShowLoader(false);
          alert(responseData.data);
        }
      });
  };

  const CallSearchReportAPI = async () => {
    // call location attibute show/hide

    if (
      locationpickervalue.id != "" &&
      locationpickervalue.id != null &&
      locationpickervalue.id != "Select Location"
    ) {
      CallLocationAttributes(locationpickervalue.id);
    }

    CallMonthWiseReportAPISearch();

    var client_id = await localStorage.getItem("client_id");

    var post = {
      client_id: client_id,
      location: locationpickervalue.id,
      user: userpickervalue.id,
      year: yearpickervalue.name,
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };
    axios.post(review_report_search, post, axiosConfig).then((responseData) => {
      if (responseData.status) {
        console.log(
          "Review Search Report List Response = " +
            JSON.stringify(responseData.data)
        );

        // facebook

        var dataTempfb = [];
        var dataTempColorfbList = [];
        var dataTemp = [];
        var dataTempList = [];

        dataTemp.push({
          name: "total : " + responseData.data.total,
          value: responseData.data.total,
          fill: "#DDDF00",
          arc: { padAngle: 0.0 },
        });
        dataTempList.push({
          name: "total",
          value: responseData.data.total,
          color: "#DDDF00",
        });

        dataTemp.push({
          name: "clicks : " + responseData.data.clicks,
          value: responseData.data.clicks,
          fill: "#FF4500",
          arc: { padAngle: 0.0 },
        });
        dataTempList.push({
          name: "clicks",
          value: responseData.data.total,
          color: "#FF4500",
        });

        dataTemp.push({
          name: "feedback :" + responseData.data.feedback,
          value: responseData.data.feedback,
          fill: "#008000",
          arc: { padAngle: 0.0 },
        });
        dataTempList.push({
          name: "feedback",
          value: responseData.data.total,
          color: "#008000",
        });

        setdata(dataTemp);
        setdataList(dataTempList);
        console.log(dataTempList);
        setShowLoader(false);

        // total
        dataTempfb.push({
          name: "total",
          value: responseData.data.total,
          svg: { fill: "#FF4500" },
          arc: { padAngle: 0.0 },
        });
        dataTempColorfbList.push({
          name: "Total - Review Link Sent",
          value: responseData.data.total,
          color: "#FF4500",
        });

        // fb
        dataTempfb.push({
          name: "facebook",
          value: responseData.data.fb,
          svg: { fill: "#008000" },
          arc: { padAngle: 0.0 },
        });
        dataTempColorfbList.push({
          name: "facebook",
          value: responseData.data.fb,
          color: "#008000",
        });

        setdatafb(dataTempfb);
        console.log("datafb");
        console.log(datafb);
        setdataColorfbList(dataTempColorfbList);

        // facebook 2

        var dataTempfb2 = [];
        var dataTempColorfb2List = [];

        // total
        dataTempfb2.push({
          name: "total",
          value: responseData.data.total,
          svg: { fill: "#FF4500" },
          arc: { padAngle: 0.0 },
        });
        dataTempColorfb2List.push({
          name: "Total - Review Link Sent",
          value: responseData.data.total,
          color: "#FF4500",
        });

        // fb
        dataTempfb2.push({
          name: "facebook 2",
          value: responseData.data.fb2,
          svg: { fill: "#008000" },
          arc: { padAngle: 0.0 },
        });
        dataTempColorfb2List.push({
          name: "facebook 2",
          value: responseData.data.fb2,
          color: "#008000",
        });

        setdatafb2(dataTempfb2);
        setdataColorfb2List(dataTempColorfb2List);

        // google

        var dataTempgoogle = [];
        var dataTempColorgoogleList = [];

        // total
        dataTempgoogle.push({
          name: "total",
          value: responseData.data.total,
          svg: { fill: "#FF4500" },
          arc: { padAngle: 0.0 },
        });
        dataTempColorgoogleList.push({
          name: "Total - Review Link Sent",
          value: responseData.data.total,
          color: "#FF4500",
        });

        // google
        dataTempgoogle.push({
          name: "google",
          value: responseData.data.google,
          svg: { fill: "#008000" },
          arc: { padAngle: 0.0 },
        });
        dataTempColorgoogleList.push({
          name: "google",
          value: responseData.data.google,
          color: "#008000",
        });

        setdatagoogle(dataTempgoogle);
        setdataColorgoogleList(dataTempColorgoogleList);

        // google 2

        var dataTempgoogle2 = [];
        var dataTempColorgoogle2List = [];

        // total
        dataTempgoogle2.push({
          name: "total",
          value: responseData.data.total,
          svg: { fill: "#FF4500" },
          arc: { padAngle: 0.0 },
        });
        dataTempColorgoogle2List.push({
          name: "Total - Review Link Sent",
          value: responseData.data.total,
          color: "#FF4500",
        });

        // google
        dataTempgoogle.push({
          name: "google",
          value: responseData.data.google2,
          svg: { fill: "#008000" },
          arc: { padAngle: 0.0 },
        });
        dataTempColorgoogle2List.push({
          name: "google",
          value: responseData.data.google2,
          color: "#008000",
        });

        setdatagoogle2(dataTempgoogle2);
        setdataColorgoogle2List(dataTempColorgoogle2List);

        // yelp

        var dataTempyelp = [];
        var dataTempColoryelpList = [];

        // total
        dataTempyelp.push({
          name: "total",
          value: responseData.data.total,
          svg: { fill: "#FF4500" },
          arc: { padAngle: 0.0 },
        });
        dataTempColoryelpList.push({
          name: "Total - Review Link Sent",
          value: responseData.data.total,
          color: "#FF4500",
        });

        // yelp
        dataTempyelp.push({
          name: "yelp",
          value: responseData.data.yelp,
          svg: { fill: "#008000" },
          arc: { padAngle: 0.0 },
        });
        dataTempColoryelpList.push({
          name: "google",
          value: responseData.data.yelp,
          color: "#008000",
        });

        setdatayelp(dataTempyelp);
        setdataColoryelpList(dataTempColoryelpList);

        // zillow

        var dataTempzillow = [];
        var dataTempColorzillowList = [];

        // total
        dataTempzillow.push({
          name: "total",
          value: responseData.data.total,
          svg: { fill: "#FF4500" },
          arc: { padAngle: 0.0 },
        });
        dataTempColorzillowList.push({
          name: "Total - Review Link Sent",
          value: responseData.data.total,
          color: "#FF4500",
        });

        // zillow
        dataTempzillow.push({
          name: "zillow",
          value: responseData.data.zillow,
          svg: { fill: "#008000" },
          arc: { padAngle: 0.0 },
        });
        dataTempColorzillowList.push({
          name: "zillow",
          value: responseData.data.zillow,
          color: "#008000",
        });

        setdatazillow(dataTempzillow);
        setdataColorzillowList(dataTempColorzillowList);

        // yp

        var dataTempyp = [];
        var dataTempColorypList = [];

        // total
        dataTempyp.push({
          name: "total",
          value: responseData.data.total,
          svg: { fill: "#FF4500" },
          arc: { padAngle: 0.0 },
        });
        dataTempColorypList.push({
          name: "Total - Review Link Sent",
          value: responseData.data.total,
          color: "#FF4500",
        });

        // yp
        dataTempyp.push({
          name: "yp",
          value: responseData.data.yp,
          svg: { fill: "#008000" },
          arc: { padAngle: 0.0 },
        });
        dataTempColorypList.push({
          name: "yp",
          value: responseData.data.yp,
          color: "#008000",
        });

        setdatayp(dataTempyp);
        setdataColorypList(dataTempColorypList);

        // trip

        var dataTemptrip = [];
        var dataTempColortripList = [];

        // total
        dataTemptrip.push({
          name: "total",
          value: responseData.data.total,
          svg: { fill: "#FF4500" },
          arc: { padAngle: 0.0 },
        });
        dataTempColortripList.push({
          name: "Total - Review Link Sent",
          value: responseData.data.total,
          color: "#FF4500",
        });

        // trip
        dataTemptrip.push({
          name: "trip",
          value: responseData.data.trip,
          svg: { fill: "#008000" },
          arc: { padAngle: 0.0 },
        });
        dataTempColortripList.push({
          name: "trip",
          value: responseData.data.trip,
          color: "#008000",
        });

        setdatatrip(dataTemptrip);
        setdataColortripList(dataTempColortripList);

        // yell

        var dataTempyell = [];
        var dataTempColoryellList = [];

        // total
        dataTempyell.push({
          name: "total",
          value: responseData.data.total,
          svg: { fill: "#FF4500" },
          arc: { padAngle: 0.0 },
        });
        dataTempColoryellList.push({
          name: "Total - Review Link Sent",
          value: responseData.data.total,
          color: "#FF4500",
        });

        // yell
        dataTempyell.push({
          name: "yell",
          value: responseData.data.yell,
          svg: { fill: "#008000" },
          arc: { padAngle: 0.0 },
        });
        dataTempColoryellList.push({
          name: "yell",
          value: responseData.data.yell,
          color: "#008000",
        });

        setdatayell(dataTempyell);
        setdataColoryellList(dataTempColoryellList);

        // sf

        var dataTempsf = [];
        var dataTempColorsfList = [];

        // total
        dataTempsf.push({
          name: "total",
          value: responseData.data.total,
          svg: { fill: "#FF4500" },
          arc: { padAngle: 0.0 },
        });
        dataTempColorsfList.push({
          name: "Total - Review Link Sent",
          value: responseData.data.total,
          color: "#FF4500",
        });

        // sf
        dataTempsf.push({
          name: "sf",
          value: responseData.data.sf,
          svg: { fill: "#008000" },
          arc: { padAngle: 0.0 },
        });
        dataTempColorsfList.push({
          name: "sf",
          value: responseData.data.sf,
          color: "#008000",
        });

        setdatasf(dataTempsf);
        setdataColorsfList(dataTempColorsfList);

        // real

        var dataTempreal = [];
        var dataTempColorrealList = [];

        // total
        dataTempreal.push({
          name: "total",
          value: responseData.data.total,
          svg: { fill: "#FF4500" },
          arc: { padAngle: 0.0 },
        });
        dataTempColorrealList.push({
          name: "Total - Review Link Sent",
          value: responseData.data.total,
          color: "#FF4500",
        });

        // sf
        dataTempreal.push({
          name: "real",
          value: responseData.data.real,
          svg: { fill: "#008000" },
          arc: { padAngle: 0.0 },
        });
        dataTempColorrealList.push({
          name: "real",
          value: responseData.data.real,
          color: "#008000",
        });

        setdatareal(dataTempreal);
        setdataColorrealList(dataTempColorrealList);

        // apar

        var dataTempapar = [];
        var dataTempColoraparList = [];

        // total
        dataTempapar.push({
          name: "total",
          value: responseData.data.total,
          svg: { fill: "#FF4500" },
          arc: { padAngle: 0.0 },
        });
        dataTempColoraparList.push({
          name: "Total - Review Link Sent",
          value: responseData.data.total,
          color: "#FF4500",
        });

        // apar
        dataTempapar.push({
          name: "apar",
          value: responseData.data.apar,
          svg: { fill: "#008000" },
          arc: { padAngle: 0.0 },
        });
        dataTempColoraparList.push({
          name: "apar",
          value: responseData.data.apar,
          color: "#008000",
        });

        setdataapar(dataTempapar);
        setdataColoraparList(dataTempColoraparList);

        // aparrate

        var dataTempaparrate = [];
        var dataTempColoraparrateList = [];

        // total
        dataTempaparrate.push({
          name: "total",
          value: responseData.data.total,
          svg: { fill: "#FF4500" },
          arc: { padAngle: 0.0 },
        });
        dataTempColoraparrateList.push({
          name: "Total - Review Link Sent",
          value: responseData.data.total,
          color: "#FF4500",
        });

        // aparrate
        dataTempaparrate.push({
          name: "aparrate",
          value: responseData.data.aparrate,
          svg: { fill: "#008000" },
          arc: { padAngle: 0.0 },
        });
        dataTempColoraparrateList.push({
          name: "aparrate",
          value: responseData.data.aparrate,
          color: "#008000",
        });

        setdataaparrate(dataTempaparrate);
        setdataColoraparrateList(dataTempColoraparrateList);

        // great_schools

        var dataTempgreat_schools = [];
        var dataTempColorgreat_schoolsList = [];

        // total
        dataTempgreat_schools.push({
          name: "total",
          value: responseData.data.total,
          svg: { fill: "#FF4500" },
          arc: { padAngle: 0.0 },
        });
        dataTempColorgreat_schoolsList.push({
          name: "Total - Review Link Sent",
          value: responseData.data.total,
          color: "#FF4500",
        });

        // aparrate
        dataTempgreat_schools.push({
          name: "great_schools",
          value: responseData.data.great_schools,
          svg: { fill: "#008000" },
          arc: { padAngle: 0.0 },
        });
        dataTempColorgreat_schoolsList.push({
          name: "great_schools",
          value: responseData.data.great_schools,
          color: "#008000",
        });

        setdatagreat_schools(dataTempgreat_schools);
        setdataColorgreat_schoolsList(dataTempColorgreat_schoolsList);

        // healthgrades

        var dataTemphealthgrades = [];
        var dataTempColorhealthgradesList = [];

        // total
        dataTemphealthgrades.push({
          name: "total",
          value: responseData.data.total,
          svg: { fill: "#FF4500" },
          arc: { padAngle: 0.0 },
        });
        dataTempColorhealthgradesList.push({
          name: "Total - Review Link Sent",
          value: responseData.data.total,
          color: "#FF4500",
        });

        // healthgrades
        dataTemphealthgrades.push({
          name: "healthgrades",
          value: responseData.data.healthgrades,
          svg: { fill: "#008000" },
          arc: { padAngle: 0.0 },
        });
        dataTempColorhealthgradesList.push({
          name: "healthgrades",
          value: responseData.data.healthgrades,
          color: "#008000",
        });

        setdatahealthgrades(dataTemphealthgrades);
        setdataColorhealthgradesList(dataTempColorhealthgradesList);

        // mysite

        var dataTempmysite = [];
        var dataTempColormysiteList = [];

        // total
        dataTempmysite.push({
          name: "total",
          value: responseData.data.total,
          svg: { fill: "#FF4500" },
          arc: { padAngle: 0.0 },
        });
        dataTempColormysiteList.push({
          name: "Total - Review Link Sent",
          value: responseData.data.total,
          color: "#FF4500",
        });

        // mysite
        dataTempmysite.push({
          name: "mysite",
          value: responseData.data.mysite,
          svg: { fill: "#008000" },
          arc: { padAngle: 0.0 },
        });
        dataTempColormysiteList.push({
          name: "mysite",
          value: responseData.data.mysite,
          color: "#008000",
        });

        setdatamysite(dataTempmysite);
        setdataColormysiteList(dataTempColormysiteList);

        // thumbtack

        var dataTempthumbtack = [];
        var dataTempColorthumbtackList = [];

        // total
        dataTempthumbtack.push({
          name: "total",
          value: responseData.data.total,
          svg: { fill: "#FF4500" },
          arc: { padAngle: 0.0 },
        });
        dataTempColorthumbtackList.push({
          name: "Total - Review Link Sent",
          value: responseData.data.total,
          color: "#FF4500",
        });

        // thumbtack
        dataTempthumbtack.push({
          name: "thumbtack",
          value: responseData.data.thumbtack,
          svg: { fill: "#008000" },
          arc: { padAngle: 0.0 },
        });
        dataTempColorthumbtackList.push({
          name: "thumbtack",
          value: responseData.data.thumbtack,
          color: "#008000",
        });

        setdatathumbtack(dataTempthumbtack);
        setdataColorthumbtackList(dataTempColorthumbtackList);

        // amazon

        var dataTempamazon = [];
        var dataTempColoramazonList = [];

        // total
        dataTempamazon.push({
          name: "total",
          value: responseData.data.total,
          svg: { fill: "#FF4500" },
          arc: { padAngle: 0.0 },
        });
        dataTempColoramazonList.push({
          name: "Total - Review Link Sent",
          value: responseData.data.total,
          color: "#FF4500",
        });

        // amazon
        dataTempamazon.push({
          name: "amazon",
          value: responseData.data.amazon,
          svg: { fill: "#008000" },
          arc: { padAngle: 0.0 },
        });
        dataTempColoramazonList.push({
          name: "amazon",
          value: responseData.data.amazon,
          color: "#008000",
        });

        setdataamazon(dataTempamazon);
        setdataColoramazonList(dataTempColoramazonList);

        setShowLoader(false);
      } else {
        setShowLoader(false);
        alert(responseData.data);
      }
    });
  };

  let sampleData = [
    {
      seriesName: "series1",
      data: [
        { name: "s11", count: 30 },
        { name: "s11", count: 60 },
        { name: "s11", count: 60 },
        { name: "s11", count: 60 },
        { name: "s11", count: 60 },
        { name: "s11", count: 60 },
        { name: "s11", count: 60 },
        { name: "s11", count: 60 },
        { name: "s11", count: 60 },
        { name: "s11", count: 60 },
      ],
      color: "green",
    },
    {
      seriesName: "series2",
      data: [
        { name: "s22", count: 20 },
        { name: "s22", count: 50 },
        { name: "s22", count: 50 },
        { name: "s22", count: 50 },
        { name: "s22", count: 50 },
        { name: "s22", count: 50 },
        { name: "s22", count: 50 },
        { name: "s22", count: 50 },
        { name: "s22", count: 50 },
        { name: "s22", count: 50 },
      ],
      color: "orange",
    },
    {
      seriesName: "series3",
      data: [
        { name: "s33", count: 120 },
        { name: "s33", count: 150 },
        { name: "s33", count: 150 },
        { name: "s33", count: 150 },
        { name: "s33", count: 150 },
        { name: "s33", count: 150 },
        { name: "s33", count: 150 },
        { name: "s33", count: 150 },
        { name: "s33", count: 150 },
        { name: "s33", count: 150 },
      ],
      color: "yellow",
    },
  ];

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
        >{`PV ${value}`}</text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#999"
        >
          {`(Rate ${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };

  const CallLocationAttributes = async (value) => {
    var post = {
      id: value,
      client_id: localStorage.getItem("client_id"),
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };
    axios
      .post(location_by_client_id_locationid, post, axiosConfig)
      .then((responseData) => {
        if (responseData.status) {
          console.log(
            "Location Attribute Response = " + JSON.stringify(responseData.data)
          );

          responseData.data.location
            ? setDataLocation(responseData.data.location)
            : setDataLocation(null);
        }
      });
  };

  let user_type= localStorage.getItem("user_type");
  let user_id= localStorage.getItem("id");
  const userListing =(item, idx)=>{
    if(user_type==='employee' )
    {
      if(user_id==item.id)
      {
        return (<option key={idx} value={item.value}>
          {item.label}
        </option>);
      }
    }
    else
    {
      return (<option key={idx} value={item.value}>
        {item.label}
      </option>);
    }
  }

  return (
    <LoadingOverlay
      active={isShowLoader}
      spinner={<RiseLoader sizeUnit={"px"} size={80} color={"#2a8dd1"} />}
    >
      <>
        <div className="row">
          <div className="col-md-12">
            <CodeExample beforeCodeTitle="Review Report">
              <div className="kt-section">
                <span className="kt-section__sub"></span>
                <br></br>
                <div className="row">
                  <div className="col-4 col-md-4">
                    <select
                      className="form-control col-md-12"
                      id="location_id"
                      name="location_id"
                      onChange={handleChangeForLocationList}
                    >
                      <option value="">Select Location</option>
                      {locationList.map((item, idx) => (
                        <option key={idx} value={item.value}>
                          {" "}
                          {item.label}
                        </option>
                      ))}
                    </select>
                    <label>Location List</label>
                  </div>
                  <div className="col-4 col-md-4">
                    <select
                      className="form-control col-md-12"
                      id="location_id"
                      name="location_id"
                      onChange={handleChangeForUserList}
                    >
                      <option value="">Select User</option>
                      {/* {userList.map((item, idx) => (
                        <option key={idx} value={item.value}>
                          {" "}
                          {item.label}
                        </option>
                      ))} */}
                      {userList.map((item, idx) => userListing(item, idx))}

                    </select>
                    <label>Users List</label>
                  </div>
                  <div className="col-4 col-md-4">
                    <select
                      className="form-control col-md-12"
                      id="location_id"
                      name="location_id"
                      onChange={handleChangeForOptionList}
                    >
                      <option value="">Select Year</option>
                      {radio_props_years.map((item, idx) => (
                        <option key={idx} value={item.value}>
                          {" "}
                          {item.label}
                        </option>
                      ))}
                    </select>
                    <label>Select Year</label>
                  </div>
                  <div className="col-12 ">
                    <button
                      onClick={() => CallSearchReportAPI()}
                      className=" form-control col-md-2 btn btn-success float-right"
                    >
                      Search
                    </button>
                  </div>
                </div>
                <div className="kt-separator kt-separator--dashed responsive"></div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="background-margin">
                      <h3>Social Review Count</h3>

                      <RadialBarChart
                        width={500}
                        height={500}
                        cx={150}
                        cy={150}
                        innerRadius={20}
                        outerRadius={150}
                        barSize={10}
                        data={data}
                      >
                        <RadialBar
                          minAngle={15}
                          label=""
                          background
                          clockWise
                          dataKey="value"
                        />
                        <Legend
                          iconSize={10}
                          width={120}
                          height={140}
                          layout="vertical"
                          verticalAlign="middle"
                          wrapperStyle={style}
                        />
                      </RadialBarChart>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="background-margin monthwid">
                      <h3>Monthly Report</h3>

                      <BarChart
                        width={500}
                        height={300}
                        data={monthwiseList}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="count" fill="#8884d8" />
                        <Bar dataKey="clicks" fill="#82ca9d" />
                        <Bar dataKey="feedback" fill="#222dad" />
                      </BarChart>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {(dataLocation && dataLocation.fb_link === "undefined") ||
                  dataLocation.fb_link === "" ||
                  datafb.length <= 0 ? null : (
                    <div style={{ width: "33%", height: 500 }}>
                      <h3 className="text-center">Facebook Breakdown</h3>

                      <ResponsiveContainer>
                        <PieChart width={400} height={400}>
                          <Pie
                            activeIndex={activeIndex}
                            activeShape={renderActiveShape}
                            data={datafb}
                            cx={200}
                            cy={200}
                            innerRadius={60}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                            onMouseEnter={onPieEnter}
                          />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  )}

                  {(dataLocation && dataLocation.fb_link2 === "undefined") ||
                  dataLocation.fb_link2 === "" ||
                  datafb2.length <= 0 ? null : (
                    <div style={{ width: "33%", height: 500 }}>
                      <h3 className="text-center">Facebook 2 Breakdown</h3>

                      <ResponsiveContainer>
                        <PieChart width={400} height={400}>
                          <Pie
                            activeIndex={activeIndex}
                            activeShape={renderActiveShape}
                            data={datafb2}
                            cx={200}
                            cy={200}
                            innerRadius={60}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                            onMouseEnter={onPieEnter}
                          />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  )}

                  {(dataLocation && dataLocation.google_link === "undefined") ||
                  dataLocation.google_link === "" ||
                  datagoogle.length <= 0 ? null : (
                    <div style={{ width: "33%", height: 500 }}>
                      <h3 className="text-center">Google Breakdown</h3>

                      <ResponsiveContainer>
                        <PieChart width={400} height={400}>
                          <Pie
                            activeIndex={activeIndex}
                            activeShape={renderActiveShape}
                            data={datagoogle}
                            cx={200}
                            cy={200}
                            innerRadius={60}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                            onMouseEnter={onPieEnter}
                          />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  )}

                  {(dataLocation &&
                    dataLocation.google_link2 === "undefined") ||
                  dataLocation.google_link2 === "" ||
                  datagoogle2.length <= 0 ? null : (
                    <div style={{ width: "33%", height: 500 }}>
                      <h3 className="text-center">Google 2 Breakdown</h3>

                      <ResponsiveContainer>
                        <PieChart width={400} height={400}>
                          <Pie
                            activeIndex={activeIndex}
                            activeShape={renderActiveShape}
                            data={datagoogle2}
                            cx={200}
                            cy={200}
                            innerRadius={60}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                            onMouseEnter={onPieEnter}
                          />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  )}

                  {(dataLocation && dataLocation.yelp_link === "undefined") ||
                  dataLocation.yelp_link === "" ||
                  datayelp.length <= 0 ? null : (
                    <div style={{ width: "33%", height: 500 }}>
                      <h3 className="text-center">Yelp Breakdown</h3>

                      <ResponsiveContainer>
                        <PieChart width={400} height={400}>
                          <Pie
                            activeIndex={activeIndex}
                            activeShape={renderActiveShape}
                            data={datayelp}
                            cx={200}
                            cy={200}
                            innerRadius={60}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                            onMouseEnter={onPieEnter}
                          />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  )}

                  {(dataLocation && dataLocation.zillow_link === "undefined") ||
                  dataLocation.zillow_link === "" ||
                  datazillow.length <= 0 ? null : (
                    <div style={{ width: "33%", height: 500 }}>
                      <h3 className="text-center">Zillow Breakdown</h3>

                      <ResponsiveContainer>
                        <PieChart width={400} height={400}>
                          <Pie
                            activeIndex={activeIndex}
                            activeShape={renderActiveShape}
                            data={datazillow}
                            cx={200}
                            cy={200}
                            innerRadius={60}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                            onMouseEnter={onPieEnter}
                          />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  )}

                  {(dataLocation && dataLocation.yp_link === "undefined") ||
                  dataLocation.yp_link === "" ||
                  datayp.length <= 0 ? null : (
                    <div style={{ width: "33%", height: 500 }}>
                      <h3 className="text-center">YP Breakdown</h3>

                      <ResponsiveContainer>
                        <PieChart width={400} height={400}>
                          <Pie
                            activeIndex={activeIndex}
                            activeShape={renderActiveShape}
                            data={datayp}
                            cx={200}
                            cy={200}
                            innerRadius={60}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                            onMouseEnter={onPieEnter}
                          />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  )}

                  {(dataLocation && dataLocation.trip_link === "undefined") ||
                  dataLocation.trip_link === "" ||
                  datatrip.length <= 0 ? null : (
                    <div style={{ width: "33%", height: 500 }}>
                      <h3 className="text-center">Trip Breakdown</h3>

                      <ResponsiveContainer>
                        <PieChart width={400} height={400}>
                          <Pie
                            activeIndex={activeIndex}
                            activeShape={renderActiveShape}
                            data={datatrip}
                            cx={200}
                            cy={200}
                            innerRadius={60}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                            onMouseEnter={onPieEnter}
                          />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  )}

                  {(dataLocation && dataLocation.yell_link === "undefined") ||
                  dataLocation.yell_link === "" ||
                  datayell.length <= 0 ? null : (
                    <div style={{ width: "33%", height: 500 }}>
                      <h3 className="text-center">Yell Breakdown</h3>

                      <ResponsiveContainer>
                        <PieChart width={400} height={400}>
                          <Pie
                            activeIndex={activeIndex}
                            activeShape={renderActiveShape}
                            data={datayell}
                            cx={200}
                            cy={200}
                            innerRadius={60}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                            onMouseEnter={onPieEnter}
                          />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  )}

                  {(dataLocation && dataLocation.sf_link === "undefined") ||
                  dataLocation.sf_link === "" ||
                  datasf.length <= 0 ? null : (
                    <div style={{ width: "33%", height: 500 }}>
                      <h3 className="text-center">SF Breakdown</h3>

                      <ResponsiveContainer>
                        <PieChart width={400} height={400}>
                          <Pie
                            activeIndex={activeIndex}
                            activeShape={renderActiveShape}
                            data={datasf}
                            cx={200}
                            cy={200}
                            innerRadius={60}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                            onMouseEnter={onPieEnter}
                          />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  )}

                  {(dataLocation && dataLocation.real_link === "undefined") ||
                  dataLocation.real_link === "" ||
                  datareal.length <= 0 ? null : (
                    <div style={{ width: "33%", height: 500 }}>
                      <h3 className="text-center">Real Breakdown</h3>

                      <ResponsiveContainer>
                        <PieChart width={400} height={400}>
                          <Pie
                            activeIndex={activeIndex}
                            activeShape={renderActiveShape}
                            data={datareal}
                            cx={200}
                            cy={200}
                            innerRadius={60}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                            onMouseEnter={onPieEnter}
                          />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  )}

                  {(dataLocation && dataLocation.apar_link === "undefined") ||
                  dataLocation.apar_link === "" ||
                  dataapar.length <= 0 ? null : (
                    <div style={{ width: "33%", height: 500 }}>
                      <h3 className="text-center">Apar Breakdown</h3>

                      <ResponsiveContainer>
                        <PieChart width={400} height={400}>
                          <Pie
                            activeIndex={activeIndex}
                            activeShape={renderActiveShape}
                            data={dataapar}
                            cx={200}
                            cy={200}
                            innerRadius={60}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                            onMouseEnter={onPieEnter}
                          />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  )}

                  {(dataLocation &&
                    dataLocation.aparrate_link === "undefined") ||
                  dataLocation.aparrate_link === "" ||
                  dataaparrate.length <= 0 ? null : (
                    <div style={{ width: "33%", height: 500 }}>
                      <h3 className="text-center">Aparrate Breakdown</h3>

                      <ResponsiveContainer>
                        <PieChart width={400} height={400}>
                          <Pie
                            activeIndex={activeIndex}
                            activeShape={renderActiveShape}
                            data={dataaparrate}
                            cx={200}
                            cy={200}
                            innerRadius={60}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                            onMouseEnter={onPieEnter}
                          />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  )}

                  {(dataLocation &&
                    dataLocation.great_schools_link === "undefined") ||
                  dataLocation.great_schools_link === "" ||
                  datagreat_schools.length <= 0 ? null : (
                    <div style={{ width: "33%", height: 500 }}>
                      <h3 className="text-center">Great Schools Breakdown</h3>

                      <ResponsiveContainer>
                        <PieChart width={400} height={400}>
                          <Pie
                            activeIndex={activeIndex}
                            activeShape={renderActiveShape}
                            data={datagreat_schools}
                            cx={200}
                            cy={200}
                            innerRadius={60}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                            onMouseEnter={onPieEnter}
                          />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  )}
                  {(dataLocation &&
                    dataLocation.healthgrades_link === "undefined") ||
                  dataLocation.healthgrades_link === "" ||
                  datahealthgrades.length <= 0 ? null : (
                    <div style={{ width: "33%", height: 500 }}>
                      <h3 className="text-center">Healthgrades Breakdown</h3>

                      <ResponsiveContainer>
                        <PieChart width={400} height={400}>
                          <Pie
                            activeIndex={activeIndex}
                            activeShape={renderActiveShape}
                            data={datahealthgrades}
                            cx={200}
                            cy={200}
                            innerRadius={60}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                            onMouseEnter={onPieEnter}
                          />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  )}
                  {(dataLocation && dataLocation.mysite_link === "undefined") ||
                  dataLocation.mysite_link === "" ||
                  datamysite.length <= 0 ? null : (
                    <div style={{ width: "33%", height: 500 }}>
                      <h3 className="text-center"> Mysite Breakdown</h3>

                      <ResponsiveContainer>
                        <PieChart width={400} height={400}>
                          <Pie
                            activeIndex={activeIndex}
                            activeShape={renderActiveShape}
                            data={datamysite}
                            cx={200}
                            cy={200}
                            innerRadius={60}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                            onMouseEnter={onPieEnter}
                          />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  )}
                  {(dataLocation &&
                    dataLocation.thumbtack_link === "undefined") ||
                  dataLocation.thumbtack_link === "" ||
                  datathumbtack.length <= 0 ? null : (
                    <div style={{ width: "33%", height: 500 }}>
                      <h3 className="text-center"> Thumbtack Breakdown</h3>

                      <ResponsiveContainer>
                        <PieChart width={400} height={400}>
                          <Pie
                            activeIndex={activeIndex}
                            activeShape={renderActiveShape}
                            data={datathumbtack}
                            cx={200}
                            cy={200}
                            innerRadius={60}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                            onMouseEnter={onPieEnter}
                          />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  )}

                  {(dataLocation && dataLocation.amazon_link === "undefined") ||
                  dataLocation.amazon_link === "" ||
                  dataamazon.length <= 0 ? null : (
                    <div style={{ width: "33%", height: 500 }}>
                      <h3 className="text-center"> Amazon Breakdown</h3>

                      <ResponsiveContainer>
                        <PieChart width={400} height={300}>
                          <Pie
                            activeIndex={activeIndex}
                            activeShape={renderActiveShape}
                            data={dataamazon}
                            cx={200}
                            cy={200}
                            innerRadius={60}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                            onMouseEnter={onPieEnter}
                          />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  )}
                </div>
              </div>
            </CodeExample>
          </div>
        </div>
      </>
    </LoadingOverlay>
  );
}
