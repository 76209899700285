export default {
  header: {
    self: {},
    items: [
      {
        title: "Send Review Request",
        root: true,
        alignment: "left",
        page: "reviewadd",
      },
      // comment survey module
      // localStorage.getItem("surveycount") != 0
      //   ? {
      //       title: "Surveys",
      //       root: true,
      //       alignment: "left",
      //       page: "survey-links",
      //       submenu: [
      //         {
      //           title: "Send Survey",
      //           bullet: "line",
      //           page: "send-survey",
      //         },
      //         {
      //           title: "Survey Feedback",
      //           bullet: "line",
      //           page: "mysurvey",
      //         },
      //         {
      //           title: "Survey Reports",
      //           bullet: "line",
      //           page: "survey-report",
      //         },
      //         {
      //           title: "Survey Links",
      //           bullet: "line",
      //           page: "survey-links",
      //         },
      //       ],
      //     }
      //   : "",
      {
        title: "Dashboard",
        root: true,
        alignment: "left",
        page: "dashboardclient",
        translate: "MENU.DASHBOARD",
      },
      //   {
      //     title: "Reviews",
      //     root: true,
      //     alignment: "left",
      //     page: "myreviews",
      //     submenu: [
      //       {
      //         title: "Reviews Report",
      //         bullet: "line",
      //         page: "myreviews",
      //       },
      //       {
      //         title: "Reviews Activity reports",
      //         bullet: "line",
      //         page: "reports-activity",
      //       },
      //       {
      //         title: "Customers Feedback",
      //         bullet: "line",
      //         page: "feedback",
      //       },
      //     ],
      //   },
    ],
  },
  aside: {
    self: {},
    items: [
      {
        title: "Dashboard",
        root: true,
        icon: "flaticon2-architecture-and-city",
        page: "dashboardclient",
        translate: "MENU.DASHBOARD",
        bullet: "dot",
      },
    ],
  },
};
