import React, { useState, useEffect } from "react";
import Notice from "../../../partials/content/Notice";
import CodeExample from "../../../partials/content/CodeExample";
import { Table, Button } from "react-bootstrap";
import axios from "axios";
import { API } from "../../../../const";
import $ from "jquery";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import localeInfo from "rc-pagination/lib/locale/en_US";
import "rc-pagination/assets/index.css";
import Moment from "react-moment";
import LoadingOverlay from "react-loading-overlay";
import { RiseLoader } from "react-spinners";
const Clients = (props) => {
  const [showLoading, setShowLoading] = useState(true);

  const [data, setData] = useState([]);
  const [currdata, setcurrData] = useState([]);
  const [count, setcount] = useState(0);
  const [current, setcurrent] = useState(1);
  const [formlist1, setformData1] = useState([]);

  const apiUrlall = API + "api/client_listall";
  const apiUrl = API + "api/client_list";

  useEffect(() => {
    const fetchData = async () => {
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      };
      var postData = {};
      axios
        .post(apiUrlall, postData, axiosConfig)
        .then((res) => {
          setData(res.data);
          let count = Object.keys(res.data).length;
          setcount(count);
          setShowLoading(false);
        })
        .catch((err) => {
          //console.log("AXIOS ERROR: ", err);
        });
    };

    fetchData();
  }, []);

  const loadData = async (offsetS, current) => {
    let off = offsetS;
    var curval;
    if (!current) {
      var curval = 1;
    } else {
      var curval = current;
    }
    setcurrent(curval);
    $.ajax({
      url: apiUrl,
      data: {
        limit: 20,
        offset: off,
        client_name: formlist1.client_name,
        client_email: formlist1.client_email,
      },
      dataType: "json",
      type: "POST",
      headers: {
        Authorization: localStorage.getItem("token"),
      },

      success: (data) => {
        setcurrData(data);
      },

      error: (xhr, status, err) => {
        console.error(this.props.url, status, err.toString()); // eslint-disable-line
      },
    });
  };
  const onKeyUp = (e) => {
    const filterd = data.filter((item) =>
      item.client_name.toLowerCase().includes(e.target.value.toLowerCase())
    );

    if (e.target.value) {
      setcurrData(filterd);
    } else {
      loadData();
    }
  };

  useEffect(() => {
    loadData();
  }, [data]);

  const editClient = (id) => {
    props.history.push({
      pathname: "/client-edit/" + id,
    });
  };

  const deleteClient = (id, idx) => {
    const apiurldelete = API + "api/client_delete/" + id;
    axios
      .get(apiurldelete, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((result) => {
        alert("Client Deleted Successfully");
        var updatedData = [...data];
        updatedData.splice(idx, 1);
        setData(updatedData);
        props.history.push("/clients");
      })
      .catch((error) => "");
  };

  const addusers = () => {
    props.history.push({
      pathname: "/clients-add",
    });
  };

  const handlePageClick = (data) => {
    let offsetS = Math.ceil(data * 20 - 20);
    loadData(offsetS, data);
  };

  const viewusers = (id) => {
    props.history.push({
      pathname: "/client-users/" + id,
    });
  };
  const viewsurvey = (id) => {
    props.history.push({
      pathname: "/client-survey/" + id,
    });
  };

  const viewlocation = (id) => {
    props.history.push({
      pathname: "/client-location/" + id,
    });
  };
  const viewreport = (id, name) => {
    props.history.push({
      pathname: "/reports/" + id + "/" + name,
    });
  };

  const viewcustomer = (id) => {
    props.history.push({
      pathname: "/reviewlist/" + id,
    });
  };

  const handleChange = (id, status) => {
    var setstatus;
    if (status === "Enabled") {
      setstatus = "Disabled";
    } else {
      setstatus = "Enabled";
    }
    var postData = {
      id: id,
      mystatus: setstatus,
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };
    const api = API + "api/client_status";

    axios
      .post(api, postData, axiosConfig)
      .then((res) => {
        alert(res.data.message);
        props.history.push({
          pathname: "/clients",
        });
      })
      .catch((err) => {
        //console.log("AXIOS ERROR: ", err);
      });
  };

  const resetemaildata = async () => {
    window.location.reload();
  };

  const handleChange1 = (event) => {
    setformData1({ ...formlist1, [event.target.name]: event.target.value });
  };
  const getemaildata = async () => {
    const loadData = async (offsetS, current) => {
      let off = offsetS;
      var curval;
      if (!current) {
        curval = 1;
      } else {
        curval = current;
      }
      setcurrent(curval);
      $.ajax({
        url: apiUrl,
        data: {
          limit: 20,
          offset: off,
          client_name: formlist1.client_name,
          client_email: formlist1.client_email,
        },
        dataType: "json",
        type: "POST",
        headers: {
          Authorization: localStorage.getItem("token"),
        },

        success: (data) => {
          setcurrData(data);
        },

        error: (xhr, status, err) => {
          console.error(this.props.url, status, err.toString()); // eslint-disable-line
        },
      });
    };

    const fetchData = async () => {
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      };
      var postData = {
        client_name: formlist1.client_name,
        client_email: formlist1.client_email,
      };
      axios
        .post(apiUrlall, postData, axiosConfig)
        .then((res) => {
          setData(res.data);
          let count = Object.keys(res.data).length;
          setcount(count);
          setShowLoading(false);
        })
        .catch((err) => {
          //console.log("AXIOS ERROR: ", err);
        });
    };

    fetchData();
    loadData();
  };
  return (
    <LoadingOverlay
      active={showLoading}
      spinner={<RiseLoader sizeUnit={"px"} size={80} color={"#2a8dd1"} />}
    >
      <>
        {/* <Notice icon="flaticon-warning kt-font-primary">
        For more info please check the components's official{" "}
      </Notice> */}

        <div className="row">
          <div className="col-md-12">
            <CodeExample beforeCodeTitle="Clients List">
              <div className="kt-section">
                <Button
                  variant="primary"
                  className="pull-right"
                  onClick={() => {
                    addusers();
                  }}
                >
                  {" "}
                  Add Client
                </Button>
                <div className="row">
                  <div className="col-6 col-md-4">
                    <input
                      className="form-control col-md-12"
                      id="client_name"
                      name="client_name"
                      onChange={handleChange1}
                    ></input>
                    <label>Client Name</label>
                  </div>

                  <div className="col-6 col-md-4">
                    <input
                      className="form-control col-md-12"
                      id="client_email"
                      name="client_email"
                      onChange={handleChange1}
                    ></input>
                    <label>Company Name</label>
                  </div>

                  <div className="col-6 col-md-2">
                    <button
                      onClick={() => getemaildata()}
                      className=" form-control col-md-12 btn btn-success"
                    >
                      Search
                    </button>
                  </div>
                  <div className="col-6 col-md-2">
                    <button
                      onClick={() => resetemaildata()}
                      className=" form-control col-md-12 btn btn-warning"
                    >
                      Reset
                    </button>
                  </div>
                </div>
                <div className="kt-separator kt-separator--dashed responsive"></div>
                <div className="table-responsive">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Name</th>
                        <th>Company Name</th>
                        <th>Email</th>
                        <th>Customer Lists</th>
                        <th>Report</th>
                        <th>View Locations</th>
                        <th>View Users</th>
                        {/* <th>View Survey</th> */}
                        <th>Last Used</th>
                        <th>Created Date</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currdata.map((item, idx) => (
                        <tr>
                          <td>{idx + 1 + (current - 1) * 20}</td>
                          <td>{item.client_name}</td>
                          <td>{item.company_name}</td>
                          <td>{item.cp_login && item.cp_login.email}</td>
                          <td>
                            <a onClick={() => viewcustomer(item.client_id)}>
                              Customers List
                            </a>
                          </td>
                          <td>
                            <a
                              onClick={() =>
                                viewreport(item.client_id, item.client_name)
                              }
                            >
                              Report
                            </a>
                          </td>
                          <td>
                            <a onClick={() => viewlocation(item.client_id)}>
                              View Locations
                            </a>
                          </td>
                          <td>
                            <a onClick={() => viewusers(item.client_id)}>
                              View Users
                            </a>
                          </td>
                          {/* <td>
                            <a onClick={() => viewsurvey(item.client_id)}>
                              View Survey
                            </a>
                          </td> */}
                          <td>
                            {item.updatedAt ? (
                              <Moment format="YYYY-MM-DD">
                                {item.updatedAt && item.updatedAt}
                              </Moment>
                            ) : (
                              ""
                            )}
                          </td>
                          <td>
                            <Moment format="YYYY-MM-DD">
                              {item.cp_login && item.cp_login.createdAt}
                            </Moment>
                          </td>
                          <td>
                            <select
                              className="form-control wid200"
                              id="status"
                              onChange={() =>
                                handleChange(
                                  item.cp_login.id,
                                  item.cp_login.mystatus
                                )
                              }
                            >
                              <option
                                selected={
                                  item.cp_login &&
                                  item.cp_login.mystatus === "Enabled"
                                }
                                value="Enabled"
                              >
                                Enabled
                              </option>
                              <option
                                selected={
                                  item.cp_login &&
                                  item.cp_login.mystatus === "Disabled"
                                }
                                value="Disabled"
                              >
                                Disabled
                              </option>
                            </select>
                          </td>
                          <td>
                            <i
                              onClick={() => {
                                editClient(item.client_id);
                              }}
                              className="flaticon2-pen"
                            ></i>{" "}
                            /
                            <i
                              onClick={(e) =>
                                window.confirm(
                                  "Are you sure you wish to delete this item?"
                                ) && deleteClient(item.id, idx)
                              }
                              className="flaticon-delete-1"
                            ></i>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
                <Pagination
                  showQuickJumper
                  showSizeChanger
                  onChange={handlePageClick}
                  current={current}
                  total={count}
                  pageSize={20}
                  locale={localeInfo}
                />
              </div>
            </CodeExample>
          </div>
        </div>
      </>
    </LoadingOverlay>
  );
};
export default Clients;
