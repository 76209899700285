import React from "react";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import UpdatePassword from "./UpdatePassword";

import Login from "./Login";
import { Helmet } from "react-helmet";
import { toAbsoluteUrl } from "../../../_metronic";
import "../../../_metronic/_assets/sass/pages/login/login-1.scss";
import AccountDelete from "./AccountDelete";
const today = new Date().getFullYear();

export default function AuthPage() {
  return (
    <>
      {/* https://github.com/nfl/react-helmet */}
      <Helmet>
        {/* <link
            type="text/css"
            rel="stylesheet"
            href={toAbsoluteUrl(
                "/assets/css/demo1/style.bundle.css"
            )}
        />
        <link
          type="text/css"
          rel="stylesheet"
          href={toAbsoluteUrl(
            "/assets/css/demo1/pages/login/login-1.css"
          )}
        /> */}
        <title>GroFire Reviews &amp; Survey App</title>
        <meta name="description" content="GroFire Reviews &amp; Survey App" />
      </Helmet>

      <div className="kt-grid kt-grid--ver kt-grid--root">
        <div
          id="kt_login"
          className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
        >
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
            <div
              className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
              style={{
                backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-4.jpg")})`,
              }}
            >
              <div className="kt-grid__item">
                <a
                  href="https://www.grofirereviews.com"
                  className="kt-login__logo"
                >
                  <img
                    alt="Logo"
                    src={toAbsoluteUrl("/media/logos/logo-4.png")}
                  />
                </a>
              </div>
              <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
                <div className="kt-grid__item kt-grid__item--middle">
                  <h3 className="kt-login__title">
                    LET US HELP YOU STAND OUT.
                  </h3>
                  <h4 className="kt-login__subtitle">
                    START WINNING ONLINE WITH A HIGHER STAR RATING AND MORE
                    REVIEWS.
                  </h4>
                </div>
              </div>
              <div className="kt-grid__item" id="hidemob">
                <div className="kt-login__info">
                  <div className="kt-login__copyright">
                    &copy;&nbsp;{today}&nbsp;
                    <a href="https://www.grofirereviews.com">
                      Grofire Reviews And Survey App
                    </a>
                  </div>
                  <div className="kt-login__menu">
                    <a
                      href="https://www.grofirereviews.com"
                      className="kt-link"
                    >
                      Home
                    </a>

                    <a
                      href="https://www.grofirereviews.com/contact.php"
                      className="kt-link"
                    >
                      Contact
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
              <Switch>
                <Redirect from="/auth" exact={true} to="/auth/login" />

                <Route path="/auth/login" component={Login} />
                <Route
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                />
                <Route
                  path="/auth/update-password/:id"
                  component={UpdatePassword}
                />
                <Route path="/auth/delete-account" component={AccountDelete} />
              </Switch>
            </div>
            <div className="kt-grid__item" id="hidedesk">
              <div className="kt-login__info">
                <div className="kt-login__copyright text-center">
                  &copy;&nbsp;{today}&nbsp;
                  <a href="https://www.grofirereviews.com">
                    Grofire Reviews And Survey App
                  </a>
                </div>
                <div className="kt-login__menu text-center">
                  <a href="https://www.grofirereviews.com" className="kt-link">
                    Home
                  </a>
                  &nbsp;|&nbsp;
                  <a
                    href="https://www.grofirereviews.com/contact.php"
                    className="kt-link"
                  >
                    Contact
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
