import React, { useState } from "react";
import axios from "axios";
import { API } from "../../../../const";
import { Form } from "react-bootstrap";
import Validator from "validatorjs";
import ReactPasswordStrength from "react-password-strength";

const Change_password = (props) => {
  const [formdata, setformdata] = useState({
    id: "",
    new_password: "",
    confirm_password: "",
  });

  const validateForm = () => {
    let data = {
      new_password: formdata.new_password,
      confirm_password: formdata.confirm_password,
    };

    let rules = {
      new_password: "required",
      confirm_password: "required",
    };

    let validation = new Validator(data, rules);

    if (validation.passes()) {
      return true;
    }
  };

  if (localStorage.getItem("menu") !== "undefined") {
    var password_update = API + "api/updatePassword_user";
  } else {
    password_update = API + "api/updatepassword_login";
  }

  const back = () => {
    props.history.push({
      pathname: "/clients",
    });
  };

  const formSubmit = async () => {
    var postData = {
      email: localStorage.getItem("email"),
      new_password: formdata.new_password,
      password: formdata.confirm_password,
    };

    if (postData.password !== postData.new_password) {
      alert("Confirm password and new password Not Match");
    } else {
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      };
      axios
        .post(password_update, postData, axiosConfig)
        .then((res) => {
          alert(res.data.message);
          setTimeout(function() {
            window.location.reload();
          }, 1000);
        })
        .catch((err) => {
          //console.log("AXIOS ERROR: ", err);
        });
    }
  };

  const handlenew_password = (event) => {
    setformdata({ ...formdata, ["new_password"]: event.password });
  };
  const handleconfirm_password = (event) => {
    setformdata({ ...formdata, ["confirm_password"]: event.password });
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="kt-space-40" />
          <Form encType="multipart/form-data">
            <div className="review">
              <div className="row">
                <div className="col-md-12">
                  <div className="card border-top-0 border-right-0 border-left-0 pb-4">
                    <div className="card-header bg-white ">
                      <div className="row">
                        <div className="col-md-12">
                          <h5 className="card-title mb-0 pt-2">
                            Change Password
                          </h5>
                        </div>
                        <div className="col-md-12 text-right">
                          <input
                            type="button"
                            onClick={() => back()}
                            value="Back"
                            className="margin btn btn-primary save-btn font-weight-bold"
                          />
                          <input
                            type="button"
                            disabled={!validateForm()}
                            onClick={() => formSubmit()}
                            value="Save"
                            className="btn btn-success save-btn font-weight-bold"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="card-body" id="newborderclass">
                      <div id="introduction">
                        <div className="row">
                          <div className="form-group col-md-6 ">
                            <ReactPasswordStrength
                              className="showsection"
                              id="new_password"
                              name="new_password"
                              style={{ display: "block", border: "none" }}
                              minLength={5}
                              minScore={2}
                              changeCallback={handlenew_password}
                              scoreWords={[
                                "weak",
                                "okay",
                                "good",
                                "strong",
                                "stronger",
                              ]}
                              inputProps={{
                                id: "new_password",
                                name: "new_password",
                                autoComplete: "off",
                                className: "form-control",
                              }}
                            />
                            <input
                              type="hidden"
                              name="id"
                              id="id"
                              className="form-control"
                              placeholder="id"
                              value={formdata.id}
                            />
                            <label>
                              New Password: (this field is required)
                            </label>
                          </div>
                          <div className="form-group col-md-6 ">
                            <ReactPasswordStrength
                              className="showsection"
                              id="confirm_password"
                              name="confirm_password"
                              style={{ display: "block", border: "none" }}
                              minLength={5}
                              minScore={2}
                              changeCallback={handleconfirm_password}
                              scoreWords={[
                                "weak",
                                "okay",
                                "good",
                                "strong",
                                "stronger",
                              ]}
                              inputProps={{
                                id: "confirm_password",
                                name: "confirm_password",
                                autoComplete: "off",
                                className: "form-control",
                              }}
                            />
                            <label>
                              Confirm Password: (this field is required)
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>{" "}
        <div className="kt-space-20 sdd" />
      </div>
    </>
  );
};
export default Change_password;
