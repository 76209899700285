import React, { useState, useEffect } from "react";
import Notice from "../../../partials/content/Notice";
import CodeExample from "../../../partials/content/CodeExample";
import { Table, Button } from "react-bootstrap";
import axios from "axios";
import { API, BASE_URL } from "../../../../const";
import $ from "jquery";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import localeInfo from "rc-pagination/lib/locale/en_US";
import "rc-pagination/assets/index.css";
import LoadingOverlay from "react-loading-overlay";
import { RiseLoader } from "react-spinners";
import Popup from "reactjs-popup";

const Survey = props => {
  const [showLoading, setShowLoading] = useState(true);
  const [name, setName] = useState({});
  const [data, setData] = useState([]);
  const [currdata, setcurrData] = useState([]);
  const [count, setcount] = useState(0);
  const [locationdata, setLocationData] = useState([]);
  const [current, setcurrent] = useState(1);
  const [formlist, setformData] = useState([]);
  const [formlist1, setformData1] = useState([]);
  if (localStorage.getItem("client_id") === "000") {
    var apiUrl = API + "api/surveylist/";
    apiUrl = API + "api/surveylist/";
  }

  if (localStorage.getItem("client_id") === "000") {
    var apiUrlall = API + "api/surveylist/";
  } else {
    apiUrlall = API + "api/surveylist/";
  }

  const apiUrlLocation = API + "api/user_getby_locationid";

  useEffect(() => {
    const fetchData = async () => {
      var postData = {
        client_id: props.match.params.id
      };
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token")
        }
      };
      axios.post(apiUrlall, postData, axiosConfig).then(result => {
        setData(result.data);
        let count = Object.keys(result.data).length;
        setcount(count);
        setShowLoading(false);
      });
    };

    fetchData();
  }, []);

  const loadData = async (offsetS, current) => {
    var off1 = offsetS;
    var off = offsetS;

    var curval;
    if (!current) {
      curval = 1;
    } else {
      curval = current;
    }
    setcurrent(curval);
    $.ajax({
      url: apiUrl,
      data: {
        limit: 20,
        offset: off1,
        client_id: props.match.params.id
      },
      dataType: "json",
      type: "POST",
      headers: {
        Authorization: localStorage.getItem("token")
      },

      success: data => {
        setcurrData(data);
      },

      error: (xhr, status, err) => {
        console.error(this.props.url, status, err.toString()); // eslint-disable-line
      }
    });
  };

  useEffect(() => {
    loadData();
  }, [data]);

  const handlePageClick = data => {
    let offsetS = Math.ceil(data * 20 - 20);
    loadData(offsetS, data);
  };

  const edituser = id => {
    props.history.push({
      pathname: "/survey/" + id
    });
  };

  const addsurvey = id => {
    props.history.push({
      pathname: "/add-survey/" + props.match.params.id
    });
  };
  const viewQuesion = (id, url_field) => {
    localStorage.setItem("survey_id", id);
    localStorage.setItem("url_field", url_field);
    props.history.push({
      pathname: "/view_question/" + id + "/" + props.match.params.id
    });
  };
  const back = () => {
    props.history.push({
      pathname: "/clients"
    });
  };

  const location = (url , client_id) => {
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token")
      }
    };
    var postData = {
      client_id: client_id
    };
    axios
      .post(apiUrlLocation, postData, axiosConfig)
      .then(res => {
        setLocationData(res.data);
        setName(url);
        setShowLoading(false);
      })
      .catch(err => {
        //console.log("AXIOS ERROR: ", err);
      });
  };

  const allLocation = () => {
    return (
      <table
        className="table table-striped table-bordered table-hover"
        id="dataTables-example"
        width="100%;"
      >
        <thead>
          <tr>
            <th>ID</th>
            <th>Location Address</th>
            <th>Survey Link</th>
          </tr>
        </thead>
        <tbody>
          {locationdata.map((item, idx) => (
            <tr className="odd gradeX" key={idx}>
              <td>{item.id}</td>
              <td>{item.add1 + ", " + item.city}</td>
              <td>
                <div id="rep">
                  {BASE_URL}/surveyview/
                  <span className="surveyname">{name}</span>/{item.id}{" "}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <LoadingOverlay
      active={showLoading}
      spinner={<RiseLoader sizeUnit={"px"} size={80} color={"#2a8dd1"} />}
    >
      <>
        {/* <Notice icon="flaticon-warning kt-font-primary">
        For more info please check the components's official{" "}
      </Notice> */}

        <div className="row">
          <div className="col-md-12">
            <CodeExample beforeCodeTitle="Survey List">
              <div className="kt-section">
                <Button
                  variant="primary"
                  className="pull-right btn btn-primary"
                  onClick={() => {
                    back();
                  }}
                >
                  {" "}
                  Back
                </Button>
                <div className="col-6 col-md-2">
                  <button
                    onClick={() => addsurvey()}
                    className=" form-control col-md-12 btn btn-warning"
                  >
                    Add Survey
                  </button>
                </div>

                <div className="kt-separator kt-separator--dashed responsive"></div>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Sr. No.</th>
                      <th>Survey Name</th>
                      <th>Location Address </th>
                      <th>Active</th>
                      <th>Created Date</th>
                      <th>View Questions</th>
                      <th>Survey Url</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currdata.map((item, idx) => (
                      <tr>
                        <td>{idx + 1 + (current - 1) * 20}</td>
                        <td>{item.survey_name}</td>
                        <td>
                          {item.cp_location
                            ? item.cp_location.add1 +
                              "," +
                              item.cp_location.city
                            : " All Location "}
                          {item.cp_location ? (
                            ""
                          ) : (
                            <Popup
                              trigger={
                                <button
                                  className="button"
                                  onMouseEnter={() => location(item.url_field , item.client_id)}
                                >
                                  {" "}
                                  View Location{" "}
                                </button>
                              }
                              modal
                              closeOnDocumentClick
                            >
                              {allLocation}
                            </Popup>
                          )}
                        </td>
                        <td>{item.active == 1 ? "Yes" : "No"}</td>
                        <td>{item.created_date}</td>
                        <td>
                          <a
                            onClick={() => {
                              viewQuesion(item.id, item.url_field);
                            }}
                          >
                            Click Here
                          </a>
                        </td>
                        <td>
                          <a
                            href={
                              BASE_URL + "/SurveyLinksView/" + item.id
                            }
                          >
                            {BASE_URL}/SurveyLinksView/{item.id}
                          </a>
                        </td>
                        <td>
                          <i
                            onClick={() => {
                              edituser(item.id);
                            }}
                            className="flaticon2-pen"
                          ></i>{" "}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Pagination
                  showQuickJumper
                  showSizeChanger
                  onChange={handlePageClick}
                  current={current}
                  total={count}
                  pageSize={20}
                  locale={localeInfo}
                />
              </div>
            </CodeExample>
          </div>
        </div>
      </>
    </LoadingOverlay>
  );
};
export default Survey;
