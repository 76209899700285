import React, { useState, useEffect } from "react";
import CodeExample from "../../../partials/content/CodeExample";
import { Table, Button } from "react-bootstrap";
import axios from "axios";
import { API } from "../../../../const";
import $ from "jquery";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import localeInfo from "rc-pagination/lib/locale/en_US";
import "rc-pagination/assets/index.css";
import Moment from "react-moment";
import LoadingOverlay from "react-loading-overlay";
import { RiseLoader } from "react-spinners";
const FeedbackList = props => {
  const [data, setData] = useState([]);
  const [showLoading, setShowLoading] = useState(true);

  const [currdata, setcurrData] = useState([]);
  const [count, setcount] = useState(0);
  const [current, setcurrent] = useState(1);
  const [clinetlist, setclientData] = useState([]);
  const [locationlist, setlocationData] = useState([]);
  const [formlist, setformData] = useState([]);
  const [formlist1, setformData1] = useState([]);

  const apiUrlall = API + "api/review_all";
  const apiUrl = API + "api/reviewlist";
  useEffect(() => {
    const fetchData = async () => {
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token")
        }
      };
      var postData = {
        client_id: localStorage.getItem("client_id")
      };
      axios
        .post(apiUrlall, postData, axiosConfig)
        .then(res => {
          setData(res.data);
          let count = Object.keys(res.data).length;
          setcount(count);
          setShowLoading(false);
        })
        .catch(err => {
          //console.log("AXIOS ERROR: ", err);
        });
    };

    fetchData();
  }, []);

  const loadData = async (offsetS, current) => {
    let off = offsetS;
    var curval;
    if (!current) {
      var curval = 1;
    } else {
      var curval = current;
    }
    setcurrent(curval);
    $.ajax({
      url: apiUrl,
      data: {
        limit: 20,
        offset: off,
        client_id: localStorage.getItem("client_id"),
        user: formlist.client_id,
        location: formlist1.location_id
      },
      dataType: "json",
      type: "POST",
      headers: {
        Authorization: localStorage.getItem("token")
      },

      success: data => {
        setcurrData(data);
      },

      error: (xhr, status, err) => {
        console.error(this.props.url, status, err.toString()); // eslint-disable-line
      }
    });
  };
  const onKeyUp = e => {
    const filterd = data.filter(item =>
      item.client_name.toLowerCase().includes(e.target.value.toLowerCase())
    );

    if (e.target.value) {
      setcurrData(filterd);
    } else {
      loadData();
    }
  };

  useEffect(() => {
    loadData();
  }, [data]);

  useEffect(() => {
    const fetchData1 = async () => {
      const apiUrl = API + "api/user_getby_clientidpost";

      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token")
        }
      };
      var postData = {
        id: localStorage.getItem("client_id")
      };
      axios
        .post(apiUrl, postData, axiosConfig)
        .then(res => {
          setclientData(res.data);
        })
        .catch(err => {
          //console.log("AXIOS ERROR: ", err);
        });
    };

    const fetchData12 = async () => {
      const apiUrl1 = API + "api/location_by_client_id";

      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token")
        }
      };
      var postData = {
        client_id: localStorage.getItem("client_id")
      };
      axios
        .post(apiUrl1, postData, axiosConfig)
        .then(responce => {
          //console.log(responce.data.location);
          setlocationData(responce.data.location);
        })
        .catch(err => {
          //console.log("AXIOS ERROR: ", err);
        });
    };

    fetchData1();
    fetchData12();
  }, []);

  const handlePageClick = data => {
    let offsetS = Math.ceil(data * 20 - 20);
    loadData(offsetS, data);
  };

  const handleChange = event => {
    setformData({ ...formlist, [event.target.name]: event.target.value });
  };

  const handleChange1 = event => {
    setformData1({ ...formlist1, [event.target.name]: event.target.value });
  };
  const getemaildata = async () => {
    var postData1 = {
      client_id: localStorage.getItem("client_id"),
      user: formlist.client_id,
      location: formlist1.location_id
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token")
      }
    };

    axios
      .post(apiUrlall, postData1, axiosConfig)
      .then(res => {
        setData(res.data);
        let count = Object.keys(res.data).length;
        setcount(count);
      })
      .catch(err => {
        //console.log("AXIOS ERROR: ", err);
      });

    const loadData = async (offsetS, current) => {
      let off = offsetS;
      var curval;
      if (!current) {
        var curval = 1;
      } else {
        var curval = current;
      }
      setcurrent(curval);
      $.ajax({
        url: apiUrl,
        data: {
          limit: 20,
          offset: off,
          client_id: localStorage.getItem("client_id"),
          user: formlist.client_id,
          location: formlist1.location_id
        },
        dataType: "json",
        type: "POST",
        headers: {
          Authorization: localStorage.getItem("token")
        },

        success: data => {
          setcurrData(data);
        },

        error: (xhr, status, err) => {
          console.error(this.props.url, status, err.toString()); // eslint-disable-line
        }
      });
    };
    loadData();
  };

  const resetemaildata = async () => {
    // setEmailStartdate({ ...startdata });
    // setEmailEndata({ ...enddata });
    // setMovedata({ ...movedata });
    window.location.reload();
  };

  let user_type= localStorage.getItem("user_type");
  let user_id= localStorage.getItem("id");
  
  const userListing =(item, idx)=>{
    if(user_type==='employee' )
    {
      if(user_id==item.id)
      {
        return (<option key={idx} value={item.id}>
          {item.name}
        </option>);
      }
    }
    else
    {
      return (<option key={idx} value={item.id}>
        {item.name}
      </option>);
    }
  }

  return (
    <LoadingOverlay
      active={showLoading}
      spinner={<RiseLoader sizeUnit={"px"} size={80} color={"#2a8dd1"} />}
    >
      <>
        {/* <Notice icon="flaticon-warning kt-font-primary">
        For more info please check the components's official{" "}
      </Notice> */}

        <div className="row">
          <div className="col-md-12">
            <CodeExample beforeCodeTitle="Clients List">
              <div className="kt-section">
                <span className="kt-section__sub">Customer Feedback</span>
                <br></br>
                <div className="row">
                  <div className="col-6 col-md-4">
                    <select
                      className="form-control col-md-12"
                      id="client_id"
                      name="client_id"
                      onChange={handleChange}
                    >
                      <option value="">Select User</option>
                      {clinetlist.map((item, idx) => userListing(item, idx))}
                    </select>
                    <label>User List</label>
                  </div>

                  <div className="col-6 col-md-4">
                    <select
                      className="form-control col-md-12"
                      id="location_id"
                      name="location_id"
                      onChange={handleChange1}
                    >
                      <option value="">Select Location</option>
                      {locationlist.map((item, idx) => (
                        <option key={idx} value={item.id}>
                          {item.add1}
                          {", "}
                          {item.city}
                        </option>
                      ))}
                    </select>
                    <label>Location List</label>
                  </div>

                  <div className="col-6 col-md-2">
                    <button
                      onClick={() => getemaildata()}
                      className=" form-control col-md-12 btn btn-success"
                    >
                      Search
                    </button>
                  </div>
                  <div className="col-6 col-md-2">
                    <button
                      onClick={() => resetemaildata()}
                      className=" form-control col-md-12 btn btn-warning"
                    >
                      Reset
                    </button>
                  </div>
                </div>
                <div className="kt-separator kt-separator--dashed responsive"></div>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Sr. No.</th>
                      <th>Name</th>
                      <th>Email/Mobile</th>
                      <th>Message</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currdata.map((item, idx) => (
                      <tr>
                        <td>{idx + 1 + (current - 1) * 20}</td>

                        <td> {item.cp_link && item.cp_link.username}</td>
                        <td>
                          {item.cp_link && item.cp_link.email}
                          {item.cp_login && item.cp_login.mobile}
                        </td>
                        <td>{item.desc}</td>
                        <td>
                          <Moment format="YYYY-MM-DD">
                            {item.created_date}
                          </Moment>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Pagination
                  showQuickJumper
                  showSizeChanger
                  onChange={handlePageClick}
                  current={current}
                  total={count}
                  pageSize={20}
                  locale={localeInfo}
                />
              </div>
            </CodeExample>
          </div>
        </div>
      </>
    </LoadingOverlay>
  );
};
export default FeedbackList;
