import React, { useState, useEffect } from "react";
import axios from "axios";
import { API } from "../../../../const";
import Locationform from "./form";

const EditLocation = props => {
  const [formdata, setformdata] = useState({
    sitelink:"",
    client_id: "",
    l_name: "",
    add1: "",
    add2: "",
    state: "",
    city: "",
    zip: "",
    fb_link: "",
    view_fb2: "",
    yelp_link: "",
    google_link: "",
    google_link2: "",
    zillow_link: "",
    yp_link: "",
    trip_link: "",
    yell_link: "",
    sf_link: "",
    real_link: "",
    apar_link: "",
    aparrate_link: "",
    great_schools_link: "",
    healthgrades_link: "",
    mysite_link: "",
    thumbtack_link: "",
    amazon_link: "",
    fb_account: "",
    fb_account2: "",
    yelp_account: "",
    google_account: "",
    google_account2: "",
    zillow_account: "",
    yp_account: "",
    trip_account: "",
    yell_account: "",
    sf_account: "",
    real_account: "",
    apar_account: "",
    aparrate_account: "",
    great_schools_account: "",
    healthgrades_account: "",
    mysite_account: "",
    thumbtack_account: "",
    amazon_account: "",
    c_no: "",
    c_email: "",
    msg: "",
    msg1: "",
    sms_msg: "",
    sms_msg1: "",
    google_count: 0,
    yelp_count: 0,
    fb_count: 0,
    fb_count2: 0,
    zillow_count: 0,
    yp_count: 0,
    trip_count: 0,
    yell_count: 0,
    sf_count: 0,
    real_count: 0,
    apar_count: 0,
    aparrate_count: 0,
    great_schools_count: 0,
    healthgrades_count: 0,
    thumbtack_count: 0,
    amazon_count: 0,
    google_ratings: 0,
    google_ratings2: 0,
    yelp_ratings: 0,
    fb_ratings: 0,
    fb_ratings2: 0,
    zillow_ratings: 0,
    yp_ratings: 0,
    trip_ratings: 0,
    yell_ratings: 0,
    real_ratings: 0,
    apar_ratings: 0,
    aparrate_ratings: 0,
    great_schools_ratings: 0,
    healthgrades_ratings: 0,
    mysite_ratings: 0,
    thumbtack_ratings: 0,
    amazon_ratings: 0,
    google_date: "",
    google_date2: "",
    yelp_date: "",
    fb_date: "",
    fb_date2: "",
    zillow_date: "",
    yp_date: "",
    sf_date: "",
    apar_date: "",
    aparrate_date: "",
    real_date: "",
    trip_date: "",
    yell_date: "",
    great_schools_date: "",
    healthgrades_date: "",
    mysite_date: "",
    thumbtack_date: "",
    amazon_date: "",
    view_google: "",
    view_google2: "",
    view_yelp: "",
    view_fb: "",
    view_zillow: "",
    view_yp: "",
    view_trip: "",
    view_yell: "",
    view_sf: "",
    view_real: "",
    view_apar: "",
    view_aparrate: "",
    view_great_schools: "",
    view_healthgrades: "",
    view_mysite: "",
    view_thumbtack: "",
    view_amazon: "",
    setname: "",
    businees_name: "",
    color_code: "",
    logo: "",
    review_msg: "",
    yes_msg: "",
    no_msg: "",
    subject: "",
    subject1: "",
    sign: "",
    sign1: "",
    thanks_msg: "",
    thanks_msg1: "",
    sendlogo: "",
    sendlogo1: "",
    sendlogoemail: "",
    sendlogoemail1: "",
    underlogo: "",
    coupon: "",
    setfeed: "",
    loc_code: "",
    corp_code: "",
    corp_pass: "",
    corp_login: "",
    when_to_send: "",
    auto_sent_text: "",
    id: "",
    background: "",
    sf_ratings: 0,
    mysite_count: 0,
    solar_review: "",
    solar_review_view: "",
    home_advisor: "",
    home_advisor_view: "",
    energysage: "",
    energysage_view : ""
  });

  const apiUrl = API + "api/location_data_byid";

  useEffect(() => {
    const fetchData = async () => {
      var postData = {
        id: props.match.params.id
      };

      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token")
        }
      };
      const result = await axios
        .post(apiUrl, postData, axiosConfig)
        .then(res => {
          if (res.data) {
            //console.log(res.data.location.cp_client.sitelink);
            // setformdata(res.data.location);
            setformdata({
              sitelink:res.data.location.cp_client.sitelink,
              client_id: res.data.location.client_id,
              l_name: res.data.location.l_name,
              add1: res.data.location.add1,
              add2: res.data.location.add2,
              state: res.data.location.state,
              city: res.data.location.city,
              zip: res.data.location.zip,
              fb_link: res.data.location.fb_link,
              view_fb2: res.data.location.view_fb2,
              yelp_link: res.data.location.yelp_link,
              google_link: res.data.location.google_link,
              google_link2: res.data.location.google_link2,
              zillow_link: res.data.location.zillow_link,
              yp_link: res.data.location.yp_link,
              trip_link: res.data.location.trip_link,
              yell_link: res.data.location.yell_link,
              sf_link: res.data.location.sf_link,
              real_link: res.data.location.real_link,
              apar_link: res.data.location.apar_link,
              aparrate_link: res.data.location.aparrate_link,
              great_schools_link: res.data.location.great_schools_link,
              healthgrades_link: res.data.location.healthgrades_link,
              mysite_link: res.data.location.mysite_link,
              thumbtack_link: res.data.location.thumbtack_link,
              amazon_link: res.data.location.amazon_link,
              fb_account: res.data.location.fb_account,
              fb_account2: res.data.location.fb_account2,
              yelp_account: res.data.location.yelp_account,
              google_account: res.data.location.google_account,
              google_account2: res.data.location.google_account2,
              zillow_account: res.data.location.zillow_account,
              yp_account: res.data.location.yp_account,
              trip_account: res.data.location.trip_account,
              yell_account: res.data.location.yell_account,
              sf_account: res.data.location.sf_account,
              real_account: res.data.location.real_account,
              apar_account: res.data.location.apar_account,
              aparrate_account: res.data.location.aparrate_account,
              great_schools_account: res.data.location.great_schools_account,
              healthgrades_account: res.data.location.healthgrades_account,
              mysite_account: res.data.location.mysite_account,
              thumbtack_account: res.data.location.thumbtack_account,
              amazon_account: res.data.location.amazon_account,
              c_no: res.data.location.c_no,
              c_email: res.data.location.c_email,
              msg: res.data.location.msg,
              msg1: res.data.location.msg1,
              sms_msg: res.data.location.sms_msg,
              sms_msg1: res.data.location.sms_msg1,
              google_count: res.data.location.google_count,
              yelp_count: res.data.location.yelp_count,
              fb_count: res.data.location.fb_count,
              fb_count2: res.data.location.fb_count2,
              zillow_count: res.data.location.zillow_count,
              yp_count: res.data.location.yp_count,
              trip_count: res.data.location.trip_count,
              yell_count: res.data.location.yell_count,
              sf_count: res.data.location.sf_count,
              real_count: res.data.location.real_count,
              apar_count: res.data.location.apar_count,
              aparrate_count: res.data.location.aparrate_count,
              great_schools_count: res.data.location.great_schools_count,
              healthgrades_count: res.data.location.healthgrades_count,
              thumbtack_count: res.data.location.thumbtack_count,
              amazon_count: res.data.location.amazon_count,
              google_ratings: res.data.location.google_ratings,
              google_ratings2: res.data.location.google_ratings2,
              yelp_ratings: res.data.location.yelp_ratings,
              fb_ratings: res.data.location.fb_ratings,
              fb_ratings2: res.data.location.fb_ratings2,
              zillow_ratings: res.data.location.zillow_ratings,
              yp_ratings: res.data.location.yp_ratings,
              trip_ratings: res.data.location.trip_ratings,
              yell_ratings: res.data.location.yell_ratings,
              real_ratings: res.data.location.real_ratings,
              apar_ratings: res.data.location.apar_ratings,
              aparrate_ratings: res.data.location.aparrate_ratings,
              great_schools_ratings: res.data.location.great_schools_ratings,
              healthgrades_ratings: res.data.location.healthgrades_ratings,
              mysite_ratings: res.data.location.mysite_ratings,
              thumbtack_ratings: res.data.location.thumbtack_ratings,
              amazon_ratings: res.data.location.amazon_ratings,
              google_date: res.data.location.google_date,
              google_date2: res.data.location.google_date2,
              yelp_date: res.data.location.yelp_date,
              fb_date: res.data.location.fb_date,
              fb_date2: res.data.location.fb_date2,
              zillow_date: res.data.location.zillow_date,
              yp_date: res.data.location.yp_date,
              sf_date: res.data.location.sf_date,
              apar_date: res.data.location.apar_date,
              aparrate_date: res.data.location.aparrate_date,
              real_date: res.data.location.real_date,
              trip_date: res.data.location.trip_date,
              yell_date: res.data.location.yell_date,
              great_schools_date: res.data.location.great_schools_date,
              healthgrades_date: res.data.location.healthgrades_date,
              mysite_date: res.data.location.mysite_date,
              thumbtack_date: res.data.location.thumbtack_date,
              amazon_date: res.data.location.amazon_date,
              view_google: res.data.location.view_google,
              view_google2: res.data.location.view_google2,
              view_yelp: res.data.location.view_yelp,
              view_fb: res.data.location.view_fb,
              view_zillow: res.data.location.view_zillow,
              view_yp: res.data.location.view_yp,
              view_trip: res.data.location.view_trip,
              view_yell: res.data.location.view_yell,
              view_sf: res.data.location.view_sf,
              view_real: res.data.location.view_real,
              view_apar: res.data.location.view_apar,
              view_aparrate: res.data.location.view_aparrate,
              view_great_schools: res.data.location.view_great_schools,
              view_healthgrades: res.data.location.view_healthgrades,
              view_mysite: res.data.location.view_mysite,
              view_thumbtack: res.data.location.view_thumbtack,
              view_amazon: res.data.location.view_amazon,
              setname: res.data.location.setname,
              businees_name: res.data.location.businees_name,
              color_code: res.data.location.color_code,
              logo: res.data.location.logo,
              review_msg: res.data.location.review_msg,
              yes_msg: res.data.location.yes_msg,
              no_msg: res.data.location.no_msg,
              subject: res.data.location.subject,
              subject1: res.data.location.subject1,
              sign: res.data.location.sign,
              sign1: res.data.location.sign1,
              thanks_msg: res.data.location.thanks_msg,
              thanks_msg1: res.data.location.thanks_msg1,
              sendlogo: res.data.location.sendlogo,
              sendlogo1: res.data.location.sendlogo1,
              sendlogoemail: res.data.location.sendlogoemail,
              sendlogoemail1: res.data.location.sendlogoemail1,
              underlogo: res.data.location.underlogo,
              coupon: res.data.location.coupon,
              setfeed: res.data.location.setfeed,
              loc_code: res.data.location.loc_code,
              corp_code: res.data.location.corp_code,
              corp_pass: res.data.location.corp_pass,
              corp_login: res.data.location.corp_login,
              when_to_send: res.data.location.when_to_send,
              auto_sent_text: res.data.location.auto_sent_text,
              id: res.data.location.id,
              background: res.data.location.color_code,
              sf_ratings: res.data.location.sf_ratings,
              solar_review: res.data.location.solar_review,
              solar_review_view: res.data.location.solar_review_view,
              home_advisor: res.data.location.home_advisor,
              home_advisor_view: res.data.location.home_advisor_view,
              energysage: res.data.location.energysage,
              energysage_view : res.data.location.energysage_view
            });
          }
        })
        .catch(err => {
          //console.log("AXIOS ERROR: ", err);
        });
    };
    fetchData();
  }, []);

  return (
    <>
      {/* <Notice icon="flaticon-warning kt-font-primary">
                For more info please check the components's official{" "}
            </Notice> */}

      <div className="row">
        <div className="col-md-12">
          <div className="kt-space-40" />
          <Locationform data={formdata} history={props.history} />
          <div className="kt-space-20" />
        </div>
      </div>
    </>
  );
};
export default EditLocation;
