import React, { useState, useEffect } from "react";
import CodeExample from "../../../partials/content/CodeExample";
import { Table, Button } from "react-bootstrap";
import axios from "axios";
import { API } from "../../../../const";
import $ from "jquery";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import { toast } from "react-toastify";
import localeInfo from "rc-pagination/lib/locale/en_US";
import "rc-pagination/assets/index.css";
import LoadingOverlay from "react-loading-overlay";
import { RiseLoader } from "react-spinners";

const Survey = (props) => {
  const [showLoading, setShowLoading] = useState(true);

  const [data, setData] = useState([]);
  const [currdata, setcurrData] = useState([]);
  const [count, setcount] = useState(0);
  const [current, setcurrent] = useState(1);
  if (localStorage.getItem("client_id") === "000") {
    var apiUrl = API + "api/questions/";
    apiUrl = API + "api/questions/";
  }

  if (localStorage.getItem("client_id") === "000") {
    var apiUrlall = API + "api/questions/";
  } else {
    apiUrlall = API + "api/questions/";
  }

  var apiUrldelete = API + "api/questionsdelete/";

  useEffect(() => {
    const fetchData = async () => {
      var postData = {
        survey_id: props.match.params.id,
      };
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      };
      axios.post(apiUrlall, postData, axiosConfig).then((result) => {
        setData(result.data);
        let count = Object.keys(result.data).length;
        setcount(count);
        setShowLoading(false);
      });
    };

    fetchData();
  }, []);

  const loadData = async (offsetS, current) => {
    var off1 = offsetS;

    var curval;
    if (!current) {
      curval = 1;
    } else {
      curval = current;
    }
    setcurrent(curval);
    $.ajax({
      url: apiUrl,
      data: {
        limit: 20,
        offset: off1,
        survey_id: props.match.params.id,
      },
      dataType: "json",
      type: "POST",
      headers: {
        Authorization: localStorage.getItem("token"),
      },

      success: (data) => {
        setcurrData(data);
      },

      error: (xhr, status, err) => {
        console.error(this.props.url, status, err.toString()); // eslint-disable-line
      },
    });
  };

  useEffect(() => {
    loadData();
  }, [data]);

  const handlePageClick = (data) => {
    let offsetS = Math.ceil(data * 20 - 20);
    loadData(offsetS, data);
  };

  const editquestion = (id) => {
    localStorage.setItem("clientid", props.match.params.client_id);
    props.history.push({
      pathname: "/question/" + id,
    });
  };

  const deletequestion = (id) => {
    var postData = {
      id: id,
    };
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };
    axios.post(apiUrldelete, postData, axiosConfig).then((result) => {
      setData(result.data);
      let count = Object.keys(result.data).length;
      setcount(count);
      toast(result.data.message);
      setShowLoading(false);
    });
  };

  const addquestion = (id) => {
    localStorage.setItem("clientid", props.match.params.client_id);
    props.history.push({
      pathname: "/add-question/" + props.match.params.client_id,
    });
  };

  const back = () => {
    props.history.push({
      pathname: "/client-survey/" + props.match.params.client_id,
    });
  };

  return (
    <LoadingOverlay
      active={showLoading}
      spinner={<RiseLoader sizeUnit={"px"} size={80} color={"#2a8dd1"} />}
    >
      <>
        {/* <Notice icon="flaticon-warning kt-font-primary">
        For more info please check the components's official{" "}
      </Notice> */}

        <div className="row">
          <div className="col-md-12">
            <CodeExample beforeCodeTitle="Question List">
              <div className="kt-section">
                <Button
                  variant="primary"
                  className="pull-right btn btn-primary"
                  onClick={() => {
                    back();
                  }}
                >
                  {" "}
                  Back
                </Button>
                <div className="col-md-2">
                  <button
                    onClick={() => addquestion()}
                    className=" form-control col-md-12 btn btn-warning"
                  >
                    Add Question
                  </button>
                </div>
                <div className="kt-separator kt-separator--dashed responsive"></div>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Sr. No.</th>
                      <th>Question</th>
                      <th>Order No. </th>
                      <th>Options</th>
                      <th>Location Name</th>
                      <th>Question Type</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currdata.map((item, idx) => (
                      <tr key={idx}>
                        <td>{idx + 1 + (current - 1) * 20}</td>
                        <td>{item.question}</td>
                        <td>{item.seq_no}</td>
                        <td>
                          {item.option1 +
                            ", " +
                            item.option2 +
                            ", " +
                            item.option3 +
                            ", " +
                            item.option4 +
                            ", " +
                            item.option5 +
                            ", " +
                            item.option6 +
                            ", " +
                            item.option7 +
                            ", " +
                            item.option8 +
                            ", " +
                            +item.option9 +
                            ", " +
                            item.option10}
                        </td>
                        <td>
                          {item.cp_location
                            ? item.cp_location.add1 +
                              "," +
                              item.cp_location.city
                            : " All Location "}
                        </td>{" "}
                        <td>{item.question_type}</td>
                        <td>
                          <i
                            onClick={() => {
                              editquestion(item.id);
                            }}
                            className="flaticon2-pen"
                          ></i>{" "}
                          <i
                            onClick={() => {
                              deletequestion(item.id);
                            }}
                            className="flaticon-delete-1"
                          ></i>{" "}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Pagination
                  showQuickJumper
                  showSizeChanger
                  onChange={handlePageClick}
                  current={current}
                  total={count}
                  pageSize={20}
                  locale={localeInfo}
                />
              </div>
            </CodeExample>
          </div>
        </div>
      </>
    </LoadingOverlay>
  );
};
export default Survey;
