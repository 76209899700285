import React, { useState, useEffect, Fragment } from "react";
import { Form } from "react-bootstrap";
import axios from "axios";
import { API, BASE_URL } from "../../../../const";
import "react-toastify/dist/ReactToastify.css";
import Validator from "validatorjs";
import { Image } from "react-bootstrap";
import { toAbsoluteUrl } from "./../../../../_metronic";
import CodeExample from "../../../partials/content/CodeExample";
const Review = (props) => {
  let linkUrl = localStorage.getItem("linkUrl");
  // //console.log(BASE_URL+linkUrl);
  const [client, setClient] = useState([]);
  const [dataNew, setData] = useState({
    client_id: "",
    cust_id: "",
    user_id: "",
    location_id: "",
  })

  const [cplinkData, setcplinkData] = useState({
    fb: "",
    google: "",
    yelp: "",
    zillow: "",
    survey: "",
    yp: "",
    trip: "",
    yell: "",
    sf: "",
    fb2: "",
    google2: "",
    realtor: "",
    apar: "",
    aparrate: "",
    great_schools: "",
    healthgrades: "",
    mysite: "",
    thumbtack: "",
    amazon: "",
    home_advisor: "",
    solar_review: "",
    energysage: "",
  });
  const apiUrlgetdata = API + "api/getreviewdata/"; //+ props.match.params.id +  props.location.search;
  const apiUrlgetdataSaveClicks = API + "api/saveclicks";
  useEffect(() => {
    var postData = {
      link: BASE_URL + linkUrl,
    };
    const getinvitedata = async () => {
      const result = await axios.post(apiUrlgetdata, postData);
      //console.log(result.data.data);
      let linkData = result.data.data;
      if (result.data.data.cp_client) {
        setClient(result.data.data.cp_client);
      } else {
        setClient({});
      }
      setData({
        client_id: linkData.client_id,
        cust_id: linkData.cust_id,
        user_id: linkData.user_id,
        location_id: linkData.location_id,
      });
      setcplinkData({
        fb: linkData.fb,
        google: linkData.google,
        yelp: linkData.yelp,
        zillow: linkData.zillow,
        survey: linkData.survey,
        yp: linkData.yp,
        trip: linkData.trip,
        yell: linkData.yell,
        sf: linkData.sf,
        fb2: linkData.fb2,
        google2: linkData.google2,
        realtor: linkData.realtor,
        apar: linkData.apar,
        aparrate: linkData.aparrate,
        great_schools: linkData.great_schools,
        healthgrades: linkData.healthgrades,
        mysite: linkData.mysite,
        thumbtack: linkData.thumbtack,
        amazon: linkData.amazon,
        home_advisor: linkData.home_advisor,
        solar_review: linkData.solar_review,
        energysage : linkData.energysage
      });
    };
    getinvitedata();
  }, []);

  const save_click = async (data) => {
    var postData = {
      client_id: dataNew.client_id,
      location: dataNew.location_id,
      link_type: data,
      cust_id: dataNew.cust_id,
      user_id: dataNew.user_id,
    };


    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };
    axios
      .post(apiUrlgetdataSaveClicks, postData, axiosConfig)
      .then((responseData) => {
        //console.log(responseData);
      });
  };

  const [formdata, setformdata] = useState({
    client_id: "",
    l_name: "",
   location_logo:"",
    add1: "",
    add2: "",
    home_advisor: "",
    solar_review: "",
    energysage: "",
    state: "",
    city: "",
    zip: "",
    fb_link: "",
    view_fb2: "",
    yelp_link: "",
    google_link: "",
    google_link2: "",
    zillow_link: "",
    yp_link: "",
    trip_link: "",
    yell_link: "",
    sf_link: "",
    real_link: "",
    apar_link: "",
    aparrate_link: "",
    great_schools_link: "",
    healthgrades_link: "",
    mysite_link: "",
    thumbtack_link: "",
    amazon_link: "",
    fb_account: "",
    fb_account2: "",
    yelp_account: "",
    google_account: "",
    google_account2: "",
    zillow_account: "",
    yp_account: "",
    trip_account: "",
    yell_account: "",
    sf_account: "",
    real_account: "",
    apar_account: "",
    aparrate_account: "",
    great_schools_account: "",
    healthgrades_account: "",
    mysite_account: "",
    thumbtack_account: "",
    amazon_account: "",
    c_no: "",
    c_email: "",
    msg: "",
    msg1: "",
    sms_msg: "",
    sms_msg1: "",
    google_count: 0,
    yelp_count: 0,
    fb_count: 0,
    fb_count2: "",
    zillow_count: 0,
    yp_count: 0,
    trip_count: 0,
    yell_count: 0,
    sf_count: 0,
    real_count: 0,
    apar_count: 0,
    aparrate_count: 0,
    great_schools_count: 0,
    healthgrades_count: 0,
    thumbtack_count: 0,
    amazon_count: 0,
    google_ratings: "",
    google_ratings2: "",
    yelp_ratings: "",
    fb_ratings: "",
    fb_ratings2: "",
    zillow_ratings: "",
    yp_ratings: "",
    trip_ratings: "",
    yell_ratings: "",
    real_ratings: "",
    apar_ratings: "",
    aparrate_ratings: "",
    great_schools_ratings: "",
    healthgrades_ratings: "",
    mysite_ratings: "",
    thumbtack_ratings: "",
    amazon_ratings: "",
    google_date: "",
    google_date2: "",
    yelp_date: "",
    fb_date: "",
    fb_date2: "",
    zillow_date: "",
    yp_date: "",
    sf_date: "",
    apar_date: "",
    aparrate_date: "",
    real_date: "",
    trip_date: "",
    yell_date: "",
    great_schools_date: "",
    healthgrades_date: "",
    mysite_date: "",
    thumbtack_date: "",
    amazon_date: "",
    view_google: "",
    view_google2: "",
    view_yelp: "",
    view_fb: "",
    view_zillow: "",
    view_yp: "",
    view_trip: "",
    view_yell: "",
    view_sf: "",
    view_real: "",
    view_apar: "",
    view_aparrate: "",
    view_great_schools: "",
    view_healthgrades: "",
    view_mysite: "",
    view_thumbtack: "",
    view_amazon: "",
    setname: "",
    businees_name: "",
    color_code: "",
    logo: "",
    review_msg: "",
    yes_msg: "",
    no_msg: "",
    subject: "",
    subject1: "",
    sign: "",
    sign1: "",
    thanks_msg: "",
    thanks_msg1: "",
    sendlogo: "",
    sendlogo1: "",
    sendlogoemail: "",
    sendlogoemail1: "",
    underlogo: "",
    coupon: "",
    setfeed: "",
    loc_code: "",
    corp_code: "",
    corp_pass: "",
    corp_login: "",
    when_to_send: "",
    auto_sent_text: "",
    id: "",
    background: "",
    sf_ratings: "",
    mysite_count: 0,
  });

  const apiUrl = API + "api/location_data_byid_for_review";

  useEffect(() => {
    const fetchData = async () => {
      var postData = {
        id: props.match.params.id,
      };

      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      };

   
      const result = await axios
        .post(apiUrl, postData, axiosConfig)
        .then((res) => {
          if (res.data) {
            //console.log(res.data.location);
            // setformdata(res.data.location);
            setformdata({
              client_id: res.data.location.client_id,
              l_name: res.data.location.l_name,
              add1: res.data.location.add1,
              add2: res.data.location.add2,
              location_logo:res.data.location.logo,
              home_advisor: res.data.location.home_advisor,
              solar_review: res.data.location.solar_review,
              energysage : res.data.location.energysage,
              state: res.data.location.state,
              city: res.data.location.city,
              zip: res.data.location.zip,
              fb_link: res.data.location.fb_link,
              view_fb2: res.data.location.view_fb2,
              yelp_link: res.data.location.yelp_link,
              google_link: res.data.location.google_link,
              google_link2: res.data.location.google_link2,
              zillow_link: res.data.location.zillow_link,
              yp_link: res.data.location.yp_link,
              trip_link: res.data.location.trip_link,
              yell_link: res.data.location.yell_link,
              sf_link: res.data.location.sf_link,
              real_link: res.data.location.real_link,
              apar_link: res.data.location.apar_link,
              aparrate_link: res.data.location.aparrate_link,
              great_schools_link: res.data.location.great_schools_link,
              healthgrades_link: res.data.location.healthgrades_link,
              mysite_link: res.data.location.mysite_link,
              thumbtack_link: res.data.location.thumbtack_link,
              amazon_link: res.data.location.amazon_link,
              fb_account: res.data.location.fb_account,
              fb_account2: res.data.location.fb_account2,
              yelp_account: res.data.location.yelp_account,
              google_account: res.data.location.google_account,
              google_account2: res.data.location.google_account2,
              zillow_account: res.data.location.zillow_account,
              yp_account: res.data.location.yp_account,
              trip_account: res.data.location.trip_account,
              yell_account: res.data.location.yell_account,
              sf_account: res.data.location.sf_account,
              real_account: res.data.location.real_account,
              apar_account: res.data.location.apar_account,
              aparrate_account: res.data.location.aparrate_account,
              great_schools_account: res.data.location.great_schools_account,
              healthgrades_account: res.data.location.healthgrades_account,
              mysite_account: res.data.location.mysite_account,
              thumbtack_account: res.data.location.thumbtack_account,
              amazon_account: res.data.location.amazon_account,
              c_no: res.data.location.c_no,
              c_email: res.data.location.c_email,
              msg: res.data.location.msg,
              msg1: res.data.location.msg1,
              sms_msg: res.data.location.sms_msg,
              sms_msg1: res.data.location.sms_msg1,
              google_count: res.data.location.google_count,
              yelp_count: res.data.location.yelp_count,
              fb_count: res.data.location.fb_count,
              fb_count2: res.data.location.fb_count2,
              zillow_count: res.data.location.zillow_count,
              yp_count: res.data.location.yp_count,
              trip_count: res.data.location.trip_count,
              yell_count: res.data.location.yell_count,
              sf_count: res.data.location.sf_count,
              real_count: res.data.location.real_count,
              apar_count: res.data.location.apar_count,
              aparrate_count: res.data.location.aparrate_count,
              great_schools_count: res.data.location.great_schools_count,
              healthgrades_count: res.data.location.healthgrades_count,
              thumbtack_count: res.data.location.thumbtack_count,
              amazon_count: res.data.location.amazon_count,
              google_ratings: res.data.location.google_ratings,
              google_ratings2: res.data.location.google_ratings2,
              yelp_ratings: res.data.location.yelp_ratings,
              fb_ratings: res.data.location.fb_ratings,
              fb_ratings2: res.data.location.fb_ratings2,
              zillow_ratings: res.data.location.zillow_ratings,
              yp_ratings: res.data.location.yp_ratings,
              trip_ratings: res.data.location.trip_ratings,
              yell_ratings: res.data.location.yell_ratings,
              real_ratings: res.data.location.real_ratings,
              apar_ratings: res.data.location.apar_ratings,
              aparrate_ratings: res.data.location.aparrate_ratings,
              great_schools_ratings: res.data.location.great_schools_ratings,
              healthgrades_ratings: res.data.location.healthgrades_ratings,
              mysite_ratings: res.data.location.mysite_ratings,
              thumbtack_ratings: res.data.location.thumbtack_ratings,
              amazon_ratings: res.data.location.amazon_ratings,
              google_date: res.data.location.google_date,
              google_date2: res.data.location.google_date2,
              yelp_date: res.data.location.yelp_date,
              fb_date: res.data.location.fb_date,
              fb_date2: res.data.location.fb_date2,
              zillow_date: res.data.location.zillow_date,
              yp_date: res.data.location.yp_date,
              sf_date: res.data.location.sf_date,
              apar_date: res.data.location.apar_date,
              aparrate_date: res.data.location.aparrate_date,
              real_date: res.data.location.real_date,
              trip_date: res.data.location.trip_date,
              yell_date: res.data.location.yell_date,
              great_schools_date: res.data.location.great_schools_date,
              healthgrades_date: res.data.location.healthgrades_date,
              mysite_date: res.data.location.mysite_date,
              thumbtack_date: res.data.location.thumbtack_date,
              amazon_date: res.data.location.amazon_date,
              view_google: res.data.location.view_google,
              view_google2: res.data.location.view_google2,
              view_yelp: res.data.location.view_yelp,
              view_fb: res.data.location.view_fb,
              view_zillow: res.data.location.view_zillow,
              view_yp: res.data.location.view_yp,
              view_trip: res.data.location.view_trip,
              view_yell: res.data.location.view_yell,
              view_sf: res.data.location.view_sf,
              view_real: res.data.location.view_real,
              view_apar: res.data.location.view_apar,
              view_aparrate: res.data.location.view_aparrate,
              view_great_schools: res.data.location.view_great_schools,
              view_healthgrades: res.data.location.view_healthgrades,
              view_mysite: res.data.location.view_mysite,
              view_thumbtack: res.data.location.view_thumbtack,
              view_amazon: res.data.location.view_amazon,
              setname: res.data.location.setname,
              businees_name: res.data.location.businees_name,
              color_code: res.data.location.color_code,
              logo: res.data.location.logo,
              review_msg: res.data.location.review_msg,
              yes_msg: res.data.location.yes_msg,
              no_msg: res.data.location.no_msg,
              subject: res.data.location.subject,
              subject1: res.data.location.subject1,
              sign: res.data.location.sign,
              sign1: res.data.location.sign1,
              thanks_msg: res.data.location.thanks_msg,
              thanks_msg1: res.data.location.thanks_msg1,
              sendlogo: res.data.location.sendlogo,
              sendlogo1: res.data.location.sendlogo1,
              sendlogoemail: res.data.location.sendlogoemail,
              sendlogoemail1: res.data.location.sendlogoemail1,
              underlogo: res.data.location.underlogo,
              coupon: res.data.location.coupon,
              setfeed: res.data.location.setfeed,
              loc_code: res.data.location.loc_code,
              corp_code: res.data.location.corp_code,
              corp_pass: res.data.location.corp_pass,
              corp_login: res.data.location.corp_login,
              when_to_send: res.data.location.when_to_send,
              auto_sent_text: res.data.location.auto_sent_text,
              
              id: res.data.location.id,
              background: res.data.location.color_code,
              sf_ratings: res.data.location.sf_ratings,
            });
          }
        })
        .catch((err) => {
          //console.log("AXIOS ERROR: ", err);
        });
    };
    fetchData();
  }, []);

  //console.log(formdata);

  const renderIcons = () => {
    let tempArr = [];
    if (formdata.fb_link.length > 0 && cplinkData.fb === 1) {
      tempArr.push(
        <div className="row text-center" id="kt-list__inr">
          <div className="col-md-3">
            <Image
              src={toAbsoluteUrl("/media/review-icons/fb.png")}
              width="80px"
              alt="Facebook"
            />
          </div>
          <div className="col-md-9">
            <a
              href={formdata.fb_link}
              className="btn btn-secondary btn-review btn-block btn-lg"
              target="_blank"
              onClick={() => save_click("fb")}
            >
              Write a Review
            </a>
          </div>
        </div>
      );
    }

    if (formdata.home_advisor.length > 0 && cplinkData.home_advisor === 1) {
      tempArr.push(
        <div className="row text-center" id="kt-list__inr">
          <div className="col-md-3">
            <Image
              src={toAbsoluteUrl("/media/review-icons/home-advisor.png")}
              width="80px"
              alt="Home Advisor"
            />
          </div>
          <div className="col-md-9">
            <a
              href={formdata.home_advisor}
              className="btn btn-secondary btn-review btn-block btn-lg"
              target="_blank"
              onClick={() => save_click("home_advisor")}
            >
              Write a Review
            </a>
          </div>
        </div>
      );
    }


    if (formdata?.energysage?.length > 0 && cplinkData?.energysage === 1) {
      tempArr.push(
        <div className="row text-center" id="kt-list__inr">
          <div className="col-md-3">
            <Image
              src={toAbsoluteUrl("/media/review-icons/energysage.png")}
              width="80px"
              alt="energysage"
            />
          </div>
          <div className="col-md-9">
            <a
              href={formdata.energysage}
              className="btn btn-secondary btn-review btn-block btn-lg"
              target="_blank"
              onClick={() => save_click("energysage")}
            >
              Write a Review
            </a>
          </div>
        </div>
      );
    }


    if (formdata.solar_review.length > 0 && cplinkData.solar_review === 1) {
      tempArr.push(
        <div className="row text-center" id="kt-list__inr">
          <div className="col-md-3">
            <Image
              src={toAbsoluteUrl("/media/review-icons/Solar.png")}
              width="80px"
              alt="Solar"
            />
          </div>
          <div className="col-md-9">
            <a
              href={formdata.solar_review}
              className="btn btn-secondary btn-review btn-block btn-lg"
              target="_blank"
              onClick={() => save_click("solar_review")}
            >
              Write a Review
            </a>
          </div>
        </div>
      );
    }



    if (formdata.google_link.length > 0 && cplinkData.google === 1) {
      tempArr.push(
        <div className="row text-center" id="kt-list__inr">
          <div className="col-md-3">
            <Image
              src={toAbsoluteUrl("/media/review-icons/google.png")}
              width="80px"
              alt="Google"
            />
          </div>
          <div className="col-md-9">
            <a
              href={formdata.google_link}
              className="btn btn-secondary btn-review btn-block btn-lg"
              target="_blank"
              onClick={() => save_click("google")}
            >
              Write a Review
            </a>
          </div>
        </div>
      );
    }
    if (formdata.yelp_link.length > 0 && cplinkData.yelp === 1) {
      tempArr.push(
        <div className="row text-center" id="kt-list__inr">
          <div className="col-md-3">
            <Image
              src={toAbsoluteUrl("/media/review-icons/yelp.png")}
              width="80px"
              alt="Yelp"
            />
          </div>
          <div className="col-md-9">
            <a
              href={formdata.yelp_link}
              className="btn btn-secondary btn-review btn-block btn-lg"
              target="_blank"
              onClick={() => save_click("yelp")}
            >
              Write a Review
            </a>
          </div>
        </div>
      );
    }
    if (formdata.zillow_link.length > 0 && cplinkData.zillow === 1) {
      tempArr.push(
        <div className="row text-center" id="kt-list__inr">
          <div className="col-md-3">
            <Image
              src={toAbsoluteUrl("/media/review-icons/zillow.png")}
              width="80px"
              alt="Zillow"
            />
          </div>
          <div className="col-md-9">
            <a
              href={formdata.zillow_link}
              className="btn btn-secondary btn-review btn-block btn-lg"
              target="_blank"
              onClick={() => save_click("zillow")}
            >
              Write a Review
            </a>
          </div>
        </div>
      );
    }
    if (formdata.yp_link.length > 0 && cplinkData.yp === 1) {
      tempArr.push(
        <div className="row text-center" id="kt-list__inr">
          <div className="col-md-3">
            <Image
              src={toAbsoluteUrl("/media/review-icons/yp.png")}
              width="80px"
              alt="YP"
            />
          </div>
          <div className="col-md-9">
            <a
              href={formdata.yp_link}
              className="btn btn-secondary btn-review btn-block btn-lg"
              target="_blank"
              onClick={() => save_click("yp")}
            >
              Write a Review
            </a>
          </div>
        </div>
      );
    }
    if (formdata.trip_link.length > 0 && cplinkData.trip === 1) {
      tempArr.push(
        <div className="row text-center" id="kt-list__inr">
          <div className="col-md-3">
            <Image
              src={toAbsoluteUrl("/media/review-icons/trip.png")}
              width="80px"
              alt="Trip"
            />
          </div>
          <div className="col-md-9">
            <a
              href={formdata.trip_link}
              className="btn btn-secondary btn-review btn-block btn-lg"
              target="_blank"
              onClick={() => save_click("trip")}
            >
              Write a Review
            </a>
          </div>
        </div>
      );
    }
    if (formdata.yell_link.length > 0 && cplinkData.yell === 1) {
      tempArr.push(
        <div className="row text-center" id="kt-list__inr">
          <div className="col-md-3">
            <Image
              src={toAbsoluteUrl("/media/review-icons/yell.png")}
              width="80px"
              alt="Yell"
            />
          </div>
          <div className="col-md-9">
            <a
              href={formdata.yell_link}
              className="btn btn-secondary btn-review btn-block btn-lg"
              target="_blank"
              onClick={() => save_click("yell")}
            >
              Write a Review
            </a>
          </div>
        </div>
      );
    }

    if (formdata.sf_link.length > 0 && cplinkData.sf === 1) {
      tempArr.push(
        <div className="row text-center" id="kt-list__inr">
          <div className="col-md-3">
            <Image
              src={toAbsoluteUrl("/media/review-icons/sf.png")}
              width="80px"
              alt="SF"
            />
          </div>
          <div className="col-md-9">
            <a
              href={formdata.sf_link}
              className="btn btn-secondary btn-review btn-block btn-lg"
              target="_blank"
              onClick={() => save_click("sf")}
            >
              Write a Review
            </a>
          </div>
        </div>
      );
    }
    if (formdata.view_fb2.length > 0 && cplinkData.fb2 === 1) {
      tempArr.push(
        <div className="row text-center" id="kt-list__inr">
          <div className="col-md-3">
            <Image
              src={toAbsoluteUrl("/media/review-icons/fb.png")}
              width="80px"
              alt="Facebook 2"
            />
          </div>
          <div className="col-md-9">
            <a
              href={formdata.view_fb2}
              className="btn btn-secondary btn-review btn-block btn-lg"
              target="_blank"
              onClick={() => save_click("fb2")}
            >
              Write a Review
            </a>
          </div>
        </div>
      );
    }

    if (formdata.google_link2.length > 0 && cplinkData.google2 === 1) {
      tempArr.push(
        <div className="row text-center" id="kt-list__inr">
          <div className="col-md-3">
            <Image
              src={toAbsoluteUrl("/media/review-icons/google.png")}
              width="80px"
              alt="Google 2"
            />
          </div>
          <div className="col-md-9">
            <a
              href={formdata.google_link2}
              className="btn btn-secondary btn-review btn-block btn-lg"
              target="_blank"
              onClick={() => save_click("google2")}
            >
              Write a Review
            </a>
          </div>
        </div>
      );
    }

    if (formdata.real_link.length > 0 && cplinkData.realtor === 1) {
      tempArr.push(
        <div className="row text-center" id="kt-list__inr">
          <div className="col-md-3">
            <Image
              src={toAbsoluteUrl("/media/review-icons/real.png")}
              width="80px"
              alt="Real"
            />
          </div>
          <div className="col-md-9">
            <a
              href={formdata.real_link}
              className="btn btn-secondary btn-review btn-block btn-lg"
              target="_blank"
              onClick={() => save_click("real")}
            >
              Write a Review
            </a>
          </div>
        </div>
      );
    }
    if (formdata.apar_link.length > 0 && cplinkData.apar === 1) {
      tempArr.push(
        <div className="row text-center" id="kt-list__inr">
          <div className="col-md-3">
            <Image
              src={toAbsoluteUrl("/media/review-icons/apar.png")}
              width="80px"
              alt="Apar"
            />
          </div>
          <div className="col-md-9">
            <a
              href={formdata.apar_link}
              className="btn btn-secondary btn-review btn-block btn-lg"
              target="_blank"
              onClick={() => save_click("apar")}
            >
              Write a Review
            </a>
          </div>
        </div>
      );
    }
    if (formdata.aparrate_link.length > 0 && cplinkData.aparrate === 1) {
      tempArr.push(
        <div className="row text-center" id="kt-list__inr">
          <div className="col-md-3">
            <Image
              src={toAbsoluteUrl("/media/review-icons/aparrate.png")}
              width="80px"
              alt="Aparrate"
            />
          </div>
          <div className="col-md-9">
            <a
              href={formdata.aparrate_link}
              className="btn btn-secondary btn-review btn-block btn-lg"
              target="_blank"
              onClick={() => save_click("aparrate")}
            >
              Write a Review
            </a>
          </div>
        </div>
      );
    }
    if (
      formdata.great_schools_link.length > 0 &&
      cplinkData.great_schools === 1
    ) {
      tempArr.push(
        <div className="row text-center" id="kt-list__inr">
          <div className="col-md-3">
            <Image
              src={toAbsoluteUrl("/media/review-icons/great_schools.png")}
              width="80px"
              alt="Great schools"
            />
          </div>
          <div className="col-md-9">
            <a
              href={formdata.great_schools_link}
              className="btn btn-secondary btn-review btn-block btn-lg"
              target="_blank"
              onClick={() => save_click("great_schools")}
            >
              Write a Review
            </a>
          </div>
        </div>
      );
    }
    if (
      formdata.healthgrades_link.length > 0 &&
      cplinkData.healthgrades === 1
    ) {
      tempArr.push(
        <div className="row text-center" id="kt-list__inr">
          <div className="col-md-3">
            <Image
              src={toAbsoluteUrl("/media/review-icons/healthgrades.png")}
              width="80px"
              alt="Healthgrades"
            />
          </div>
          <div className="col-md-9">
            <a
              href={formdata.healthgrades_link}
              className="btn btn-secondary btn-review btn-block btn-lg"
              target="_blank"
              onClick={() => save_click("healthgrades")}
            >
              Write a Review
            </a>
          </div>
        </div>
      );
    }
    if (formdata.mysite_link.length > 0 && cplinkData.mysite === 1) {
      tempArr.push(
        <div className="row text-center" id="kt-list__inr">
          <div className="col-md-3">
            <Image
              src={toAbsoluteUrl("/media/review-icons/mysite.png")}
              width="80px"
              alt="Mysite"
            />
          </div>
          <div className="col-md-9">
            <a
              href={formdata.mysite_link}
              className="btn btn-secondary btn-review btn-block btn-lg"
              target="_blank"
              onClick={() => save_click("mysite")}
            >
              Write a Review
            </a>
          </div>
        </div>
      );
    }
    if (formdata.thumbtack_link.length > 0 && cplinkData.thumbtack === 1) {
      tempArr.push(
        <div className="row text-center" id="kt-list__inr">
          <div className="col-md-3">
            <Image
              src={toAbsoluteUrl("/media/review-icons/thumbtack.png")}
              width="80px"
              alt="Thumbtack"
            />
          </div>
          <div className="col-md-9">
            <a
              href={formdata.thumbtack_link}
              className="btn btn-secondary btn-review btn-block btn-lg"
              target="_blank"
              onClick={() => save_click("thumbtack")}
            >
              Write a Review
            </a>
          </div>
        </div>
      );
    }
    if (formdata.amazon_link.length > 0 && cplinkData.amazon === 1) {
      tempArr.push(
        <div className="row text-center" id="kt-list__inr">
          <div className="col-md-3">
            <Image
              src={toAbsoluteUrl("/media/review-icons/amazon.png")}
              width="80px"
              alt="Amazon"
            />
          </div>
          <div className="col-md-9">
            <a
              href={formdata.amazon_link}
              className="btn btn-secondary btn-review btn-block btn-lg"
              target="_blank"
              onClick={() => save_click("amazon")}
            >
              Write a Review
            </a>
          </div>
        </div>
      );
    }
    if (tempArr.length === 0) {
      tempArr.push(
        <div className="row text-center" id="kt-list__inr">
          <div className="col-md-3">No data found</div>
        </div>
      );
    }
    return tempArr;
  };

  return (
    <>
      <div className="review-wrap">
        <div className="review-logo__wrap">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
              {formdata.location_logo ? (
                <div className="review-logo">
                  <Image
                    src={client && API + "api/" + formdata.location_logo}
                    className="img-fluid"
                    alt={client.company_name}
                  />
                </div>
                ) : (
                    <div className="review-logo">
                    <Image
                      src={client && API + "api/" + client.client_logo}
                      className="img-fluid"
                      alt={client.company_name}
                    />
                  </div>
                  )}
              </div>
              {/* <div className="col-md-6">
                <p className="info_txt text-right">
                  We'd love to hear from you
                </p>
              </div> */}
            </div>
          </div>
        </div>
        <div className="thank-txt">
          <p>
            Thank you for your business! Please select one of the options below
            to leave a review.
          </p>
        </div>
        <div className="container">
          <CodeExample beforeCodeTitle="">
            <div className="kt-section">
              <span className="kt-section__sub"></span>
              <br></br>
              <div className="kt-review__list">{renderIcons()}</div>
            </div>
          </CodeExample>
        </div>
      </div>
    </>
  );
};
export default Review;
