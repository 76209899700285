import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";

import axios from "axios";
import { API } from "../../../../const";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Validator from "validatorjs";
import "./form.css";
toast.configure();

const Surveyform = (props) => {
  const [style, setstyle] = useState("hidden");
  const [formdata, setformdata] = useState({
    client_name1: "",
    loc_name: "",
    survey_name: "",
    active: "",
    url_field: "",
    comments: "",
    text_msg: "",
    msg: "",
    sur_temp: "",
  });

  //console.log(props)

  const validateForm = () => {
    let data = {
      survey_name: formdata.survey_name,
      active: formdata.active,
      url_field: formdata.url_field,
      comments: formdata.comments,
      text_msg: formdata.text_msg,
      email_msg: formdata.email_msg,
      sur_temp: formdata.sur_temp,
    };

    let rules = {
      url_field: "required",
      active:"required"
    };

    let validation = new Validator(data, rules);

    if (validation.passes()) {
      return true;
    }
  };

  useEffect(() => {
    setformdata(props.data);
  }, [props.data]);

  useEffect(() => {
    if (formdata.sitelink === 1) {
      setstyle("show");
    } else {
      setstyle("hidden");
    }
  }, [formdata.sitelink]);

  const back = () => {
    if (props.data.client_id !== "000") {
      props.history.push({
        pathname: "/client-survey/" + props.data.client_id,
      });
    } else {
      props.history.push({
        pathname: "/client-survey/" + props.client_id,
      });
    }
  };
  const handleChange = (event) => {
    setformdata({ ...formdata, [event.target.id]: event.target.value });
  };

  const formSubmit = async () => {
    var postData = {
      id: props.id,
      client_name1: formdata.client_name1,
      loc_name: formdata.loc_name,
      survey_name: formdata.survey_name,
      active: formdata.active,
      url_field: formdata.url_field,
      comments: formdata.comments,
      text_msg: formdata.text_msg,
      msg: formdata.msg,
      sur_temp: formdata.sur_temp,
      client_id: props.client_id,
      users_id: localStorage.getItem("id"),
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };
    const apiedit = API + "api/surveyUpdate";
    const apiadd = API + "api/surveyadd";
    if (props.id) {
      axios
        .post(apiedit, postData, axiosConfig)
        .then((res) => {
          toast(res.data.message);
          back();
        })
        .catch((err) => {
          //console.log("AXIOS ERROR: ", err);
        });
    } else {
      axios
        .post(apiadd, postData, axiosConfig)
        .then((res) => {
          toast(res.data.message);
          back();
        })
        .catch((err) => {
          //console.log("AXIOS ERROR: ", err);
        });
    }
  };

  return (
    <div>
      <Form encType="multipart/form-data">
        <div className="review">
          <div className="row">
            <div className="col-md-12">
              <div className="card border-top-0 border-right-0 border-left-0 pb-4">
                <div className="card-header bg-white ">
                  <div className="row">
                    <div className="col-md-9">
                      <h5 className="card-title mb-0 pt-2">Survey</h5>
                    </div>
                    <div className="col-md-3 text-right">
                      <input
                        type="button"
                        onClick={() => back()}
                        value="Back"
                        className="margin btn btn-primary save-btn font-weight-bold"
                      />
                      <input
                        type="button"
                        disabled={!validateForm()}
                        onClick={() => formSubmit()}
                        value="Save"
                        className="btn btn-success save-btn font-weight-bold"
                      />
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div id="introduction">
                    <div className="row">
                      <div className="form-group col-md-6 ">
                        <input
                          type="text"
                          name="client_name1"
                          id="client_name1"
                          className="form-control"
                          placeholder="Company Name"
                          value={props.client}
                          readOnly
                          onChange={handleChange}
                        />
                        <label>Company Name * :</label>
                      </div>
                      <div className="form-group col-md-6 ">
                        <select
                          className="form-control"
                          id="loc_name"
                          name="loc_name"
                          onChange={handleChange}
                        >
                          <option value="">Please Select Location :</option>
                          <option value="0">All Locations</option>

                          {props.location &&
                            props.location.map((item, idx) => (
                              <option
                                selected={formdata.loc_name == item.id}
                                key={idx}
                                value={item.id}
                              >
                                {item.add1 + ", " + item.city}
                              </option>
                            ))}
                        </select>
                        <label>Location Name</label>
                      </div>
                      {props.data.url_field === "" ? (
                        <div className="form-group col-md-6 ">
                          <select
                            className="form-control"
                            id="sur_temp"
                            name="sur_temp"
                            onChange={handleChange}
                          >
                            <option value="">Please Select Survey :</option>
                            <option value="0">For All Locations :</option>
                            {props.survey &&
                              props.survey.map((item, idx) => (
                                <option
                                  selected={formdata.sur_temp === item.id}
                                  key={idx}
                                  value={item.id}
                                >
                                  {item.survey_name}
                                </option>
                              ))}
                          </select>
                          <label>Survey Template :</label>
                        </div>
                      ) : (
                        <div> </div>
                      )}

                      <div className="form-group col-md-6 ">
                        <input
                          type="text"
                          name="survey_name"
                          id="survey_name"
                          className="form-control"
                          placeholder="Survey Name"
                          value={formdata.survey_name}
                          onChange={handleChange}
                        />
                        <label>Survey Name * :</label>
                      </div>
                      <div className="form-group col-md-6 ">
                        <select
                          className="form-control"
                          id="active"
                          name="active"
                          onChange={handleChange}
                        >
                          <option value="">Please Select Status :</option>
                          <option value="1"  key={1} value="1">
                            Yes
                          </option>
                          <option value="0" key={2} value="2">
                            No
                          </option>
                        </select>
                        <label>Active </label>
                      </div>
                      <div className="form-group col-md-6 ">
                        <input
                          type="text"
                          name="url_field"
                          id="url_field"
                          className="form-control"
                          placeholder="Survey URL field"
                          value={formdata.url_field}
                          onChange={handleChange}
                        />
                        <label>Survey URL field * :</label>
                      </div>

                      <div className="form-group col-md-6 ">
                        <textarea
                          type="textarea"
                          name="comments"
                          id="comments"
                          className="form-control"
                          value={formdata.comments}
                          onChange={handleChange}
                        />
                        <label>Survey Comments / Instructions  :</label>
                      </div>
                      {/* <div className="form-group col-md-6 ">
                        <textarea
                          type="textarea"
                          name="text_msg"
                          id="text_msg"
                          className="form-control"
                          value={formdata.text_msg}
                          onChange={handleChange}
                        />
                        <label>Text Msg Survey :</label>
                      </div>
                      <div className="form-group col-md-6 ">
                        <textarea
                          type="textarea"
                          name="msg"
                          id="msg"
                          className="form-control"
                          value={formdata.msg}
                          onChange={handleChange}
                        />
                        <label>Email Msg Survey :</label>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};
export default Surveyform;
