import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import axios from "axios";
import { API } from "../../../../const";
import "react-toastify/dist/ReactToastify.css";
import Validator from "validatorjs";

import "./form.css";
const ClientUserform = props => {
  const [locationlist, setlocationData] = useState([]);
  const [formdata, setformdata] = useState({
    client_id: "",
    email: "",
    password: "",
    name: "",
    locations: "",
    menu: "",
    user_type: "",
    mystatus: "",
    id: ""
  });

  const [locationdata, setlocationdata] = useState([]);

  useEffect(() => {
    const getclinet = async () => {

        let axiosConfig = {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: localStorage.getItem("token")
          }
        };
        var postData = {
          client_id: props.data.paramid
        };
        axios
          .post(apiUrlstyle, postData, axiosConfig)
          .then(result => {
            setlocationData(result.data.location);
            //console.log(result.data.location);

            //setformdata({ ...formdata, ['locations']: result.data.location });
          })
          .catch(err => {
            //console.log("AXIOS ERROR: ", err);
          });
      }

      // setformdata({ ...formdata, ["client_id"]: event.target.value });

      // getlocation(event.target.id,event.target.value);


    setformdata(props.data);
    getclinet();
  }, [props.data]);


  if (locationlist >= 0 && formdata.id !== "") {
    const apiUrlgetlocation = API + "api/location_byname/";
    var locarray = formdata.locations.split(",");
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token")
      }
    };
    var postData = {
      l_name: locarray
    };

    axios
      .post(apiUrlgetlocation, postData, axiosConfig)
      .then(result => {

        setlocationData(result.data);
      })
      .catch(err => {
        //console.log("AXIOS ERROR: ", err);
      });
  }

  const apiUrlstyle = API + "api/location_by_client_id/";
  const handleChange1 = event => {
    let newVal = event.target.value;
    let stateVal = locationdata;
    stateVal.indexOf(newVal) === -1
      ? stateVal.push(newVal)
      : stateVal.length === 1
      ? (stateVal = [])
      : stateVal.splice(stateVal.indexOf(newVal), 1);
    setformdata({ ...formdata, ["locations"]: stateVal });
  };

  const handleChange = event => {
    setformdata({ ...formdata, [event.target.id]: event.target.value });
    if (event.target.id === "client_id") {
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token")
        }
      };
      var postData = {
        client_id: props.match.params.id
      };
      axios
        .post(apiUrlstyle, postData, axiosConfig)
        .then(result => {
          setlocationData(result.data.location);
        })
        .catch(err => {
          //console.log("AXIOS ERROR: ", err);
        });
    }
  };

  const back = () => {
    props.history.push({
      pathname: "/users"
    });
  };
  const validateForm = () => {
    if (formdata.id === "") {
      let data = {
        email: formdata.email,
        password: formdata.password,
        menu: formdata.menu,
        user_type: formdata.user_type,
        mystatus: formdata.mystatus,
        locations: formdata.locations,
        name: formdata.name
      };

      let rules = {
        email: "required|email",
        password: "required",
        menu: "required",
        user_type: "required",
        mystatus: "required",
        locations: "required",
        name: "required"
      };

      let validation = new Validator(data, rules);

      if (validation.passes()) {
        return true;
      }
    } else {
      let data = {
        email: formdata.email,
        menu: formdata.menu,
        user_type: formdata.user_type,
        mystatus: formdata.mystatus,
        locations: formdata.locations,
        name: formdata.name
      };

      let rules = {
        email: "required|email",
        menu: "required",
        user_type: "required",
        mystatus: "required",
        locations: "required",
        name: "required"
      };

      let validation = new Validator(data, rules);

      if (validation.passes()) {
        return true;
      }
    }
  };

  const formSubmit = async () => {
    var postData = {
      client_id: props.data.paramid,
      email: formdata.email,
      password: formdata.password,
      name: formdata.name,
      locations: formdata.locations,
      menu: formdata.menu,
      user_type: formdata.user_type,
      mystatus: formdata.mystatus
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token")
      }
    };
    const apiedit = API + "api/user_update";
    const apiadd = API + "api/user_add";
    if (props.data.id) {
      axios
        .post(apiedit, postData, axiosConfig)
        .then(res => {
          alert(res.data.message);
          back();
        })
        .catch(err => {
          //console.log("AXIOS ERROR: ", err);
        });
    } else {
      axios
        .post(apiadd, postData, axiosConfig)
        .then(res => {
          alert(res.data.message);
          back();
        })
        .catch(err => {
          //console.log("AXIOS ERROR: ", err);
        });
    }
  };

  return (
    <div>
      <Form encType="multipart/form-data">
        <div className="review">
          <div className="row">
            <div className="col-md-12">
              <div className="card border-top-0 border-right-0 border-left-0 pb-4">
                <div className="card-header bg-white ">
                  <div className="row">
                    <div className="col-md-12">
                      <h5 className="card-title mb-0 pt-2">User</h5>
                      <span>All * fields are mandatory </span>
                    </div>
                    <div className="col-md-12 text-right">
                      <input
                        type="button"
                        disabled={!validateForm()}
                        onClick={() => formSubmit()}
                        value="Save"
                        className="btn btn-success save-btn font-weight-bold"
                      />
                      <input
                        type="button"
                        onClick={() => back()}
                        value="Back"
                        className="margin btn btn-primary save-btn font-weight-bold"
                      />
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div id="introduction">
                    <div className="row">
                      <div className="form-group col-md-6 ">
                        <select
                          multiple={true}
                          value={formdata.locations}
                          onChange={handleChange1}
                          className="form-control"
                          id="locations"
                          name="locations"
                        >
                          <option value="">Please Select location</option>
                          {locationlist.map((item, idx) => (
                            <option key={idx} value={item.l_name}>
                              {item.l_name}
                            </option>
                          ))}
                        </select>

                        <label>Location *</label>
                      </div>
                      <div className="form-group col-md-6 ">
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          name="email"
                          placeholder=" Email"
                          value={formdata.email}
                          onChange={handleChange}
                        />
                        <label>Enter Email * </label>
                      </div>
                      <div className="form-group col-md-6 ">
                        <input
                          type="text"
                          name="name"
                          id="name"
                          className="form-control"
                          placeholder="Enter Name"
                          value={formdata.name}
                          onChange={handleChange}
                        />
                        <label>Name * </label>
                      </div>
                      <div className="form-group col-md-6 ">
                        <input
                          type="password"
                          name="password"
                          id="password"
                          className="form-control"
                          placeholder="Password"
                          value={formdata.password}
                          onChange={handleChange}
                        />
                        <label>Password {formdata.id ? "" : "*"}</label>
                      </div>

                      <div className="form-group col-md-6">
                        <select
                          className="form-control"
                          id="menu"
                          name="menu"
                          defaultValue={formdata.menu}
                          onChange={handleChange}
                        >
                          <option>Please Select Menu Access </option>
                          <option
                            selected={formdata.menu === "Full"}
                            value="Full"
                          >
                            Full
                          </option>
                          <option
                            selected={formdata.menu === "Limited"}
                            value="Limited"
                          >
                            Limited
                          </option>
                        </select>
                        <label>Menu Access* </label>
                      </div>

                      <div className="form-group col-md-6">
                        <select
                          className="form-control"
                          id="user_type"
                          name="user_type"
                          defaultValue={formdata.user_type}
                          onChange={handleChange}
                        >
                          <option>Please Select User Type </option>
                          <option
                            selected={formdata.user_type === "employee"}
                            value="employee"
                          >
                            Employee
                          </option>
                          <option
                            selected={formdata.user_type === "client"}
                            value="client"
                          >
                            Client
                          </option>
                        </select>
                        <label>User Type (For Reporting Access)* </label>
                      </div>

                      <div className="form-group col-md-6">
                        <select
                          className="form-control"
                          id="mystatus"
                          name="mystatus"
                          defaultValue={formdata.status}
                          onChange={handleChange}
                        >
                          <option>Please Select Status</option>
                          <option
                            selected={formdata.mystatus === "Enabled"}
                            value="Enabled"
                          >
                            Enabled
                          </option>
                          <option
                            selected={formdata.mystatus === "Disabled"}
                            value="Disabled"
                          >
                            Disabled
                          </option>
                        </select>
                        <label>Status*</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};
export default ClientUserform;
