import React, { useMemo, useState, useEffect, PureComponent } from "react";
import axios from "axios";
import { Button } from "react-bootstrap";
import { API } from "../../../../const";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
export default function Reports(props) {
  const [data, setdata] = useState([]);
  const apiUrlall = API + "api/allcnt";
  let client_id = props.match.params.id;
  let name = props.match.params.name;
  const [clicksdata, setClicksData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      var postData = {
        client_id: client_id,
        client_name: name,
      };
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      };
      axios.post(apiUrlall, postData, axiosConfig).then((result) => {
        setdata(result.data.array);
      });
    };

    fetchData();
  }, []);

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28"];

  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {data[index].value} {data[index].name}
      </text>
    );
  };

  const apiUrlclicks = API + "api/reportsclicks";
  useEffect(() => {
    const clickData = async () => {
      var postData = {
        client_id: client_id,
        client_name: name,
      };
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      };
      axios.post(apiUrlclicks, postData, axiosConfig).then((result) => {
        setClicksData(result.data.data);
      });
    };

    clickData();
  }, [apiUrlclicks, client_id]);

  const back = () => {
    props.history.push({
      pathname: "/clients",
    });
  };

  return (
    <>
      <div className="background-white">
        <Button
          variant="primary"
          className="margin pull-right btn btn-primary"
          onClick={() => {
            back();
          }}
        >
          {" "}
          Back
        </Button>
      </div>

      <div className="background-white">
        <div className="kt-space-20" />

        <div className="row">
          <div class="col-md-6">
            <div class="background-margin">
              <PieChart width={500} height={500}>
                <Pie
                  data={data}
                  cx={200}
                  cy={200}
                  labelLine={false}
                  label={renderCustomizedLabel}
                  outerRadius={200}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </div>
          </div>
          <div class="col-md-6">
            <BarChart
              width={600}
              height={300}
              data={clicksdata}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="label" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="Link_Sends" fill="#0088FE" />
              <Bar dataKey="Feedback" fill="#00C49F" />
              <Bar dataKey="Clickss" fill="#FFBB28" />
            </BarChart>
          </div>
        </div>
      </div>
    </>
  );
}
